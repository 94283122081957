<webrtc-player #webrtcPlayer
               [edgeId]="edgeId"
               [cameraId]="cameraId"
               [locationId]="locationId"
               [cameraName]="cameraName"
               [autostart]="true"
               [allowZoom]="true"
               [accessToken]="accessToken"
               [permissionCheck]="false"
               [isRespectRatio]="isRespectRatio"
               class="h-100"
               style="max-height: 100vh;"
               (clickTakeSnapshot)="webrtcPlayer.takeSnapshot(cameraName, location.name, now)"
               (clickFullScreen)="webrtcPlayer.maximize()"
>
</webrtc-player>
