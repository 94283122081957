import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { finalize, lastValueFrom, map, Observable, of, Subject, Subscription, switchMap, take, timestamp } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { KeyValuePairs } from '../core/interfaces';
import { PulsationModels } from '@models/pulsation.model';
import { EdgeHeartbeatPulsationActions } from '@states/edge-heartbeat-pulsation/edge-heartbeat-pulsation-types';
import { CameraHeartbeatPulsationActions } from '@states/camera-heartbeat-pulsation/camera-heartbeat-pulsation-types';
import { AnalyticHeartbeatPulsationActions } from '@states/analytic-heartbeat-pulsation/analytic-heartbeat-pulsation-types';
import { EdgeHeartbeatPulsationSelectors } from '@states/edge-heartbeat-pulsation/edge-heartbeat-pulsation.selector-types';
import { AnalyticHeartbeatPulsationSelectors } from '@states/analytic-heartbeat-pulsation/analytic-heartbeat-pulsation.selector-types';
import { CameraHeartbeatPulsationSelectors } from '@states/camera-heartbeat-pulsation/camera-heartbeat-pulsation.selector-types';
import { StorageHeartbeatPulsationActions } from '@states/storage-heartbeat-pulsation/storage-heartbeat-pulsation-types';
import { StorageHeartbeatPulsationSelectors } from '@states/storage-heartbeat-pulsation/storage-heartbeat-pulsation.selector-types';
import { AppState } from '../store/app.state';
import { SocketEvents } from '../socket/socket.model';
import { SmartStorageHeartbeatPulsationActions } from '@states/smart-storage-heartbeat-pulsation/smart-storage-heartbeat-pulsation-types';
import { SubstreamHeartbeatPulsationActions } from '@states/substream-heartbeat-pulsation/substream-heartbeat-pulsation-types';
import { api } from '@consts/url.const';
import { ProperFitting } from '../cameras/camera.model';
import { pulsationStatusConverter } from '@consts/pulsation.const';
import { HeartbeatService } from '../development/heartbeat.service';
import { SmartStorageHeartbeatPulsationSelectors } from '@states/smart-storage-heartbeat-pulsation/smart-storage-heartbeat-pulsation.selector-types';
import { SubstreamHeartbeatPulsationSelectors } from '@states/substream-heartbeat-pulsation/substream-heartbeat-pulsation.selector-types';
import { ThumbnailsActions } from '@states/thumbnails/thumbnails.action-types';
import { catchError } from 'rxjs/operators';
import { DeviceStatusActions } from '@states/device-status/device-status.actions-types';
import { SocketMainService } from '../socket/socket-main.service';
import { Dictionary } from '@ngrx/entity/src/models';
import { CameraSettingsModel } from '@models/camera-settings.model';
import Shortcut = CameraSettingsModel.Shortcut;

@Injectable({
  providedIn: 'root',
})
export class EdgeStatusService implements OnDestroy {
  private edgeStatuses$: Observable<any>;
  private stopPolling = new Subject();

  subscriptions: KeyValuePairs<Subscription> = {};

  constructor(
    private heartbeatService: HeartbeatService,
    private store$: Store<AppState>,
    private http: HttpClient,
    private socketMainService: SocketMainService) {
  }

  getPulsationFromSinglestore(edgeId: string, rebootCounter?: number, fetchInProgress = false) {
    this.store$.dispatch(EdgeHeartbeatPulsationActions.setLoading({ loading: true }));
    return this.getPulsationFromSinglestoreV2(edgeId, rebootCounter)
      .pipe(
        finalize(() => {
          // this.store$.dispatch(DeviceStatusActions.calculateLocationStatus());
          // console.log('getPulsationFromSinglestore FINISHED');
        }),
        catchError((err) => {
          console.error(`getEdgePulsationsStatus error`, err);
          this.store$.dispatch(EdgeHeartbeatPulsationActions.setLoading({ loading: false }));
          return of(null);
        }),
      )
      .subscribe(
        response => {
          const result: PulsationModels.PulsationResponse = response;
          if (result && result?.res) {
            for(let msg of result.res) {
              this.savePulsationStatus(msg, fetchInProgress);
            }
          }
          this.store$.dispatch(EdgeHeartbeatPulsationActions.setLoading({ loading: false }));
          //todo start calculate location statuses

        },
      );
  }


  getPulsationFromSinglestoreV2(edgeId: string, rebootCounter?: number, fetchInProgress = false) {
    return this.heartbeatService
      .getEdgePulsationsStatus(edgeId, rebootCounter)
      .pipe(
        catchError((err) => {
          console.error(`getEdgePulsationsStatus error`, err);
          this.store$.dispatch(EdgeHeartbeatPulsationActions.setLoading({ loading: false }));
          return of(null);
        }),
      );
  }

  setCamerasUnhealthy(edgeId: string) {
    this.store$.select(CameraHeartbeatPulsationSelectors.selectCameraStatusByEdgeId(edgeId))
      .pipe(take(1))
      .subscribe((cameraStatus) => {
        const update = cameraStatus.map((camStatus) => {
          return {
            ...camStatus,
            status: PulsationModels.ComponentStatus.NotConnected,
          };
        });
        this.store$.dispatch(CameraHeartbeatPulsationActions.GetCameraPulsationStatusSuccess({
          payload: update,
        }));
        this.store$.dispatch(AnalyticHeartbeatPulsationActions.GetAnalyticPulsationStatusSuccess({
          payload: update,
        }));
        this.store$.dispatch(StorageHeartbeatPulsationActions.GetStoragePulsationStatusSuccess({
          payload: update,
        }));
        this.store$.dispatch(SmartStorageHeartbeatPulsationActions.GetSmartStoragePulsationStatusSuccess({
          payload: update,
        }));
        this.store$.dispatch(SubstreamHeartbeatPulsationActions.GetSubstreamPulsationStatusSuccess({
          payload: update,
        }));
      });
  }

  async rebootCounterChanged(component: PulsationModels.StreamerComponentAsNumber, msg: PulsationModels.ComponentStatusMajorChangeMessage) {
    switch (component) {
      case PulsationModels.StreamerComponentAsNumber.Edge:
        const edgeStatus = await lastValueFrom(this.store$.pipe(select(EdgeHeartbeatPulsationSelectors.selectEdgeStatusByEdgeId(msg.edgeId)))
          .pipe(take(1)));
        if (!!edgeStatus ? msg.rebootCounter !== edgeStatus?.rebootCounter : false) {
          return true;
        }
        break;
      case PulsationModels.StreamerComponentAsNumber.Input:
        const cameraStatus = await lastValueFrom(this.store$.pipe(select(CameraHeartbeatPulsationSelectors.selectCameraStatusByCameraId(msg.cameraId)))
          .pipe(take(1)));
        if (!!cameraStatus ? msg.rebootCounter !== cameraStatus?.rebootCounter : false) {
          return true;
        }
        break;
      case PulsationModels.StreamerComponentAsNumber.Analytics:
        const analyticStatus = await lastValueFrom(this.store$.pipe(select(AnalyticHeartbeatPulsationSelectors.selectAnalyticStatusByCameraId(msg.cameraId)))
          .pipe(take(1)));
        if (!!analyticStatus ? msg.rebootCounter !== analyticStatus?.rebootCounter : false) {
          return true;
        }
        break;
      case PulsationModels.StreamerComponentAsNumber.SmartStorage:
        const smartStorageStatus = await lastValueFrom(this.store$.pipe(select(SmartStorageHeartbeatPulsationSelectors.selectSmartStoragePulsationByCameraId(msg.cameraId)))
          .pipe(take(1)));
        if (!!smartStorageStatus ? msg.rebootCounter !== smartStorageStatus?.rebootCounter : false) {
          return true;
        }
        break;
      case PulsationModels.StreamerComponentAsNumber.Storage:
        const storageStatus = await lastValueFrom(this.store$.pipe(select(StorageHeartbeatPulsationSelectors.selectStorageStatusByCameraId(msg.cameraId)))
          .pipe(take(1)));
        if (!!storageStatus ? msg.rebootCounter !== storageStatus?.rebootCounter : false) {
          return true;
        }
        break;
      case PulsationModels.StreamerComponentAsNumber.SubStream1:
        const substreamStatus = await lastValueFrom(this.store$.pipe(select(SubstreamHeartbeatPulsationSelectors.selectSubstreamStatusPulsationByCameraId(msg.cameraId)))
          .pipe(take(1)));
        if (!!substreamStatus ? msg.rebootCounter !== substreamStatus?.rebootCounter : false) {
          return true;
        }
        break;
    }
    return false;
  }


  private groupMessagesByComponent(
    msgs: PulsationModels.ComponentStatusMajorChangeMessage[],
    fetchInProgress: boolean,
  ): PulsationModels.GroupedPulsationMessages {
    // Initialize an empty grouping object
    const grouped: PulsationModels.GroupedPulsationMessages = {};

    // Loop over each message
    for(const msg of msgs) {
      const component = msg.component;

      // --- 1) Apply per-message logic here ---
      switch (component) {
        case PulsationModels.StreamerComponentAsNumber.Dockers:
          // If dockers are stopped or not connected => set cameras unhealthy
          if (
            msg.status === PulsationModels.ComponentStatusAsNumber.Stopped ||
            msg.status === PulsationModels.ComponentStatusAsNumber.NotConnected
          ) {
            this.setCamerasUnhealthy(msg.edgeId);
          }
          break;

        case PulsationModels.StreamerComponentAsNumber.Edge:
          // If Edge is online => do something if !fetchInProgress
          if (
            msg.status === PulsationModels.ComponentStatusAsNumber.Online &&
            !fetchInProgress
          ) {
            // ...
          }
          // If Edge is not connected => set cameras unhealthy
          if (
            msg.status === PulsationModels.ComponentStatusAsNumber.NotConnected
          ) {
            this.setCamerasUnhealthy(msg.edgeId);
          }
          break;

      }

      if (!grouped[component]) {
        grouped[component] = [];
      }
      grouped[component].push(msg);
    }

    // Return the grouped result
    return grouped;
  }

  async savePulsationStatusesBatch(
    msgs: PulsationModels.ComponentStatusMajorChangeMessage[],
    fetchInProgress = false,
  ) {
    const grouped = this.groupMessagesByComponent(msgs, fetchInProgress);

    const componentKeys = Object.keys(grouped); // e.g. ['1', '2', '3', ...]

    for(const compStr of componentKeys) {
      const component = parseInt(compStr, 10) as PulsationModels.StreamerComponentAsNumber;
      const messagesForThisComponent = grouped[component];
      if (!messagesForThisComponent || !messagesForThisComponent.length) {
        continue;
      }

      // 3. Build payload array for the store action
      //    - Convert each message to the shape needed by your store
      const payload = messagesForThisComponent.map((msg) => ({
        edgeId: msg.edgeId,
        cameraId: msg.cameraId,
        status: pulsationStatusConverter[msg.status], // presumably you have this converter
        timestamp: msg.timestamp,
        rebootCounter: msg.rebootCounter,
      }));

      // 4. Dispatch exactly one action per component with the array payload
      switch (component) {
        case PulsationModels.StreamerComponentAsNumber.Edge:
          // e.g. Edge now supports an array payload in GetEdgePulsationStatusSuccess
          this.store$.dispatch(
            EdgeHeartbeatPulsationActions.GetEdgePulsationStatusSuccess({ payload }),
          );
          break;
        case PulsationModels.StreamerComponentAsNumber.Input:
          this.store$.dispatch(
            CameraHeartbeatPulsationActions.GetCameraPulsationStatusSuccess({ payload }),
          );
          break;

        case PulsationModels.StreamerComponentAsNumber.Analytics:
          this.store$.dispatch(
            AnalyticHeartbeatPulsationActions.GetAnalyticPulsationStatusSuccess({ payload }),
          );
          break;

        case PulsationModels.StreamerComponentAsNumber.Storage:
          this.store$.dispatch(
            StorageHeartbeatPulsationActions.GetStoragePulsationStatusSuccess({ payload }),
          );
          break;

        case PulsationModels.StreamerComponentAsNumber.SmartStorage:
          this.store$.dispatch(
            SmartStorageHeartbeatPulsationActions.GetSmartStoragePulsationStatusSuccess({ payload }),
          );
          break;

        case PulsationModels.StreamerComponentAsNumber.SubStream1:
          this.store$.dispatch(
            SubstreamHeartbeatPulsationActions.GetSubstreamPulsationStatusSuccess({ payload }),
          );
          break;
        case PulsationModels.StreamerComponentAsNumber.Dockers:

          break;

        default:
          console.log(
            `edge status service, unknown component in batch save`,
            component,
            messagesForThisComponent,
          );
          break;
      }
    }
    this.store$.dispatch(EdgeHeartbeatPulsationActions.setLoading({ loading: false }));
  }


  async savePulsationStatus(msg: PulsationModels.ComponentStatusMajorChangeMessage, fetchInProgress = false) {
    const component = msg.component;
    switch (component) {
      case PulsationModels.StreamerComponentAsNumber.Dockers:
        // In case of dockers status change to stopped (not connected), set all cameras to unhealthy
        if (msg.status === PulsationModels.ComponentStatusAsNumber.Stopped || msg.status === PulsationModels.ComponentStatusAsNumber.NotConnected) {
          this.setCamerasUnhealthy(msg.edgeId);
        }

        if (msg.status === PulsationModels.ComponentStatusAsNumber.Online && !fetchInProgress
        ) {
          // this.getPulsationFromSinglestore(msg.edgeId, undefined, true);
          // this.store$.dispatch(ThumbnailsActions.resetThumbnailsCache());
        }
        break;
      case PulsationModels.StreamerComponentAsNumber.Edge:
        // In case of major edge status change to online, get pulsation from singlestore
        if (msg.status === PulsationModels.ComponentStatusAsNumber.Online && !fetchInProgress
        ) {
          // this.getPulsationFromSinglestore(msg.edgeId, msg.rebootCounter, true);
          // this.store$.dispatch(ThumbnailsActions.resetThumbnailsCache());
        }
        // In case of edge status change to unhealthy (not connected), set all cameras to unhealthy
        if (msg.status === PulsationModels.ComponentStatusAsNumber.NotConnected) {
          this.setCamerasUnhealthy(msg.edgeId);
        }
        this.store$.dispatch(EdgeHeartbeatPulsationActions.GetEdgePulsationStatusSuccess({
          payload: [{
            edgeId: msg.edgeId,
            status: pulsationStatusConverter[msg.status],
            timestamp: msg.timestamp,
            rebootCounter: msg.rebootCounter,
          }],
        }));

        break;
      case PulsationModels.StreamerComponentAsNumber.Input:
        const data = {
          payload: [{
            edgeId: msg.edgeId,
            cameraId: msg.cameraId,
            status: pulsationStatusConverter[msg.status],
            timestamp: msg.timestamp,
            rebootCounter: msg.rebootCounter,
          }],
        };
        this.store$.dispatch(CameraHeartbeatPulsationActions.GetCameraPulsationStatusSuccess(data));
        break;
      case PulsationModels.StreamerComponentAsNumber.Analytics:
        this.store$.dispatch(AnalyticHeartbeatPulsationActions.GetAnalyticPulsationStatusSuccess({
          payload: [{
            edgeId: msg.edgeId,
            cameraId: msg.cameraId,
            status: pulsationStatusConverter[msg.status],
            timestamp: msg.timestamp,
            rebootCounter: msg.rebootCounter,
          }],
        }));
        break;
      case PulsationModels.StreamerComponentAsNumber.Storage:
        this.store$.dispatch(StorageHeartbeatPulsationActions.GetStoragePulsationStatusSuccess({
          payload: [{
            edgeId: msg.edgeId,
            cameraId: msg.cameraId,
            status: pulsationStatusConverter[msg.status],
            timestamp: msg.timestamp,
            rebootCounter: msg.rebootCounter,
          }],
        }));
        break;
      case PulsationModels.StreamerComponentAsNumber.SmartStorage:
        this.store$.dispatch(SmartStorageHeartbeatPulsationActions.GetSmartStoragePulsationStatusSuccess({
          payload: [{
            edgeId: msg.edgeId,
            cameraId: msg.cameraId,
            status: pulsationStatusConverter[msg.status],
            timestamp: msg.timestamp,
            rebootCounter: msg.rebootCounter,
          }],
        }));
        break;
      case PulsationModels.StreamerComponentAsNumber.SubStream1:
        this.store$.dispatch(SubstreamHeartbeatPulsationActions.GetSubstreamPulsationStatusSuccess({
          payload: [{
            edgeId: msg.edgeId,
            cameraId: msg.cameraId,
            status: pulsationStatusConverter[msg.status],
            timestamp: msg.timestamp,
            rebootCounter: msg.rebootCounter,
          }],
        }));
        break;
      default:
        console.log(`edge status service, edgeIncomingPulsation, unknown component`, msg);
    }
  }

  subscribeToEdgeStatusSocket(): void {

    const snapshot$ = this.socketMainService
      .consume<PulsationModels.ComponentStatusMajorChangeMessage>(SocketEvents.edgeIncomingPulsation)
      .pipe(
        map(data => {
          const msg: PulsationModels.ComponentStatusMajorChangeMessage = data;
          this.savePulsationStatus(msg);
        }),
      )
    ;


    snapshot$.subscribe(() => {
      this.store$.dispatch(DeviceStatusActions.calculateTrigger());
    });
  }

  getCameraPulsationStatus(cameraId: string): Observable<PulsationModels.ComponentStatus> {
    if (!cameraId) {
      return of(PulsationModels.ComponentStatus.Offline);
    }
    return this.store$.pipe(
      select(CameraHeartbeatPulsationSelectors.selectCameraStatusByCameraId(cameraId)),
      switchMap(((res: PulsationModels.Store.CameraPulsation | undefined) => {
          const edgeId = res?.edgeId;
          return this.getEdgePulsationStatus(edgeId)
            .pipe(
              map((edgeStatus) => {

                if (edgeStatus !== PulsationModels.ComponentStatus.Online) {
                  return PulsationModels.ComponentStatus.Offline;
                }

                if (!res || !res?.timestamp) {
                  return PulsationModels.ComponentStatus.Offline;
                }

                if (!res?.status) {
                  return PulsationModels.ComponentStatus.Unknown;
                }

                return res.status ?? PulsationModels.ComponentStatus.Offline;
              }),
            );
        }),
      ),
    );
  }

  getAnalyticPulsationStatus(cameraId: string): Observable<PulsationModels.ComponentStatus> {
    if (!cameraId) {
      return of(PulsationModels.ComponentStatus.Offline);
    }
    return this.store$.pipe(
      select(AnalyticHeartbeatPulsationSelectors.selectAnalyticStatusByCameraId(cameraId)),
      switchMap(((res: PulsationModels.Store.AnalyticPulsation | undefined) => {
          const edgeId = res?.edgeId;
          return this.getEdgePulsationStatus(edgeId)
            .pipe(
              map((edgeStatus) => {

                if (edgeStatus !== PulsationModels.ComponentStatus.Online) {
                  return PulsationModels.ComponentStatus.Offline;
                }

                if (!res || !res?.timestamp) {
                  return PulsationModels.ComponentStatus.Offline;
                }

                if (!res?.status) {
                  return PulsationModels.ComponentStatus.Unknown;
                }

                return res?.status;
              }),
            );
        }),
      ),
    );
  }

  getStoragePulsationStatus(cameraId: string): Observable<PulsationModels.ComponentStatus> {
    if (!cameraId) {
      return of(PulsationModels.ComponentStatus.Offline);
    }
    return this.store$.pipe(
      select(StorageHeartbeatPulsationSelectors.selectStorageStatusByCameraId(cameraId)),
      timestamp(),
      switchMap(((res: { timestamp: number; value: PulsationModels.Store.StoragePulsation | undefined }) => {
          const edgeId = res?.value?.edgeId;
          return this.getEdgePulsationStatus(edgeId)
            .pipe(
              map((edgeStatus) => {

                if (edgeStatus !== PulsationModels.ComponentStatus.Online) {
                  return PulsationModels.ComponentStatus.Offline;
                }

                if (!res || !res?.timestamp) {
                  return PulsationModels.ComponentStatus.Offline;
                }

                if (!res?.value || !res?.value?.status) {
                  return PulsationModels.ComponentStatus.Offline;
                }

                if (res?.timestamp - Number(res?.value.timestamp) > 480000) {
                  return PulsationModels.ComponentStatus.Offline;
                }

                return res?.value?.status;
              }),
            );
        }),
      ),
    );
  }

  getEdgePulsationStatus(edgeId: string): Observable<PulsationModels.ComponentStatus> {

    if (!edgeId) {
      return of(PulsationModels.ComponentStatus.Offline);
    }

    return this.store$.pipe(
      select(EdgeHeartbeatPulsationSelectors.selectEdgeStatusByEdgeId(edgeId)),
      map((res: PulsationModels.Store.EdgePulsation | undefined) => {
        if (!res || !res?.timestamp) {
          return PulsationModels.ComponentStatus.Offline;
        }

        if (!res?.status) {
          return PulsationModels.ComponentStatus.Unknown;
        }

        return res.status;

      }),
    );
  }

  cameraStreamAllowed(cameraId: string): Observable<boolean> {
    return this.getCameraPulsationStatus(cameraId)
      .pipe(
        map(status => status === PulsationModels.ComponentStatus.Streaming || status === PulsationModels.ComponentStatus.Online),
      );
  }

  cameraOperationsAllowed(cameraId: string): Observable<boolean> {
    return this.getCameraPulsationStatus(cameraId)
      .pipe(
        map(
          res =>
            (res !== PulsationModels.ComponentStatus.Online && !res) ||
            (res !== PulsationModels.ComponentStatus.Offline && res !== PulsationModels.ComponentStatus.Stopped && res !== PulsationModels.ComponentStatus.Init),
        ),
      );
  }

  edgeOperationsAllowed(edgeId: string): Observable<boolean> {
    return this.getEdgePulsationStatus(edgeId)
      .pipe(
        map(
          res =>
            (res !== PulsationModels.ComponentStatus.Online && !res) ||
            (res !== PulsationModels.ComponentStatus.Offline &&
              res !== PulsationModels.ComponentStatus.Init &&
              res !== PulsationModels.ComponentStatus.Stopped),
        ),
      );
  }

  public getProperFitting(): Observable<ProperFitting[]> {
    return this.http.get<ProperFitting[]>(api.stats.properFitting);
  }

  ngOnDestroy(): void {
    this.stopPolling.next({});
    Object.values(this.subscriptions)
      .forEach(subscription => subscription.unsubscribe());
  }

  public updateCameraCoords(locationId: string, edgeId: string, cameraId: string, lat: number, lng: number, address: string): Observable<boolean> {
    return this.http.post<boolean>(api.location.updateCameraCoords(locationId, edgeId, cameraId), {
      lat, lng, address,
    });
  }

  public updateCameraShortcuts(locationId: string, edgeId: string, cameraId: string, shortcuts: Dictionary<Shortcut>): Observable<CameraSettingsModel.CameraSettingMongoDocument> {
    return this.http.post<CameraSettingsModel.CameraSettingMongoDocument>(api.location.updateCameraShortcuts(locationId, edgeId, cameraId), {
      shortcuts,
    });
  }
}
