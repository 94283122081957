import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AsyncCallModels } from '@models/async-call.models';
import { Observable } from 'rxjs';
import { LocationModel } from '../locations/location.model';
import { api } from '@consts/url.const';
import { Edge } from '../edge/edge.model';
import { EdgeConfigFileType } from '@models/edge.model';

@Injectable({ providedIn: 'root' })
export class EdgeServiceV2 {
  constructor(private http: HttpClient) {
  }

  public deleteEdge(locationId: string, edgeId: string, force = false): Observable<AsyncCallModels.AsyncCallResponse<LocationModel.LocationItem>> {
    const url = `${api.edge.delete(locationId, edgeId)}?force=${force}`;
    return this.http.delete<AsyncCallModels.AsyncCallResponse<any>>(url);
  }

  public addEdgeToLocationAsync(addEdgeToLocationRequest: LocationModel.AddEdgeToLocationRequest): Observable<AsyncCallModels.AsyncCallResponse<Edge.V2.EdgeCreateResponse>> {
    return this.http.post<AsyncCallModels.AsyncCallResponse<any>>(api.edge.add, addEdgeToLocationRequest);
  }

  public updateEdgeInLocationASync(updateEdgeInLocationRequest: LocationModel.UpdateEdgeInLocationRequest): Observable<AsyncCallModels.AsyncCallResponse<LocationModel.LocationItem>> {
    return this.http.post<AsyncCallModels.AsyncCallResponse<any>>(api.edge.update, updateEdgeInLocationRequest);
  }

  public getEdgeConfig(locationId: string, edgeId: string, configFileType?: EdgeConfigFileType): Observable<{ config: any } & AsyncCallModels.AsyncCallResponse<LocationModel.LocationItem>> {
    return this.http.post<{ config: any } & AsyncCallModels.AsyncCallResponse<LocationModel.LocationItem>>(api.location.getEdgeConfig, {
      locationId,
      edgeId,
      configFileType,
    });
  }

  public getEdgeLogs(dto: {
    locationId: string;
    edgeId: string;
    daysBack: number;
  }): Observable<any> {
    return this.http.post<any>(api.location.getEdgeLogs, dto);
  }
}
