import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AlertEventsActions from '@states/alert-events/alert-events.actions';
import { catchError, debounceTime, exhaustMap, of, share, switchMap } from 'rxjs';
import { Action, select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import * as SharedActions from '@states/shared/shared.actions';
import { AlertEventsService } from '../../development/alert-events.service';
import { CameraActions } from '@states/camera/camera.action-types';
import { AlertV2Document, EventModels } from '@models/alerts-v2.model';
import { HttpErrorResponse } from '@angular/common/http';
import { EventSyncStatusWorkerService } from '../../services/event-sync-status.worker.service';
import { UtilsService } from '../../edge/utils.service';

@Injectable()
export class AlertEventsEffect {

  public startRemoveEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertEventsActions.startRemoveEvent),
      mergeMap(({ id }) => [
        AlertEventsActions.removeEvent({ id }),
        AlertEventsActions.setRemovingEventId({ id }),
      ]),
      share(),
    ),
  );

  public removeAlertEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertEventsActions.removeEvent),
      mergeMap(({ id }) => {
        return this.alertEventsService
          .remove(id)
          .pipe(
            mergeMap(res => {
              return [
                SharedActions.showMessageFromSocket({
                  level: res.messageLevel,
                  msg: res.message,
                }),
                AlertEventsActions.removeEventSuccess({ id: id }),
                AlertEventsActions.unSetRemovingEventId({ id: id }),
              ];
            }),
            catchError((response: HttpErrorResponse) => {
              return [
                AlertEventsActions.unSetRemovingEventId({ id }),
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public enableAlertEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertEventsActions.enableEvent),
      mergeMap(({ alert, enabled }) => {
        const operation = enabled ? this.alertEventsService.enable(alert._id) : this.alertEventsService.disable(alert._id);
        return operation
          .pipe(
            mergeMap(res => {
              return [
                AlertEventsActions.updateEventSuccess({ document: res.document }),
                SharedActions.showMessageFromSocket({
                  level: res.messageLevel,
                  msg: res.message,
                }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getAlertEventsLookup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CameraActions.getCameraEventsLookup),
      switchMap(() => {
        return this.alertEventsService.getAllNoPaging()
          .pipe(
            switchMap(res => {
              const lookup: {
                [key: string]: {
                  [alertId: string]: AlertV2Document
                }
              } = {};
              res.forEach(item => {
                if (item?.selectedFlow?.formValue?.camera?.length) {
                  for(let camera of item.selectedFlow.formValue.camera) {
                    const key = camera?.cameraId;
                    if (key) {
                      if (lookup[key]) {
                        lookup[key] = {
                          ...lookup[key],
                          [item._id]: item,
                        };
                      } else {
                        lookup[key] = {
                          [item._id]: item,
                        };
                      }
                    }
                  }
                }
              });
              return [
                CameraActions.getCameraEventsLookupSuccess({
                  cameraEvents: lookup,
                }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getEventSyncStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertEventsActions.getEventSyncStatus),
      mergeMap(({ id }) => {
          return this.eventSyncStatusWorkerService.fetchData(id)
            .pipe(
              mergeMap((res) => {
                return [
                  AlertEventsActions.getEventSyncStatusSuccess({ id, data: res }),
                ];
              }),
              catchError((res: HttpErrorResponse) => {
                const actions: Action[] = [
                  AlertEventsActions.getEventSyncStatusFail(),
                ];
                return actions;
              }),
            );
        },
      ),
      share(),
    ));

  public resetEventSyncStatusTriggers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertEventsActions.enableEvent),
      exhaustMap(({ alert }) => [
        AlertEventsActions.resetEventSyncStatus({ id: alert._id }),
      ]),
    ),
  );

  public getAlertEventsV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertEventsActions.getAlertEventsV3),
      withLatestFrom(this.store$.pipe(select(state => state.alertEventsState))),
      mergeMap(([{ filters }, { perPage, pagination }]) => {
        return this.alertEventsService.getAlertEventsV3(perPage, filters, pagination)
          .pipe(
            mergeMap(result => {
              return [
                AlertEventsActions.setListLoader({ listLoader: false }),
                AlertEventsActions.getAlertEventsV3Success({
                  documents: result,
                }),
              ];
            }),
            catchError(error => {
              return [
                AlertEventsActions.setListLoader({ listLoader: false }),
                SharedActions.showMessage({ error: this.utilsService.errMessage(error) }),
                AlertEventsActions.getAlertEventsV3Fail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public loadNextPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertEventsActions.loadNextPage),
      withLatestFrom(this.store$.pipe(select(state => state.alertEventsState))),
      exhaustMap(([{ query }, { entities }]) => {
        const records = Object.values(entities);
        const length = records.length;
        let record: EventModels.EventDocument = records[length - 1];
        const lastId = record._id;
        const lastValueInOrderedField = record._id;
        return [
          AlertEventsActions.setPagination({ lastId, lastOrderedValue: lastValueInOrderedField }),
          AlertEventsActions.getAlertEventsV3({ filters: query }),
        ];
      }),
    ),
  );

  public onQueryParamsChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertEventsActions.onQueryParamsChanged),
      switchMap(({ query }) => {
          return [
            AlertEventsActions.setListLoader({ listLoader: true }),
            AlertEventsActions.resetPagination(),
            AlertEventsActions.getAlertEventsV3({ filters: query }),
          ];
        },
      ),
    ),
  );


  constructor(private actions$: Actions,
              private store$: Store<AppState>,
              private alertEventsService: AlertEventsService,
              private eventSyncStatusWorkerService: EventSyncStatusWorkerService,
              private utilsService: UtilsService) {
  }
}

