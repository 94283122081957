import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AsyncCallModels } from '@models/async-call.models';
import { api } from '@consts/url.const';
import { VpnConfiguration } from '@models/search-configuration.model';

@Injectable({ providedIn: 'root' })
export class CameraApiService {

  constructor(private http: HttpClient) {
  }

  public getDetails(locationId: string, edgeId: string, cameraId: string): Observable<AsyncCallModels.AsyncCallResponse<any> & { vpnConfiguration: VpnConfiguration }> {
    return this.http.get<AsyncCallModels.AsyncCallResponse<any> & { vpnConfiguration: VpnConfiguration }>(api.cameraApi.getDetails(locationId, edgeId, cameraId));
  }

  public saveDetails(params: {
    locationId: string, edgeId: string, cameraId: string
  }, vpnConfiguration?: VpnConfiguration, autoConfigure?: boolean): Observable<AsyncCallModels.AsyncCallResponse<any> & { vpnConfiguration: VpnConfiguration }> {
    return this.http.put<AsyncCallModels.AsyncCallResponse<any> & { vpnConfiguration: VpnConfiguration }>(api.cameraApi.saveDetails(params.locationId, params.edgeId, params.cameraId), {
      vpnConfiguration,
      autoConfigure,
    });
  }
}
