import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import * as ArchiveAction from '@states/archive/archive.actions';
import { catchError, debounceTime, exhaustMap, mergeMap, of, share, switchMap, withLatestFrom } from 'rxjs';
import * as SharedActions from '@states/shared/shared.actions';
import { ArchiveStorageType, Playback } from '../../cameras/playback.model';
import * as moment from 'moment-timezone';
import { LocationSelectors } from '@states/location/location.selector-types';
import { ArchiveService } from '../../development/archive.service';
import { OrganizationService } from '../../development/organization.service';
import { ArchiveModel, ArchiveStatus, ArchiveVideoFormat } from '@models/archive.model';
import { TIMELINE_MAX_WINDOW } from '../../shared/playback-player/playback-timeline/playback-timeline.model';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { getArchiveCloudPreviewUri, trimMp4, validateEmail, validatePhone } from '../../helpers/common.helpers';
import { GrantedAccessType } from '@enums/granted-access.enum';
import { UtilsV2Service } from '../../services/utils-v2.service';

@Injectable()
export class ArchiveEffect {
  public createArchive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveAction.createArchive),
      withLatestFrom(
        this.store$.pipe(select(state => state.archiveState)),
        this.store$.pipe(select(LocationSelectors.selectLocationLookup)),
        this.store$.pipe(select(state => state.grantedAccessState)),
      ),
      switchMap(([, { selectedArchive }, locationLookup, { selectedGrantedAccess }]) => {
        const requests = this.prepareDownloadPlaybackRequestArchiveModel(selectedArchive, locationLookup);
        if (!requests?.length) {
          return [SharedActions.showMessage({ error: 'Clip should be 10 minutes long at most' })];
        }
        const result: Action[] = [];
        requests.forEach(req => {
          const action = ArchiveAction.sendArchiveToServer({
            archive: req,
            grantedAccess: {
              ...selectedGrantedAccess,
              type: GrantedAccessType.ARCHIVE,
              emails: selectedGrantedAccess.emails.filter(item => validateEmail(item)),
              phones: selectedGrantedAccess.emails.filter(item => validatePhone(item)),
            },
          });
          result.push(action);
        });
        return result;
      }),
      catchError(err => [SharedActions.showMessage({ error: err }), SharedActions.setIsSaving({ isSaving: false })]),
      share(),
    ),
  );

  public validateArchive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveAction.changeArchiveProperty),
      withLatestFrom(this.store$.pipe(select(state => state.archiveState))),
      switchMap(([, { selectedArchive }]) => {
        const duration = moment.duration(moment(selectedArchive.end)
          .diff(selectedArchive.start));
        const minutes = duration.asMinutes();
        if (selectedArchive.name && selectedArchive.selectedCamera && selectedArchive.start && selectedArchive.end && minutes <= 10) {
          return [ArchiveAction.setValid({ isArchiveValid: true })];
        } else {
          return [ArchiveAction.setValid({ isArchiveValid: false })];
        }
      }),
    ),
  );


  public startGetArchiveList = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveAction.startGetArchiveList),
      switchMap(() => [
        ArchiveAction.setIsLoading({ isLoading: true }),
        ArchiveAction.getArchiveList(),
      ]),
    ),
  );


  public getArchiveList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveAction.getArchiveList),
      withLatestFrom(this.store$.pipe(select(state => state.archiveState))),
      switchMap(([, { perPage, page, filters, isLastPage }]) => {
        if (!isLastPage) {
          const dateRange = this.utilsV2Service.dateRangeToServerRequest(filters.dateRange);
          return this.archiveService
            .getAll(page, perPage, {
              selectedCameras: Object.values(filters?.selectedCameras ?? {}),
              start: dateRange?.start,
              end: dateRange?.end,
              tags: filters.tags,
              query: filters.query,
              status: filters.status,
            })
            .pipe(
              switchMap(res => {
                return [
                  ArchiveAction.getArchiveListSuccess({ archives: res.items, totalItemsCount: res.totalItemsCount }),
                  ArchiveAction.setIsLoading({ isLoading: false }),
                ];

              }),
              share(),
            );
        } else {
          return of(ArchiveAction.setIsLoading({ isLoading: false }));
        }
      }),
    ),
  );

  public getArchiveListSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveAction.getArchiveListSuccess),
      mergeMap(({ archives }) => {
        const actions: Action[] = [];
        const filteredArchives = archives.filter(item => {
          const diff = moment()
            .diff(moment(item.createdAt));
          const duration = moment.duration(diff);
          return item.status === ArchiveStatus.UPLOADING && duration.asMinutes() > 15;
        });

        filteredArchives.forEach(archive => {
          const tmpArchive = {
            ...archive,
            status: ArchiveStatus.ERROR,
          };
          actions.push(
            ArchiveAction.updateArchive({
              archive: tmpArchive,
            }),
          );
          actions.push(ArchiveAction.updateArchiveProgress({ archive: tmpArchive }));
        });

        return actions;
      }),
    ),
  );
  public setFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveAction.setFilter, ArchiveAction.rmFilter),
      debounceTime(400),
      exhaustMap(() => [ArchiveAction.resetArchives(), ArchiveAction.startGetArchiveList()]),
    ),
  );

  public getUserOrganizationsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveAction.getOrgUsersAutocomplete),
      switchMap(() =>
        this.organizationService.getActiveOrganizationUsers()
          .pipe(
            switchMap(users => {
              return of(ArchiveAction.setUserAutocomplete({ users }));
            }),
          ),
      ),
    ),
  );

  public deleteArchive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveAction.deleteArchive),
      withLatestFrom(
        this.store$.pipe(select(state => state.archiveState)),
      ),
      switchMap(([{ sessionId }, { entities }]) => {
          const deletedArchive = Object.values(entities)
            .find(archive => archive.sessionId === sessionId);
          return this.archiveService.delete(sessionId)
            .pipe(
              switchMap(res => {
                return [
                  SharedActions.showMessage({ success: 'Archive has been deleted' }),
                  ArchiveAction.deleteArchiveSuccess({ sessionId: deletedArchive.sessionId }),
                ];
              }),
            );
        },
      ),
      catchError(err => [SharedActions.showMessage({ error: err })]),
    ),
  );

  public downloadArchive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveAction.downloadArchive),
      mergeMap(({ archive, sharedToken }) => {
          if (archive.videoFormat === ArchiveVideoFormat.HLS) {
            return this.archiveService.downloadHls(archive.sessionId, sharedToken)
              .pipe(
                mergeMap(res => {
                  const url = window.URL.createObjectURL(res);
                  return [
                    ArchiveAction.downloadArchiveSuccess({ url, name: archive.name }),
                    ArchiveAction.downloadArchiveFinished({ archive }),
                  ];
                }),
                catchError(err => [
                    SharedActions.showMessage({ error: err }),
                    ArchiveAction.downloadArchiveFinished({ archive }),
                  ],
                ),
              );
          } else {
            const url = getArchiveCloudPreviewUri(archive);
            return [
              ArchiveAction.downloadArchiveSuccess({ url: trimMp4(url), name: archive.name }),
              ArchiveAction.downloadArchiveFinished({ archive }),
            ];
          }
        },
      ),
      share(),
    ),
  );

  public retry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveAction.retry),
      withLatestFrom(
        this.store$.pipe(select(LocationSelectors.selectLocationLookup)),
        this.store$.pipe(select(CameraSelectors.selectCameraState)),
      ),
      switchMap(([{ archive }, locationLookup, allCameras]) => {
        const selectedCamera = allCameras['entities'][archive.cameraId];
        if (!selectedCamera) {
          return of(SharedActions.showMessage({ error: 'archive can\'t be created as camera was deleted.' }));
        }
        const playBack = this.prepareDownloadPlaybackRequestArchiveModel({
          ...archive,
          selectedCamera,
          start: moment(archive.start * 1000)
            .toString(),
          end: moment(archive.end * 1000)
            .toString(),
        }, locationLookup);
        if (archive.sessionId) {
          playBack[0].sessionId = archive.sessionId;
        }

        return [
          ArchiveAction.sendArchiveToServer({ archive: playBack[0], grantedAccess: null }),
          SharedActions.showMessage({ success: 'Retry has been triggered' }),
        ];
      }),
    ));

  public sendArchiveToServer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveAction.sendArchiveToServer),
      mergeMap(({ archive, grantedAccess }) =>
        this.archiveService.createArchive(archive, grantedAccess)
          .pipe(
            mergeMap(response => {
              return [
                SharedActions.setIsSaving({ isSaving: false }),
                ArchiveAction.sendArchiveToServerSuccess({ filename: response.filename, sessionId: response.sessionId, archiveId: response.archiveId, entityParams: grantedAccess.entityParams }),
              ];
            }),
            catchError(err => [
              ArchiveAction.sendArchiveToServerFail(),
              SharedActions.showMessage({ error: err?.error.message }),
              SharedActions.setIsSaving({ isSaving: false })]),
          ),
      ),
      share(),
    ),
  );

  public updateArchive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveAction.updateArchive),
      mergeMap(({ archive }) =>
        this.archiveService.updateArchive(archive)
          .pipe(
            mergeMap(response => {
              return [SharedActions.doNothing()];
            }),
            catchError(err => [SharedActions.showMessage({ error: err }), SharedActions.setIsSaving({ isSaving: false })]),
          ),
      ),
      share(),
    ),
  );

  public setSelectedCameraId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveAction.setSelectedCameraId),
      withLatestFrom(this.store$.pipe(select(CameraSelectors.selectAllCameras))),
      mergeMap(([{ cameraId }, allCameras]) => {
        return [ArchiveAction.changeArchiveProperty({ property: 'selectedCamera', value: allCameras[cameraId] })];
      }),
      catchError(err => [SharedActions.showMessage({ error: err }), SharedActions.setIsSaving({ isSaving: false })]),
      share(),
    ),
  );

  public archiveProgressSocketUpdate$ = createEffect(() => this.actions$.pipe(ofType(ArchiveAction.archiveProgressSocketUpdate), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private archiveService: ArchiveService,
    private organizationService: OrganizationService,
    private utilsV2Service: UtilsV2Service,
  ) {
  }

  private prepareDownloadPlaybackRequestArchiveModel(selectedArchive: ArchiveModel, locationLookup): Playback.PlaybackDownloadRequest[] {
    const result: Playback.PlaybackDownloadRequest[] = [];
    const clipDuration = Math.floor((new Date(selectedArchive.end).getTime() - new Date(selectedArchive.start).getTime()) / 1000);
    // To stay on the safe side we will return an empty result if clip is longer than 10 minutes
    if (clipDuration > TIMELINE_MAX_WINDOW) {
      return result;
    }
    let timestamp;
    let duration;

    if (selectedArchive.isSmartStorage) {
      timestamp = selectedArchive?.smartStorageInterval[0];
      duration = Math.floor((selectedArchive?.smartStorageInterval[1] - selectedArchive?.smartStorageInterval[0]) / 1000);
    } else {
      timestamp = new Date(selectedArchive.start).getTime();
      duration = Math.floor((new Date(selectedArchive.end).getTime() - timestamp) / 1000);

    }

    // OLD CODE - SPLIT CLIPS
    // const numClips = Math.ceil((clipDuration * 1000) / TIMELINE_CLIP_SIZE);
    // for(let clip = 0; clip < numClips; clip++) {
    //   const timestamp = new Date(selectedArchive.start).getTime() + clip * TIMELINE_CLIP_SIZE;
    //   const duration = Math.floor(
    //     (clip === numClips - 1 ? new Date(selectedArchive.end).getTime() - timestamp : TIMELINE_CLIP_SIZE) / 1000,
    //   );
    //   const archive = {
    //     cameraId: selectedArchive.selectedCamera.edgeOnly.cameraId,
    //     locationId: selectedArchive.selectedCamera.locationId,
    //     edgeId: selectedArchive.selectedCamera.edgeId,
    //     filename: selectedArchive.sessionId ? selectedArchive.name : `${selectedArchive.name}${numClips > 1 ? `-(${clip}).mp4` : '.mp4'}`,
    //     exitAfterDone: false,
    //     timestamp: timestamp,
    //     duration,
    //     tags: selectedArchive.tags,
    //     storage: ArchiveStorageType.CLOUD,
    //     blurFaces: selectedArchive.blurFaces,
    //     timestampLocation: selectedArchive.timestampLocation,
    //   };
    //   result.push(archive);
    // }

    const archive = {
      cameraId: selectedArchive.selectedCamera.cameraId ?? selectedArchive.selectedCamera.edgeOnly.cameraId,
      locationId: selectedArchive.selectedCamera.locationId,
      edgeId: selectedArchive.selectedCamera.edgeId,
      filename: selectedArchive.name,
      exitAfterDone: false,
      timestamp: timestamp,
      duration,
      tags: selectedArchive.tags,
      storage: ArchiveStorageType.CLOUD,
      blurFaces: selectedArchive.blurFaces,
      timestampLocation: selectedArchive.timestampLocation,
      isSmartStorage: selectedArchive.isSmartStorage,
    };
    result.push(archive);
    return result;
  }
}
