import { AfterViewInit, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ui-option-toggle',
  templateUrl: './ui-option-toggle.component.html',
  styleUrls: ['./ui-option-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiOptionToggleComponent),
      multi: true,
    },
  ],
})
export class UiOptionToggleComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  @Input() options: string[] = ['Option 1', 'Option 2'];
  @Input()
  public value: boolean = false;
  @Input()
  public disabled: boolean = false;

  @Input() minWidth: number;

  initDone = false;

  constructor() {
  }

  public ngOnInit(): void {
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  public writeValue(val: boolean): void {
    this.value = val;
  }

  public toggle() {
    this.value = !this.value;
    this.onChange(this.value);
    this.onTouched();
  }

  private onChange = (value: boolean) => {
  };
  private onTouched = () => {
  };

  public setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.initDone = true;
    });
  }

  public toggleValue() {
    this.value = !this.value;
  }

}
