import { Component, OnInit } from '@angular/core';
import { UIInputStyle } from '@enums/shared.enum';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

;
import { EdgeCamera } from '../../../cameras/camera.model';
import { CameraSelectorComponent } from '../../camera-selector/camera-selector.component';
import { CameraSelectorSubComponent } from '../../camera-selector-sub/camera-selector-sub.component';

@Component({
  selector: 'app-upload-video-storage-modal',
  templateUrl: './upload-video-storage-modal.component.html',
  styleUrls: ['./upload-video-storage-modal.component.scss'],
})
export class UploadVideoStorageModalComponent implements OnInit {
  public inputStyle = UIInputStyle;
  public form: UntypedFormGroup;
  public selectedCamera: EdgeCamera.CameraItem;

  constructor(private matDialogRef: MatDialogRef<UploadVideoStorageModalComponent>, private fb: UntypedFormBuilder, private dialog: MatDialog) {
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      dirPattern: ['', [Validators.required]],
      filePattern: ['', [Validators.required]],
      cameraId: ['', [Validators.required]],
    });
  }

  public ok() {
    if (this.form.valid) {
      this.matDialogRef.close(this.form.value);
    }
  }

  public cancel() {
    this.matDialogRef.close(null);
  }

  public selectCamera(): void {
    this.dialog
      .open(CameraSelectorSubComponent, {
        width: '600px',
        panelClass: 'modal-no-padding',
        disableClose: true,
        data: {
          multi: false,
          selectedCameras: [],
        },
      })
      .afterClosed()
      .subscribe((cameras: EdgeCamera.CameraItem[]) => {
        if (cameras?.length) {
          this.selectedCamera = cameras[0];
          this.form.patchValue({ cameraId: this.selectedCamera.edgeOnly.cameraId });
        }
      });
  }
}
