import { createReducer, on } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import { AuthenticationActions } from './authentication.action-types';
import { NumberOrEmpty, StringOrEmpty } from 'src/app/app.model';

export interface AuthenticationState {
  idToken: StringOrEmpty;
  accessToken: StringOrEmpty;
  expiresAt: NumberOrEmpty | StringOrEmpty;
  authProviderId: StringOrEmpty;
  isLoggedIn: boolean;
  isIdle: boolean;
  error: StringOrEmpty;
}

const initialAuthenticationState: AuthenticationState = {
  idToken: null,
  accessToken: null,
  expiresAt: null,
  authProviderId: null,
  isLoggedIn: false,
  isIdle: false,
  error: null,
};

export const authenticationReducer = createReducer(
  initialAuthenticationState,

  on(AuthenticationActions.Login, (state, action) => {
    return {
      ...state,
      idToken: action.auth.idToken,
      accessToken: action.auth.accessToken,
      expiresAt: action.auth.expiresAt,
      authProviderId: action.auth.authProviderId || action.auth.idTokenPayload?.sub,
      isLoggedIn: true,
    };
  }),

  on(AuthenticationActions.Logout, (state, action) => {
    return {
      ...initialAuthenticationState,
    };
  }),
  on(AuthenticationActions.IdleSuccess, (state, action) => {
    return {
      ...state,
      isIdle: action.payload.idle,
    };
  }),

  on(AuthenticationActions.IdleFail, (state, action) => {
    return {
      ...state,
      error: action.message,
    };
  })
);

