import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { PulsationModels } from '@models/pulsation.model';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AsyncPipe, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { UiKitModule } from '../ui-kit.module';
import { PreloaderColor } from '@enums/shared.enum';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { PushPipe } from '@ngrx/component';
import * as moment from 'moment-timezone';
import ComponentStatusDisplay = PulsationModels.ComponentStatusDisplay;
import { DEFAULT_TIMEOUT } from '@consts/shared.const';

export enum Device {
  camera,
  edge,
  analytic,
  alert,
  location,
  storage
}

@Component({
  selector: 'ui-device-status',
  standalone: true,
  imports: [
    NgIf,
    UiKitModule,
    MatTooltip,
    NgSwitch,
    MatIcon,
    NgSwitchCase,
    NgSwitchDefault,
    AsyncPipe,
    PushPipe,
  ],
  templateUrl: './ui-device-status.component.html',
  styleUrl: './ui-device-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiDeviceStatusComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public status: PulsationModels.ComponentStatusDisplay;

  @Input()
  public showIcon: boolean = true;

  @Input()
  public showStatus: boolean = false;

  @Input()
  public device: Device = Device.camera;

  @Input()
  public iconSizePx: number = 20;

  @Input() showTime = false;
  @Input() timezone: string;
  @Input() playing = false;

  @Input()
  public tooltip: string = '';

  @Input()
  public tooltipEnabled: boolean = true;

  public statusString$: BehaviorSubject<string> = new BehaviorSubject<string>('Online');
  public time$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public loadingTimeout;
  public preloaderColor = PreloaderColor;
  public componentStatusDisplay = PulsationModels.ComponentStatusDisplay;
  private timeInterval;
  private time: string;

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    if (this.showTime) {
      this.timeInterval = setInterval(() => {
        this.computeTime();
      }, 1000);
    }
  }

  public ngOnChanges(): void {
    if (this.status) {
      this.setObject();
    } else {
      this.startLoader();
    }
  }

  private setObject() {
    switch (this.device) {
      case Device.camera:
      case Device.location:
      case Device.edge:
        if (this.status === PulsationModels.ComponentStatusDisplay.NotRecording) {
          this.statusString$.next('Recording is not scheduled');
          break;
        }
        const status = this.status.toString()
          .replace(/([a-z])([A-Z])/g, '$1 $2');
        this.statusString$.next(status);
        break;
      case Device.analytic:
        const statusAnalytic =
          this.status === this.componentStatusDisplay.Online
            ? 'AI On'
            : 'AI Off';
        this.statusString$.next(statusAnalytic);
        break;
    }
  }

  private computeTime() {
    const now = new Date().getTime();
    this.time = moment(now)
      .tz(this.timezone)
      .format('hh:mm:ss A z');
    this.time$.next(this.time);
  }

  public ngOnDestroy(): void {
    clearInterval(this.timeInterval);
    clearTimeout(this.loadingTimeout);
  }


  private startLoader() {
    clearTimeout(this.loadingTimeout);
    this.loadingTimeout = setTimeout(() => {
      if (this.status === null || this.status === undefined) {
        this.status = ComponentStatusDisplay.Offline;
        this.cdr.markForCheck();
      }
    }, DEFAULT_TIMEOUT);
  }
}
