import { createAction, props } from '@ngrx/store';
import { MenuKey } from '@enums/menu.enum';
import { VariableConfig, VariableSendModel } from '@models/variable.model';
import { EdgeCamera } from '../../../cameras/camera.model';
import { VariableType } from '@enums/variable.enum';

export const resetToInitialState = createAction('[VARIABLES] Reset to Initial state');

export const continueVariable = createAction('[VARIABLES] Continue Variable');

export const backVariable = createAction('[VARIABLES] Back Variable');

export const validateDetails = createAction('[VARIABLES] Validate Details');
export const validateData = createAction('[VARIABLES] Validate Data');

export const addValidMenuPoint = createAction('[VARIABLES] Add Valid Menu Point', props<{ point: MenuKey }>());

export const rmValidMenuPoint = createAction('[VARIABLES] Rm Valid Menu Point', props<{ point: MenuKey }>());

export const removeVariable = createAction(
  '[VARIABLES] Remove Variable',
  props<{ id: string; cameraId: string; locationId: string; edgeId: string }>()
);

export const setQuery = createAction('[VARIABLES] Set Query', props<{ query: string }>());

export const setPaginationParams = createAction('[VARIABLES] Set Pagination Params', props<{ perPage: number; page: number }>());

export const getVariables = createAction('[VARIABLES] Get Variables from Server');

export const setVariables = createAction('[VARIABLES] Set Variables to Server', props<{ variables: VariableConfig[] }>());

export const setTotalItemsCount = createAction('[VARIABLES] Set Total Item Counts', props<{ totalItemsCount: number }>());

export const setSelectedCameras = createAction('[VARIABLES] Set Selected Cameras', props<{ selectedCameras: EdgeCamera.CameraItem[] }>());

export const setVariableType = createAction('[VARIABLES] Set Variable Type', props<{ selectedVariableType: VariableType }>());

export const setVariableName = createAction('[VARIABLES] Set Variable Name', props<{ variableName: string }>());

export const setVariablePeriodInMinutes = createAction('[VARIABLES] Set Period In Minutes', props<{ periodInMinutes: number }>());

export const setObjectSelection = createAction('[VARIABLES] Set Object Selection', props<{ objectSelections: any }>());

export const setObjectSelectionFormatted = createAction(
  '[VARIABLES] Set Object Selection Formatted',
  props<{ objectSelectionsFormatted: any }>()
);

export const setSelectedPersonObjectType = createAction(
  '[VARIABLES] Set Object Selected Person Object Type',
  props<{ selectedPersonObjectType: boolean }>()
);

export const loadLevel3Menu = createAction('[VARIABLES] Load level 3 menu', props<{ copy: boolean }>());

export const setCompoundFormulaValidation = createAction(
  '[VARIABLES] Validate Compound Formula',
  props<{ compoundFormulaInvalid: boolean }>()
);

export const getVariablesAutocomplete = createAction(
  '[VARIABLES] Get Variables Autocomplete',
  props<{ query: string; notType: VariableType }>()
);

export const getVariablesAutocompleteSuccess = createAction(
  '[VARIABLES] Get Variables Autocomplete Success',
  props<{ variablesAutoComplete: { _id: string; name: string }[] }>()
);

export const getVariablesAutocompleteFail = createAction('[VARIABLES] Get Variables Autocomplete Fail');
