import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SensorModel } from '../store/models/sensor.model';
import { api } from '../consts/url.const';


@Injectable({
  providedIn: 'root'
})
export class SensorsService {
  constructor(private http: HttpClient) {}

  upsertSensor(sensor: SensorModel.Sensor): Observable<SensorModel.SensorResponse> {
    return this.http.post<SensorModel.SensorResponse>(api.sensors.crud, sensor);
  }

  testSensorConnection(sensorTest: SensorModel.SensorTest): Observable<boolean> {
    return this.http.post<boolean>(`${api.sensors.crud}/test`, sensorTest);
  }

  getAllSensors(): Observable<SensorModel.Sensor[]> {
    return this.http.get<SensorModel.Sensor[]>(api.sensors.crud);
  }

  getSensorById(sensorId: string): Observable<SensorModel.Sensor> {
    return this.http.get<SensorModel.Sensor>(api.sensors.one(sensorId));
  }

  getSensorsByLocation(locationId: string): Observable<SensorModel.Sensor[]> {
    return this.http.get<SensorModel.Sensor[]>(`${api.sensors.crud}/location/${locationId}`);
  }

  removeSensor(sensorId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${api.sensors.crud}/${sensorId}`);
  }

  // Search
  // New search endpoints
  searchSensorData(request: SensorModel.SensorSearchData): Observable<SensorModel.SensorSearchResult[]> {
    return this.http.post<SensorModel.SensorSearchResult[]>(api.sensors.search.data, request);
  }

  getLastSensorReadings(request: SensorModel.SensorSearchData): Observable<SensorModel.SensorLastReading[]> {
    return this.http.post<SensorModel.SensorLastReading[]>(api.sensors.search.last, request);
  }

  getSensorStats(request: SensorModel.SensorSearchRequest): Observable<SensorModel.SensorStats[]> {
    return this.http.post<SensorModel.SensorStats[]>(api.sensors.search.stats, request);
  }

  getSensorSupportTypes(sensorId: string): Observable<SensorModel.SensorTypeSupport[]> {
    return this.http.post<SensorModel.SensorTypeSupport[]>(api.sensors.search.supportType, { sensorId });
  }

}