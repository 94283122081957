import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Thumbnail, ThumbnailEntity } from '../../cameras/camera-thumbnails/camera-thumbnails.model';
import { KeyValuePairs } from '../../core/interfaces';
import { catchError, interval, lastValueFrom, Observable, shareReplay, Subscription, take, takeWhile, tap } from 'rxjs';
import { SearchService, SearchType } from '../search.service';
import { CamerasThumbnailsService } from '../../cameras/camera-thumbnails/camera-thumnails.service';
import { Search } from '../search.model';
import { CameraThumbnailsSort } from '../../cameras/camera-thumbnails/camera-thumbnails.component';
import { map } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { Sort } from '../shared.model';
import { HttpService } from '../../core/http.service';
import * as _ from 'lodash';
import { select, Store } from '@ngrx/store';
import * as MultiSearchSelectors from '@states/multi-search/multi-search.selectors';
import * as TrackObjectSelectors from '@states/track-object/track-object.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TrackObjectState } from '@states/track-object/track-object.reducer';
import { LocationSelectors } from '@states/location/location.selector-types';
import * as GeneralSelectors from '@states/general/general.selectors';
import { EdgeCamera } from '../../cameras/camera.model';
import { SearchObjectTypes, SearchQueryOperator } from '@enums/search.enum';
import { ThumbnailTemplate } from '../thumbnail/thumbnail.component';
import { SearchCamera, SearchSelection, SearchSelectionForm, SearchSelectionPersonProperty } from '@models/search.model';
import { MultiSearchActions } from '@states/multi-search/multi-search.action-types';
import { OnRangeSelectedResult } from '../ui-kit/ui-calendar-inline/ui-calendar-inline.component';
import { PreloaderColor, UiCalendarPickerType } from '@enums/shared.enum';
import { personSelectionFormFieldsTitles } from '@consts/alert-events.const';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { PeopleSelectors } from '@states/people/people.selector-types';
import { CustomEventModel } from '@models/custom-event.model';
import { MediaCacheService } from '../media-cache/media-cache.service';
import { precisionStr } from '@consts/search.const';
import { DashboardModel } from '@models/dashboard.model';
import { objectsStr } from '../../pages/alerts-v2/components/step-objects-selector/step-objects-selector.component';


@UntilDestroy()
@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnInit, OnDestroy, OnChanges {
  public precisionStr = precisionStr;
  public PreloaderColor = PreloaderColor;
  public ThumbnailTemplate: typeof ThumbnailTemplate = ThumbnailTemplate;
  public SearchObjectTypes: typeof SearchObjectTypes = SearchObjectTypes;
  public personSelectionFormFieldsTitles = personSelectionFormFieldsTitles;
  public hideHint = false;

  @Input() thumbnailTemplate = ThumbnailTemplate.TEMPLATE1;

  @Input() public showObjects = true;

  public selectUseSingleStore$: Observable<boolean> = this.store$.pipe(select(MultiSearchSelectors.selectUseSingleStore));

  public selectObjectSelections$: Observable<SearchSelectionForm[]> = this.store$.pipe(select(MultiSearchSelectors.selectObjectSelections));
  public selectObjectSelectionsFormatted$: Observable<SearchSelectionForm[]> = this.store$.pipe(select(MultiSearchSelectors.selectObjectSelectionsFormatted));
  public selectOuterOperator$: Observable<SearchQueryOperator> = this.store$.pipe(select(MultiSearchSelectors.selectOuterOperator));
  public selectAccessDoors$: Observable<Search.AccessControlSelectionChange> = this.store$.pipe(select(MultiSearchSelectors.selectAccessDoors));

  public selectCustomEvent$: Observable<CustomEventModel.CustomEvent> = this.store$.pipe(select(MultiSearchSelectors.selectCustomEvent));

  // public selectObjectSelectionsFormattedAndOuterOperator$: Observable<{
  //   objectSelectionsFormatted: any;
  //   outerOperator: SearchQueryOperator;
  // }> = this.store$.pipe(select(MultiSearchSelectors.selectObjectSelectionsFormattedAndOuterOperator));

  public selectHighConfidence$: Observable<boolean> = this.store$.pipe(select(MultiSearchSelectors.selectSearchHighConfidence));
  public selectPrecision$: Observable<Search.Precision> = this.store$.pipe(select(MultiSearchSelectors.selectSearchPrecision));
  public selectDateTimeRange: Observable<OnRangeSelectedResult> = this.store$.pipe(select(MultiSearchSelectors.selectDateTimeRange));
  public hasFaces$: Observable<boolean> = this.store$.select(MultiSearchSelectors.hasFaces);
  public selectFaces$: Observable<Search.Faces> = this.store$.pipe(select(MultiSearchSelectors.selectFaces));

  public UiCalendarPickerType = UiCalendarPickerType;

  trackObject$: Observable<TrackObjectState> = this.store$.pipe(select(TrackObjectSelectors.selectTrackObjectState));

  trackerThreshold$: Observable<number> = this.store$.pipe(untilDestroyed(this), select(GeneralSelectors.selectTrackerThreshold));

  @Output() refresh = new EventEmitter<void>();
  @Output() resetFilters = new EventEmitter<void>();

  @Input() showChips = false;

  @Input()
  width: number;

  @Input()
  height: number;

  @Input()
  edgeId: string | undefined;

  @Input()
  cameraId: string | undefined;

  @Input()
  cameraIds: string[];

  @Input()
  type;

  @Input()
  active;

  @Input()
  alpr = {};

  searchSelections: SearchSelection[];

  useSingleStore = false;

  @Input()
  startInput;

  @Input()
  endInput;

  start;
  end;

  @Input()
  sensitivity = 50;

  @Input()
  timezone: string;

  @Input()
  sort: CameraThumbnailsSort = CameraThumbnailsSort.DESC;

  @Input()
  multiTracker: boolean = false;

  // Multi Search params

  @Input()
  multiSearch: boolean = false;

  @Input()
  loadIndividualEvents = false;

  @Input()
  maskDateChange = false;

  @Input() widgetDataInfo: DashboardModel.SearchLinkObject;
  @Input() widgetCustomEventDataInfo: DashboardModel.EventTagLinkObject;

  @Input() hideControls = false;

  cameras: EdgeCamera.CameraItem[] = [];
  currentSearchCameras: Search.SearchCamera[] = [];

  /**
   * Search related variables
   */
  @ViewChild('startPicker')
  startPicker: ElementRef;

  @ViewChild('endPicker')
  endPicker: ElementRef;

  @ViewChild('scroller')
  scroller: ElementRef;

  @ViewChild('wrapper')
  wrapper: ElementRef;

  events: KeyValuePairs<number[]> = {};

  results: number[][] = [];
  duration = 2000;
  rendered: Thumbnail[] = [];
  thumbs: Thumbnail[][] = [];

  loader = false;
  loadingMore = false;
  more = true;
  fetching = false;
  started = false;

  markedIdx$: Observable<number[] | undefined>;
  markedIdx: number[] | undefined;

  // Infinite scrolling values
  page = 0;
  size = 50;

  subscriptions: Subscription[] = [];

  latestForPage: number[] = [];
  latestTs: number;
  rtl = false;
  initMsg = true;
  private outerOperator: SearchQueryOperator = SearchQueryOperator.OR;

  scrollIndex = 0;

  idBase;
  idIndex;
  orgEdges;
  threshold;

  cameraSet = new Set<string>();
  objectChips = [];
  propertyChips = [];

  customEvent: CustomEventModel.CustomEvent;
  motion: Search.MotionSearchParams;
  unusualEvent: Search.UnusualEventSearchParams;
  accessDoors: Search.AccessControlSelectionChange;

  @Input() motionSearch = false;
  @Input() customEventSearch = false;
  @Input() unusualEventSearch = false;
  @Input() accessDoorsSearch = false;

  count = 0;

  hasFaces = false;

  public objectsStr = objectsStr;

  constructor(
    private searchService: SearchService,
    private cameraThumbnailsService: CamerasThumbnailsService,
    public cd: ChangeDetectorRef,
    public httpService: HttpService,
    private store$: Store,
    private mediaCacheService: MediaCacheService,
  ) {
  }

  public top() {
    // return this.scroller.nativeElement.scrollTop < 5;
  }

  public isCameraExist(cameraId: string) {
    return this.store$.select(CameraSelectors.selectCameraById(cameraId))
      .pipe(take(1));
  }

  ngOnDestroy(): void {
    for(let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  ngOnInit(): void {

    this.start = this.startInput;
    this.end = this.endInput;

    this.selectAccessDoors$.pipe(untilDestroyed(this))
      .subscribe(accessDoors => {
        this.accessDoors = accessDoors;
        this.accessDoorsSearch = accessDoors?.isAccessDoorSearch;
      });

    // Track Object init
    if (this.multiTracker) {
      this.store$
        .select(LocationSelectors.selectAllLocationsEdges)
        .pipe(untilDestroyed(this))
        .subscribe(edges => {
          this.orgEdges = edges;
        });
      this.trackerThreshold$.pipe(untilDestroyed(this))
        .subscribe(threshold => {
          this.threshold = +threshold;
        });
      this.trackObject$.pipe(untilDestroyed(this), shareReplay())
        .subscribe(searchObject => {
          if (searchObject) {
            this.idBase = +searchObject.idBase;
            this.idIndex = +searchObject.idIndex;
            if (!!this.idBase && !!this.idIndex) {
              this.search();
            }
          }
        });
    }

    if (this.multiSearch) {
      this.store$
        .select(MultiSearchSelectors.selectSelectedCameras)
        .pipe(
          untilDestroyed(this),
          tap(cameras => {
            this.cameras = cameras ?? [];
          }),
        )
        .subscribe();

      this.store$
        .select(MultiSearchSelectors.selectCustomEvent)
        .pipe(
          untilDestroyed(this),
          tap(customEvent => {
            this.customEvent = customEvent;
          }),
        )
        .subscribe();

      this.store$
        .select(MultiSearchSelectors.selectMotion)
        .pipe(
          untilDestroyed(this),
          tap(motion => {
            this.motion = motion;
          }),
        )
        .subscribe();

      this.store$
        .select(MultiSearchSelectors.selectUnusualEvent)
        .pipe(
          untilDestroyed(this),
          tap(unusualEvent => {
            this.unusualEvent = unusualEvent;
          }),
        )
        .subscribe();

      this.selectOuterOperator$.pipe(untilDestroyed(this))
        .subscribe(outerOperator => this.outerOperator = outerOperator ?? SearchQueryOperator.OR);

      this.selectObjectSelectionsFormatted$.pipe(untilDestroyed(this))
        .subscribe(objectSelectionsFormatted => {
          this.searchSelections = objectSelectionsFormatted ?? [];
          this.setObjectChips();
          if ((!!this.cameras?.length && !!this.searchSelections?.length || this.accessDoorsSearch) || (!this.multiSearch && !this.multiTracker)) {
            this.search();
          } else {
            if (!this.cameras?.length) {
              // this.store$.dispatch(SharedActions.showMessage({ warning: 'Please select at least one camera and search again' }));
            }
            if (!this.searchSelections?.length) {
              // this.store$.dispatch(SharedActions.showMessage({ warning: 'Please select at list one search filter' }));
            }
            this.initMsg = true;
            this.httpService.cancelPendingRequests();
            this.loader = false;
            this.page = 0;
            this.count = 0;
            this.results = [];
            this.rendered = [];
            this.thumbs = [];
            this.more = true;
            this.fetching = false;
            this.latestForPage = [];
          }
        });

    } else {
      this.selectObjectSelectionsFormatted$.pipe(untilDestroyed(this))
        .subscribe(objectSelectionsFormatted => {
          this.searchSelections = objectSelectionsFormatted ?? [];
        });
    }

    this.searchService.search$.pipe(untilDestroyed(this))
      .subscribe(_ => {
        if ((!!this.cameras?.length && (!!this.searchSelections?.length || this.hasFaces || !!this.customEventSearch || !!this.motionSearch || !!this.unusualEventSearch || !!this.widgetDataInfo || !!this.widgetCustomEventDataInfo)) || (!this.multiSearch && !this.multiTracker)) {
          this.search();
        } else if (this.accessDoorsSearch && this.accessDoors?.doors?.length && this.accessDoors.status) {
          this.search();
        } else {
          if (!this.cameras?.length) {
            // this.store$.dispatch(SharedActions.showMessage({ warning: 'Please select at least one camera and search again' }));
          }
          if (!this.searchSelections?.length) {
            // this.store$.dispatch(SharedActions.showMessage({ warning: 'Please select at list one search filter' }));
          }
          this.initMsg = true;
          this.httpService.cancelPendingRequests();
          this.loader = false;
          this.page = 0;
          this.results = [];
          this.rendered = [];
          this.thumbs = [];
          this.more = true;
          this.fetching = false;
          this.latestForPage = [];
        }
      });

    this.searchService.reset$.pipe(untilDestroyed(this))
      .subscribe(_ => {
        this.reset();
      });

    this.selectUseSingleStore$.pipe(untilDestroyed(this))
      .subscribe(useSingleStore => {
        this.useSingleStore = useSingleStore;
        if ((!!this.cameras?.length && !!this.searchSelections?.length) || (!this.multiSearch && !this.multiTracker)) {
          this.search();
        }
      });

    this.hasFaces$.pipe(untilDestroyed(this))
      .subscribe(hasFaces => {
        this.hasFaces = hasFaces;
      });

    this.hasFaces$.pipe(take(1))
      .subscribe(hasFaces => {
        if (hasFaces) {
          this.search();
        }
      });


  }

  getEvents(base: number, start: number, end: number, edgeId?: string, cameraId?: string): Observable<ThumbnailEntity[]> {
    return this.cameraThumbnailsService.getThumbnailsByDateFromDb(edgeId ?? this.edgeId!, cameraId ?? this.cameraId!, start, end);
  }

  public replaceBottomLine(str: string) {
    return str.replace('_', ' ');
  }

  private setObjectChips() {
    const chips = [];
    let typeIndex = 1;
    let type: SearchObjectTypes = this.searchSelections[0]?.type ?? SearchObjectTypes.PERSON;
    for(let [index, selection] of this.searchSelections.entries()) {
      if (type !== selection.type) {
        type = selection.type;
        typeIndex = 1;
      }
      chips.push({ type: selection.type, typeIndex: typeIndex++, index });
    }
    this.objectChips = chips;
  }

  normalizeTimestamp(timestamp: number, freq = 2000) {
    return this.cameraThumbnailsService.normalizeTimestamp(timestamp, freq);
  }

  getBaseInLocale(date: Date) {
    return this.cameraThumbnailsService.getBaseInLocale(date, this.timezone);
  }

  populateThumbArray(
    startTime: number,
    endTime: number,
    searchEvents?: number[][],
    bestImages?: Search.SearchObject[],
    cameraId?: string,
    edgeId?: string,
    loadIndividualEvents?: boolean,
  ): number[] {
    let thumbnails: number[] = [];

    if (startTime >= endTime) {
      endTime = [startTime, (startTime = endTime)][0];
    }

    startTime -= 10000;
    endTime += 10000;

    const base = this.getBaseInLocale(new Date(startTime));
    const baseEnd = this.getBaseInLocale(new Date(endTime));
    const clipInSeconds = (endTime - startTime) / 1000;

    if (!!loadIndividualEvents && (!!this.events[base] || !!this.events[baseEnd])) {
      if (base !== baseEnd && baseEnd !== endTime) {
        const indexStart = this.cameraThumbnailsService.getEventLocation(startTime, base);
        const indexEnd = this.cameraThumbnailsService.getEventLocation(endTime, baseEnd);
        // We need to combine 2 days to one array
        let first: number[] = [];
        let second: number[] = [];
        if (!!this.events[base]) {
          first = this.events[base].slice(indexStart, this.events[base].length);
        }
        if (!!this.events[baseEnd]) {
          second = this.events[baseEnd].slice(0, indexEnd);
        }
        thumbnails = first.concat(second);
      } else {
        if (!!this.events[base]) {
          const indexStart = this.cameraThumbnailsService.getEventLocation(startTime, base);
          const indexEnd = this.cameraThumbnailsService.getEventLocation(endTime, base);
          thumbnails = this.events[base].slice(indexStart, indexEnd);
        }
      }
    }
    const render = {
      thumbnails: this.loadIndividualEvents ? thumbnails : undefined,
      searchEvents,
      cameraId,
      edgeId,
      objects: bestImages,
      defaultThumb: !!searchEvents.length ? `thumbnail-${searchEvents[Math.floor(searchEvents.length / 2)][0]}-0-0.jpg` : undefined,
      options: {
        startTime: this.normalizeTimestamp(startTime, 20000),
        endTime: this.normalizeTimestamp(endTime, 20000),
        duration: this.duration,
        // base: !this.events[base] && !!this.events[baseEnd] ? baseEnd : undefined,
        clipInSeconds,
        offsetResInDurations: 1,
      },
    };
    if (this.multiTracker) {
      this.rendered.push(render);
    }
    const len = this.thumbs.length;
    // TODO: 3 need to be configured as to how many columns we display (might be configurable)
    if (this.thumbs[len - 1]?.length < 3) {
      this.thumbs[len - 1].push(render);
    } else {
      this.thumbs.push([render]);
    }
    this.thumbs = [...this.thumbs];

    // this.thumbs = [...this.rendered];
    return thumbnails;
  }

  async render(res: Search.MotionVectorSearchResponse | Search.AnalyticSearchResponse) {
    const results = res.results;
    const searchEvents: number[][][] = res.searchEvents;
    const cameraIds: string[] = res.cameraIds;
    const edgeIds: string[] = res.edgeIds;
    const bestImage: Search.SearchObject[][] = res.searchObjects;
    this.results.push(...results);

    for(let idx = 0, len = results.length; idx < len; idx++) {
      const res = results[idx];
      const startTime = this.normalizeTimestamp(res[0]);
      const endTime = this.normalizeTimestamp(res[1]);
      const base = this.getBaseInLocale(new Date(startTime));
      let thumbnails: number[] = [];
      // TODO: Might need to change the logic here, we want to reduce the number of requests to minimum
      if (!!this.loadIndividualEvents) {
        if (!this.events[base]) {

          thumbnails = this.populateThumbArray(
            startTime,
            endTime,
            !!searchEvents ? searchEvents[idx] : undefined,
            !!bestImage ? bestImage[idx] : undefined,
            !!cameraIds ? cameraIds[idx] : undefined,
            !!edgeIds ? edgeIds[idx] : undefined,
            this.loadIndividualEvents,
          );
        } else {
          thumbnails = this.populateThumbArray(
            startTime,
            endTime,
            !!searchEvents ? searchEvents[idx] : undefined,
            !!bestImage ? bestImage[idx] : undefined,
            !!cameraIds ? cameraIds[idx] : undefined,
            !!edgeIds ? edgeIds[idx] : undefined,
            this.loadIndividualEvents,
          );
        }
      } else {
        thumbnails = this.populateThumbArray(
          startTime,
          endTime,
          !!searchEvents ? searchEvents[idx] : undefined,
          !!bestImage ? bestImage[idx] : undefined,
          !!cameraIds ? cameraIds[idx] : undefined,
          !!edgeIds ? edgeIds[idx] : undefined,
          this.loadIndividualEvents,
        );
      }
    }

    if (this.multiTracker) {
      this.count = this.rendered?.length;
      this.rendered.sort((a, b) => {
        return this.sort === CameraThumbnailsSort.DESC
          ? this.getMaxScore(b.objects) - this.getMaxScore(a.objects)
          : this.getMaxScore(a.objects) - this.getMaxScore(b.objects);
      });
      this.thumbs = _.chunk(this.rendered, 3);
    }

    // this.cd.detectChanges();
    this.fetching = false;
  }

  getMaxScore(objects: Search.SearchObject[]) {
    const scores = objects.map(object => object.score);
    return _.max(scores);
  }

  async parseSearch(page: number, res: Search.AnalyticSearchResponse) {
    // In case there was no movement / analytics in the timeframe
    // we will receive an empty result, but we still have more to go
    if (true) {
      for(let [index, entry] of res.results.entries()) {
        const isCameraExist = await lastValueFrom(this.isCameraExist(res.cameraIds[index]));
        if (!isCameraExist) {
          res.results.splice(index, 1);
          res.cameraIds.splice(index, 1);
          res.searchEvents.splice(index, 1);
          res.searchObjects.splice(index, 1);
          res.edgeIds.splice(index, 1);
        }
      }
      const startTimes = res.results.map(arr => arr[0]);
      const endTimes = res.results.map(arr => arr[1]);
      const minStart = _.min(startTimes);
      const maxEnd = _.max(endTimes);
      const bases = this.mediaCacheService.getBasesForRange(minStart, maxEnd);

      const cameraSet = new Set<string>();

      const requestCameras: SearchCamera[] = [];
      if (res.cameraIds) {
        for(let [index, cameraId] of res.cameraIds.entries()) {
          const edgeId = res.edgeIds[index];
          const existingCache = await this.mediaCacheService.eventsExistForRange(edgeId, cameraId, bases, true);
          if (!cameraSet.has(cameraId) && !existingCache) {
            cameraSet.add(cameraId);
            requestCameras.push({ cameraId, edgeId: res.edgeIds[index] });
          }
        }
      }
      if (!!requestCameras.length) {
        this.cameraThumbnailsService
          .getThumbnailsByRange(
            new Date(minStart).getTime(),
            new Date(maxEnd).getTime(),
            requestCameras,
          )
          .pipe(
            tap(res => {
              this.mediaCacheService.getThumbnailBits(requestCameras, minStart, maxEnd);
            }),
          )
          .subscribe();
      }
    }
    this.loader = false;
    this.latestForPage[page] = res?.latestTs!;
    this.latestTs = res?.latestTs!;
    await this.render(res);
    this.more = res?.more;
    if (
      this.scrollIndex >= this.thumbs.length - 3 &&
      (this.thumbs[this.thumbs.length - 1]?.length < 3 || this.thumbs.length < 6) &&
      res?.more
      // (!res?.results?.length || this.rendered.length < 9 && this.page > 0)
      // TODO: Revisit this condition
      // !((this.rendered.length + res?.results?.length) % 4 === 0))
    ) {
      this.fetching = false;
      this.onScrollDown();
    } else {
      this.loadingMore = false;
    }
  }

  getLatestTs(page: number): Promise<number> {
    return lastValueFrom(
      interval(1000)
        .pipe(
          map(() => {
            return this.latestForPage[page];
          }),
          takeWhile(res => res === undefined, true),
        ),
    );
  }

  async searchAnalytic(page: number, sort: Sort) {
    const start = this.start;
    const end = this.end;

    const startTs = !!this.widgetDataInfo ? this.widgetDataInfo?.queryTimeRange?.start : this.cameraThumbnailsService.convertTsToZone(new Date(start).getTime(), moment.tz.guess(), this.timezone);
    const endTs = !!this.widgetDataInfo ? this.widgetDataInfo?.queryTimeRange?.end : this.cameraThumbnailsService.convertTsToZone(new Date(end).getTime(), moment.tz.guess(), this.timezone);

    let request: DashboardModel.SearchLinkObject | Search.AnalyticSearchRequest;

    const searchRequest: Search.AnalyticSearchRequest = {
      markedIdx: this.markedIdx,
    };

    searchRequest.searchSelections = this.searchSelections;
    searchRequest.useSingleStore = this.useSingleStore;

    if (this.widgetDataInfo) {
      request = {
        ...this.widgetDataInfo,
      };
    } else {
      request = {
        ...searchRequest,
      };
    }

    if (page === 0) {
      this.latestForPage = [];
      this.latestTs = sort === Sort.ASC ? endTs : startTs;
    }

    if (page !== 0) {
      await this.getLatestTs(page - 1);
      if (this.latestForPage[page - 1] === -1) {
        return;
      }
    }

    const latestTs = page === 0 ? 0 : this.latestForPage[page - 1];
    const pageStartTs = page === 0 ? startTs : sort === Sort.ASC ? latestTs : startTs;
    const pageEndTs = page === 0 ? endTs : sort === Sort.DESC ? latestTs : endTs;

    if (this.multiTracker) {
      this.searchService
        .searchMultiTracker(
          0,
          this.size,
          sort,
          {
            ...searchRequest,
            operator: this.outerOperator,
            idBase: this.idBase,
            idIndex: this.idIndex,
            orgEdges: this.orgEdges,
            threshold: this.threshold,
          },
          {
            edgeId: undefined,
            cameraId: this.cameraId,
            start: pageStartTs,
            end: pageEndTs,
          },
        )
        .pipe(catchError(err => {
          this.loader = false;
          this.fetching = false;
          this.more = false;
          throw err;
        }))
        .subscribe((res: Search.AnalyticSearchResponse) => {
          this.parseSearch(page, res);
        });
      return;
    }

    if (page === 0) {
      this.count = 0;
      this.searchService.count(!!this.widgetDataInfo ? this.widgetDataInfo : {
            ...searchRequest,
            operator: this.outerOperator,
            idBase: this.idBase,
            idIndex: this.idIndex,
            orgEdges: this.orgEdges,
            threshold: this.threshold,
            cameras: this.multiSearch ? this.currentSearchCameras : undefined,
            maxStart: startTs,
            maxEnd: endTs,
          },
          this.motionSearch ? SearchType.MOTION_VECTOR :
            (this.customEventSearch ? SearchType.CUSTOM_EVENTS :
                (this.unusualEventSearch ? SearchType.UNUSUAL_EVENTS :
                  SearchType.ANALYTICS)
            ),
          {
            edgeId: this.multiSearch ? undefined : this.edgeId,
            cameraId: this.multiSearch ? undefined : this.cameraId,
            start: pageStartTs,
            end: pageEndTs,
          },
          !!this.widgetDataInfo,
        )
        .subscribe((count) => {
          this.count = count as any;
        });
    }

    this.searchService
      .searchAnalytic(
        0,
        this.size,
        sort,
        !!this.widgetDataInfo ? this.widgetDataInfo : {
          ...searchRequest,
          operator: this.outerOperator,
          idBase: this.idBase,
          idIndex: this.idIndex,
          orgEdges: this.orgEdges,
          threshold: this.threshold,
          cameras: this.multiSearch ? this.currentSearchCameras : undefined,
          maxStart: startTs,
          maxEnd: endTs,
        },
        {
          edgeId: this.multiSearch ? undefined : this.edgeId,
          cameraId: this.multiSearch ? undefined : this.cameraId,
          start: pageStartTs,
          end: pageEndTs,
        },
        false,
        false,
        !!this.widgetDataInfo,
      )
      .pipe(
        untilDestroyed(this),
        catchError(err => {
          this.loadingMore = false;
          this.fetching = false;
          this.more = false;
          throw err;
        }),
      )
      .subscribe((res: Search.AnalyticSearchResponse) => {
        this.parseSearch(page, res);
      });
  }


  async searchMotionVector(page: number, sort: Sort) {
    const startTs = this.cameraThumbnailsService.convertTsToZone(new Date(this.start).getTime(), moment.tz.guess(), this.timezone);
    const endTs = this.cameraThumbnailsService.convertTsToZone(new Date(this.end).getTime(), moment.tz.guess(), this.timezone);

    // if (page === 0) {
    //   this.latestForPage = [];
    //   this.latestTs = sort === Sort.ASC ? endTs : startTs;
    // }
    //
    // if (page !== 0) {
    //   await this.getLatestTs(page - 1);
    //   if (this.latestForPage[page - 1] === -1) {
    //     return;
    //   }
    // }
    //
    // const latestTs = page === 0 ? 0 : this.latestForPage[page - 1];
    // const start = page === 0 ? startTs : sort === Sort.ASC ? latestTs : startTs;
    // const end = page === 0 ? endTs : sort === Sort.DESC ? latestTs : endTs;

    const start = startTs;
    const end = endTs;

    const searchRequest: Search.MotionVectorSearchRequest = {
      // selection: this.noSelection() ? undefined : Array.from(this.selection),
      // flatArray: this.noSelection() ? undefined : Array.from(this.matrix).flat(),
      // points: this.noSelection() ? undefined : this.buildPointsArray(),
      markedIdx: this.markedIdx,
      sensitivity: this.sensitivity,
    };
    this.searchService
      .searchMotionVectors(this.page, this.size, sort, searchRequest, {
        edgeId: this.edgeId,
        cameraId: this.cameraId,
        start,
        end,
      })
      .subscribe((res: Search.MotionVectorSearchResponse) => {
        this.parseSearch(page, res);
      });
  }

  async searchCustomEvents(page: number, sort: Sort) {

    if (!this.customEvent && !this.widgetCustomEventDataInfo) {
      return;
    }


    let startTs, endTs;
    let searchRequest: Search.CustomEventSearchRequest;
    if (!!this.widgetCustomEventDataInfo) {
      startTs = this.widgetCustomEventDataInfo?.start;
      endTs = this.widgetCustomEventDataInfo?.end;
    } else {

      startTs = this.cameraThumbnailsService.convertTsToZone(new Date(this.start).getTime(), moment.tz.guess(), this.timezone);
      endTs = this.cameraThumbnailsService.convertTsToZone(new Date(this.end).getTime(), moment.tz.guess(), this.timezone);

      searchRequest = {
        customEvents: [this.customEvent].map(event => {
          return {
            fields: event?.fields,
            id: event?._id,
            videoLength: event?.videoLength,
          };
        }),
      };
    }

    if (page === 0) {
      this.latestForPage = [];
      this.latestTs = sort === Sort.ASC ? endTs : startTs;
    }

    if (page !== 0) {
      await this.getLatestTs(page - 1);
      if (this.latestForPage[page - 1] === -1) {
        return;
      }
    }

    const latestTs = page === 0 ? 0 : this.latestForPage[page - 1];
    const start = page === 0 ? startTs : sort === Sort.ASC ? latestTs : startTs;
    const end = page === 0 ? endTs : sort === Sort.DESC ? latestTs : endTs;


    let request = this.widgetCustomEventDataInfo ?? {
      ...searchRequest, cameras: this.multiSearch ? this.currentSearchCameras : undefined,
    };

    if (page === 0) {
      this.count = 0;
      this.searchService.count(request,
          SearchType.CUSTOM_EVENTS,
          {
            edgeId: undefined,
            cameraId: undefined,
            start,
            end,
          })
        .subscribe((count) => {
          this.count = count as any;
        });
    }

    this.searchService
      .searchCustomEvents(
        0,
        this.size,
        sort,
        request,
        {
          edgeId: undefined,
          cameraId: undefined,
          start,
          end,
        },
      )
      .pipe(
        untilDestroyed(this),
        catchError(err => {
          this.loadingMore = false;
          this.fetching = false;
          this.more = false;
          throw err;
        }),
      )
      .subscribe((res: Search.AnalyticSearchResponse) => {
        this.parseSearch(page, res);
      });
  }

  async searchMotion(page: number, sort: Sort) {
    const startTs = this.cameraThumbnailsService.convertTsToZone(new Date(this.start).getTime(), moment.tz.guess(), this.timezone);
    const endTs = this.cameraThumbnailsService.convertTsToZone(new Date(this.end).getTime(), moment.tz.guess(), this.timezone);

    const searchRequest: Search.MotionSearchRequest = {
      minMotion: this.motion?.sensitivityEnabled ? this.motion?.minMotion : undefined,
    };

    if (page === 0) {
      this.latestForPage = [];
      this.latestTs = sort === Sort.ASC ? endTs : startTs;
    }

    if (page !== 0) {
      await this.getLatestTs(page - 1);
      if (this.latestForPage[page - 1] === -1) {
        return;
      }
    }

    const latestTs = page === 0 ? 0 : this.latestForPage[page - 1];
    const start = page === 0 ? startTs : sort === Sort.ASC ? latestTs : startTs;
    const end = page === 0 ? endTs : sort === Sort.DESC ? latestTs : endTs;

    // if (page === 0) {
    //   this.cameraThumbnailsService
    //     .getThumbnailsByRange(
    //       start,
    //       end,
    //       this.multiSearch
    //         ? this.currentSearchCameras
    //         : [
    //           {
    //             edgeId: this.edgeId,
    //             cameraId: this.cameraId,
    //           },
    //         ],
    //     )
    //     .pipe(
    //       tap(res => {
    //         this.store$.dispatch(ThumbnailsActions.resetThumbnailsCache());
    //         this.store$.dispatch(ThumbnailsActions.setThumbnailsCache({thumbnails: res}));
    //       }),
    //     )
    //     .subscribe();
    // }

    if (page === 0) {
      this.count = 0;
      this.searchService.count({
            ...searchRequest,
            cameras: this.multiSearch ? this.currentSearchCameras : undefined,
            start: startTs,
            end: endTs,
          },
          SearchType.MOTION_VECTOR,
          {
            edgeId: undefined,
            cameraId: undefined,
            start,
            end,
          })
        .subscribe((count) => {
          this.count = count as any;
        });
    }

    this.searchService
      .searchMotionVector(
        0,
        this.size,
        sort,
        {
          ...searchRequest,
          cameras: this.multiSearch ? this.currentSearchCameras : undefined,
          start: startTs,
          end: endTs,
        },
        {
          edgeId: undefined,
          cameraId: undefined,
          start,
          end,
        },
      )
      .pipe(
        untilDestroyed(this),
        catchError(err => {
          this.loadingMore = false;
          this.fetching = false;
          this.more = false;
          throw err;
        }),
      )
      .subscribe((res: Search.AnalyticSearchResponse) => {
        this.parseSearch(page, res);
      });
  }


  private async searchAccessDoors(page: number, sort: Sort.DESC | Sort.ASC) {
    const startTs = this.cameraThumbnailsService.convertTsToZone(new Date(this.start).getTime(), moment.tz.guess(), this.timezone);
    const endTs = this.cameraThumbnailsService.convertTsToZone(new Date(this.end).getTime(), moment.tz.guess(), this.timezone);


    const searchRequest: Search.AccessDoorsSearchRequest = {
      doorIds: this.accessDoors?.doors.map(door => door.doorId),
      state: this.accessDoors?.status,
      name: this.accessDoors?.personName,
    };

    if (page === 0) {
      this.latestForPage = [];
      this.latestTs = sort === Sort.ASC ? endTs : startTs;
    }

    if (page !== 0) {
      await this.getLatestTs(page - 1);
      if (this.latestForPage[page - 1] === -1) {
        return;
      }
    }

    const latestTs = page === 0 ? 0 : this.latestForPage[page - 1];
    const start = page === 0 ? startTs : sort === Sort.ASC ? latestTs : startTs;
    const end = page === 0 ? endTs : sort === Sort.DESC ? latestTs : endTs;

    if (page === 0) {
      this.count = 0;
      this.searchService.count({
            ...searchRequest,
            cameras: this.multiSearch ? this.currentSearchCameras : undefined,
            start: startTs,
            end: endTs,
          },
          SearchType.ACCESS_DOORS,
          {
            edgeId: undefined,
            cameraId: undefined,
            start,
            end,
          })
        .subscribe((res) => {
          const response: { count: number } = res as unknown as { count: number };
          this.count = response.count;
        });
    }

    this.searchService
      .search(
        0,
        this.size,
        sort,
        SearchType.ACCESS_DOORS,
        {
          ...searchRequest,
          cameras: this.multiSearch ? this.currentSearchCameras : undefined,
          start: startTs,
          end: endTs,
        }, false,
        {
          edgeId: undefined,
          cameraId: undefined,
          start,
          end,
        },
      )
      .pipe(
        untilDestroyed(this),
        catchError(err => {
          this.loadingMore = false;
          this.fetching = false;
          this.more = false;
          throw err;
        }),
      )
      .subscribe((res: Search.AnalyticSearchResponse) => {
        this.parseSearch(page, res);
      });

  }

  async searchUnusualEvent(page: number, sort: Sort) {
    const startTs = this.cameraThumbnailsService.convertTsToZone(new Date(this.start).getTime(), moment.tz.guess(), this.timezone);
    const endTs = this.cameraThumbnailsService.convertTsToZone(new Date(this.end).getTime(), moment.tz.guess(), this.timezone);

    const searchRequest: Search.UnusualEventSearchRequest = {
      sensitivity: this.unusualEvent?.sensitivity,
    };

    if (page === 0) {
      this.latestForPage = [];
      this.latestTs = sort === Sort.ASC ? endTs : startTs;
    }

    if (page !== 0) {
      await this.getLatestTs(page - 1);
      if (this.latestForPage[page - 1] === -1) {
        return;
      }
    }

    const latestTs = page === 0 ? 0 : this.latestForPage[page - 1];
    const start = page === 0 ? startTs : sort === Sort.ASC ? latestTs : startTs;
    const end = page === 0 ? endTs : sort === Sort.DESC ? latestTs : endTs;

    if (page === 0) {
      this.count = 0;
      this.searchService.count({
            ...searchRequest,
            cameras: this.multiSearch ? this.currentSearchCameras : undefined,
            start: startTs,
            end: endTs,
          },
          SearchType.UNUSUAL_EVENTS,
          {
            edgeId: undefined,
            cameraId: undefined,
            start,
            end,
          })
        .subscribe((res) => {
          const response: { count: number } = res as unknown as { count: number };
          this.count = response.count;
        });
    }

    this.searchService
      .searchUnusualEvents(
        0,
        this.size,
        sort,
        {
          ...searchRequest,
          cameras: this.multiSearch ? this.currentSearchCameras : undefined,
          start: startTs,
          end: endTs,
        },
        {
          edgeId: undefined,
          cameraId: undefined,
          start,
          end,
        },
      )
      .pipe(
        untilDestroyed(this),
        catchError(err => {
          this.loadingMore = false;
          this.fetching = false;
          this.more = false;
          throw err;
        }),
      )
      .subscribe((res: Search.AnalyticSearchResponse) => {
        this.parseSearch(page, res);
      });
  }

  isSelection() {
    return !!this.searchSelections && !!this.searchSelections[0]?.type;
  }

  search(page: number = 0) {
    if (this.multiSearch &&
      (!this.type && (!this.isSelection() || this.cameras?.length === 0) && !this.multiTracker && !this.hasFaces)
      && !(this.customEvent && this.cameras?.length !== 0) && !this.motionSearch && !this.unusualEventSearch && !this.widgetDataInfo && !this.widgetCustomEventDataInfo && !(this.accessDoorsSearch && this.accessDoors?.doors?.length)
    ) {
      return;
    }
    this.started = true;
    if (page === 0) {
      this.initMsg = false;
      this.httpService.cancelPendingRequests();
      this.loader = true;
      this.page = 0;
      this.results = [];
      this.rendered = [];
      this.thumbs = [];
      this.more = true;
      this.latestForPage = [];
      if (this.multiSearch) {
        this.currentSearchCameras = this.cameras.map(camera => {
          return {
            edgeId: camera.edgeId,
            cameraId: camera.edgeOnly.cameraId,
            markedIdx: camera.markedIdx,
          };
        });
      }
    } else {
      this.loadingMore = true;
    }

    this.cameraThumbnailsService.setThumbnailsData({
      cameraId: this.cameraId!,
      edgeId: this.edgeId!,
      timezone: this.timezone,
      multiSearch: this.multiSearch ?? undefined,
    });
    const sort = this.sort === CameraThumbnailsSort.DESC ? Sort.DESC : Sort.ASC;


    if (this.customEventSearch) {
      this.fetching = true;
      this.searchCustomEvents(page, sort);
    } else if (this.motionSearch) {
      this.fetching = true;
      this.searchMotion(page, sort);
    } else if (this.accessDoorsSearch) {
      this.fetching = true;
      this.searchAccessDoors(page, sort);
    } else if (this.unusualEventSearch) {
      this.fetching = true;
      this.searchUnusualEvent(page, sort);
    } else if (!!this.type || this.isSelection() || this.multiTracker || this.hasFaces || this.widgetDataInfo) {
      this.fetching = true;
      this.searchAnalytic(page, sort);
    }
  }

  public onScrollDown() {
    if (!this.more || this.fetching) {
      this.loadingMore = false;
      return;
    }
    this.search(++this.page);
  }

  onDateChanged() {
    if (
      !!this.start &&
      !!this.end &&
      !!this.searchSelections?.length &&
      this.cameras?.length &&
      new Date(this.start).getTime() < new Date(this.end).getTime()
    ) {
    }
  }

  clear() {
    this.httpService.cancelPendingRequests();
    this.page = 0;
    this.results = [];
    this.rendered = [];
    this.thumbs = [];
    this.initMsg = true;
    this.count = 0;
    this.fetching = false;
    this.started = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['startInput']) {
      this.start = changes['startInput'].currentValue;
    }
    if (changes['endInput']) {
      this.end = changes['endInput'].currentValue;
    }

    if (changes['customEventSearch'] || changes['unusualEventSearch'] || changes['accessDoorsSearch'] || changes['motionSearch']) {
      if (!!this.customEventSearch || !!this.unusualEventSearch || !!this.accessDoorsSearch || this.motionSearch) {
        this.showObjects = false;
      }
    }

    if (changes.hasOwnProperty('active')) {
      if (!changes['active']?.currentValue) {
        if (!!changes['active'].previousValue) {
          this.clear();
        }
        this.httpService.cancelPendingRequests();
        return;
      }
    }

    if (!this.active || !this.started) {
      return;
    }

    if (changes['sort']) {
      this.rtl = changes['sort'].currentValue === CameraThumbnailsSort.DESC ? true : false;
      this.search();
    }

    if (changes['startInput'] || changes['endInput']) {
      if (this.maskDateChange) {
        return;
      }
      this.search();
    }
  }

  scrolledIndexChange(index: number) {
    this.scrollIndex = index;
    const currentLength = this.thumbs.length;
    if (!!index && index >= currentLength - 5) {
      this.onScrollDown();
    }
  }

  reset() {
    this.initMsg = true;
    this.started = true;
    this.httpService.cancelPendingRequests();
    this.loader = true;
    this.page = 0;
    this.count = 0;
    this.fetching = false;
    this.results = [];
    this.rendered = [];
    this.thumbs = [];
    this.more = true;
    this.latestForPage = [];
    this.loadingMore = false;
  }

  public removeCamera(cameraId: string) {
    this.store$.dispatch(MultiSearchActions.removeSelectedCamera({ cameraId }));
    this.search();
  }

  public removeObject(index: number) {
    this.store$.dispatch(MultiSearchActions.deleteObjectFromSelection({ index }));
  }

  public removeHighConfidence() {
    this.store$.dispatch(MultiSearchActions.setHighConfidence({ highConfidence: false }));
    this.search();
  }

  public removeProperty(index: number, property: SearchSelectionPersonProperty, value: any) {
    this.store$.dispatch(MultiSearchActions.deletePropertyFromSelection({ index, property, value }));
  }

  public getPropertiesChips(index: number) {
    this.selectObjectSelections$.pipe(take(1))
      .subscribe(objectSelections => {
        const object = objectSelections[index];
        let chips = [];
        switch (object.type) {
          case SearchObjectTypes.PERSON:
            if (object?.groupId) {
              const chip = {
                object: object.type,
                key: 'group',
                value: object.groupId,
              };
              chips.push(chip);
            }
            for(let [key, value] of Object.entries(object.properties)) {
              const props = Object.entries(value.props);
              const chip = {
                object: object.type,
                key,
                props,
              };
              if (value.enabled) {
                const colors: string[] = <string[]>value?.props['colors'];
                const types: string[] = <string[]>value?.props['type'];
                if (!!colors?.length) {
                  chip['colors'] = colors;
                }
                if (!!types?.length) {
                  chip['types'] = types;
                }
                chips.push(chip);
              }
            }
            break;
          case SearchObjectTypes.VEHICLE:
            for(let [key, value] of Object.entries(object.properties)) {
              const chip = {
                object: object.type,
                key,
                value,
              };
              chips.push(chip);
            }
            break;
        }
        this.propertyChips = chips;
      });
  }

  public toggleShowObjects() {
    this.showObjects = !this.showObjects;
  }

  public selectPersonById(id: number) {
    return this.store$.select(PeopleSelectors.selectPersonById(id));
  }

  // private async setAlertReplicas(res: ThumbnailModel.ThumbnailDocument[]) {
  //   for (let item of res) {
  //     if (item?.alerts?.length) {
  //       item.alertReplicas = item.alerts.map(alert => {
  //         return this.normalizeTimestamp((alert[0] + alert[1]) / 2) + 1000;
  //       });
  //     }
  //   }
  // }

}
