import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AlertEvent, AlertEventSendModel } from '@models/alert-events.model';
import { api } from '@consts/url.const';
import { SortDirection } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { PaginationResult } from '@models/shared.model';
import { SQSMsgInfo } from '../core/interfaces';
import { AlertCategory, AlertHttpRequest, AlertHttpRequestTestItem, AlertLogsFilter, AlertPromptConfig, AlertV2Document, AlertV2SendModel, EventModels, EventV2Document, FlowLookup, MissingObjectRequest, MissingObjectResponse } from '@models/alerts-v2.model';
import { CameraLookup } from '@models/camera.model';
import { JsonParseIfValid } from '../helpers/common.helpers';
import { AsyncCallModels } from '@models/async-call.models';

@Injectable()
export class AlertEventsService {
  constructor(private http: HttpClient) {
  }

  public getPromptConfig(flowType: number, category: AlertCategory): Observable<AlertPromptConfig> {
    return this.http.get<AlertPromptConfig>(`${api.alertEvents.promptConfig}`, {
      params: { flowType, category },
    });
  }

  public createAlertV2(alertV2: AlertV2SendModel): Observable<AsyncCallModels.ResponseBody<EventModels.EventDocument>> {
    return this.http.post<AsyncCallModels.ResponseBody<EventModels.EventDocument>>(api.alertEvents.create, alertV2);
  }

  public updateEvent(id: string, alertEvent: AlertV2SendModel): Observable<AsyncCallModels.ResponseBody<EventModels.EventDocument>> {
    return this.http.put<AsyncCallModels.ResponseBody<EventModels.EventDocument>>(api.alertEvents.update(id), alertEvent);
  }

  public getSqs(id: string): Observable<any> {
    return this.http.get(api.alertEvents.getSqs(id));
  }

  /**
   * @deprecated
   * user getAlertEventsV2 instead
   * @param page
   * @param perPage
   * @param orderBy
   * @param orderDirection
   * @param query
   * @param cameras
   * @param flowTypes
   */
  public getAlertEvents(
    page: number,
    perPage: number,
    orderBy: string,
    orderDirection: SortDirection,
    query: string,
    cameras: CameraLookup[],
    flowTypes: FlowLookup[],
  ): Observable<PaginationResult<AlertEvent>> {
    let url = `${api.alertEvents.list}?limit=${perPage}&offset=${page * perPage}&orderBy=${orderBy}&orderDirection=${orderDirection}`;
    if (query) {
      url += `&query=${encodeURIComponent(query)}`;
    }
    return this.http.post<PaginationResult<AlertEvent>>(url, { cameras, flowTypes });
  }

  public getSelectedEvent(id: string): Observable<AlertEventSendModel> {
    return this.http.get<AlertEventSendModel>(api.alertEvents.one(id));
  }

  public remove(id: string): Observable<AsyncCallModels.ResponseBody<EventModels.EventDocument>> {
    return this.http.delete<AsyncCallModels.ResponseBody<EventModels.EventDocument>>(api.alertEvents.remove(id));
  }

  public enable(id: string): Observable<AsyncCallModels.ResponseBody<EventModels.EventDocument>> {
    return this.http.put<AsyncCallModels.ResponseBody<EventModels.EventDocument>>(api.alertEvents.enable(id), {});
  }

  public disable(id: string): Observable<AsyncCallModels.ResponseBody<EventModels.EventDocument>> {
    return this.http.put<AsyncCallModels.ResponseBody<EventModels.EventDocument>>(api.alertEvents.disable(id), {});
  }

  public migrate(id: string, alert: AlertEventSendModel): Observable<any> {
    return this.http.post(api.alertEvents.migrate(id), alert);
  }

  public getAllNoPaging(): Observable<AlertV2Document[]> {
    return this.http.get<AlertV2Document[]>(api.alertEvents.allNoPaging, {});
  }

  public testHttp(httpRequest: AlertHttpRequest) {
    return this.http.post<AlertHttpRequestTestItem | SQSMsgInfo[]>(api.alertEvents.testHttp, { httpRequest });
  }

  /**
   * @deprecated
   * @param page
   * @param perPage
   * @param query
   * @param orderBy
   * @param orderDirection
   * @param cameras
   * @param flowTypes
   */
  public getAlertEventsV2(
    page: number,
    perPage: number,
    query: string,
    orderBy: string = 'timestamp',
    orderDirection: SortDirection = 'desc',
    cameras?: CameraLookup[],
    flowTypes?: FlowLookup[],
  ): Observable<PaginationResult<EventV2Document>> {
    let url = `${api.alertEvents.list}?limit=${perPage}&offset=${page * perPage}&orderBy=${orderBy}&orderDirection=${orderDirection}`;
    if (query) {
      url += `&query=${encodeURIComponent(query)}`;
    }
    return this.http.post<PaginationResult<EventV2Document>>(url, { cameras, flowTypes });
  }

  public getMissingObject(missingObjectRequest: MissingObjectRequest) {
    return this.http.post<MissingObjectResponse>(api.alertEvents.missingObject, missingObjectRequest);
  }

  public getEntrances(): Observable<EventV2Document[]> {
    return this.http.get<EventV2Document[]>(api.alertEvents.entrances);
  }

  public getAlertEventsV3(
    limit: number,
    filters: EventModels.Filters,
    pagination: EventModels.Pagination,
  ): Observable<EventV2Document[]> {
    let params = new HttpParams()
      .set('limit', limit.toString());

    if (filters.query) {
      params = params.set('query', encodeURIComponent(filters.query));
    }

    if (filters?.cameras) {
      const cameras = JsonParseIfValid<EventModels.SelectedCamera[]>(filters.cameras);
      params = params.set('cameraIds', cameras.map(camera => camera.cameraId)
        .join(','));
    }

    if (filters?.flowTypes) {
      const flowTypes = JsonParseIfValid<FlowLookup>(filters.flowTypes);
      params = params.set('flowTypes', JSON.stringify(flowTypes));
    }

    if (pagination.lastId) {
      params = params.set('lastId', pagination.lastId);
    }

    if (typeof pagination.lastOrderedValue !== 'undefined' && pagination.lastOrderedValue !== null) {
      params = params.set('lastValue', pagination.lastOrderedValue);
    }

    return this.http.get<EventV2Document[]>(api.alertEvents.getEvents, { params });
  }

}
