import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { GeneaModel } from '@models/genea.model';
import { GeneaActions } from '@states/genea/genea.action-types';

export interface GeneaState extends EntityState<GeneaModel.DocumentMongo> {
  isInitialLoaded: boolean; // show loader until first loading happening
  isCreating: boolean;
  deletingGeneaIds: string[];
  notEmpty: boolean; //show no Data if result empty
}

export const adapter: EntityAdapter<GeneaModel.DocumentMongo> = createEntityAdapter<GeneaModel.DocumentMongo>({
  selectId: (document: GeneaModel.DocumentMongo) => document._id,
});

const { selectAll } = adapter.getSelectors();


const initialState: GeneaState = adapter.getInitialState({
  isInitialLoaded: false,
  isCreating: false,
  deletingGeneaIds: [],
  notEmpty: null,
});


export const geneaStateReducer = createReducer(
  initialState,
  on(GeneaActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(GeneaActions.getOrganizationGeneaDoorsSuccess, (state, { documents }) => {
    return adapter.setAll(documents, {
      ...state,
      isInitialLoaded: true,
      notEmpty: !state.isInitialLoaded ? !!documents.length : state.notEmpty,
    });
  }),
  on(GeneaActions.getOrganizationGeneaDoorsFail, (state) => {
    return {
      ...state,
      isInitialLoaded: true,
    };
  }),
  on(GeneaActions.setIsCreatingLoader, (state, { isCreating }) => {
    return {
      ...state,
      isCreating,
    };
  }),
  on(GeneaActions.startDeleteGenea, (state, { id }) => {
    return {
      ...state,
      deletingGeneaIds: [...state.deletingGeneaIds, id],
    };
  }),
  on(GeneaActions.sendDeleteGeneaRequestSuccess, (state, { id }) => {
    const deletingIndex = state.deletingGeneaIds.findIndex(_id => _id === id);
    let newDeletingArray = [...state.deletingGeneaIds];
    newDeletingArray.splice(deletingIndex, 1);
    return adapter.removeOne(id, {
      ...state,
      deletingGeneaIds: newDeletingArray,
      notEmpty: selectAll(state).length > 1,
    });
  }),
  on(GeneaActions.sendDeleteGeneaRequestFail, (state, { id }) => {
    const deletingIndex = state.deletingGeneaIds.findIndex(_id => _id === id);
    let newDeletingArray = [...state.deletingGeneaIds];
    newDeletingArray.splice(deletingIndex, 1);
    return {
      ...state,
      deletingGeneaIds: newDeletingArray,
    };
  }),
  on(GeneaActions.sendAddGeneaDoorRequestSuccess, (state, { geneaDoor }) => {
    return adapter.setAll([geneaDoor, ...selectAll(state)], { ...state, notEmpty: true });
  }),
  on(GeneaActions.sendUpdateGeneaRequestSuccess, (state, { geneaDoor }) => {
    return adapter.updateOne({
      id: geneaDoor._id,
      changes: {
        ...geneaDoor,
      },
    }, { ...state });
  }),
);
