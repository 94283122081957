import { EdgeCamera } from '../../../cameras/camera.model';
import { createReducer, on } from '@ngrx/store';
import * as AlertEventsActions from '@states/alert-events/alert-events.actions';
import { AlertEventNotification } from '@models/alert-events.model';
import { EventModels, EventV2Document, UiEventSyncResponse } from '@models/alerts-v2.model';
import { DEFAULT_ACCESS_PERIOD_HOURS } from '@consts/shared.const';
import { Dictionary } from '@ngrx/entity/src/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export declare interface AlertEventsState extends EntityState<EventV2Document> {
  initialLoaded: boolean;// show loader until first loading happening
  notEmpty: boolean; //show no Data if result empty
  listLoading: boolean; //show loaded each time when query params changed

  perPage: number;

  //in use in selectors
  //todo ask Roy why we need it
  selectedCamera: EdgeCamera.CameraItem;

  //todo ask Roy why we need it
  notifications: AlertEventNotification;

  eventSyncStatus: Dictionary<Dictionary<UiEventSyncResponse>>;
  pagination: EventModels.Pagination;
  deletingIds: Dictionary<boolean>;
}

export const eventAdapter: EntityAdapter<EventV2Document> = createEntityAdapter<EventV2Document>({
  selectId: (document: EventV2Document) => document._id,
});

export const { selectAll } = eventAdapter.getSelectors();


export const initialState: AlertEventsState = eventAdapter.getInitialState({
  initialLoaded: false,
  notEmpty: false,
  listLoading: false,
  perPage: 20,
  selectedCamera: null,
  notifications: {
    orgUsers: [],
    manualUsers: [],
    notificationMethods: {},
    accessPeriodHours: DEFAULT_ACCESS_PERIOD_HOURS,
  },
  eventSyncStatus: {},
  pagination: EventModels.defaultPagination,
  deletingIds: {},
});

export const alertEventsStateReducer = createReducer(
  initialState,
  on(AlertEventsActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(AlertEventsActions.setNotifications, (state, { notifications }) => {
    return {
      ...state,
      notifications,
    };
  }),
  on(AlertEventsActions.getAlertEventsV3Success, (state, { documents }) => {
    return eventAdapter.setMany(documents, {
      ...state,
      initialLoaded: true,
      notEmpty: !!documents.length,
    });
  }),
  on(AlertEventsActions.getAlertEventsV3Fail, (state) => {
    return {
      ...state,
      initialLoaded: true,
    };
  }),
  on(AlertEventsActions.resetEventSyncStatus, (state, { id }) => {
    const eventSyncStatusMap = { ...state.eventSyncStatus };
    delete eventSyncStatusMap[id];
    return {
      ...state,
      eventSyncStatus: eventSyncStatusMap,
    };
  }),
  on(AlertEventsActions.getEventSyncStatusSuccess, (state, { id, data }) => {
    return {
      ...state,
      eventSyncStatus: {
        ...state.eventSyncStatus,
        [id]: data,
      },
    };
  }),
  on(AlertEventsActions.setPagination, (state, { lastOrderedValue, lastId }) => {
    return {
      ...state,
      pagination: {
        lastId,
        lastOrderedValue,
      },
    };
  }),
  on(AlertEventsActions.resetPagination, (state) => {
    return eventAdapter.removeAll({
      ...state,
      pagination: initialState.pagination,
    });
  }),
  on(AlertEventsActions.removeEventSuccess, (state, { id }) => {
    return eventAdapter.removeOne(id, {
      ...state,
    });
  }),
  on(AlertEventsActions.updateEventSuccess, (state, { document }) => {
    return eventAdapter.updateOne({
      id: document._id,
      changes: {
        ...document,
      },
    }, {
      ...state,
    });
  }),
  on(AlertEventsActions.createEventSuccess, (state, { document }) => {
    const existingEvents = [...selectAll(state)];
    existingEvents.unshift(document);
    return eventAdapter.setAll(existingEvents, {
      ...state,
    });
  }),
  on(AlertEventsActions.setListLoader, (state, { listLoader }) => {
    return {
      ...state,
      listLoading: listLoader,
    };
  }),
  on(AlertEventsActions.setRemovingEventId, (state, { id }) => {
    const deletingIds = { ...state.deletingIds };
    deletingIds[id] = true;
    return {
      ...state,
      deletingIds,
    };
  }),
  on(AlertEventsActions.unSetRemovingEventId, (state, { id }) => {
    const deletingIds = { ...state.deletingIds };
    delete deletingIds[id];
    return {
      ...state,
      deletingIds,
    };
  }),
);
