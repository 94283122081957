import { createAction, props } from '@ngrx/store';
import { StatResult } from '@states/stats/stats.reducer';
import { KeyValuePairs } from '../../../core/interfaces';

export const resetToInitialState = createAction('[STATS] Reset to Initial state');

export const getCameraUptime = createAction(
  '[STATS] Get Camera Uptime',
  props<{ edgeId: string; cameraId: string; period: number; bar: number }>(),
);

export const getCameraUptimeSuccess = createAction('[STATS] Get Camera Uptime Success', props<{ cameraUptime: number[] }>());

export const getAnalyticUptime = createAction(
  '[STATS] Get Analytic Uptime',
  props<{ edgeId: string; cameraId: string; period: number; bar: number }>(),
);

export const getAnalyticUptimeSuccess = createAction('[STATS] Get Analytic Uptime Success', props<{ analyticUptime: number[] }>());

export const getEdgeUptime = createAction('[STATS] Get edge Uptime', props<{ period: number; bar: number }>());
export const getEdgeUptimeAll = createAction('[STATS] Get edge Uptime All', props<{ period: number; bar: number, analytics?: boolean, edgeId?: string, locationId?: string }>());
export const resetEdgeUptimeAll = createAction('[STATS] Reset edge Uptime All');

export const getEdgeUptimeSuccess = createAction('[STATS] Get Camera Uptime Success', props<{ edgeUptime: number[] }>());
export const getEdgeUptimeAllSuccess = createAction('[STATS] Get Camera Uptime All Success', props<{ edgeUptimeAll: StatResult[] }>());

export const getEdgeNetworkBandwidthFromGrafana = createAction('[STATS] Get Camera Uptime All Success', props<{ locationId: string; edgeId: string; timeBack: number }>());
export const getEdgeNetworkBandwidthFromGrafanaSuccess = createAction('[STATS] Get Camera Uptime All Success Success', props<{ result: KeyValuePairs<any> }>());
export const getEdgeNetworkBandwidthFromGrafanaFail = createAction('[STATS] Get Camera Uptime All Success Fail', props<{ errorMessage: string }>());


