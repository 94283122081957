import { Component, Inject, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { SharedEffects } from '@effects/shared.effects';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import * as SharedActions from '@states/shared/shared.actions';
import * as EdgeEditActions from '@states/edge-edit/edge-edit.actions';
import { PreloaderColor } from '@enums/shared.enum';
import { Observable, switchMap } from 'rxjs';
import * as SharedSelector from '@states/shared/shared.selectors';
import { SocketMainConsumerService } from 'src/app/socket/socket-main-consumer.service';
import { EdgeEditEffects } from '@effects/edge-edit.effects';

@UntilDestroy()
@Component({
  selector: 'app-edge-software-version-modal',
  templateUrl: './edge-software-version-modal.component.html',
  styleUrls: ['./edge-software-version-modal.component.scss'],
})
export class EdgeSoftwareVersionModalComponent implements OnInit {
  public selectSomethingWentWrong$: Observable<boolean> = this.store$.pipe(select(SharedSelector.selectSomethingWentWrong));
  public versions: any[] = null;
  public preloaderColor = PreloaderColor;

  constructor(
    private store$: Store<AppState>,
    private sharedEffects: SharedEffects,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      edgeId: string;
      locationId: string;
    },
    private socketMainConsumerService: SocketMainConsumerService,
    private edgeEditEffects$: EdgeEditEffects,
    private actions$: Actions,
  ) {
  }

  ngOnInit(): void {
    this.store$.dispatch(SharedActions.setSomethingWentWrong({ somethingWentWrong: false }));
    this.store$.dispatch(EdgeEditActions.getEdgeSWByIds({ edgeId: this.data.edgeId, locationId: this.data.locationId }));

    this.edgeEditEffects$.getEdgeSWByIds$.pipe(untilDestroyed(this), ofType(EdgeEditActions.getEdgeSWByIdsSuccess))
      .subscribe(({ res }) => {
        this.versions = res.versions;
      });
  }
}
