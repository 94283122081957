import { SNSMsgInterface, TokenDataMessageBase } from '../../core/messaging.interfaces';

/**
 * @deprecated
 */
export interface PaginationResult<T> {
  items: T[];
  totalItemsCount: number;
}

export interface FormOption {
  title: string;
  options?: { name: string; value: string | boolean | number }[];
}

export interface SessionDataSuccessResponse extends TokenDataMessageBase {
  session: any; //todo set type
  result?: any;
  snsMessage?: SNSMsgInterface;
}

export interface UIUptimeModel {
  offline: number;
  online: number;
}

export enum AudioList {
  SoftwareInterface,
  Announcement,
  Arcade,
  DoubleBeep,
  VideoGame
}

export const audioListArray = Object.values(AudioList)
  .filter(value => typeof value === 'number');


export const audioListNames: { [Property in AudioList] } = {
  [AudioList.SoftwareInterface]: 'Software interface',
  [AudioList.Announcement]: 'Announcement',
  [AudioList.Arcade]: 'Arcade',
  [AudioList.DoubleBeep]: 'Double Beep',
  [AudioList.VideoGame]: 'Video GameBeep',
};


export const audioListFiles: { [Property in AudioList] } = {
  [AudioList.SoftwareInterface]: '../assets/audio/SoftwareInterface.wav',
  [AudioList.Announcement]: '../assets/audio/Announcement.wav',
  [AudioList.Arcade]: '../assets/audio/Arcade.wav',
  [AudioList.DoubleBeep]: '../assets/audio/DoubleBeep.wav',
  [AudioList.VideoGame]: '../assets/audio/VideoGame.wav',
};


export interface HashedIds {
  orgId: string;
  orgIdHash: string;
  locationId: string;
  locationIdHash: string;
  edgeId: string;
  edgeIdHash: string;
}
