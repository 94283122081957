import { edgeEditMenuLevel2Validation } from '@consts/menu.const';
import { createReducer, on } from '@ngrx/store';
import * as EdgeEditActions from '@states/edge-edit/edge-edit.actions';
import { Edge } from '../../../edge/edge.model';
import { HeartbeatEntry } from '../../../development/heartbeat.service';
import { ThumbnailEntity } from '../../../cameras/camera-thumbnails/camera-thumbnails.model';

export declare interface EdgeEditState {
  level2MenuValid: { [key: string]: boolean };
  // will need these when routing will be fixed.
  selectedLocationId: string;
  selectedEdgeId: string;
  selectedEdge: Edge.EdgeDocument; //consist Location Id and edge Id,
  logs: string[];
  heartbeats: HeartbeatEntry[];
  systemStatus: [];
  systemStatusDateFrom: number;
  systemStatusDateTo: number;
  systemStatuPaginationTimeStamps: number;
  heartBeatsDateFrom: number;
  heartBeatsDateTo: number;
  thumbnailsBitmap: ThumbnailEntity[];
  heartBeatsPaginationTimeStamps: number;
  hasChanged: boolean;
  edgeRecoveryDocument: any;
  edgeHeartBeatStatus: any[];
  isSaving: boolean;
  pingLoader: boolean;
  digLoader: boolean;
  traceLoader: boolean;
  firewallLoader: boolean;
  speedTestLoader: boolean;
  installationLoader: boolean;
  testConnectionLoader: boolean;
  isRebooting: boolean;
}

export const initialState: EdgeEditState = {
  level2MenuValid: edgeEditMenuLevel2Validation,
  selectedLocationId: null,
  selectedEdgeId: null,
  selectedEdge: null,
  logs: null,
  heartbeats: null,
  systemStatus: null,
  systemStatusDateFrom: null,
  systemStatusDateTo: null,
  systemStatuPaginationTimeStamps: null,
  heartBeatsDateFrom: null,
  heartBeatsDateTo: null,
  thumbnailsBitmap: null,
  heartBeatsPaginationTimeStamps: null,
  hasChanged: false,
  edgeRecoveryDocument: null,
  edgeHeartBeatStatus: null,
  isSaving: false,
  pingLoader: false,
  digLoader: false,
  traceLoader: false,
  firewallLoader: false,
  speedTestLoader: false,
  installationLoader: false,
  testConnectionLoader: false,
  isRebooting: false,
};

export const edgeEditStateReducer = createReducer(
  initialState,
  on(EdgeEditActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(EdgeEditActions.setSelectedLocationId, (state, { selectedLocationId }) => {
    return {
      ...state,
      selectedLocationId,
    };
  }),
  on(EdgeEditActions.setSelectedEdgeId, (state, { selectedEdgeId }) => {
    return {
      ...state,
      selectedEdgeId,
    };
  }),
  on(EdgeEditActions.setSelectedEdge, (state, { selectedEdge }) => {
    return {
      ...state,
      selectedEdge,
    };
  }),
  on(EdgeEditActions.setEdgeAWSLogs, (state, { logs }) => {
    return {
      ...state,
      logs,
    };
  }),
  on(EdgeEditActions.getHeartbeatsSuccess, (state, { heartbeats }) => {
    return {
      ...state,
      heartbeats: state.heartbeats ? state.heartbeats.concat(heartbeats) : [].concat(heartbeats),
    };
  }),
  on(EdgeEditActions.getCameraThumbnailsSuccess, (state, { thumbnailsBitmap }) => {
    return {
      ...state,
      thumbnailsBitmap,
    };
  }),
  on(EdgeEditActions.setHeartbeatsDateRAnge, (state, { from, to }) => {
    return {
      ...state,
      heartBeatsDateFrom: from,
      heartBeatsDateTo: to,
      heartBeatsPaginationTimeStamps: initialState.heartBeatsPaginationTimeStamps,
      heartbeats: initialState.heartbeats,
    };
  }),
  on(EdgeEditActions.setEdgeSystemStatusPagination, state => {
    return {
      ...state,
      //@ts-ignore
      systemStatuPaginationTimeStamps: parseInt(state.systemStatus[state.systemStatus.length - 1].timestamp),
    };
  }),
  on(EdgeEditActions.setHasChanged, (state, { hasChanged }) => {
    return {
      ...state,
      hasChanged,
    };
  }),
  on(EdgeEditActions.getCoreRecoveryDocumentSuccess, (state, { edgeRecoveryDocument }) => {
    return {
      ...state,
      edgeRecoveryDocument,
    };
  }),
  on(EdgeEditActions.getEdgeHeartBeatStatusSuccess, (state, { body }) => {
    return {
      ...state,
      edgeHeartBeatStatus: body,
    };
  }),
  on(EdgeEditActions.setIsSaving, (state, { isSaving }) => {
    return {
      ...state,
      isSaving,
    };
  }),
  on(EdgeEditActions.ping, (state) => {
    return {
      ...state,
      pingLoader: true,
    };
  }),
  on(EdgeEditActions.setPingLoader, (state, { pingLoader }) => {
    return {
      ...state,
      pingLoader: pingLoader,
    };
  }),
  on(EdgeEditActions.dig, (state) => {
    return {
      ...state,
      digLoader: true,
    };
  }),
  on(EdgeEditActions.setDigLoader, (state, { digLoader }) => {
    return {
      ...state,
      digLoader,
    };
  }),
  on(EdgeEditActions.traceroute, (state) => {
    return {
      ...state,
      traceLoader: true,
    };
  }),
  on(EdgeEditActions.setTracerLoader, (state, { traceLoader }) => {
    return {
      ...state,
      traceLoader,
    };
  }),
  on(EdgeEditActions.firewallTest, (state) => {
    return {
      ...state,
      firewallLoader: true,
    };
  }),
  on(EdgeEditActions.setFirewallLoader, (state, { firewallLoader }) => {
    return {
      ...state,
      firewallLoader,
    };
  }),
  on(EdgeEditActions.speedTest, (state) => {
    return {
      ...state,
      speedTestLoader: true,
    };
  }),
  on(EdgeEditActions.setSpeedTestLoader, (state, { speedTestLoader }) => {
    return {
      ...state,
      speedTestLoader,
    };
  }),
  on(EdgeEditActions.checkEdgeInstallation, (state) => {
    return {
      ...state,
      installationLoader: true,
    };
  }),
  on(EdgeEditActions.setInstallationLoader, (state, { installationLoader }) => {
    return {
      ...state,
      installationLoader,
    };
  }),
  on(EdgeEditActions.testConnection, (state) => {
    return {
      ...state,
      testConnectionLoader: true,
    };
  }),
  on(EdgeEditActions.setTestConnectionLoader, (state, { testConnectionLoader }) => {
    return {
      ...state,
      testConnectionLoader,
    };
  }),
  on(EdgeEditActions.setIsRebooting, (state, { isRebooting }) => {
    return {
      ...state,
      isRebooting,
    };
  }),
);
