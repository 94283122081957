import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Edge } from './edge.model';
import { BehaviorSubject, catchError, filter, from, map, Observable, of, Subscription, takeWhile, tap, throwError, timeout, TimeoutError } from 'rxjs';
import { CameraListResponse, CameraUpdateHlsRequest, CameraUpdateRequest, DiscoveryMessageType, SearchDevicesByIpRangeData, SearchDevicesScanCamerasRequest } from '../cameras/camera.model';
import { HttpService } from '../core/http.service';
import { LocationModel } from '../locations/location.model';
import { KeyValuePairs, SQSMsgInfo } from '../core/interfaces';
import { TokenDataMessageBase, TokenDataStatus } from '../core/messaging.interfaces';
import { CreateEdgeToken } from '../core/sessions/create-edge-session';
import { SearchDevicesManualGetCameraDetailsToken } from '../core/sessions/searce-devices-manual-get-camera-details-session';
import { deleteDoc, doc, docSnapshots, DocumentData, DocumentReference, DocumentSnapshot, Firestore } from '@angular/fire/firestore';
import { api } from '@consts/url.const';
import { Dictionary } from '@ngrx/entity/src/models';
import { LocalNetworkWorkerService } from '../development/local-network.worker.service';
import { SHARE_ACCESS_TOKEN_KEY } from '../authentication/authentication.service';
import { SessionStorageService } from '../core/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class EdgeService {
  subscriptions: KeyValuePairs<Subscription> = {};

  private currentEdgeSubject = new BehaviorSubject<Edge.EdgeDocument>({});
  private discoverSubject = new BehaviorSubject<CameraListResponse>({
    cameras: {},
  });

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private firestore: Firestore,
    private localNetworkWorkerService: LocalNetworkWorkerService,
    private sessionStorageService: SessionStorageService) {
  }

  cameraHlsUpdate(data: CameraUpdateHlsRequest): Observable<boolean> {
    const url = `${environment.apiUrl}/cameras-operations/update-camera/hls-config/${data.locationId}/${data.edgeId}/${data.cameraId}`;
    return this.http.post<boolean>(url, data);
  }

  getEdgeLocalNetwork(address: string): Observable<Edge.EdgeDocument> {
    const url = `${address}`;
    return this.http.get<Edge.EdgeDocument>(url)
      .pipe(
        timeout(4000),
        catchError(err => {
          if (err instanceof TimeoutError) {
            return throwError(() => new Error(`ping timout occured for edgeId: ${address} `));
          }
          return throwError(() => err);
        }),
      );
  }

  getEdgeLocalNetworkViaWorker(edgeId: string, address: string): Observable<Edge.EdgeDocument> {
    return this.localNetworkWorkerService.fetchLocalNetworkInWorker(edgeId, address)
      .pipe(
        timeout(4000),
        catchError((err) => {
          if (err instanceof TimeoutError) {
            return throwError(() => new Error(`ping timeout occurred for address: ${address}`));
          }
          return throwError(() => err);
        }),
      );
  }


  getCameraDetails(data: LocationModel.GetCameraDetailsRequest, manual = false): Observable<SearchDevicesManualGetCameraDetailsToken.Result> {
    const url = manual
      ? `${environment.apiUrl}/locations-sync/get-camera-details-manually`
      : `${environment.apiUrl}/locations-sync/get-camera-details`;
    return this.http.post<SearchDevicesManualGetCameraDetailsToken.Result>(url, data);
  }

  getMultipleCameraDetails(data: LocationModel.GetMultipleCameraDetailsRequest): Observable<SQSMsgInfo[]> {
    const url = `${environment.apiUrl}/locations/get-multiple-camera-details-manually`;
    return this.http.post<SQSMsgInfo[]>(url, data);
  }

  getCameraDetailsManually(data: LocationModel.GetCameraDetailsRequest): Observable<SearchDevicesManualGetCameraDetailsToken.Result> {
    const url = `${environment.apiUrl}/locations-sync/get-camera-details-manually`;
    return this.http.post<SearchDevicesManualGetCameraDetailsToken.Result>(url, data);
  }

  addCamerasToLocation(data: LocationModel.AddCamerasToLocationRequest): Observable<SQSMsgInfo[]> {
    const url = `${environment.apiUrl}/locations/add-cameras`;
    return this.http.post<SQSMsgInfo[]>(url, data);
  }

  selectEdge(edge) {
    this.currentEdgeSubject.next(edge);
  }

  discoverCameras(edgeId): Observable<SQSMsgInfo> {
    try {
      const url = `${environment.apiUrl}/catalog/${edgeId}/discovery`;
      return this.http.get<SQSMsgInfo>(url);
    } catch (error) {
      return throwError(() => error);
    }
  }

  scanCameras(edgeId: string, scanData: SearchDevicesByIpRangeData): Observable<SQSMsgInfo> {
    const request: SearchDevicesScanCamerasRequest = {
      edgeId,
      msgType: DiscoveryMessageType.SearchDevicesByIpRange,
      data: scanData,
    };
    try {
      const url = `${environment.apiUrl}/catalog/device-discovery`;
      return this.http.post<SQSMsgInfo>(url, request);
    } catch (error) {
      return throwError(() => error);
    }
  }


  pollConfirmed(token: string): Observable<CreateEdgeToken.AllSessionData> {
    const url = `${environment.apiUrl}/sessions/${token}`;
    return this.httpService.poll<CreateEdgeToken.AllSessionData>(
      url,
      3000,
      (data: CreateEdgeToken.AllSessionData) => {
        return data.status === TokenDataStatus.COMPLETED;
      },
      40000,
    );
  }

  restoreEdge(locationId: string, edgeId: string) {
    const url = api.edge.restore(locationId, edgeId);
    return this.http.get(url);
  }

  public getCertifications(edgeId: string): Observable<Edge.EdgeCertificationManageDocument[]> {
    const url = api.edge.certifications(edgeId);
    return this.http.get<Edge.EdgeCertificationManageDocument[]>(url);
  }

  public updateEdgeWithCertificate(locationId: string, edgeId: string, certId: string): Observable<Edge.EdgeCertificationManageDocument[]> {
    const url = api.edge.updateEdgeWithExistsCert;
    return this.http.post<Edge.EdgeCertificationManageDocument[]>(url, { locationId, edgeId, id: certId });
  }

  public validateExistsCert(locationId: string, edgeId: string, certId: string): Observable<Edge.EdgeCertificationManageDocument[]> {
    const url = api.edge.validateExistsCert;
    return this.http.post<Edge.EdgeCertificationManageDocument[]>(url, { locationId, edgeId, id: certId });
  }

  public getEdgeLocalNetworkUrlBulk(edgeIds: string[]): Observable<Dictionary<Edge.LocalNetworkConfigurationObject>> {
    if (!edgeIds.length) {
      return of(null);
    }
    const sharedToken = this.sessionStorageService.getItem(SHARE_ACCESS_TOKEN_KEY);
    if (sharedToken) {
      const url = `${api.shareApi.getEdgeLocalAddresses}?ids=${edgeIds.join(',')}`;
      return this.http.get<Dictionary<Edge.LocalNetworkConfigurationObject>>(url, {
          params: {
            sharedToken: true,
          },
        })
        .pipe(
          catchError(err => {
            if (err instanceof TimeoutError) {
              return throwError(() => new Error(`ping timout occured for edgeId: ${edgeIds.join(',')} `));
            }
            return throwError(() => err);
          }),
        );
    } else {
      const url = `${api.edge.getEdgeLocalAddresses}?ids=${edgeIds.join(',')}`;
      return this.http.get<Dictionary<Edge.LocalNetworkConfigurationObject>>(url)
        .pipe(
          catchError(err => {
            if (err instanceof TimeoutError) {
              return throwError(() => new Error(`ping timout occured for edgeId: ${edgeIds.join(',')} `));
            }
            return throwError(() => err);
          }),
        );
    }

  }

  public toggleDhcp(edgeId: string, dhcp: Edge.Dhcp, enabled: boolean): Observable<{
    reservedAddresses: Edge.DhcpDevice[],
    attachedAddresses: Edge.DhcpDevice[],
    dhcpForm: Edge.Dhcp
    enabled: boolean,
  }> {
    return this.http.post<{
      reservedAddresses: Edge.DhcpDevice[],
      attachedAddresses: Edge.DhcpDevice[],
      dhcpForm: Edge.Dhcp
      enabled: boolean,
    }>(api.edgeManagement.toggleDhcp(edgeId), { ...dhcp, enabled });
  }

  public dhcpGetConfig(edgeId: string): Observable<{
    reservedAddresses: Edge.DhcpDevice[],
    attachedAddresses: Edge.DhcpDevice[],
    enabled: boolean,
    dhcpForm: Edge.Dhcp
  }> {
    return this.http.get<{
      reservedAddresses: Edge.DhcpDevice[],
      attachedAddresses: Edge.DhcpDevice[],
      enabled: boolean,
      dhcpForm: Edge.Dhcp
    }>(api.edgeManagement.dhcpGetConfig(edgeId));
  }


  public addNewReservedAddress(edgeId: string, dhcp: Edge.DhcpDevice[]): Observable<{
    reservedAddresses: Edge.DhcpDevice[],
    attachedAddresses: Edge.DhcpDevice[],
    enabled: boolean,
    dhcpForm: Edge.Dhcp
  }> {
    return this.http.post<{
      reservedAddresses: Edge.DhcpDevice[],
      attachedAddresses: Edge.DhcpDevice[],
      enabled: boolean,
      dhcpForm: Edge.Dhcp
    }>(api.edgeManagement.addNewReservedAddress(edgeId), { devices: dhcp });
  }
}
