import { createReducer, on } from "@ngrx/store";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { StringOrEmpty } from "../../../app.model";
import { EdgeCamera, ProperFitting } from "../../../cameras/camera.model";
import { CameraActions } from "./camera.action-types";
import { Update } from "@ngrx/entity/src/models";
import { environment } from "../../../../environments/environment";
import { AlertV2Document } from "@models/alerts-v2.model";
import { CameraEditActions } from "@states/camera-edit/camera-edit.action-types";
import { deleteExternalStorageFromCamera, updateCameraLocationCoordsAddress } from "@states/camera/camera.actions";
import { api } from "@consts/url.const";
import * as _ from "lodash";

export interface CameraState extends EntityState<EdgeCamera.CameraItem> {
  isFirstLocationEdgeCameraLoaded: boolean;
  isAllLocationEdgeCameraLoaded: boolean;
  isPagination: boolean;
  error: StringOrEmpty;
  searchQuery: string;
  cameraEvents: { [key: string]: { [alertId: string]: AlertV2Document } };
  properFitting: ProperFitting[];
  scanToken: string;
}

export const adapter: EntityAdapter<EdgeCamera.CameraItem> = createEntityAdapter<EdgeCamera.CameraItem>({
  selectId: (camera: EdgeCamera.CameraItem) => camera.edgeOnly.cameraId,
});

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

const initialLocationEdgeCameraState: CameraState = adapter.getInitialState({
  isFirstLocationEdgeCameraLoaded: false,
  isAllLocationEdgeCameraLoaded: false,
  isPagination: false,
  error: undefined,
  searchQuery: null,
  cameraEvents: {},
  properFitting: null,
  scanToken: null,
});

export const locationEdgeCameraReducer = createReducer(
  initialLocationEdgeCameraState,

  on(CameraActions.GetLocationEdgesCamerasSuccess, (state, action) => {
    if (action.payload.length === 1) {
      const update = _.cloneDeep(action.payload);
      update[0].snapshot = state.entities[update[0]?.edgeOnly?.cameraId]?.snapshot;
      update[0].snapShotTs = state.entities[update[0]?.edgeOnly?.cameraId]?.snapShotTs;
      return adapter.setMany(update, state);
    }
    return adapter.setMany(action.payload, state);
  }),

  on(CameraActions.GetLocationEdgesCamerasFail, (state, action) => {
    return {
      ...initialLocationEdgeCameraState,
      error: action.message,
    };
  }),

  on(CameraActions.CreateLocationEdgeCameraSuccess, (state, action) => {
    return adapter.addOne(action.payload, state);
  }),

  on(CameraActions.CreateLocationEdgeCameraFail, (state, action) => {
    return {
      ...initialLocationEdgeCameraState,
      error: action.message,
    };
  }),

  on(CameraActions.UpdateLocationEdgeCameraSuccess, (state, action) => {
    const update: Update<EdgeCamera.CameraItem> = {
      id: action.payload.cameraId,
      changes: {
        edgeOnly: action.payload,
      },
    };
    return adapter.updateOne(update, state);
  }),

  on(CameraActions.UpdateLocationEdgeCameraCloudOnly, (state, { cameraId, cloudOnly }) => {
    const update: Update<EdgeCamera.CameraItem> = {
      id: cameraId,
      changes: {
        cloudOnly,
      },
    };
    return adapter.updateOne(update, state);
  }),

  on(CameraActions.UpdateCameraIp, (state, action) => {
    const id = action.payload.cameraId;
    const update: Update<EdgeCamera.CameraItem> = {
      id,
      changes: {
        edgeOnly: {
          ...state.entities[id].edgeOnly,
          networkDetails: {
            ...state.entities[id].edgeOnly.networkDetails,
            ipAddress: action.payload.ipAddress,
          },
          sync: true,
        },
      },
    };
    return adapter.updateOne(update, state);
  }),

  on(CameraActions.SetPlaybackSessionId, (state, action) => {
    const update: Update<EdgeCamera.CameraItem> = {
      id: action.payload.cameraId,
      changes: {
        playbackSessionId: action.payload.playbackSessionId,
      },
    };
    return adapter.updateOne(update, state);
  }),

  on(CameraActions.UpdateLocationEdgeCameraFail, (state, action) => {
    return {
      ...initialLocationEdgeCameraState,
      error: action.message,
    };
  }),

  on(CameraActions.DeleteCameraSuccess, (state, action) => {
    return adapter.removeOne(action.response.cameraId, state);
  }),

  on(CameraActions.DeleteCameraFail, (state, action) => {
    return {
      ...state,
      error: action.message,
    };
  }),

  on(CameraActions.GetLocationEdgesCamerasSnapshotsSuccess, (state, action) => {
    const update: Update<EdgeCamera.CameraItem>[] = action.payload.map((snapshot) => {
      return {
        id: snapshot?.cameraId,
        changes: {
          snapshot: `snapshot/${snapshot.edgeId}/${snapshot.cameraId}/${snapshot.thumbnail}`,
          snapShotTs: snapshot.timestamp,
        },
      };
    });
    return adapter.updateMany(update, state);
  }),
  on(CameraActions.deleteExternalStorageFromCamera, (state, { storageId }) => {
    let cameraItems = Object.values(state.entities);
    const update: Update<EdgeCamera.CameraItem>[] = cameraItems
      .filter((camera) => {
        return camera.edgeOnly?.externalStorage?.enabled && camera?.edgeOnly?.externalStorage?.externalStorageId === storageId;
      })
      .map((camera) => {
        return {
          id: camera.cameraId,
          changes: {
            edgeOnly: {
              ...camera.edgeOnly,
              additionalEnabled: false,
              externalStorage: {
                ...camera.edgeOnly.externalStorage,
                enabled: false,
              },
            },
          },
        };
      });
    return adapter.updateMany(update, state);
  }),
  on(CameraActions.SetCameraSnapshotManually, (state, action) => {
    const url = action.url;
    const cameraId = action.cameraId;
    const update: Update<EdgeCamera.CameraItem> = {
      id: cameraId,
      changes: {
        snapshot: url,
      },
    };
    return adapter.updateOne(update, state);
  }),

  on(CameraActions.GetLocationEdgesCamerasSnapshotsFail, (state, action) => {
    return {
      // ...initialLocationEdgeCameraState,
      ...state,
      error: action.message,
    };
  }),
  on(CameraActions.SetSearchQuery, (state, { searchQuery }) => {
    return {
      ...state,
      searchQuery,
    };
  }),
  on(CameraActions.getCameraEventsLookupSuccess, (state, { cameraEvents }) => {
    return {
      ...state,
      cameraEvents,
    };
  }),
  on(CameraActions.SetLastVideoDate, (state, action) => {
    const update: Update<EdgeCamera.CameraItem> = {
      id: action.payload.cameraId,
      changes: {
        lastVideoTimestamp: action.payload.timestamp,
        cameraHealth: action.payload,
      },
    };
    return adapter.updateOne(update, state);
  }),
  on(CameraActions.SetDeleting, (state, action) => {
    const update: Update<EdgeCamera.CameraItem> = {
      id: action.payload.cameraId,
      changes: {
        deleting: action.payload.deleting,
      },
    };
    return adapter.updateOne(update, state);
  }),
  on(CameraActions.getProperFittingSuccess, (state, { data }) => {
    return {
      ...state,
      properFitting: data,
    };
  }),
  on(CameraActions.SetScanToken, (state, { scanToken }) => {
    return {
      ...state,
      scanToken,
    };
  }),
  on(CameraActions.updateCameraLocationCoordsAddress, (state, { cameraId, coords, address }) => {
    return adapter.updateOne(
      {
        id: cameraId,
        changes: {
          cloudOnly: {
            coords,
            address,
          },
        },
      },
      {
        ...state,
      }
    );
  }),
  on(CameraActions.incrementCameraErrorCounter, (state, { cameraId }) => {
    const update: Update<EdgeCamera.CameraItem> = {
      id: cameraId,
      changes: {
        errorCounter: state.entities[cameraId]?.errorCounter ? state.entities[cameraId].errorCounter + 1 : 1,
      },
    };
    return adapter.updateOne(update, state);
  }),
  on(CameraActions.resetCameraErrorCounter, (state, { cameraId }) => {
    const update: Update<EdgeCamera.CameraItem> = {
      id: cameraId,
      changes: {
        errorCounter: 0,
      },
    };
    return adapter.updateOne(update, state);
  })
);
