export namespace WorkerModel {

  export const ALARM_MESSAGE_STATUS = {
    getAlarmEdgeStatus: 'GET_ALARM_EDGE_STATUS',
    getAlarmEdgeStatusSuccess: 'GET_ALARM_EDGE_STATUS_SUCCESS',
    getAlarmEdgeStatusFail: 'GET_ALARM_EDGE_STATUS_FAIL',
  };

  export const EVENT_SYNC_MESSAGE_STATUS = {
    getEventSyncStatus: 'GET_EVENT_SYNC_STATUS',
    getEventSyncStatusSuccess: 'GET_EVENT_SYNC_STATUS_SUCCESS',
    getEventSyncStatusFail: 'GET_EVENT_SYNC_STATUS_FAIL',
  };
}
