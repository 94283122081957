import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AlertEventsState, eventAdapter } from '@states/alert-events/alert-events.reducer';
import { EventModels } from '@models/alerts-v2.model';

export const selectAlertEventsState = createFeatureSelector<AlertEventsState>('alertEventsState');

export const selectSelectedCamera = createSelector(selectAlertEventsState, ({ selectedCamera }: AlertEventsState) => selectedCamera);

export const selectNotifications = createSelector(selectAlertEventsState, ({ notifications }: AlertEventsState) => notifications);

export const selectPerPage = createSelector(selectAlertEventsState, ({ perPage }: AlertEventsState) => perPage);

export const selectEventSyncStatus = createSelector(selectAlertEventsState, ({ eventSyncStatus }: AlertEventsState) => eventSyncStatus);

export const { selectAll: selectEvents } = eventAdapter.getSelectors(selectAlertEventsState);

export const selectInitialLoaded = createSelector(selectAlertEventsState, ({ initialLoaded }: AlertEventsState) => initialLoaded);
export const selectListLoading = createSelector(selectAlertEventsState, ({ listLoading }: AlertEventsState) => listLoading);
export const selectNotEmpty = createSelector(selectAlertEventsState, ({ notEmpty }: AlertEventsState) => notEmpty);

export const selectIsFirstPage = createSelector(selectAlertEventsState, ({ pagination }: AlertEventsState) => pagination.lastId === EventModels.defaultPagination.lastId
  && pagination.lastOrderedValue === EventModels.defaultPagination.lastOrderedValue);

export const selectDeletingIds = createSelector(selectAlertEventsState, ({ deletingIds }: AlertEventsState) => deletingIds);
