import { Component, ElementRef, HostListener, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EdgeCamera } from '../../../../cameras/camera.model';
import { Observable } from 'rxjs';
import { CamerasService } from '../../../../cameras/cameras.service';
import * as _ from 'lodash';
import { AlertEventLineCrossing, AlertEventLineCrossingState } from '@models/alert-events.model';
import { UiLineCrossingComponent } from '../ui-line-crossing.component';
import { Store } from '@ngrx/store';
import { SharedActions } from '@states/shared/shared.action-types';

export interface UiLineCrossingDialogData {
  camera: EdgeCamera.CameraItem;
  lineCrossing?: AlertEventLineCrossing;
}

export interface UiLineCrossingDialogResult {
  lineCrossing?: AlertEventLineCrossing;
}

@Component({
  selector: 'ui-line-crossing-dialog',
  templateUrl: './ui-line-crossing-dialog.component.html',
  styleUrls: ['./ui-line-crossing-dialog.component.scss'],
})
export class UiLineCrossingDialogComponent implements OnInit {
  lineCrossing?: AlertEventLineCrossing;

  @ViewChild('snapshot') snapshot: ElementRef;
  @ViewChild('zoneSelectorWrapper') zoneSelectorWrapper: ElementRef;

  @ViewChild('lineCrossing') lineCrossingSelector: UiLineCrossingComponent;

  timestamp = new Date().getTime();

  imageLoaded = false;

  constructor(
    private store$: Store,
    private camerasService: CamerasService,
    private dialogRef: MatDialogRef<UiLineCrossingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UiLineCrossingDialogData,
  ) {
  }

  ngOnInit(): void {
    this.lineCrossing = !!this.data.lineCrossing ? _.cloneDeep(this.data.lineCrossing) : {};
  }

  cameraSnapshot(cameraId: string): Observable<any> {
    return this.camerasService.getCameraSnapshot(cameraId);
  }

  update(update: { lineCrossing: AlertEventLineCrossing }) {
    this.lineCrossing = update.lineCrossing;
  }

  onImageLoad() {
    this.imageLoaded = true;
  }

  select() {
    const state = this.lineCrossingSelector.lineCrossing.state;
    this.lineCrossing = this.lineCrossingSelector.lineCrossing;
    if (state !== AlertEventLineCrossingState.P2_SELECTED) {
      this.store$.dispatch(SharedActions.showMessage({ warning: 'Please set line crossing' }));
      return;
    }
    this.dialogRef.close({
      lineCrossing: this.lineCrossing,
    });
  }
}
