import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EdgeCamera } from '../../../../cameras/camera.model';
import { Observable } from 'rxjs';
import { CamerasService } from '../../../../cameras/cameras.service';
import * as _ from 'lodash';
import { AlertEventLineCrossingState, AlertEventTrafficControl } from '@models/alert-events.model';
import { UiTrafficControlComponent } from '../ui-traffic-control.component';
import { Store } from '@ngrx/store';
import { SharedActions } from '@states/shared/shared.action-types';

export interface UiTrafficControlDialogData {
  camera: EdgeCamera.CameraItem;
  asLineCrossing?: boolean;
  trafficControl?: AlertEventTrafficControl;
}

export interface UiTrafficControlDialogResult {
  trafficControl?: AlertEventTrafficControl | {};
}

@Component({
  selector: 'ui-line-crossing-dialog',
  templateUrl: './ui-traffic-control-dialog.component.html',
  styleUrls: ['./ui-traffic-control-dialog.component.scss'],
})
export class UiTrafficControlDialogComponent implements OnInit {
  trafficControl?: AlertEventTrafficControl | {};

  @ViewChild('snapshot') snapshot: ElementRef;
  @ViewChild('zoneSelectorWrapper') zoneSelectorWrapper: ElementRef;

  @ViewChild('trafficControl') trafficControlSelector: UiTrafficControlComponent;


  @Input() asLineCrossing = false;

  timestamp = new Date().getTime();

  imageLoaded = false;

  constructor(
    private store$: Store,
    private camerasService: CamerasService,
    private dialogRef: MatDialogRef<UiTrafficControlDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UiTrafficControlDialogData,
  ) {
  }

  ngOnInit(): void {
    this.trafficControl = !!this.data.trafficControl ? _.cloneDeep(this.data.trafficControl) : {};
  }

  cameraSnapshot(cameraId: string): Observable<any> {
    return this.camerasService.getCameraSnapshot(cameraId);
  }

  update(update: { trafficControl: AlertEventTrafficControl }) {
    this.trafficControl = update.trafficControl;
  }

  onImageLoad() {
    this.imageLoaded = true;
  }

  select() {
    const state = this.trafficControlSelector.trafficControl.state;
    const linesLen = this.trafficControlSelector.trafficControl.lines.length;
    this.trafficControl = this.trafficControlSelector.trafficControl;
    if (state !== AlertEventLineCrossingState.NO_SELECTION && linesLen < 2) {
      this.store$.dispatch(SharedActions.showMessage({ warning: 'Please set at least 2 lines' }));
      return;
    }
    const trafficControl = this.trafficControl as AlertEventTrafficControl;
    const result: UiTrafficControlDialogResult = {
      trafficControl: trafficControl?.lines?.length ? trafficControl : null,
    };
    this.dialogRef.close(result);
  }
}
