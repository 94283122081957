import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NotificationActions } from '@states/notification/notification.action-types';
import { MultiPlaybackActions } from '@states/multi-playback/multi-playback.action-types';
import { SharedActions } from '@states/shared/shared.action-types';
import { EdgeActions } from '@states/edge/edge.action-types';
import * as AlertMonitoringActions from '@states/alert-monitoring/alert-monitoring.actions';
import { EdgeHeartbeatPulsationActions } from '@states/edge-heartbeat-pulsation/edge-heartbeat-pulsation-types';
import { CameraHeartbeatPulsationActions } from '@states/camera-heartbeat-pulsation/camera-heartbeat-pulsation-types';
import { AnalyticHeartbeatPulsationActions } from '@states/analytic-heartbeat-pulsation/analytic-heartbeat-pulsation-types';
import { StorageHeartbeatPulsationActions } from '@states/storage-heartbeat-pulsation/storage-heartbeat-pulsation-types';
import { SubstreamHeartbeatPulsationActions } from '@states/substream-heartbeat-pulsation/substream-heartbeat-pulsation-types';
import { SmartStorageHeartbeatPulsationActions } from '@states/smart-storage-heartbeat-pulsation/smart-storage-heartbeat-pulsation-types';
import { DeviceStatusActions } from '@states/device-status/device-status.actions-types';
import { WallV2Actions } from '@states/wall-v2/wall-v2.action-types';
import { getUserNotificationsSuccess } from '@states/notification/notification.actions';
import { AlertEventsActions } from '@states/alert-events/alert-events.action-types';
import { GetEdgeLocalNetworkV3 } from '@states/edge/edge.actions';
import { CameraActions } from '@states/camera/camera.action-types';

export const extModules = [
  StoreDevtoolsModule.instrument({
    maxAge: 100,
    actionsBlocklist: ['[Shared] Set Inactivity Time',
      NotificationActions.getNotificationById.type,
      NotificationActions.getNotificationByIdSuccess.type,
      MultiPlaybackActions.setMove.type,
      MultiPlaybackActions.setDefault.type,
      SharedActions.doNothing.type,
      EdgeActions.ClassifyIpAddress.type,
      AlertMonitoringActions.setNewAlerts.type,
      AlertMonitoringActions.archiveAlertSuccess.type,
      AlertMonitoringActions.newAlertsValidation.type,
      AlertMonitoringActions.refreshAlertsByAutoAcknowledgesAndFrequency.type,
      MultiPlaybackActions.setData.type,
      EdgeHeartbeatPulsationActions.GetEdgePulsationStatus.type,
      EdgeHeartbeatPulsationActions.GetEdgePulsationStatusSuccess.type,
      CameraHeartbeatPulsationActions.GetCameraPulsationStatus.type,
      CameraHeartbeatPulsationActions.GetCameraPulsationStatusSuccess.type,
      AnalyticHeartbeatPulsationActions.GetAnalyticPulsationStatus.type,
      AnalyticHeartbeatPulsationActions.GetAnalyticPulsationStatusSuccess.type,
      StorageHeartbeatPulsationActions.GetStoragePulsationStatus.type,
      StorageHeartbeatPulsationActions.GetStoragePulsationStatusSuccess.type,
      SubstreamHeartbeatPulsationActions.GetSubstreamPulsationStatusSuccess.type,
      SmartStorageHeartbeatPulsationActions.GetSmartStoragePulsationStatusSuccess.type,
      DeviceStatusActions.setCamerasStatus.type,
      DeviceStatusActions.setEdgesStatus.type,
      DeviceStatusActions.setLocationStatus.type,
      DeviceStatusActions.calculateEdgeStatus.type,
      DeviceStatusActions.calculateTrigger.type,
      SharedActions.setInactivityCountdown.type,
      WallV2Actions.updatePlaybackVideoTimeForAlertId.type,
      NotificationActions.getUserNotificationsSuccess.type,
      AlertEventsActions.getEventSyncStatus.type,
      AlertEventsActions.getEventSyncStatusSuccess.type,
      GetEdgeLocalNetworkV3.type,
      CameraActions.getProperFitting.type,

    ],
    connectInZone: true,
  }),
];
