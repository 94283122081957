import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UiLocationSelectItem } from '@models/ui.model';
import { AlertEvent } from '@models/alert-events.model';
import { EdgeCamera } from '../../../cameras/camera.model';
import { CameraEventSelectionResult } from '@models/camera.model';
import { KeyValuePairs } from '../../../core/interfaces';
import { UiZoneSelectorDialogComponent, UiZoneSelectorDialogData, UiZoneSelectorDialogResult } from '../../ui-kit/ui-zone-selector/ui-zone-selector-dialog/ui-zone-selector-dialog.component';
import { ZoneSelectionType } from '../location-row-sub/location-row-sub.component';
import { select, Store } from '@ngrx/store';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { lastValueFrom, map, take } from 'rxjs';
import { LocationModel } from '../../../locations/location.model';
import * as _ from 'lodash';
import { UiLineCrossingDialogComponent } from '../../ui-kit/ui-line-crossing/ui-line-crossing-dialog/ui-line-crossing-dialog.component';
import { UiTrafficControlDialogComponent } from '../../ui-kit/ui-traffic-control/ui-line-crossing-dialog/ui-traffic-control-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MissingObjectRequest, MissingObjectResponse } from '@models/alerts-v2.model';
import { MatDialog } from '@angular/material/dialog';
import { AlertEventsService } from '../../../development/alert-events.service';
import { HomeModel } from '@models/home.model';
import { HomeSelectors } from '@states/home/home.selector-types';
import { getAllDescendantCameras, getAllDescendantNames } from '@states/home/home.selectors';
import { withLatestFrom } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'sublocation-row-sub',
  templateUrl: './sublocation-row-sub.component.html',
  styleUrl: './sublocation-row-sub.component.scss',
})
export class SublocationRowSubComponent implements OnInit {

  @ViewChild('subBlock') subBlock: ElementRef;


  @Input() narrow = false;
  @Input() sublocation: HomeModel.Location;
  @Input() multi: boolean = false;
  @Input() selectedCameras: { [key: string]: boolean } = {};
  @Input() selectedAlerts: { [key: string]: boolean } = {};
  @Input() isSingleLocation: boolean = false;
  @Input() selectedTimeZone: string;
  @Input() zoneSelection: boolean = false;
  @Input() edgeSelection: boolean = false;
  @Input() cameraEventsLookup: { [key: string]: AlertEvent[] } = {};
  @Input() withAlerts: boolean = false;
  @Input() zoneSelectionType: ZoneSelectionType = ZoneSelectionType.ZONES;
  @Input() fetchMissingObjectData = false;
  @Input() selectOnly = false;
  @Input() selectedCameraId: string;

  @Output() onCameraSelected: EventEmitter<EdgeCamera.CameraItem> = new EventEmitter<EdgeCamera.CameraItem>();
  @Output() onEventSelected: EventEmitter<CameraEventSelectionResult> = new EventEmitter<CameraEventSelectionResult>();
  @Output() onSelectZones: EventEmitter<EdgeCamera.CameraItem> = new EventEmitter<EdgeCamera.CameraItem>();
  @Output() onSelectedAll: EventEmitter<EdgeCamera.CameraItem[]> = new EventEmitter<EdgeCamera.CameraItem[]>();
  @Output() onEdgeSelected: EventEmitter<string[]> = new EventEmitter<string[]>();

  public hidden: boolean = false;
  @Input() isSelectedAll: boolean = false;

  @Input() zonesCache: KeyValuePairs<UiZoneSelectorDialogResult> = {};

  @Input() selectedEdges: string[];

  public sublocationHidden: KeyValuePairs<boolean> = {};
  public subLocations: HomeModel.Location[];

  public descCameras: EdgeCamera.CameraItem[] = [];

  public searchQuery: string;

  public fullNames: string[] = [];
  public childNames: string[] = [];

  constructor(private store$: Store, private dialog: MatDialog, public alertEventsService: AlertEventsService) {
  }

  public get cameras() {
    if (this.searchQuery && !this.locationNameMatching && !this.upperLocationNameMatching) {
      return this.sublocation.cameras.filter((camera) => {
        return camera.name.toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      });
    }
    return this.sublocation.cameras;
  }

  public get descMatching(): boolean {
    if (this.searchQuery) {
      return this.descCameras?.length > 0 && this.descCameras?.some((camera) => {
        return camera?.edgeOnly?.name.toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      });
    }
    return this.descCameras?.length > 0;
  }

  public get locationNameMatching(): boolean {
    if (this.searchQuery) {
      return this.sublocation.name.toLowerCase()
        .includes(this.searchQuery.toLowerCase());
    }
    return false;
  }

  public get descLocationNameMatching(): boolean {
    if (this.searchQuery) {
      return this.childNames.some((cameraName) => {
        return cameraName.toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      });
    }
    return false;
  }

  public get upperLocationNameMatching(): boolean {
    if (this.searchQuery) {
      return this.fullNames.some((cameraName) => {
        return cameraName.toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      });
    }
    return false;
  }

  ngOnInit(): void {
    this.store$.select(HomeSelectors.selectSubLocations(this.sublocation?._id))
      .pipe(untilDestroyed(this))
      .subscribe((subLocations) => {
        this.subLocations = subLocations;
        for(let sublocation of this.subLocations) {
          this.sublocationHidden[sublocation._id] = false;
        }
      });

    this.store$.select(HomeSelectors.getAllDescendantCameras(this.sublocation?._id))
      .pipe(take(1))
      .subscribe((descCameras) => {
        this.descCameras = descCameras.map((camera) => {
          const item: EdgeCamera.CameraItem = {
            ...camera,
            edgeOnly: {
              cameraId: camera.cameraId,
              name: camera.name,
            },
          };
          return item;
        });
      });

    this.store$.select(CameraSelectors.selectSearchQuery)
      .pipe(untilDestroyed(this))
      .subscribe((searchQuery) => {
        this.searchQuery = searchQuery;
      });

    this.store$.select(HomeSelectors.selectFullNamesByIdWithBase(this.sublocation?._id))
      .pipe(untilDestroyed(this), withLatestFrom(this.store$.select(HomeSelectors.getAllDescendantNames(this.sublocation?._id))))
      .subscribe(
        ([fullNames, childNames]) => {
          this.fullNames = fullNames;
          this.childNames = childNames;
        },
      );
  }

  public allSublocationCamerasSelected(edgeId: string) {
    return this.descCameras.every((camera) => this.selectedCameras[camera.cameraId]);
  }

  getCameraById(cameraId: string) {
    return this.store$.pipe(select(CameraSelectors.selectCameraById(cameraId)))
      .pipe(
        map((res) => {
          return res as LocationModel.LocationCameraItem;
        }),
      );
  }

  selectCamera(camera: EdgeCamera.CameraItem) {
    if (!this.zonesCache[camera.cameraId]) {
      this.onCameraSelected.emit(camera);
    } else {
      const update = _.cloneDeep(camera);
      const cached = this.zonesCache[camera.cameraId];
      update.zones = cached.zones;
      update.markedIdx = cached.markedIdx;
      update.zonesExclude = cached.exclude;
      this.onCameraSelected.emit(update);
    }
  }

  public selectEvent(event: CameraEventSelectionResult): void {
    this.onEventSelected.emit(event);
  }

  selectZones(camera: EdgeCamera.CameraItem, update?: EdgeCamera.CameraItem) {
    if (update) {
      this.onSelectZones.emit(update);
      return;
    } else {
      this.onSelectZones.emit(camera);
    }
    // if (!this.selectedCameras[camera.edgeOnly.cameraId]) {
    //   return;
    // }
    // const cameraId = camera.edgeOnly.cameraId;
    // const data: UiZoneSelectorDialogData = {
    //   camera,
    //   zones: this.zonesCache[cameraId]?.zones,
    //   markedIdx: this.zonesCache[cameraId]?.markedIdx,
    //   lineCrossing: this.zonesCache[cameraId]?.lineCrossing,
    //   trafficControl: this.zonesCache[cameraId]?.trafficControl,
    //   asLineCrossing: this.zoneSelectionType === ZoneSelectionType.MULTIPLE_LINE_CROSSING,
    //   exclude: this.zonesCache[cameraId]?.exclude,
    //   fetchMissingObjectData: this.fetchMissingObjectData,
    // };
    // let component: any = UiZoneSelectorDialogComponent;
    // switch (this.zoneSelectionType) {
    //   case ZoneSelectionType.ZONES:
    //     component = UiZoneSelectorDialogComponent;
    //     break;
    //   case ZoneSelectionType.LINE_CROSSING:
    //     component = UiLineCrossingDialogComponent;
    //     break;
    //   case ZoneSelectionType.MULTIPLE_LINE_CROSSING:
    //   case ZoneSelectionType.TRAFFIC_CONTROL:
    //     component = UiTrafficControlDialogComponent;
    //     break;
    // }
    // this.dialog
    //   .open(component, {
    //     data,
    //     panelClass: 'modal-no-padding',
    //     maxWidth: '115vh',
    //   })
    //   .afterClosed()
    //   .pipe(untilDestroyed(this))
    //   .subscribe(async (result: UiZoneSelectorDialogResult) => {
    //     if (!result) {
    //       return;
    //     }
    //
    //     if (this.zoneSelectionType === ZoneSelectionType.ZONES && _.isEmpty(result.zones)) {
    //       if (this.zonesCache[cameraId]) {
    //         this.zonesCache = _.omit(this.zonesCache, cameraId);
    //       }
    //     } else if (this.zoneSelectionType === ZoneSelectionType.TRAFFIC_CONTROL && !result.trafficControl) {
    //       if (this.zonesCache[cameraId]) {
    //         delete this.zonesCache[cameraId];
    //       }
    //     } else if (this.zoneSelectionType === ZoneSelectionType.MULTIPLE_LINE_CROSSING && !result.trafficControl) {
    //       if (this.zonesCache[cameraId]) {
    //         delete this.zonesCache[cameraId];
    //       }
    //     } else {
    //       this.zonesCache[cameraId] = result;
    //     }
    //
    //     const update = _.cloneDeep(camera);
    //     update.zones = result.zones;
    //     update.markedIdx = result.markedIdx;
    //     update.lineCrossing = result.lineCrossing;
    //     update.trafficControl = result.trafficControl;
    //     update.zonesExclude = result.exclude;
    //     if (this.fetchMissingObjectData && Object.keys(result?.zones).length > 0) {
    //       const request: MissingObjectRequest = {
    //         zones: result.zones,
    //         snapshotUrl: result.snapshotUrl,
    //       };
    //       const response: MissingObjectResponse = await lastValueFrom(this.alertEventsService.getMissingObject(request));
    //       update.objectsData = response.objectsData;
    //     }
    //     this.onZonesSelection.emit(update);
    //   });
  }

  selectAllSublocationCameras(event, edgeId: string) {
    const checked = event.checked;
    for(let camera of this.descCameras) {
      if (checked && !this.selectedCameras[camera.cameraId]) {
        this.selectCamera(camera);
      }
      if (!checked && this.selectedCameras[camera.cameraId]) {
        this.selectCamera(camera);
      }
    }
  }

  public trackByCameraId(index: number, item: HomeModel.Camera) {
    return item.cameraId;
  }

  public trackBySublocationId(index: number, item: HomeModel.Location) {
    return item._id;
  }

  public get nameWidth(): number {
    return this.subBlock?.nativeElement?.clientWidth - 100;
  }
}
