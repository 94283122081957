import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { RouteActions } from '@states/route/route.action-types';
import * as MenuActions from '@states/menu/menu.actions';
import { MenuKey } from '@enums/menu.enum';
import { routerSegments } from '@consts/routes';
import { catchError, Observable, of, switchMap, take, tap } from 'rxjs';
import { LocationEditSelectors } from '@states/location-edit/location-edit.selector-types';
import { SensorsService } from '../../services/sensors.service';
import { SensorModel } from '@models/sensor.model';
import { Chart, ChartConfiguration, ChartType, registerables } from 'chart.js';
import * as moment from 'moment-timezone';
import { haloEventTypeUnit } from '@consts/sensor.const';
import { CameraLookup } from '@models/camera.model';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { DashboardModel } from '@models/dashboard.model';
import { PreloaderColor, UiCalendarPickerType, UIInputStyle } from '@enums/shared.enum';
import { OnRangeSelectedResult } from '../../shared/ui-kit/ui-calendar-inline/ui-calendar-inline.component';
import { UiDatetimeRangePickerModel } from '../../shared/ui-kit/ui-calendar/ui-datetime-range-picker.model';
import CustomUnit = UiDatetimeRangePickerModel.CustomUnit;
import { UtilsV2Service } from 'src/app/services/utils-v2.service';
import { ConfirmDialogType } from '../../shared/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';

const FIVE_MINUTES = 1000 * 60 * 5;

@Component({
  selector: 'app-sensors-view',
  templateUrl: './sensors-view.component.html',
  styleUrl: './sensors-view.component.scss'
})
export class SensorsViewComponent implements OnInit, AfterViewInit {

  public UiCalendarPickerType = UiCalendarPickerType;
  public PreloaderColor = PreloaderColor;
  public inputStyles = UIInputStyle;

  @ViewChild('chart') chartCanvas: ElementRef<HTMLCanvasElement>;
  public chart: Chart<ChartType, any[], unknown>;

  public selectSelectedLocationId$: Observable<string> = this.store$.pipe(select(LocationEditSelectors.selectedLocationId));

  public sensorId: string;
  public locationId: string;

  public supportTypes: SensorModel.SensorTypeSupport[];

  public sensorData: SensorModel.SensorSearchResult[];
  public sensorType: SensorModel.Halo.HaloEventType;
  public supportTypeIdx: number = 0;

  public haloEventTypeUnit = haloEventTypeUnit;

  public connected = true;

  public last;

  public sensor: SensorModel.Sensor;

  public camera: CameraLookup;
  public cameraIdx = 0;

  public chartLoader = true;

  public dateDefault: OnRangeSelectedResult;
  public timezone = moment.tz.guess();

  private start =  Date.now() - 1000 * 60 * 60 * 24;
  private end = Date.now();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store$: Store,
    private sensorsService: SensorsService,
    private utilsV2Service: UtilsV2Service,
    private confirm: ConfirmDialogService,
  ) {
    Chart.register(...registerables);
  }

  public get isHalo() {
    if (!this.sensor) {
      return false;
    }
    return this.sensor?.vendor === SensorModel.Provider.HALO
  }

  public getCameraName(cameraId: string): Observable<string> {
    return this.store$.pipe(select(CameraSelectors.selectCameraNameById(cameraId)));
  }

  ngOnInit(): void {
    const start = Date.now() - 1000 * 60 * 60 * 24;
    const end = Date.now();
    this.dateDefault = {
      type: UiCalendarPickerType.RELATIVE,
      absolute: {
        start: moment(start)
          .toString(),
        end: moment(end)
          .toString(),
      },
      relative: {
        unit: CustomUnit.days,
        value: 1,
      },
    };
  }

  ngAfterViewInit(): void {
    this.selectSelectedLocationId$.pipe(take(1))
      .subscribe((locationId) => {
        console.log(locationId);
      })

    this.store$.dispatch(
      MenuActions.setActiveLevel2Menu({
        activeLevel2: MenuKey.sensorView,
      }),
    );

    this.route.params.pipe(take(1)).subscribe(params => {
      this.sensorId = params['sensorId'];
      this.locationId = params['locationId'];

      const breadcrumbs = [];
      if (this.isView) {
        breadcrumbs.push({
          name: 'Sensors',
          route: `/${routerSegments.editLocation}/${this.locationId}/${routerSegments.sensors}`
        })
      } else {
        breadcrumbs.push({
          name: 'Home',
          route: `${routerSegments.locationV2}/${routerSegments.home}`
        })
      }

      this.sensorsService.getSensorById(this.sensorId).subscribe((sensor: SensorModel.Sensor) => {
        this.sensor = sensor;
        if (!!this.sensor.cameras?.length) {
          this.camera = this.sensor.cameras[0];
        }
        breadcrumbs.push({
          name: this.sensor?.name
        })
        this.store$.dispatch(RouteActions.setBreadcrumbs({breadcrumbs}))
      })

      this.getSensorSupportTypes();
    });
  }

  get isView() {
    return this.router.url.includes(routerSegments.view);
  }

  getSensorSupportTypes() {
    this.sensorsService.getSensorSupportTypes(this.sensorId).subscribe((data) => {
      this.supportTypes = data;
      this.sensorType = data[0].key as SensorModel.Halo.HaloEventType;
      this.searchSensorData(this.sensorType);
    })
  }

  setSensorType(eventType: string) {
    this.sensorType = eventType as SensorModel.Halo.HaloEventType;
    this.supportTypeIdx = this.supportTypes.findIndex(item => item.key === this.sensorType)
    this.searchSensorData(this.sensorType);
  }

  refresh() {
    this.onRangeChange(this.dateDefault);
  }

  searchSensorData(eventType: SensorModel.Halo.HaloEventType) {
    const request: SensorModel.SensorSearchData = {
      sensorId: this.sensorId,
      start: this.start,
      end: this.end,
      eventType,
    }
    this.chartLoader = true;
    this.sensorsService.searchSensorData(request)
      .pipe(catchError(err => {
        this.chartLoader = false;
        return of();
      }))
      .subscribe((data) => {
      this.sensorData = data.sort((a,b) => a.timestamp - b.timestamp);
      if (this.sensorData?.length && this.dateDefault.type === UiCalendarPickerType.RELATIVE) {
        this.last = this.sensorData[this.sensorData?.length - 1];
        this.connected = this.last.timestamp > Date.now() - FIVE_MINUTES;
      }
      this.createChart();
      this.chartLoader = false;
    })
  }

  private createChart(): void {
    const ctx = this.chartCanvas.nativeElement.getContext('2d');
    if (this.chart) {
      this.chart.destroy();
    }

    const tooltip: any = {
      usePointStyle: true,
      backgroundColor: "#ffffff",
      titleColor: "#000000",
      bodyColor: "#000000",
      borderColor: "#cccccc",
      borderWidth: 1,
      callbacks: {
        label: (context) => {
          return `${context.dataset.label}: ${context.parsed.y} ${this.haloEventTypeUnit[this.sensorType]}`;
        }
      }
    };

    const val = this.supportTypes.find(s => s.key === this.sensorType).name;
    const timeDelta = this.end - this.start;
    const format = timeDelta > 7 * 24 * 60 * 60 * 1000 ? 'MM/DD hh:mm A' :  // more than a week
                  timeDelta > 24 * 60 * 60 * 1000 ? 'ddd hh:mm A' :  // more than a day
                  'hh:mm A';  // less than a day = 'hh:mm a';
    const minData = Math.floor(Math.min(...this.sensorData.map(d => +d.value)));
    const maxData = Math.max(...this.sensorData.map(d => +d.value));
    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.sensorData.map(item => moment(item.timestamp).format(format)),
        datasets: [{
          label: val,
          data: this.sensorData.map(item => item.value),
          borderColor: '#748CED',
          backgroundColor: '#748CED',
          fill: false,
          tension: 0.4
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'category',  // Explicitly specify scale type
            title: {
              display: true,
              text: 'Time'
            }
          },
          y: {
            type: 'linear',  // Explicitly specify scale type
            title: {
              display: true,
              text: `${val} (${this.haloEventTypeUnit[this.sensorType]})`
            },
            min: minData * (minData <= 1 ? 1 : 0.5),
            max: maxData * (maxData <= 1 ? 1.05 : 1.5)
          }
        },
        plugins: {
          legend: {
            display: false,
            position: 'top'
          },
          tooltip,
        },
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false
        }
      }
    });
  }

  public get lastValue() {
    return this.last?.value;
  }

  public get lastTime() {
    return this.last?.timestamp;
  }

  public get supportTypeName() {
    return this.supportTypes ? this.supportTypes[this?.supportTypeIdx]?.name : '';
  }

  public nextCamera() {
    this.cameraIdx = (this.cameraIdx + 1) % this.sensor?.cameras?.length;
    this.camera = this.sensor.cameras[this.cameraIdx];
  }

  public prevCamera() {
    this.cameraIdx = (this.cameraIdx - 1 + this.sensor?.cameras?.length) % this.sensor?.cameras?.length;
    this.camera = this.sensor.cameras[this.cameraIdx];
  }

  public edit() {
    this.router.navigate([routerSegments.editLocation, this.locationId, routerSegments.sensors, routerSegments.edit, this.sensorId]);
  }

  public onRangeChange(value: OnRangeSelectedResult) {
    const date = this.utilsV2Service.dateRangeToServerRequest(value);
    this.dateDefault = value;
    this.start = date.start;
    this.end = date.end;
    this.searchSensorData(this.sensorType);
  }

  public delete() {
    const sensor = this.sensor;
    this.confirm.open({
      title: 'Delete Sensor',
      msg: `Are you sure you want to delete sensor "${sensor.name}"?`,
      confirm: 'Delete',
      cancel: 'Cancel',
      type: ConfirmDialogType.CONFIRM,
    }).pipe(
      switchMap((res) => {
        if (!!res.selection) {
          return this.sensorsService.removeSensor(sensor.sensorId).pipe(
            switchMap(res => {
            if (this.isView) {
              this.router.navigate([routerSegments.editLocation, this.locationId, routerSegments.sensors]);
            } else {
              this.router.navigate([routerSegments.locationV2, routerSegments.home]);
            }
            return [];
          }))
        }
        return [];
      })).subscribe();
  }
}
