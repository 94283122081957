import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberSequence',
  pure: true,
})
export class NumberSequencePipe implements PipeTransform {
  transform(value: number): number[] {
    const result: number[] = [];
    for(let i = 0; i < value; i++) {
      result.push(i);
    }
    return result;
  }
}
