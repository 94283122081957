import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as SearchConfigurationActions from '@states/camera-edit/camera-edit.actions';
import * as MultiSearchActions from '@states/multi-search/multi-search.actions';
import * as SharedActions from '@states/shared/shared.actions';
import { withLatestFrom } from 'rxjs/operators';
import { Action, select, Store } from '@ngrx/store';
import { catchError, exhaustMap, of, share, switchMap } from 'rxjs';
import { AppState } from '../app.state';
import { SearchConfigurationAction, SearchConfigurationSendModel } from '@models/search-configuration.model';
import { SearchConfigurationService } from '../../development/search-configuration.service';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { CameraService } from '../../development/camera.service';
import { CameraActions } from '@states/camera/camera.action-types';
import { LocationsService } from '../../locations/locations.service';
import { EdgeService } from '../../edge/edge.service';
import { LocationModel } from '../../locations/location.model';
import { CameraCloudOnly, CameraUpdateHlsRequest, CameraUpdateRequest } from '../../cameras/camera.model';
import { CameraSearchSettingsService } from '../../development/camera-search-settings.service';
import { CameraSearchSettings } from '@models/camera-search.settings';
import { SearchSelection, SearchSelectionCarProperty, SearchSelectionPersonProperty } from '@models/search.model';
import { PersonSelectionFormFields, SearchObjectTypes, VehicleSelectionFormFields } from '@enums/search.enum';
import { ageTypeRadioValues } from '@consts/alert-events.const';
import { LocationSelectors } from '@states/location/location.selector-types';
import { CameraServiceV2 } from '../../services/camera.service';
import { isAsyncCallResponseError } from '../../helpers/error.helpers';
import { AsyncCallModels } from '@models/async-call.models';
import { UtilsService } from '../../edge/utils.service';
import { LocationActions } from '@states/location/location.action-types';
import { api } from '@consts/url.const';
import { HomeActions } from '@states/home/home.action-types';
import { HttpErrorResponse } from '@angular/common/http';
import { CameraApiService } from '../../services/camera-api.service';
import { EdgeActions } from '@states/edge/edge.action-types';

@Injectable()
export class SearchConfigurationEffect {
  public pressSave$ = createEffect(() => this.actions$.pipe(ofType(SearchConfigurationActions.editCameraPressSave), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public saveSearchConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.saveSearchConfiguration),
      exhaustMap(() => [SharedActions.setIsSaving({ isSaving: true }), SearchConfigurationActions.sendSearchConfiguration()]),
    ),
  );

  public sendSearchConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendSearchConfiguration),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(
        ([
           ,
           {
             selectedCamera,
             zones,
             zonesExclude,
             definedZones,
             protectiveGear,
             forklift,
             container,
             shoppingCart,
             markedIdx,
             objectsToScan,
             licensePlates,
             vehicleMMC,
             privacy,
             selectedSearchConfigurationId,
           },
         ]) => {
          const searchConfiguration: SearchConfigurationSendModel = {
            zones,
            zonesExclude,
            definedZones,
            protectiveGear,
            forklift,
            container,
            shoppingCart,
            markedIdx,
            selectedCamera: {
              cameraId: selectedCamera.edgeOnly.cameraId,
              locationId: selectedCamera.locationId,
              edgeId: selectedCamera.edgeId,
            },
            objectsToScan,
            licensePlates,
            vehicleMMC,
            privacy,
            action: selectedSearchConfigurationId ? SearchConfigurationAction.UPDATE : SearchConfigurationAction.CREATE,
          };
          return this.searchConfigurationService.update(searchConfiguration)
            .pipe(
              switchMap((res) => {
                return [
                  SharedActions.setIsSaving({ isSaving: false }),
                  SharedActions.showMessageFromSocket({ msg: res.responseBody.message, level: res.responseBody.messageLevel }),
                ];
              }),
              catchError(response => {
                const errorMessage = this.utilsService.errMessage(response);
                return [
                  SharedActions.setIsSaving({ isSaving: false }), //loader off
                ];
              }),
            );

        },
      ),
      share(),
    ),
  );

  public getSelectedCameraConfiguration$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SearchConfigurationActions.getSelectedCameraVPNConfiguration),
        withLatestFrom(this.store$.pipe(select(CameraSelectors.selectCameraState))),
        switchMap(([{ locationId, edgeId, cameraId }, allCameras]) => {
          return this.cameraApiService.getDetails(locationId, edgeId, cameraId)
            .pipe(
              switchMap(res => {
                if (!res) {
                  return of(SharedActions.doNothing());
                }
                return [
                  SearchConfigurationActions.getSelectedCameraVPNConfigurationSuccess({ vpnConfiguration: res.vpnConfiguration }),
                ];
              }),
              catchError(response => {
                const errorMessage = this.utilsService.errMessage(response);
                return [
                  SearchConfigurationActions.getSelectedCameraVPNConfigurationFail({ errorMessage }),
                  SharedActions.showMessage({ error: errorMessage }),
                  SharedActions.setIsSaving({ isSaving: false }), //loader off
                ];
              }),
            );
        }),
        share(),
      );
    },
  );

  public getSelectedSearchConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.getSelectedSearchConfiguration),
      withLatestFrom(this.store$.pipe(select(CameraSelectors.selectCameraState))),
      switchMap(([{ locationId, edgeId, cameraId }, allCameras]) => {
        return this.searchConfigurationService.getOne(locationId, edgeId, cameraId)
          .pipe(
            switchMap(selectedSearchConfiguration => {
              if (!selectedSearchConfiguration) {
                return of(SharedActions.doNothing());
              }
              return [
                SearchConfigurationActions.getSelectedSearchConfigurationSuccess({
                  selectedSearchConfiguration: {
                    ...selectedSearchConfiguration,
                    selectedCamera: allCameras.entities[selectedSearchConfiguration.selectedCamera.cameraId],
                  },
                }),
              ];
            }),
            catchError(response => {
              return [
                this.catchError(response),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getCameraById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.getCameraById),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState)), this.store$.pipe(select(CameraSelectors.selectCameraState))),
      exhaustMap(([, { selectedCameraId }, allCameras]) => {
        const camera = allCameras.entities[selectedCameraId];
        return [SearchConfigurationActions.setSelectedCamera({ selectedCamera: camera })];
      }),
    ),
  );

  public deleteSearchConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.deleteSearchConfiguration),
      exhaustMap(() => [SharedActions.setIsDeleting({ isDeleting: true }), SearchConfigurationActions.sendDeleteSearchConfiguration()]),
    ),
  );

  public sendDeleteSearchConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendDeleteSearchConfiguration),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(([, { selectedCamera, selectedSearchConfigurationId }]) => {
        return this.searchConfigurationService
          .remove(selectedSearchConfigurationId, {
            locationId: selectedCamera.locationId,
            edgeId: selectedCamera.edgeId,
            cameraId: selectedCamera.edgeOnly.cameraId,
          })
          .pipe(
            switchMap(res => {
              return [SharedActions.setIsDeleting({ isDeleting: false }), SearchConfigurationActions.deleteSearchConfigurationSuccess()];
            }),
            catchError(response => {
              return [SharedActions.setIsDeleting({ isDeleting: false }), this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public getCameraAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.getCameraAssets),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(([, { selectedCamera }]) => {
        return this.cameraService.get(selectedCamera.edgeId, selectedCamera.edgeOnly.cameraId)
          .pipe(
            switchMap(res => {
              return [
                SearchConfigurationActions.getCameraAssetsSuccess({
                  cameraAssets: res.items,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.setIsDeleting({ isDeleting: false }), this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public deleteCameraAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.deleteCameraAssets),
      switchMap(({ cameraAsset }) => {
        return this.cameraService.delete(cameraAsset)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({
                  success: 'Asset has been deleted',
                }),
                SearchConfigurationActions.getCameraAssets(),
              ];
            }),
            catchError(response => {
              return [SharedActions.setIsDeleting({ isDeleting: false }), this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public notifyCameraAssetsUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.notifyCameraAssetsUpload),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(([{ name }, { selectedCamera }]) => {
        return this.locationsService
          .notifyCameraAssetUploaded({
            locationId: selectedCamera.locationId,
            cameraId: selectedCamera.edgeOnly.cameraId,
            edgeId: selectedCamera.edgeId,
            filename: name,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({
                  success: 'Asset has been uploaded',
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.setIsDeleting({ isDeleting: false }), this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public getCameraDetailsManually$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.getCameraDetailsManually),
      exhaustMap(({ manual, form, cameraId }) => [
        SearchConfigurationActions.sendGetCameraDetailsManually({
          manual,
          form,
          cameraId,
        }),
      ]),
    ),
  );

  public sendGetCameraDetailsManually$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendGetCameraDetailsManually),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState)), this.store$.pipe(select(state => state.cameraState))),
      switchMap(([{ manual, form, cameraId }, { selectedCamera }, camera]) => {
        if (!selectedCamera) {
          if (!cameraId) {
            return [];
          }
          selectedCamera = camera.entities[cameraId];
        }
        const edgeOnly = selectedCamera.edgeOnly;
        const cameraObj = {
          ...form,
          cameraId: edgeOnly.cameraId,
          profile: 0,
          width: 0,
          height: 0,
        };
        const cameraDetailsRequest: LocationModel.GetCameraDetailsRequest = {
          locationId: selectedCamera.locationId,
          edgeId: selectedCamera.edgeId,
          camera: cameraObj,
          create: !manual,
        };
        return this.edgeService.getCameraDetailsManually(cameraDetailsRequest)
          .pipe(
            switchMap(result => {
              return [
                SearchConfigurationActions.sendGetCameraDetailsManuallySuccess({ result }),
              ];
            }),
            catchError(error => {
              return [
                SearchConfigurationActions.sendGetCameraDetailsManuallyFailed({ error }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public sendGetCameraDetailsManuallySuccess$ = createEffect(() => this.actions$.pipe(ofType(SearchConfigurationActions.sendGetCameraDetailsManuallySuccess), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public sendGetCameraDetailsManuallyFailed$ = createEffect(() => this.actions$.pipe(ofType(SearchConfigurationActions.sendGetCameraDetailsManuallyFailed), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public cameraUpdatePTZ$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.cameraUpdatePTZ),
      exhaustMap(({ data }) => [
        SharedActions.setIsSaving({ isSaving: true }), // will be canceled in shared effects
        SearchConfigurationActions.sendCameraUpdatePTZ({
          data,
        }),
      ]),
    ),
  );

  public sendCameraUpdatePTZ$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendCameraUpdatePTZ),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(([{ data }, { selectedCamera }]) => {
        const updatedData: CameraUpdateRequest = {
          cameraId: selectedCamera.edgeOnly.cameraId,
          edgeId: selectedCamera.edgeId,
          locationId: selectedCamera.locationId,
          update: {
            ...data,
          },
        };
        return this.cameraV2Service.updatePTZ(updatedData)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.setIsSaving({ isSaving: false }),
                SharedActions.showMessageFromSocket({ msg: res.responseBody.message, level: res.responseBody.messageLevel }),
                SearchConfigurationActions.sendCameraDetailsUpdateSuccess({ location: res.responseBody.document, cameraId: selectedCamera.edgeOnly.cameraId, edgeId: selectedCamera.edgeId }),
              ];
            }),
            catchError(response => {
              return this.handleCameraUpdateResponseErrors(response, selectedCamera.edgeOnly.cameraId, selectedCamera.edgeId);
            }),
          );
      }),
      share(),
    ),
  );

  public cameraAudioVideoUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.cameraAudioVideoUpdate),
      exhaustMap(({ data }) => [
        SharedActions.setIsSaving({ isSaving: true }), // will be canceled in shared effects
        SearchConfigurationActions.sendCameraAudioVideoUpdate({
          data,
        }),
      ]),
    ),
  );

  public cameraHlsUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.cameraHlsUpdate),
      exhaustMap(({ data }) => [
        SharedActions.setIsSaving({ isSaving: true }), // will be canceled in shared effects
        SearchConfigurationActions.sendCameraHlsUpdate({
          data,
        }),
      ]),
    ),
  );

  public cameraDetailsUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.cameraDetailsUpdate),
      exhaustMap(({ data }) => [
        SharedActions.setIsSaving({ isSaving: true }), // will be canceled in shared effects
        SearchConfigurationActions.sendCameraDetailsUpdate({
          data,
        }),
      ]),
    ),
  );

  public sendCameraAudioVideoUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendCameraAudioVideoUpdate),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(([{ data }, { selectedCamera }]) => {
        const updatedData: CameraUpdateRequest = {
          cameraId: selectedCamera.edgeOnly.cameraId,
          edgeId: selectedCamera.edgeId,
          locationId: selectedCamera.locationId,
          update: {
            ...data,
          },
        };
        return this.cameraV2Service.updateAudioVideoUpdate(updatedData)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.setIsSaving({ isSaving: false }),
                SharedActions.showMessageFromSocket({ msg: res.responseBody.message, level: res.responseBody.messageLevel }),
                SearchConfigurationActions.sendCameraDetailsUpdateSuccess({ location: res.responseBody.document, cameraId: selectedCamera.edgeOnly.cameraId, edgeId: selectedCamera.edgeId }),
              ];
            }),
            catchError(response => {
              return this.handleCameraUpdateResponseErrors(response, selectedCamera.edgeOnly.cameraId, selectedCamera.edgeId);
            }),
          );
      }),
      share(),
    ),
  );

  public sendCameraHlsUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendCameraHlsUpdate),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(([{ data }, { selectedCamera }]) => {
        const updatedData: CameraUpdateHlsRequest = {
          cameraId: selectedCamera.edgeOnly.cameraId,
          edgeId: selectedCamera.edgeId,
          locationId: selectedCamera.locationId,
          hlsConfig: {
            ...data,
          },
        };
        return this.edgeService.cameraHlsUpdate(updatedData)
          .pipe(
            switchMap(res => {
              const cloudOnly: CameraCloudOnly = {
                ...selectedCamera.cloudOnly,
                hlsConfig: {
                  ...selectedCamera.cloudOnly.hlsConfig,
                  ...data,
                },
              };

              return [
                CameraActions.UpdateLocationEdgeCameraCloudOnly({ cameraId: selectedCamera.edgeOnly.cameraId, cloudOnly }),
                SharedActions.showMessage({
                  success: 'HLS Configuration has been updated',
                }),
                SharedActions.setIsSaving({ isSaving: false }),
              ];
            }),
            catchError(response => {
              return [this.catchError(response), SharedActions.setIsSaving({ isSaving: false })];
            }),
          );
      }),
      share(),
    ),
  );

  public sendCameraDetailsUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendCameraDetailsUpdate),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(([{ data }, { selectedCamera }]) => {
        const updatedData: CameraUpdateRequest = {
          cameraId: selectedCamera.edgeOnly.cameraId,
          edgeId: selectedCamera.edgeId,
          locationId: selectedCamera.locationId,
          update: {
            ...data,
          },
        };
        return this.cameraV2Service.updateDetails(updatedData)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.setIsSaving({ isSaving: false }),
                SharedActions.showMessageFromSocket({ msg: res.responseBody.message, level: res.responseBody.messageLevel }),
                SearchConfigurationActions.sendCameraDetailsUpdateSuccess({ location: res.responseBody.document, cameraId: selectedCamera.edgeOnly.cameraId, edgeId: selectedCamera.edgeId }),
              ];
            }),
            catchError(response => {
              return this.handleCameraUpdateResponseErrors(response, selectedCamera.edgeOnly.cameraId, selectedCamera.edgeId);
            }),
          );
      }),
      share(),
    ),
  );

  public cameraUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.cameraUpdate),
      exhaustMap(({ data }) => [
        SharedActions.setIsSaving({ isSaving: true }), // will be canceled in shared effects
        SearchConfigurationActions.sendCameraUpdate({
          data,
        }),
      ]),
    ),
  );

  public sendCameraUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendCameraUpdate),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState)),
        this.store$.pipe(select(LocationSelectors.selectAllLocations))),
      switchMap(([{ data }, { selectedCamera }, locations]) => {
        return [
          SearchConfigurationActions.cameraDetailsUpdate({
            data,
          }),
        ];
      }),
      share(),
    ),
  );


  public cameraStorageUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.cameraStorageUpdate),
      exhaustMap(({ data, camera }) => [
        SharedActions.setIsSaving({ isSaving: true }), // will be canceled in shared effects
        SearchConfigurationActions.sendCameraStorageUpdate({
          data,
          camera,
        }),
      ]),
    ),
  );

  public sendCameraStorageUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendCameraStorageUpdate),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState)),
        this.store$.pipe(select(LocationSelectors.selectAllLocations))),
      switchMap(([{ data, camera }, { selectedCamera }, locations]) => {
        if (!selectedCamera) {
          selectedCamera = camera;
        }
        const timezone = locations.find(location => location._id === selectedCamera.locationId).timezone;
        const updatedData: CameraUpdateRequest = {
          cameraId: selectedCamera.edgeOnly.cameraId,
          edgeId: selectedCamera.edgeId,
          locationId: selectedCamera.locationId,
          update: {
            ...data,
            timezone,
          },
        };
        return this.cameraV2Service.updateStorage(updatedData)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.setIsSaving({ isSaving: false }),
                SharedActions.showMessageFromSocket({ msg: res.responseBody.message, level: res.responseBody.messageLevel }),
                SearchConfigurationActions.sendCameraDetailsUpdateSuccess({ location: res.responseBody.document, cameraId: selectedCamera.edgeOnly.cameraId, edgeId: selectedCamera.edgeId }),
              ];
            }),
            catchError(response => {
              return this.handleCameraUpdateResponseErrors(response, selectedCamera.edgeOnly.cameraId, selectedCamera.edgeId);
            }),
          );
      }),
      share(),
    ),
  );

  public saveCameraSearchSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.saveCameraSearchSettings),
      exhaustMap(({ filters }) => [
        SharedActions.setIsSaving({ isSaving: true }),
        SearchConfigurationActions.sendCameraSearchSettings({ filters }),
      ]),
    ),
  );

  public sendCameraSearchSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendCameraSearchSettings),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(([{ filters }, { selectedCamera, cameraSearchSettings }]) => {
        if (!cameraSearchSettings?._id) {
          const data: CameraSearchSettings = {
            selectedCamera: {
              locationId: selectedCamera.locationId,
              edgeId: selectedCamera.edgeId,
              cameraId: selectedCamera.edgeOnly.cameraId,
            },
            filters,
          };
          return this.cameraSearchSettingsService.create(data)
            .pipe(
              switchMap(res => {
                return [
                  SharedActions.showMessage({ success: 'Camera Search Settings has been created' }),
                  SharedActions.setIsSaving({ isSaving: false }),
                ];
              }),
              catchError(response => {
                return [SharedActions.setIsSaving({ isSaving: false }), this.catchError(response)];
              }),
            );
        } else {
          const data: CameraSearchSettings = {
            selectedCamera: {
              locationId: selectedCamera.locationId,
              edgeId: selectedCamera.edgeId,
              cameraId: selectedCamera.edgeOnly.cameraId,
            },
            filters,
          };
          return this.cameraSearchSettingsService.update(data, cameraSearchSettings._id)
            .pipe(
              switchMap(res => {
                return [
                  SharedActions.showMessage({ success: 'Camera Search Settings has been updated' }),
                  SharedActions.setIsSaving({ isSaving: false }),
                ];
              }),
              catchError(response => {
                return [SharedActions.setIsSaving({ isSaving: false }), this.catchError(response)];
              }),
            );
        }
      }),
      share(),
    ),
  );

  public getCameraSearchSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.getCameraSearchSettings),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(([, { selectedCamera }]) => {
        return this.cameraSearchSettingsService
          .getAll([
            {
              locationId: selectedCamera.locationId,
              edgeId: selectedCamera.edgeId,
              cameraId: selectedCamera.edgeOnly.cameraId,
            },
          ])
          .pipe(
            switchMap(cameraSearchSettings => {
              return [SearchConfigurationActions.getCameraSearchSettingsSuccess({ cameraSearchSettings: cameraSearchSettings[0] })];
            }),
            catchError(response => {
              return [this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public getCameraSearchSettingsMultiSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MultiSearchActions.setSelectedCameras),
      switchMap(({ selectedCameras, noReset }) => {
        if (selectedCameras.length) {
          const mappedCameras = selectedCameras?.map(camera => {
            return {
              locationId: camera?.locationId,
              edgeId: camera?.edgeId,
              cameraId: camera?.edgeOnly?.cameraId,
              markedIdx: camera?.markedIdx,
              zones: camera?.zones,
            };
          });
          return this.cameraSearchSettingsService.getAll(mappedCameras)
            .pipe(
              switchMap(cameraSearchSettings => {
                return [
                  MultiSearchActions.getCamerasSearchSettingsSuccess({
                    cameraSearchSettings: cameraSearchSettings,
                    camerasCountRequested: selectedCameras.length,
                  }),
                ];
              }),
              catchError(response => {
                return [this.catchError(response)];
              }),
            );
        } else {
          return [SharedActions.doNothing()];
        }
      }),
      share(),
    ),
  );

  public startSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MultiSearchActions.setObjectSelection,
        MultiSearchActions.deleteObjectFromSelection,
        MultiSearchActions.deletePropertyFromSelection,
      ),
      withLatestFrom(this.store$.pipe(select(state => state.multiSearchState))),
      switchMap(([, { objectSelections, selectedCameras }]) => {
        const searchSelections: SearchSelection[] = objectSelections?.map(selection => {
          let properties = [];
          let searchSelectionCarProperty: SearchSelectionCarProperty = {};
          const propsKeys = Object.keys(selection.properties ?? {});

          if (selection.type === SearchObjectTypes.PERSON) {
            propsKeys.forEach(propKey => {
              let value = [];
              let colors = [];
              const props = selection.properties[propKey].props;
              switch (propKey) {
                case PersonSelectionFormFields.genderType:
                  const propsKeys = Object.keys(props);
                  propsKeys.forEach(_prop => {
                    if (props[_prop]) {
                      value.push(_prop);
                    }
                  });
                  break;
                case PersonSelectionFormFields.ageType:
                  const ageKeys = Object.keys(props);
                  ageKeys.forEach(_prop => {
                    if (props[_prop]) {
                      value = value.concat(ageTypeRadioValues[_prop]);
                    }
                  });
                  break;
                case PersonSelectionFormFields.footwearType:
                case PersonSelectionFormFields.hairType:
                  colors = (props['colors'] as string[]) ?? [];
                  break;
                case PersonSelectionFormFields.lowerbodyType:
                case PersonSelectionFormFields.upperbodyType:
                  value = (props['type'] as string[]) ?? [];
                  colors = (props['colors'] as string[]) ?? [];
                  break;
                case PersonSelectionFormFields.accessoryType:
                case PersonSelectionFormFields.carryingType:
                  value = (props['type'] as string[]) ?? [];
                  break;
                case PersonSelectionFormFields.protectiveGear:
                  value = (props['type'] as string[]) ?? [];
                  break;
              }
              const prop: SearchSelectionPersonProperty = {
                name: propKey,
                enabled: selection.properties[propKey].enabled,
                operator: selection.properties[propKey].operator,
                value,
                colors,
              };
              properties.push(prop);
            });
          } else if (selection.type === SearchObjectTypes.VEHICLE) {
            const colors = selection.properties[VehicleSelectionFormFields.colors];
            const model = selection.properties[VehicleSelectionFormFields.model];
            const make = selection.properties[VehicleSelectionFormFields.make];
            const types = selection.properties[VehicleSelectionFormFields.type];
            const additionalProperties = selection.properties[VehicleSelectionFormFields.additionalProperty].props;
            const plate = additionalProperties['plate'] as string;
            const region = additionalProperties['region'] as string;

            if (selection.properties[VehicleSelectionFormFields.additionalProperty].enabled) {
              if (plate) {
                searchSelectionCarProperty.plate = plate;
              }
              if (region) {
                searchSelectionCarProperty.region = region;
              }
            }

            if (colors.enabled && colors.value) {
              searchSelectionCarProperty.colors = selection.properties[VehicleSelectionFormFields.colors].value;
            }
            // if (model.enabled && model.value) {
            //   searchSelectionCarProperty.model = selection.properties[VehicleSelectionFormFields.model].value;
            // }
            if (make.enabled && make.value) {
              searchSelectionCarProperty.make = selection.properties[VehicleSelectionFormFields.make].value;
            }
            if (types.enabled && types.value) {
              searchSelectionCarProperty.type = this.convertCarType(selection.properties[VehicleSelectionFormFields.type].value);
            }
          } else if (selection.type === SearchObjectTypes.PET) {
          }

          const result = {
            ...selection,
            groupId: selection.groupIdCollapsed ? selection.groupId : [],
            properties: selection.type === SearchObjectTypes.PERSON ? properties : searchSelectionCarProperty,
          };
          if (selection.type === SearchObjectTypes.PET) {
            delete result.properties;
          }
          if (selection.type === SearchObjectTypes.CONTAINER) {
            result.properties = selection.properties;
          }
          return result;
        });

        return [MultiSearchActions.setObjectSelectionFormatted({ objectSelectionsFormatted: searchSelections })];
      }),
      share(),
    ),
  );

  public cameraVpnUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.cameraVpnUpdate),
      exhaustMap(({ autoConfigure }) => [
        SharedActions.setIsSaving({ isSaving: true }), // will be canceled in shared effects
        SearchConfigurationActions.sendCameraVpnUpdate({ autoConfigure }),
      ]),
    ),
  );

  public sendCameraVpnUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendCameraVpnUpdate),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(([{ autoConfigure }, { selectedCamera, vpnConfiguration }]) => {
        const cameraId = selectedCamera.edgeOnly.cameraId;
        const edgeId = selectedCamera.edgeId;
        const locationId = selectedCamera.locationId;
        return this.cameraApiService.saveDetails({ locationId, edgeId, cameraId }, autoConfigure ? undefined : vpnConfiguration, autoConfigure)
          .pipe(
            switchMap(res => {
              return [
                SearchConfigurationActions.setVpnConfiguration({ vpnConfiguration: res?.vpnConfiguration }),
                EdgeActions.UpdateCameraIpInEdge({ edgeId: edgeId, cameraId: cameraId, ipAddress: res?.vpnConfiguration?.networkParams?.ipAddress }),
                CameraActions.UpdateCameraIp({ payload: { cameraId: cameraId, ipAddress: res?.vpnConfiguration?.networkParams?.ipAddress } }),
                SharedActions.showMessage({ success: 'Configuration saved successfully' }),
                SharedActions.setIsSaving({ isSaving: false }),
              ];
            }),
            catchError(response => {
              const responseError = this.utilsService.errMessage(response);
              return [
                SharedActions.setIsSaving({ isSaving: false }),
                SharedActions.showMessage({ error: responseError }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public cameraVpnReboot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.cameraVpnReboot),
      exhaustMap(({ restartStreamer }) => [
        SearchConfigurationActions.sendCameraVpnReboot({ restartStreamer }),
      ]),
    ),
  );

  public sendCameraVpnReboot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendCameraVpnReboot),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(([{ restartStreamer }, { selectedCamera }]) => {
        const cameraId = selectedCamera.edgeOnly.cameraId;
        const edgeId = selectedCamera.edgeId;
        return this.cameraV2Service.rebootCamera(edgeId, cameraId, restartStreamer)
          .pipe(
            switchMap(res => {
              return [
                SearchConfigurationActions.sendCameraVpnRebootSuccess(),
                SharedActions.showMessage({ success: 'Camera reboot success' }),
                SharedActions.doNothing(),
              ];
            }),
            catchError(response => {
              const errorMessage = this.utilsService.errMessage(response);
              return [
                SharedActions.showMessage({ error: errorMessage }),
                SearchConfigurationActions.sendCameraVpnRebootError(),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getHasProtectiveGear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.getHasProtectiveGear),
      switchMap(() => {
        return this.searchConfigurationService.hasProtectiveGear()
          .pipe(
            switchMap(res => {
              return [
                SearchConfigurationActions.setOrgProtectiveGear({ orgProtectiveGear: res }),
              ];
            }),
            catchError(response => {
              return [SearchConfigurationActions.setOrgProtectiveGear({ orgProtectiveGear: false })];
            }),
          );
      }),
      share(),
    ),
  );

  public getHasOrgForklift$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.getHasOrgForklift),
      switchMap(() => {
        return this.searchConfigurationService.hasForklift()
          .pipe(
            switchMap(res => {
              return [
                SearchConfigurationActions.setOrgForklift({ orgForklift: res }),
              ];
            }),
            catchError(response => {
              return [SearchConfigurationActions.setOrgForklift({ orgForklift: false })];
            }),
          );
      }),
      share(),
    ),
  );


  public getHasOrgContainer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.getHasOrgContainer),
      switchMap(() => {
        return this.searchConfigurationService.hasContainer()
          .pipe(
            switchMap(res => {
              return [
                SearchConfigurationActions.setOrgContainer({ orgContainer: res }),
              ];
            }),
            catchError(response => {
              return [SearchConfigurationActions.setOrgContainer({ orgContainer: false })];
            }),
          );
      }),
      share(),
    ),
  );

  public getHasShoppingCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.getHasShoppingCart),
      switchMap(() => {
        return this.searchConfigurationService.hasShoppingCart()
          .pipe(
            switchMap(res => {
              return [
                SearchConfigurationActions.setOrgShoppingCart({ orgShoppingCart: res }),
              ];
            }),
            catchError(response => {
              return [SearchConfigurationActions.setOrgShoppingCart({ orgShoppingCart: false })];
            }),
          );
      }),
      share(),
    ),
  );

  public sendCameraDetailsUpdateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendCameraDetailsUpdateSuccess),
      switchMap(({ location, cameraId, edgeId }) => {
        const camera = location.edges[edgeId].cameras[cameraId];
        return [
          CameraActions.GetLocationEdgesCamerasSuccess({ payload: [camera] }),
          LocationActions.UpdateLocationNoBackendCall({ location: location }),
          CameraActions.SetCameraSnapshotManually({ cameraId: camera?.edgeOnly?.cameraId, url: `${api.thumbnails.gcpSnapshotsUrl}/${camera?.cloudOnly?.defaultSnapshot}` }),
          HomeActions.getLocations(),
        ];
      }),
      share(),
    ),
  );

  private catchError(response) {
    return SharedActions.showMessage({ error: response?.error?.message });
  }


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private searchConfigurationService: SearchConfigurationService,
    private cameraService: CameraService,
    private locationsService: LocationsService,
    private edgeService: EdgeService,
    private cameraSearchSettingsService: CameraSearchSettingsService,
    private cameraV2Service: CameraServiceV2,
    private utilsService: UtilsService,
    private cameraApiService: CameraApiService,
  ) {
  }

  private convertCarType(type: string[]) {
    const result: string[] = [...type];
    const isCar = type.includes('car');
    const isTruck = type.includes('truck');

    if (isCar) {
      result.push('sedan', 'suv', 'van');
      result.splice(result.indexOf('car'), 1);
    }

    if (isTruck) {
      // result.push('pickup truck', 'big truck');
      result.push('big truck');
      result.splice(result.indexOf('truck'), 1);
    }


    return result;
  }

  private handleCameraUpdateResponseErrors(response: HttpErrorResponse, cameraId: string, edgeId: string) {
    const actions: Action[] = [
      SharedActions.setIsSaving({ isSaving: false }),
    ];
    if (isAsyncCallResponseError(response)) {
      const responseBody = response.error.responseBody as AsyncCallModels.ResponseBody<LocationModel.LocationItem>;
      actions.push(
        SharedActions.showMessageFromSocket({ level: responseBody?.messageLevel, msg: responseBody?.message }),
        SearchConfigurationActions.sendCameraDetailsUpdateSuccess({ location: responseBody?.document, cameraId: cameraId, edgeId: edgeId }),
      );
    } else {
      actions.push(
        SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
      );
    }
    return actions;
  }
}
