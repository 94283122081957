import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { LocationsService } from '../locations.service';
import { ComplianceOption, LocationModel } from '../location.model';
import { catchError, concatMap, filter, map, Observable, startWith, Subscription, tap, throwError } from 'rxjs';
import { EdgeService } from '../../edge/edge.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AppState } from 'src/app/reducers';
import { select, Store } from '@ngrx/store';
import { LocationActions } from '@states/location/location.action-types';
import { EdgeActions } from '@states/edge/edge.action-types';
import * as moment from 'moment';
import { Edge } from '../../edge/edge.model';
import { EdgeStatusService } from '../../edge/edge-status.service';
import { routerSegments } from '@consts/routes';
import { PulsationModels } from '@models/pulsation.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UiBreadCrumbItem } from '@models/route.models';
import { EdgeEffects } from '@effects/edge.effects';
import { ofType } from '@ngrx/effects';
import { UtilsService } from '../../edge/utils.service';

export enum AddLocationStep {
  ADD_LOCATION,
  LOCATION_COMPLIANCE,
  ADD_EDGE,
}

@UntilDestroy()
@Component({
  selector: 'app-location-add',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.scss'],
})
export class AddLocationComponent implements OnInit, OnDestroy {

  public loading = true;

  public routerSegments = routerSegments;


  options$: Observable<LocationModel.AddLocationOptions | undefined>;
  AddLocationStep: typeof AddLocationStep = AddLocationStep;

  newLocationId: string;

  currentLocation: LocationModel.LocationItem | undefined;

  connectedEdgeId: string;
  connectedEdgeName: string;
  connectLoader = false;
  connectedEdgeIdError: string;
  connectedEdgeIdErrorLastStatusCode: number;


  @ViewChild('stepper')
  stepper: MatStepper;

  isLinear = true;
  addLocationFormGroup: UntypedFormGroup;
  addEdgeFormGroup: UntypedFormGroup;
  lastToken;
  baseStep: AddLocationStep = AddLocationStep.ADD_LOCATION;

  // For testing purposes
  selectedIndex = 0;
  dev = false;
  tzNames: string[] = [];
  tzNamesFilter: Observable<string[]>;

  editLocation: boolean = false;
  edge: Edge.EdgeDocument;
  edgeUpdate: Partial<Edge.EdgeDocument> = {};
  updateLoader = false;

  locationId: string;
  locationName: string;
  edgeId: string;
  edgeName: string;

  ipLoading = true;
  eth0;
  eth1;

  subscriptions: Subscription[] = [];

  settingsOpened = false;

  breadCrumbs: UiBreadCrumbItem[] = [];

  public ComplianceOption = ComplianceOption;
  public ComplianceOptionKeys = Object.keys(ComplianceOption)
    .filter(key => !isNaN(Number(key)));


  constructor(
    private _formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private locationsService: LocationsService,
    private edgeService: EdgeService,
    private router: Router,
    private edgeStatusService: EdgeStatusService,
    private edgeEffects: EdgeEffects,
    private utilsService: UtilsService,
  ) {
  }

  ngOnDestroy(): void {
    for(let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.tzNames.filter(option => option.toLowerCase()
      .includes(filterValue));
  }

  tzFormat(name: string) {
    return moment.tz(name)
      .format('Z z');
  }

  getEdgeStatus(edgeId: string): Observable<PulsationModels.ComponentStatus> {
    return this.edgeStatusService.getEdgePulsationStatus(edgeId);
  }

  setBreadCrumbs() {
    this.breadCrumbs = [];
    this.breadCrumbs.push({ name: 'Home', route: routerSegments.locationV2 });
    const base =
      this.currentLocation || this.locationName
        ? this.locationName
          ? this.locationName
          : this.currentLocation.name
          : 'Create location';
    this.breadCrumbs.push({ name: base });
    if (this.baseStep === AddLocationStep.ADD_EDGE) {
      this.breadCrumbs.push({ name: 'Add core' });
    }
    if (this.connectedEdgeName) {
      this.breadCrumbs.push({ name: this.connectedEdgeName });
    }
  }

  ngOnInit() {
    this.edgeEffects
      .createEdgeServerRequest$
      .pipe(
        untilDestroyed(this),
        ofType(EdgeActions.createEdgeServerRequestSuccess),
        map((res) => {
          this.connectLoader = false;
          this.store.dispatch(LocationActions.GetLocations());
          this.connectedEdgeId = res.edge.edgeId;
          this.connectedEdgeName = this.addEdgeFormGroup.get('name')?.value;
          this.addEdgeFormGroup.disable();
        }),
      )
      .subscribe();

    this.edgeEffects
      .createEdgeServerRequest$
      .pipe(
        untilDestroyed(this),
        ofType(EdgeActions.createEdgeServerRequestFail),
        map((res) => {
          const e: HttpErrorResponse = res.err;
          this.connectLoader = false;
          this.connectedEdgeIdError = this.utilsService.errMessage(res.err);
          this.connectedEdgeIdErrorLastStatusCode = e?.error?.statusCode;
        }),
      )
      .subscribe();

    this.addLocationFormGroup = this._formBuilder.group({
      name: ['Untitled location', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', []],
      zip: ['', []],
      timezone: [moment.tz.guess(), Validators.required],
      contact: ['', []],
      phone: ['', []],
      complianceOption: ['0'],
      genderClassification: [true, []],
      faceRecognition: [true, []],
    });

    this.addLocationFormGroup.get('complianceOption')
      .valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (+value === ComplianceOption.INDEPENDENT) {
          this.addLocationFormGroup.patchValue({
            genderClassification: true,
            faceRecognition: true,
          });
        } else {
          this.addLocationFormGroup.patchValue({
            genderClassification: false,
            faceRecognition: false,
          });
        }
      });
    this.addEdgeFormGroup = this._formBuilder.group({
      name: ['Untitled core', Validators.required],
      edgeId: [null, Validators.required],
      maxStorage: [''],
    });

    this.tzNames = moment.tz.names();
    this.tzNamesFilter = this.addLocationFormGroup.get('timezone')!.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );

    this.options$ = this.locationsService.addLocationOptions$;

    this.options$.subscribe(options => {

      if (!!options?.step && !this.editLocation) {
        this.baseStep = options.step;
      }
      if (!!options?.location?._id) {
        this.currentLocation = options.location;
        this.newLocationId = options.location._id;
        this.loading = false;
      } else {
        this.loading = false;
        // this.router.navigate([routerSegments.locationV2]);
      }

    });
    // For dev purposes
    if (this.dev) {
      this.selectedIndex = AddLocationStep.ADD_EDGE;
      this.newLocationId = '62309f45b6c09b5701a7e50d';
      this.connectedEdgeId = 'N0sitmQhJb';
    }

    this.setBreadCrumbs();

  }


  addLocation(request: LocationModel.LocationCreateRequest) {
    if (this.editLocation) {
      request._id = this.locationId;
      return this.locationsService.updateLocation(request);
    }

    if (this.newLocationId) {
      request._id = this.newLocationId;
    }
    return this.locationsService.createLocation(request);
  }

  pollCreateEdge(token, edgeId) {
    this.edgeService
      .pollConfirmed(token)
      .pipe(
        catchError(error => {
          this.connectLoader = false;
          let errParsed;
          if (error.message === 'Timeout has occurred') {
            errParsed = { msg: 'Core confirmation timed out, please try again' };
          } else {
            errParsed = JSON.parse(error.message);
          }
          // TODO: Manage timeout error
          this.connectedEdgeIdError = errParsed.msg || 'Core confirmation timed out, please try again';
          this.connectedEdgeIdErrorLastStatusCode = error?.error?.statusCode || 500;
          return throwError(() => error);
        }),
      )
      .subscribe(res => {
        this.store.dispatch(EdgeActions.CreateLocationEdgeNoBackendCall({ request: res.result! }));
        this.connectLoader = false;
        this.store.dispatch(LocationActions.GetLocations());
        this.connectedEdgeId = edgeId;
        this.connectedEdgeName = this.addEdgeFormGroup.get('name')?.value;
        this.addEdgeFormGroup.disable();
      });
  }

  addEdgeToLocation() {
    this.connectedEdgeIdError = '';
    const request: LocationModel.AddEdgeToLocationRequest = this.addEdgeFormGroup.value;
    const edgeId = this.addEdgeFormGroup.get('edgeId')?.value;
    request.locationId = this.newLocationId;
    this.connectLoader = true;
    this.connectedEdgeIdError = '';
    if (this.connectedEdgeIdErrorLastStatusCode === 409 && this.lastToken) {
      this.pollCreateEdge(this.lastToken, edgeId);
      return;
    }
    this.store.dispatch(EdgeActions.startCreateEdge({ request }));
  }


  numSteps() {
    return 3 - this.baseStep;
  }

  stepsArray() {
    return [...Array(this.numSteps())
      .keys()];
  }

  async back() {
    this.stepper.previous();
  }

  async next() {
    if (!this.stepper.selected?.stepControl.valid) {
      this.stepper.selected?.stepControl.markAllAsTouched();
      if (!(this.stepper.selectedIndex + this.baseStep === AddLocationStep.ADD_EDGE)) {
        return;
      }
    }
    switch (this.baseStep + this.stepper.selectedIndex) {
      case AddLocationStep.ADD_LOCATION:
        this.stepper.next();
        break;
      case AddLocationStep.LOCATION_COMPLIANCE:
        const request: LocationModel.LocationCreateRequest = this.addLocationFormGroup.value;
        this.addLocation(request)
          .subscribe(res => {
            this.store.dispatch(
              LocationActions.CreateLocationNoBackendCall({
                request: {
                  ...request,
                  _id: this.editLocation ? this.locationId : res._id,
                },
              }),
            );
            if (this.editLocation) {
              this.router.navigateByUrl(`location/page/${this.locationId}`);
            } else {
              this.locationsService.getLocations();
              this.newLocationId = res._id;
              this.currentLocation = { ...request, _id: res._id };
            }
            this.stepper.next();
            this.setBreadCrumbs();
          });
        break;
      case AddLocationStep.ADD_EDGE:
        if (this.connectedEdgeId) {
          // this.discoverCameras(true);
          // this.stepper.next();
          // this.setBreadCrumbs();
          this.router.navigateByUrl('location');
        }
        break;
    }
  }

  stepEnabled(step: AddLocationStep) {
    return step >= this.baseStep;
  }

  cancelFinish() {
    if (this.currentLocation) {
      this.locationsService.setLocation(this.currentLocation);
      this.router.navigateByUrl(`location/page/${this.currentLocation._id}`);
    } else {
      if (this.editLocation) {
        this.router.navigateByUrl(`location/page/${this.locationId}`);
      }
      this.router.navigateByUrl('location');
    }
  }

  settings(edgeId: string) {
    this.settingsOpened = true;
  }

  // ngOnDestroy(): void {
  //   this.discoverySubscription.unsubscribe()
  // }
}
