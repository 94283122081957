import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ui-something-went-wrong',
  templateUrl: './ui-something-went-wrong.component.html',
  styleUrls: ['./ui-something-went-wrong.component.scss'],
})
export class UiSomethingWentWrongComponent implements OnInit {

  @Input() errorMessage: string = 'Something went wrong';

  constructor() {
  }

  ngOnInit(): void {
  }
}
