import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, take, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { AppState } from '../store/app.state';
import { UserService } from '../user/user.service';
import { UserSelectors } from '@states/user/user.selector-types';
import { PermissionModel } from '@models/permission.model';

export interface MainMenuConfig {
  isInitiallyOpened?: boolean;
  displayIconsOnCollapse?: boolean;
  toogleIconViewOnCollapse?: boolean;
  items: MainMenuItem[];
  developerItems: MainMenuItem[];
}

export interface MainMenuState {
  wallsExapnded: boolean;
  developerExpanded: boolean;
}

export interface MainMenuItem {
  icon?: string;
  svg?: string;
  displayName: string;
  weight?: number;
  path: string;
  target?: string;
  roles: string[];
  disabled?: boolean;
  class?: string;
  permissions: PermissionModel.Permissions[];
}

@Injectable({
  providedIn: 'root',
})
export class MainMenuService {
  private _mainMenuConfig: MainMenuConfig;
  private openedSubject = new BehaviorSubject<boolean>(true);
  private toggleSubject = new Subject<unknown>();
  private stateSubject = new BehaviorSubject<MainMenuState>({
    wallsExapnded: false,
    developerExpanded: false,
  });

  public opened$: Observable<boolean> = this.openedSubject.asObservable();
  public state$: Observable<MainMenuState> = this.stateSubject.asObservable();
  public toggle$: Observable<unknown> = this.toggleSubject.asObservable();

  public selectIsDeveloper$: Observable<boolean> = this.store$.pipe(select(UserSelectors.isDeveloper));

  constructor(private store$: Store<AppState>, private userService: UserService) {
  }

  set config(config) {
    this._mainMenuConfig = config;
  }

  get config() {
    return this._mainMenuConfig;
  }


  setState(state: MainMenuState) {
    this.stateSubject.next(state);
  }

  developerRole() {
    return environment.env === 'dev' || environment.env === 'localNoKube' || environment.env === 'dev-hosted' ? ['user'] : ['admin'];
  }

  isDeveloper() {
    let developer = false;

    this.selectIsDeveloper$
      .pipe(
        take(1),
        tap(res => (developer = res)),
      )
      .subscribe();

    return developer;
  }

  toggle() {
    this.toggleSubject.next(null);
  }
}
