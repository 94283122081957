import { createAction, props } from '@ngrx/store';
import { GetEdgeIpAddressToken } from '../../../core/sessions/get-edge-ip-address-session';
import { Edge } from '../../../edge/edge.model';
import { LocationModel } from '../../../locations/location.model';
import { GrafanaModels } from '@models/grafana.model';
import { KeyValuePairs } from '../../../core/interfaces';
import { PulsationModels } from '@models/pulsation.model';
import { HttpErrorResponse } from '@angular/common/http';

enum LocationEdgeActionTypes {
  GetLocationEdges = '[Edges API] GetLocationEdges',

  GetLocationEdgesNoBackendCall = '[Edges API] GetLocationEdgesNoBackendCall',
  GetLocationEdgesSuccess = '[Edges API] GetLocationEdges Success',
  GetLocationEdgesFail = '[Edges API] GetLocationEdges Fail',

  CreateLocationEdgeNoBackendCall = '[Cameras API] CreateLocationEdgeNoBackendCall',
  CreateLocationEdgeSuccess = '[Cameras API] CreateLocationEdge Success',
  CreateLocationEdgeFail = '[Cameras API] CreateLocationEdge Fail',

  ResetEdgeIpAddress = '[Edges API] ResetEdgeIpAddress',
  GetEdgeIpAddressFromGrafana = '[Edges API] GetEdgeIpAddressFromGrafana',
  ClassifyIpAddressFromGrafana = '[Edges API] ClassifyIpAddressFromGrafana',
  GetEdgeIpAddress = '[Edges API] GetEdgeIpAddress',
  GetEdgeIpAddressSubscribeToSessionStatus = '[Edges API] GetEdgeIpAddressSubscribeToSessionStatus',
  GetEdgeIpAddressSessionStatusChanged = '[Edges API] GetEdgeIpAddressSessionStatusChanged',
  GetEdgeLocalNetwork = '[Edges API] GetEdgeLocalNetwork',
  CreateOrUpdateLocalProxy = '[Edges API] CreateOrUpdateLocalProxy',
  ClassifyIpAddress = '[Edges API] ClassifyIpAddress',
  DeleteEdgeIpAddressToken = '[Edges API] DeleteEdgeIpAddressToken',

  UpdateEdgeLocalAddress = '[Edges API] UpdateEdgeLocalAddress',
  UpdateEdgeLocalAddressSuccess = '[Edges API] UpdateEdgeLocalAddress success',
  UpdateEdgeLocalAddressFail = '[Edges API] UpdateEdgeLocalAddress fail',

  UpdateEdgeLocalIp = '[Edges API] UpdateEdgeLocalIp',

  SetEdgeIsLocalDebug = '[Edges API] SetEdgeIsLocalDebug',
  ResetEdgeIsLocal = '[Edges API] ReseetEdgeIsLocal',
  ResetEdgesIsLocal = '[Edges API] ResetEdgesIsLocal',

  UpdateEdgeNoBackendCall = '[Edges API] UpdateEdgeNoBackendCall',
  UpdateEdgeNoBackendCallSuccess = '[Edges API] UpdateEdgeNoBackendCall success',
  UpdateEdgeNoBackendCallFail = '[Edges API] UpdateEdgeNoBackendCall fail',

  DeleteEdgeNoBackendCall = '[Edges API] DeleteEdgeNoBackendCall',
  DeleteEdge = '[Edges API] DeleteEdge',
  DeleteEdgeSuccess = '[Edges API] DeleteEdgeSuccess',
  DeleteEdgeFail = '[Edges API] DeleteEdgeFail',

  CanEdgeLocalStream = '[Edges API] CanEdgeLocalStream',
  CanEdgeLocalStreamSuccess = '[Edges API] CanEdgeLocalStreamSuccess',
  CanEdgeLocalStreamFail = '[Edges API] CanEdgeLocalStreamFail',

  GetLastVideoDates = '[Edges API] GetLastVideoDates',
  GetLastVideoDatesSuccess = '[Edges API] GetLastVideoDatesSuccess',
  GetLastVideoDatesSubscribeToSessionStatus = '[Edges API] GetLastVideoDatesSubscribeToSessionStatus',
  GetLastVideoDatesSessionStatusChanged = '[Edges API] GetLastVideoDatesSessionStatusChanged',
}


export const GetLocationEdgesNoBackendCall = createAction(LocationEdgeActionTypes.GetLocationEdgesNoBackendCall, props<{ payload: Edge.EdgeDocument[] }>());

export const GetLocationEdgesSuccess = createAction(LocationEdgeActionTypes.GetLocationEdgesSuccess, props<{ payload: Edge.EdgeDocument[] }>());

export const GetLocationEdgesFail = createAction(LocationEdgeActionTypes.GetLocationEdgesFail, props<{ message?: string }>());

export const CreateLocationEdgeNoBackendCall = createAction(LocationEdgeActionTypes.CreateLocationEdgeNoBackendCall, props<{ request: LocationModel.AddEdgeToLocationResponse }>());

export const CreateLocationEdgeSuccess = createAction(LocationEdgeActionTypes.CreateLocationEdgeSuccess, props<{ payload: Edge.EdgeDocument }>());

export const CreateLocationEdgeFail = createAction(LocationEdgeActionTypes.CreateLocationEdgeFail, props<{ message?: string }>());

export const UpdateEdgeLocalAddress = createAction(LocationEdgeActionTypes.UpdateEdgeLocalAddress, props<{ request: Edge.UpdateEdgeLocalAddressRequest, ipAddress?: string; }>());

export const UpdateEdgeLocalIp = createAction(LocationEdgeActionTypes.UpdateEdgeLocalIp, props<{ edgeId: string, ipAddress?: string; }>());

export const UpdateEdgeLocalAddressSuccess = createAction(LocationEdgeActionTypes.UpdateEdgeLocalAddressSuccess, props<{ request: Edge.UpdateEdgeLocalAddressRequest }>());

export const UpdateEdgeLocalAddressFail = createAction(LocationEdgeActionTypes.UpdateEdgeLocalAddressFail, props<{ message?: string }>());

export const ResetEdgeIsLocal = createAction(LocationEdgeActionTypes.ResetEdgeIsLocal, props<{ request: { edgeId: string } }>());
export const ResetEdgesIsLocal = createAction(LocationEdgeActionTypes.ResetEdgeIsLocal, props<{ request: { edgeIds: string[] } }>());

export const SetEdgeIsLocalDebug = createAction(LocationEdgeActionTypes.SetEdgeIsLocalDebug, props<{ request: { edgeId: string; isLocal: boolean } }>());

export const UpdateEdgeNoBackendCall = createAction(LocationEdgeActionTypes.UpdateEdgeNoBackendCall, props<{ request: LocationModel.UpdateEdgeInLocationRequest }>());

export const UpdateEdgeNoBackendCallSuccess = createAction(LocationEdgeActionTypes.UpdateEdgeNoBackendCallSuccess, props<{ request: LocationModel.UpdateEdgeInLocationRequest }>());

export const UpdateEdgeNoBackendCallFail = createAction(LocationEdgeActionTypes.UpdateEdgeNoBackendCallFail, props<{ message?: string }>());

export const DeleteEdgeNoBackendCall = createAction(LocationEdgeActionTypes.DeleteEdgeNoBackendCall, props<{ request: { locationId: string; edgeId: string } }>());

export const DeleteEdge = createAction(LocationEdgeActionTypes.DeleteEdge, props<{ request: LocationModel.DeleteEdgeFromLocationRequest }>());

export const DeleteEdgeSuccess = createAction(LocationEdgeActionTypes.DeleteEdgeSuccess, props<{ response: LocationModel.DeleteEdgeFromLocationResponse }>());

export const DeleteEdgeFail = createAction(LocationEdgeActionTypes.DeleteEdgeFail, props<{ message?: string }>());

export const CanEdgeLocalStream = createAction(LocationEdgeActionTypes.CanEdgeLocalStream, props<{ locationId: string; edgeId: string }>());

export const CanEdgeLocalStreamSuccess = createAction(LocationEdgeActionTypes.CanEdgeLocalStreamSuccess, props<{ response: LocationModel.DeleteEdgeFromLocationResponse }>());

export const CanEdgeLocalStreamFail = createAction(LocationEdgeActionTypes.CanEdgeLocalStreamFail, props<{ message?: string }>());

/**
 * Get edge ip address
 */

export const ResetEdgeIpAddress = createAction(LocationEdgeActionTypes.ResetEdgeIpAddress);

export const GetEdgeIpAddressFromGrafana = createAction(LocationEdgeActionTypes.GetEdgeIpAddressFromGrafana, props<{ request: Edge.GetEdgeIpAddressRequest }>());

export const ClassifyIpAddressFromGrafana = createAction(LocationEdgeActionTypes.ClassifyIpAddressFromGrafana, props<{
  request: { ips: GrafanaModels.GetEdgeIpAddressInfoFromGraphana, edgeId: string }
}>());

export const GetEdgeLocalNetwork = createAction(LocationEdgeActionTypes.GetEdgeLocalNetwork, props<{ request: { address: { ip: string; prefix: string }; edgeId: string; token: string } }>());

export const CreateOrUpdateLocalProxy = createAction(LocationEdgeActionTypes.CreateOrUpdateLocalProxy, props<{
  request: { address: { ip: string; prefix: string }; edgeId: string; token: string }
}>());

export const ClassifyIpAddress = createAction(LocationEdgeActionTypes.ClassifyIpAddress, props<{ request: { token: string; session: GetEdgeIpAddressToken.AllSessionData; edgeId: string } }>());

export const deleteCameraFromLocation = createAction('[EDGE-ACTIONS] Delete Camera From Location', props<{ edgeId: string; cameraId: string; locationId: string }>());

export const callDeleteCameraFromLocation = createAction('[EDGE-ACTIONS] Call Delete Camera From Location', props<{ edgeId: string; cameraId: string; locationId: string }>());
export const callDeleteCameraFromLocationSuccess = createAction('[EDGE-ACTIONS] Call Delete Camera From Location Success', props<{ edgeId: string; cameraId: string; locationId: string }>());

export const UpdateCameraIpInEdge = createAction('[EDGE-ACTIONS] Update Camera Ip In core', props<{ edgeId: string; cameraId: string; ipAddress: string }>());

export const cancelGetCamerasHealth = createAction('Cancel get camera health request');
export const GetCamerasHealth = createAction(LocationEdgeActionTypes.GetLastVideoDates, props<{ request: Edge.GetLastVideoDatesRequest }>());
export const GetCamerasHealthSuccess = createAction(LocationEdgeActionTypes.GetLastVideoDatesSuccess, props<{ response: any }>());

export const GetEdgesInitialPulsationStatus = createAction('[Edges API] Get Edges Initial Pulsation Status');

export const DeleteCameraSuccess = createAction('[Edges API] Delete Camera Success', props<{ edgeId: string; cameraId: string }>());

export const GetEdgesLocalNetwork = createAction('[EDGE ACTION] Get Edges Local Network', props<{ edgeIds: string[] }>());

export const GetEdgeLocalNetworkV2 = createAction('[EDGE ACTION] Check Edge Local Network', props<{ url: string, edgeId: string }>());

export const GetEdgeLocalNetworkV3 = createAction('[EDGE ACTION] Check Edge Local Network V3', props<{ url: string, edgeId: string, ip: string }>());


export const GetCoresSwVersion = createAction('[EDGE ACTION] Get Cores SW Version');

export const GwCoreSwVersionSuccess = createAction('[EDGE ACTION] Get Cores SW Version', props<{ edgeId: string }>());


export const getEdgesSwVersion = createAction('[Edges API] Get Edge Grafana SW Version');
export const getEdgesSwVersionSuccess = createAction('[Edges API] Get Edge Grafana SW Version', props<{ edgeId: string, swVersion: string }>());

export const getEdgesLastMp4Ts = createAction('[Edges API] Get Edge Last Mp4 Timestamp');
export const getEdgesLastMp4TsSend = createAction('[Edges API] Get Edge Last Mp4 Timestamp Send', props<{ edgeId: string }>());
export const getEdgesLastMp4TsSendSuccess = createAction('[Edges API] Get Edge Last Mp4 Timestamp Send Success', props<{ edgeId: string, lastMp4Ts: number }>());


export const getEdgesCamerasHealth = createAction('[Edges API] Get Edge Grafana health');
export const getPulsationFromSingleStore = createAction('[Edges API] Get Pulsation from SingleStore', props<{ edgeId: string; }>());

export const getEdgeLocalIpAddress = createAction('[Edges API] Get Edge Local IP Address', props<{ edgeId: string }>());
export const getEdgeLocalIpAddressSuccess = createAction('[Edges API] Get Edge Local IP Address Success', props<{ edgeId: string, localIpAddress: string; }>());

export const StartBackgroundPulsationCalls = createAction('[Edges] Start Background Pulsation Calls', props<{ edges: any[] }>());

export const AllPulsationsComplete = createAction('[Edges] All Pulsations Complete');

export const FetchPulsationViaWorker = createAction('[Edge] Fetch Pulsation via Worker', props<{ edgeId: string, rebootCounter?: number }>());

export const FetchPulsationViaWorkerSuccess = createAction('[Edge] Fetch Pulsation via Worker Success', props<{ edgeId: string; pulsationResponse: any }>());

export const FetchPulsationViaWorkerFail = createAction('[Edge] Fetch Pulsation via Worker Fail', props<{ edgeId: string; error: string }>());

export const GetBatchPulsations = createAction('[Edges API] Get Batch Pulsations', props<{ edgeIds: string[] }>());

export const GetBatchPulsationsSuccess = createAction('[Edges API] Get Batch Pulsations Success', props<{ pulsations: KeyValuePairs<PulsationModels.ComponentStatusMajorChangeMessage[]> }>());

export const SavePulsations = createAction('[Edges API] Save Pulsations', props<{ pulsations: PulsationModels.ComponentStatusMajorChangeMessage[] }>());


export const startDeleteEdge = createAction('[EDGE ACTIONS] Start Delete Edge', props<{ edgeId: string; locationId: string }>());
export const deleteEdgeServerRequest = createAction('[EDGE ACTIONS] Delete Edge Server Request', props<{ edgeId: string; locationId: string }>());
export const deleteEdgeServerRequestSuccessSocketAndApi = createAction('[EDGE ACTIONS] Delete Edge Server Request Success', props<{ location: LocationModel.LocationItem; edgeId: string }>());
export const deleteEdgeServerRequestFail = createAction('[EDGE ACTIONS] Delete Edge Server Request Fail');
export const setIsDeleting = createAction('[EDGE ACTIONS] Set is deleting', props<{ isDeleting: boolean }>());


export const startCreateEdge = createAction('[EDGE ACTIONS] Start Create Edge', props<{ request: LocationModel.AddEdgeToLocationRequest }>());
export const createEdgeServerRequest = createAction('[EDGE ACTIONS] Create Edge Server Request', props<{ request: LocationModel.AddEdgeToLocationRequest }>());
export const createEdgeServerRequestSuccess = createAction('[EDGE ACTIONS] Create Edge Server Request Success', props<{ edge: Edge.V2.EdgeCreateResponse }>());
export const createEdgeServerRequestFail = createAction('[EDGE ACTIONS] Create Edge Server Request Fail', props<{ err: HttpErrorResponse }>());
