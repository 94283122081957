import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { catchError, exhaustMap, share, switchMap, withLatestFrom } from 'rxjs/operators';
import { SharedActions } from '@states/shared/shared.action-types';
import { SyncQueueActions } from '@states/sync-queue/sync-queue.action-types';
import { SyncQueueService } from '../../services/sync-queue.service';

@Injectable()
export class SyncQueueEffects {
  public startGetCommittedOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SyncQueueActions.startGetCommittedOperations),
      exhaustMap(() => [
        SyncQueueActions.setSyncQueueLoading({ isLoading: true }),
        SyncQueueActions.getCommittedOperations(),
      ]),
    ),
  );

  public getCommittedOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SyncQueueActions.getCommittedOperations),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedEdgeId }]) => {
        return this.syncQueueService.getLastCommittedOperations(selectedEdgeId)
          .pipe(
            switchMap(committedOperations => [
              SyncQueueActions.getCommittedOperationsSuccess({ committedOperations }),
              SyncQueueActions.setSyncQueueLoading({ isLoading: false }),
            ]),
            catchError(error => [
              SyncQueueActions.getCommittedOperationsFail(),
              SharedActions.showMessage({ error: 'Failed to load committed operations' }),
              SyncQueueActions.setSyncQueueLoading({ isLoading: false }),
            ]),
          );
      }),
      share(),
    ),
  );

  getEdgesSyncStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SyncQueueActions.getEdgesSyncStatus),
      switchMap(() => {
        return this.syncQueueService.isEdgeSyncForOrg()
          .pipe(
            switchMap(edgesSyncStatus => [
              SyncQueueActions.getEdgesSyncStatusSuccess({ edgesSyncStatus }),
            ]),
            catchError(error => [
              SyncQueueActions.getEdgesSyncStatusFail(),
              SharedActions.consoleMessage({ error: 'Failed to load edges sync status' }),
            ]),
          );
      }),
      share(),
    ),
  );

  public startGetUncommittedOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SyncQueueActions.startGetUncommittedOperations),
      exhaustMap(() => [
        SyncQueueActions.setSyncQueueLoading({ isLoading: true }),
        SyncQueueActions.getUncommittedOperations(),
      ]),
    ),
  );

  public getUncommittedOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SyncQueueActions.getUncommittedOperations),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedEdgeId }]) => {
        return this.syncQueueService.getNextOperations(selectedEdgeId)
          .pipe(
            switchMap(uncommittedOperations => [
              SyncQueueActions.getUncommittedOperationsSuccess({ uncommittedOperations }),
              SyncQueueActions.setSyncQueueLoading({ isLoading: false }),
            ]),
            catchError(error => [
              SyncQueueActions.getUncommittedOperationsFail(),
              SharedActions.showMessage({ error: 'Failed to load uncommitted operations' }),
              SyncQueueActions.setSyncQueueLoading({ isLoading: false }),
            ]),
          );
      }),
      share(),
    ),
  );

  retryOperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SyncQueueActions.retryOperation),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ edgeLogId }, { selectedEdgeId }]) => {
        return this.syncQueueService.retryOperation(selectedEdgeId, edgeLogId)
          .pipe(
            switchMap(() => [
              SyncQueueActions.retryOperationSuccess(),
              SyncQueueActions.startGetUncommittedOperations(),
              SharedActions.showMessage({ success: 'The operation has been submitted for another attempt' }),
            ]),
            catchError(error => [
              SyncQueueActions.retryOperationFail(),
              SharedActions.showMessage({ error: 'Failed to retry operation' }),
            ]),
          );
      }),
    ),
  );

  cancelOperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SyncQueueActions.cancelOperation),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ edgeLogId }, { selectedEdgeId }]) => {
        return this.syncQueueService.cancelOperation(selectedEdgeId, edgeLogId)
          .pipe(
            switchMap(() => [
              SyncQueueActions.cancelOperationSuccess(),
              SyncQueueActions.startGetUncommittedOperations(),
              SharedActions.showMessage({ success: 'Operation cancelled successfully' }),
            ]),
            catchError(error => [
              SyncQueueActions.cancelOperationFail(),
              SharedActions.showMessage({ error: 'Failed to cancel operation' }),
            ]),
          );
      }),
    ),
  );
  resetPublishCounter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SyncQueueActions.resetPublishCounter),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ edgeLogId }, { selectedEdgeId }]) => {
        return this.syncQueueService.resetPublishCounter(selectedEdgeId, edgeLogId)
          .pipe(
            switchMap(() => [
              SyncQueueActions.resetPublishCounterSuccess(),
              SyncQueueActions.startGetUncommittedOperations(),
              SharedActions.showMessage({ success: 'Publish counter reset successfully' }),
            ]),
            catchError(error => [
              SyncQueueActions.resetPublishCounterFail(),
              SharedActions.showMessage({ error: 'Failed to reset publish counter' }),
            ]),
          );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private syncQueueService: SyncQueueService,
  ) {
  }
}
