// no-rotate-text.component.ts
import { Component, ElementRef, AfterViewInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'ui-no-rotate-text',
  template: `
    <span #textElement style="display: inline-block;">
      <ng-content></ng-content>
    </span>
  `,
})
export class UiNoRotateTextComponent implements AfterViewInit {
  @ViewChild('textElement', { static: true }) textElement!: ElementRef;

  constructor(private renderer: Renderer2, private el: ElementRef) {
  }

  ngAfterViewInit() {
    // Get the parent element of this component's host element.
    const parent = this.el.nativeElement.parentElement;
    const style = window.getComputedStyle(parent);
    const transform = style.transform;
    let angle = 0;

    if (transform && transform !== 'none') {
      // CSS transforms in computed style come as a matrix.
      // Example: "matrix(a, b, c, d, tx, ty)" where rotation angle = Math.atan2(b, a)
      const values = transform.split('(')[1].split(')')[0].split(',');
      const a = parseFloat(values[0]);
      const b = parseFloat(values[1]);
      angle = Math.atan2(b, a) * (180 / Math.PI);
    }

    // Apply the inverse rotation to counteract the parent's rotation.
    this.renderer.setStyle(this.textElement.nativeElement, 'transform', `rotate(${-angle}deg)`);
  }
}
