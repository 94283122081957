import { createAction, props } from '@ngrx/store';
import { ConfirmDialogOptions } from '../../../shared/confirm-dialog/confirm-dialog.model';
import { KeyValuePairs } from '../../../core/interfaces';
import { ConfirmModalModel } from '../../../services/confirm-dialogs/confirm-modal.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { SocketModels } from '../../../socket/socket.model';

export const resetToInitialState = createAction('[Shared] Reset to Initial state');

export const startLoadRequiredData = createAction('[Shared] Start load required data');
export const setIsSaving = createAction('[Shared] Set is Saving', props<{ isSaving: boolean }>());

export const setInactivityTime = createAction('[Shared] Set Inactivity Time', props<{ inactivityTime: number }>());
export const setInactivityCountdown = createAction('[Shared] Set Inactivity Countdown', props<{ inactivityCountdown: number }>());
export const startInactivityCountdown = createAction('[Shared] Start Inactivity Countdown');
export const stopInactivityCountdown = createAction('[Shared] Stop Inactivity Countdown');

export const setIsInviting = createAction('[Shared] Set is Inviting', props<{ isInviting: boolean }>());

export const setIsLoading = createAction('[Shared] Set is Loading', props<{ isLoading: boolean }>());

export const showMessage = createAction('[Shared] Show Message', props<{ success?: string; error?: string; warning?: string, info?: string }>());

export const consoleMessage = createAction('[Shared] Console Message', props<{ success?: string; error?: string; warning?: string }>());

export const doNothing = createAction('[Shared] Do Nothing');

export const setSomethingWentWrong = createAction('[Shared] Set Something Went Wrong', props<{ somethingWentWrong: boolean }>());

export const showConfirmModal = createAction('[Shared] Show confirm Modal', props<{ options: ConfirmDialogOptions }>());

export const showConfirmModalResultConfirm = createAction(
  '[Shared] Confirm Modal Return Confirm',
  props<{ params: { [key: string]: any } }>(),
);

export const showConfirmModalResultCancel = createAction('[Shared] Confirm Modal Return Cancel', props<{ params: { [key: string]: any } }>());

export const setIsDeleting = createAction('[Shared] Set is Deleting', props<{ isDeleting: boolean }>());

export const setIsInactive = createAction('[SHARED] Set Is Inactive', props<{ isInactive: boolean }>());

export const pressSave = createAction('[SHARED] Press Save');
export const pressEdit = createAction('[SHARED] Press Edit');
export const pressDelete = createAction('[SHARED] Press Delete');

export const setApplicationLoaded = createAction('[SHARED] Set Application Loaded');

export const setProcessingId = createAction('[SHARED] Set Processing Id', props<{ processingId: string }>());

export const setLocksDebug = createAction('[Shared] Set Locks Debug', props<{ locks: KeyValuePairs<boolean> }>());

export const getEdgeHeartBeatStatus = createAction('[Shared] Get Edge HeartBeat Status');
export const getEdgeHeartBeatStatusSuccess = createAction('[Shared] Get Edge HeartBeat Status Success', props<{ body: any[] }>());
export const getEdgeHeartBeatStatusFail = createAction('[Shared] Get Edge HeartBeat Status Fail');

export const showConfirmNameModal = createAction('[Shared] Show confirm name Modal', props<{ options: ConfirmModalModel.Options }>());

export const showConfirmNameModalResultConfirm = createAction(
  '[Shared] Confirm Name Modal Return Confirm',
  props<{ params: Dictionary<any> }>(),
);

export const showConfirmNameModalResultCancel = createAction('[Shared] Confirm Name Modal Return Cancel', props<{ params: { [key: string]: any } }>());

export const showMessageFromSocket = createAction('[Shared] Show Message from socket', props<{ level: SocketModels.MessageLevel; msg: string }>());

export const detectChanges = createAction('[SHARED] Detect Changes');
