import { createAction, props } from '@ngrx/store';
import { GeneaModel } from '@models/genea.model';

export const resetToInitialState = createAction('[GENEA] Reset to Initial state');

export const getOrganizationGeneaDoors = createAction('[GENEA] Get Organization Genea Doors');
export const getOrganizationGeneaDoorsSuccess = createAction('[GENEA] Get Organization Genea Doors Success', props<{ documents: GeneaModel.DocumentMongo[] }>());
export const getOrganizationGeneaDoorsFail = createAction('[GENEA] Get Organization Genea Doors Fail');

export const startAddGeneaDoor = createAction('[GENEA] Start add genea door', props<{ geneaDoor: GeneaModel.CreateDocumentDto }>());
export const sendAddGeneaDoorRequest = createAction('[GENEA] Send add genea door request', props<{ geneaDoor: GeneaModel.CreateDocumentDto }>());
export const sendAddGeneaDoorRequestSuccess = createAction('[GENEA] Send add genea door request Success', props<{ geneaDoor: GeneaModel.DocumentMongo }>());
export const sendAddGeneaDoorRequestFail = createAction('[GENEA] Send add genea door request Fail');

export const setIsCreatingLoader = createAction('[GENEA] Set is creating Loader', props<{ isCreating: boolean }>());

export const startDeleteGenea = createAction('[GENEA] Start delete genea door', props<{ id: string }>());
export const sendDeleteGeneaRequest = createAction('[GENEA] Send delete genea door request', props<{ id: string }>());
export const sendDeleteGeneaRequestSuccess = createAction('[GENEA] Send delete genea door request Success', props<{ id: string }>());
export const sendDeleteGeneaRequestFail = createAction('[GENEA] Send delete genea door request Fail', props<{ id: string }>());

export const startUpdateGenea = createAction('[GENEA] Start Update genea door', props<{ geneaDoor: GeneaModel.DocumentMongo }>());
export const sendUpdateGeneaRequest = createAction('[GENEA] Send Update genea door request', props<{ geneaDoor: GeneaModel.DocumentMongo }>());
export const sendUpdateGeneaRequestSuccess = createAction('[GENEA] Send Update genea door request Success', props<{ geneaDoor: GeneaModel.DocumentMongo }>());
export const sendUpdateGeneaRequestFail = createAction('[GENEA] Send Update genea door request Fail');
