import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concat, delay, exhaustMap, mergeMap, of, share, switchMap } from 'rxjs';
import { Action, select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { withLatestFrom } from 'rxjs/operators';
import * as SharedActions from '@states/shared/shared.actions';
import { CamerasActions } from '@states/cameras/cameras.action-types';
import { CameraService } from 'src/app/development/camera.service';
import { SearchSelection } from '@models/search.model';
import { SearchObjectTypes } from '@enums/search.enum';
import { AlertEventsConfigurationFilter } from '@models/alert-events.model';
import { LocationModel } from '../../locations/location.model';
import { EdgeService } from '../../edge/edge.service';
import { Camera, CameraCreateStatus, FileCameraCreate, Substream } from '../../cameras/camera.model';
import * as _ from 'lodash';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { isAsyncCallResponseError } from '../../helpers/error.helpers';
import { AsyncCallModels } from '@models/async-call.models';
import { UtilsService } from '../../edge/utils.service';
import { CameraServiceV2 } from '../../services/camera.service';
import { CameraActions } from '@states/camera/camera.action-types';
import { LocationActions } from '@states/location/location.action-types';
import { api } from '@consts/url.const';
import { HomeActions } from '@states/home/home.action-types';


@Injectable()
export class CameraEffect {
  public pressSave$ = createEffect(() => this.actions$.pipe(ofType(CamerasActions.saveCamera), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public pressContinue$ = createEffect(() => this.actions$.pipe(ofType(CamerasActions.continueCamera), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public pressBack$ = createEffect(() => this.actions$.pipe(ofType(CamerasActions.backCamera), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public pressRescan$ = createEffect(() => this.actions$.pipe(ofType(CamerasActions.rescan), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public setCameraNames$ = createEffect(() => this.actions$.pipe(ofType(CamerasActions.setCameraNames), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });


  public getCameraDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.getCameraDetails),
      exhaustMap(({ manual, form, multiSession, multiple, index }) => [
        CamerasActions.sendGetCameraDetails({
          manual,
          form,
          multiSession,
          multiple,
          index,
        }),
      ]),
    ),
  );

  public sendGetCameraDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.sendGetCameraDetails),
      withLatestFrom(this.store$.pipe(select(state => state.camerasState))),
      mergeMap(([{ manual, form, multiSession, multiple, index }, { edgeId, locationId, scanData }]) => {
        const cameraObj = {
          ...form,
        };
        if (!!scanData) {
          form = {
            ...form,
            port: scanData.port,
            isOnvif: false,
            onvifData: null,
          };
        }
        const cameraDetailsRequest: LocationModel.GetCameraDetailsRequest = {
          edgeId,
          locationId,
          camera: {
            ...form,
          },
          create: !manual,
        };

        return this.edgeService.getCameraDetails(cameraDetailsRequest, manual)
          .pipe(
            mergeMap(result => {
              const actions: any[] = [
                CamerasActions.sendGetCameraDetailsSuccess({ result }),
              ];
              if (!!multiSession) {
                actions.push(
                  // CamerasActions.replaceCameraSession({
                  //   oldSession: multiSession,
                  //   newSession: res.token.session,
                  // }),
                );
              }
              if (!!multiple) {
                // actions.push(
                //   CamerasActions.setSelectedCameraToken({ index, token: res.token.session }),
                //   CamerasActions.setSelectedCameraLoading({ token: res.token.session, loading: true }),
                // );
              }
              return actions;
            }),
            catchError(error => {
              return [CamerasActions.sendGetCameraDetailsFailed({ error })];
            }),
          );
      }),
      share(),
    ),
  );


  public sendGetCameraDetailsSuccess$ = createEffect(() => this.actions$.pipe(ofType(CamerasActions.sendGetCameraDetailsSuccess), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public sendGetCameraDetailsFailed$ = createEffect(() => this.actions$.pipe(ofType(CamerasActions.sendGetCameraDetailsFailed), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });


  public getMultipleCameraDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.getMultipleCameraDetails),
      exhaustMap(({ cameras }) => [
        CamerasActions.sendGetMultipleCameraDetails({
          cameras,
        }),
      ]),
    ),
  );

  public sendGetMultipleCameraDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.sendGetMultipleCameraDetails),
      withLatestFrom(this.store$.pipe(select(state => state.camerasState))),
      mergeMap(([{ cameras }, { edgeId, locationId }]) => {
        const cameraDetailsRequest: LocationModel.GetMultipleCameraDetailsRequest = {
          edgeId,
          locationId,
          cameras,
          create: false,
        };
        return this.edgeService.getMultipleCameraDetails(cameraDetailsRequest)
          .pipe(
            mergeMap(res => {
              const setFileCameraToken = res.map((msgInfo, index) => {
                return CamerasActions.setCameraSession({ index, session: msgInfo.token.session });
              });
              return [...setFileCameraToken];
            }),
            catchError(response => {
              return [this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public createCamera$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.createCamera),
      exhaustMap(({}) => [
        SharedActions.setIsSaving({ isSaving: true }),
        CamerasActions.sendCreateCamera({})]),
    ),
  );

  public createMultipleCameras$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.createMultipleCameras),
      withLatestFrom(this.store$.pipe(select(state => state.camerasState))),
      exhaustMap(([{}, { selectedCameras }]) => {
        const actions = [];
        for(let i = 0; i < selectedCameras.length; i++) {
          if (!selectedCameras[i].addState.error && !selectedCameras[i].addState.createSuccess) {
            actions.push(CamerasActions.sendCreateCamera({ index: i }));
            actions.push(CamerasActions.setSelectedCameraLoading({ index: i, loading: true }));
          }
        }
        return [
          SharedActions.setIsSaving({ isSaving: true }),
          ...actions,
        ];
      }),
    ),
  );

  public sendCreateCamera$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.sendCreateCamera),
      withLatestFrom(this.store$.pipe(select(state => state.camerasState))),
      mergeMap(([{ index }, { cameraDetails, edgeId, locationId, snapshotUrl, selectedCameras }]) => {
        let request: LocationModel.AddCameraToLocationRequest;
        if (!!index || index === 0) {
          const camera = selectedCameras[index];
          const addState = camera.addState;
          request = {
            camera: {
              ..._.cloneDeep(addState.cameraDetails),
              autoConfigureMainstream: addState.autoConfigure,
              name: selectedCameras[index].name,
            },
            edgeId,
            locationId,
            defaultSnapshot: addState.snapshot,
          };
          if (addState?.autoConfigure && camera?.addState?.suggestedStreamCapabilities) {
            request.camera.streamCapabilities = camera.addState.suggestedStreamCapabilities;
          }
          if (!addState?.autoConfigure && camera?.addState?.streamCapabilities) {
            request.camera.streamCapabilities = camera.addState.streamCapabilities;
          }
          // if (!addState?.autoConfigure && request?.camera?.subStreams?.length) {
          //   if (request?.camera?.subStreams) {
          //     request.camera.subStreams = [];
          //   }
          //   if (request?.camera?.storageStream?.storageFromSubstream) {
          //     request.camera.storageStream.storageFromSubstream = false;
          //   }
          // }

        } else {
          request = {
            camera: cameraDetails,
            edgeId,
            locationId,
            defaultSnapshot: snapshotUrl,
          };
        }

        return this.cameraServiceV2.addCameraToLocation(request)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessageFromSocket({ msg: res.responseBody.message, level: res.responseBody.messageLevel }),
                SharedActions.setIsSaving({ isSaving: false }),
                CamerasActions.sendCreateCameraSuccess({ camera: res.responseBody.document }),
                //todo consult with Edan
                // CamerasActions.setCameraCreateStatus({ session: res.sessionId, createStatus: CameraCreateStatus.SUCCESS }),

              ];
            }),
            catchError(response => {
              const actions: Action[] = [
                SharedActions.setIsSaving({ isSaving: false }),
              ];
              if (isAsyncCallResponseError(response)) {
                const responseBody = response.error.responseBody as AsyncCallModels.ResponseBody<any>;
                actions.push(
                  SharedActions.showMessageFromSocket({ level: responseBody?.messageLevel, msg: responseBody?.message }),
                  CamerasActions.sendCreateCameraSuccess({ camera: responseBody.document }),
                );
              } else {
                actions.push(
                  SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
                );
              }
              return actions;
            }),
          );
      }),
      share(),
    ),
  );

  public createCameras$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.createCameras),
      exhaustMap(({}) => [SharedActions.setIsSaving({ isSaving: true }), CamerasActions.sendCreateCameras()]),
    ),
  );

  public sendCreateCameras$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.sendCreateCameras),
      withLatestFrom(this.store$.pipe(select(state => state.camerasState))),
      switchMap(([{}, { edgeId, locationId, listFromFile }]) => {
        const successCameras = listFromFile
          .filter(camera => !!camera?.success)
          .map(camera => {
            return {
              ...camera,
              loading: false,
              createStatus: CameraCreateStatus.LOADING,
            };
          });
        // Build request cameras
        const request: LocationModel.AddCamerasToLocationRequest = {
          edgeId,
          locationId,
          cameras: successCameras.map(fileCamera => {
            const add: FileCameraCreate = {
              camera: {
                ...fileCamera.camera,
                name: fileCamera.name,
                description: fileCamera.description,
              },
              defaultSnapshot: fileCamera.snapshotUrl,
            };
            return add;
          }),
        };

        return this.edgeService.addCamerasToLocation(request)
          .pipe(
            mergeMap(res => {
              const setFileCameraToken = res.map((msgInfo, index) => {
                return CamerasActions.setCameraSession({ index, session: msgInfo.token.session, reset: false });
              });
              return [CamerasActions.setListFromFile({ listFromFile: successCameras }), ...setFileCameraToken];
            }),
            catchError(response => {
              return [SharedActions.setIsSaving({ isSaving: false }), this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public getCameras$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.getCameras),
      withLatestFrom(
        this.store$.pipe(select(CameraSelectors.selectAllCameras)),
        this.store$.pipe(select(state => state.camerasState)),
      ),
      switchMap(([, existingCameras, { edgeId, scanData, scanType, selectedCameraCreateType }]) => {
        return this.cameraService.discoverCameras(edgeId, scanType, selectedCameraCreateType, scanData)
          .pipe(
            switchMap((res) => {
              return [
                CameraActions.SetScanToken({ scanToken: res.token.session }),
              ];
            }),
            catchError(response => {
              return [SharedActions.setIsLoading({ isLoading: false }), this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  private camerasCreateMenuLevel2: any;

  public loadLevel3Menu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.loadLevel3Menu),
      withLatestFrom(this.store$.pipe(select(state => state.camerasState))),
      switchMap(([{ copy }, { selectedCameraCreateType }]) => {
        switch (selectedCameraCreateType) {
          default:
            return [SharedActions.doNothing()];
        }
      }),
    ),
  );

  private catchError(response) {
    return SharedActions.showMessage({ error: response?.error?.message });
  }

  public sendCreateCameraSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CamerasActions.sendCreateCameraSuccess),
      withLatestFrom(this.store$.pipe(select(state => state.locationState))),
      mergeMap(([{ camera }, { entities }]) => {
        const locationId = camera.locationId;
        const location = entities[locationId];
        const immediateActions = [
          CameraActions.CreateLocationEdgeCameraSuccess({ payload: camera }),
          CameraActions.GetLocationEdgesCamerasSuccess({ payload: [camera] }),
          LocationActions.UpdateLocationNoBackendCall({ location: location }),
          CameraActions.SetCameraSnapshotManually({ cameraId: camera.cameraId, url: `${api.thumbnails.gcpSnapshotsUrl}/${camera?.defaultSnapshot}` }),
          HomeActions.getLocations(),
          LocationActions.computeLocationLookup(),
        ];
        const delayedAction = of(CameraActions.GetLocationEdgesCamerasSnapshots())
          .pipe(delay(5000));

        return concat(of(...immediateActions), delayedAction);
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private cameraService: CameraService,
    private edgeService: EdgeService,
    private utilsService: UtilsService,
    private cameraServiceV2: CameraServiceV2,
  ) {
  }
}

const typeFieldToColorField = (name: string) => {
  return name.replace('Type', 'Color');
};

const filtersAdapter = (searchSelections: SearchSelection[]): any => {
  let result: AlertEventsConfigurationFilter[] = searchSelections.map(searchSelection => {
    const configFilter: AlertEventsConfigurationFilter = {
      accessoryType: [],
      ageType: [],
      carryingType: [],
      colors: [],
      footwearColor: [],
      footwearType: [],
      genderType: [],
      greenList: '',
      hairColor: [],
      hairType: [],
      lowerbodyColor: [],
      lowerbodyType: [],
      make: [],
      model: [],
      redList: '',
      type: [],
      unrecognized: false,
      upperbodyColor: [],
      upperbodyType: [],
    };
    for(let property of Object.values(searchSelection.properties)) {
      if (searchSelection.type === SearchObjectTypes.PERSON) {
        if (!!property.enabled && !!property?.value?.length) {
          const propsToLower = property.value?.map(elem => elem.toLowerCase());
          configFilter[property.name] = propsToLower;
        }
        if (!!property.enabled && !!property.colors?.length) {
          const colorPropertyName = typeFieldToColorField(property.name);
          configFilter[colorPropertyName] = property.colors;
        }
      } else {
        for(let key of Object.keys(searchSelection.properties)) {
          configFilter[key] = searchSelection.properties[key];
        }
      }
    }
    return configFilter;
  });
  return result;
};

