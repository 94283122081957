import { AfterContentChecked, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MainMenuService } from './layout/main-menu.service';
import { developerMenuItems, mainMenuItems } from './main-menu-items';
import { fromEvent, Observable, take } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EdgeActions } from '@states/edge/edge.action-types';
import { select, Store } from '@ngrx/store';
import { EdgeSelectors } from '@states/edge/edge.selector-types';
import * as SharedActions from '@states/shared/shared.actions';
import { environment } from '../environments/environment';
import { APP_INACTIVITY_THRESHOLD, APP_INACTIVITY_TIME } from '@consts/general.const';
import { UserSettings } from '@models/user-settings';
import * as UserSettingsSelectors from '@states/user-settings/user-settings.selectors';
import { MatDialog } from '@angular/material/dialog';
import { InactivityModalComponent } from './shared/modals/inactivity-modal/inactivity-modal.component';
import { SwUpdateNotificationService } from './shared/sw-update-notification.service';
import { DOCUMENT } from '@angular/common';
import { EdgeStatusService } from './edge/edge-status.service';
import { EdgeEffects } from '@effects/edge.effects';
import { IconsService } from './shared/icons.service';
import { UserSelectors } from '@states/user/user.selector-types';
import { UserActions } from '@states/user/user.action-types';
import { BrowserCompatibilityModalComponent } from './modals/browser-compatibility-modal/browser-compatibility-modal.component';
import { SwPush } from '@angular/service-worker';
import { NotificationService } from './services/notification.service';
import { ActivatedRoute } from '@angular/router';
import { OrganizationSelectors } from '@states/organization/organization.selector-types';

declare const document: any;

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterContentChecked {
  public selectIsDeveloper$: Observable<boolean> = this.store$.pipe(select(UserSelectors.isDeveloper));
  public selectSelectedUserSettings$: Observable<UserSettings> = this.store$.pipe(select(UserSettingsSelectors.selectSelectedUserSettings));

  readonly VAPID_PUBLIC_KEY = 'BLNcbeY7KpjyGl8qxWclBBLjW7sOyOVwQqLbRftdOBkP43Brii7AfHfNghaCPdvMpCRO4Sp0zk8VbYds5H7jijY';

  onlineStatus: Observable<Event>;
  offlineStatus: Observable<Event>;
  public isExpanded: boolean = false;
  public autoLogoutCounter = 0; // in minutes
  public isAutoLogoutEnabled = false;
  private inactivityTime = 0;
  private inactivityTimerInterval = null;
  private autoLogoutOpened = false;
  private disableInactivity = false;

  showNotification = false;
  notificationMessage = [];

  constructor(
    private mainMenuService: MainMenuService,
    private iconsService: IconsService,
    private store$: Store,
    private changeDetector: ChangeDetectorRef,
    private renderer2: Renderer2,
    private dialog: MatDialog,
    private swUpdateNotificationService: SwUpdateNotificationService,
    private edgeStatusService: EdgeStatusService,
    private edgeEffects$: EdgeEffects,
    private swPush: SwPush,
    @Inject(DOCUMENT) private document: Document,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
  ) {
    this.loadSmartlookScript()
      .then(() => {
        this.store$.dispatch(UserActions.SessionIdentity());
      })
      .catch((error) => {
        console.error('Error loading Smartlook script:', error);
      });

    this.store$.pipe(select(OrganizationSelectors.selectActiveOrganization), untilDestroyed(this))
      .subscribe((activeOrganization) => {
        if (activeOrganization) {
          this.disableInactivity = activeOrganization?.disableInactivity ?? false;
        }
      });
  }

  title = 'Lumana';

  @HostListener('window:keydown')
  @HostListener('window:mousemove')
  @HostListener('window:mousedown')
  checkUserActivity() {
    if (this.inactivityTimerInterval) {
      clearInterval(this.inactivityTimerInterval);
      this.inactivityTime = 0;
    }
    this.inactivityTimerInterval = setInterval(() => {
      if (this.inactivityTime === APP_INACTIVITY_THRESHOLD - 1) {
        if (!this.disableInactivity) {
          this.store$.dispatch(SharedActions.startInactivityCountdown());
        }
      }
      if (this.inactivityTime >= APP_INACTIVITY_TIME) {
        if (!this.disableInactivity) {
          this.store$.dispatch(SharedActions.setIsInactive({ isInactive: true }));
        }
        clearInterval(this.inactivityTimerInterval);
        this.inactivityTime = 0;
      }
      /**
       * NOTE: need to check if counter Enabled
       */
      if (this.isAutoLogoutEnabled) {
        if (this.inactivityTime >= ((this.autoLogoutCounter * 60) - 30) && !this.autoLogoutOpened) {
          this.autoLogoutOpened = true;
          const inactivityModal = this.dialog.open(InactivityModalComponent, {
            width: '475px',
            panelClass: 'modal-no-padding',
            disableClose: true,
            data: {
              autoLogoutCounter: 30, //show on 30 sec before finish
            },
          });
          inactivityModal.afterClosed()
            .subscribe(res => {
              clearInterval(this.inactivityTimerInterval);
              this.inactivityTime = 0;
              this.autoLogoutOpened = false;
            });
        }
      }

      this.inactivityTime++;
      this.store$.dispatch(SharedActions.setInactivityTime({ inactivityTime: this.inactivityTime }));
    }, 1000);
  }

  public ngOnInit(): void {
    const url = new URL(window.location.href);
    const isEmbedded = url.searchParams.get('embedded');
    if (!isEmbedded) {
      if (isNotChromeOrEdge()) {
        this.dialog.open(BrowserCompatibilityModalComponent, {
          width: '516px',
        });
        console.log('The browser is not Chrome.');
      } else {
        console.log('The browser is Chrome.');
      }
    }

    function isNotChromeOrEdge() {
      let userAgent = navigator.userAgent;
      let isChrome = /Chrome/.test(userAgent) && !/Edg|OPR|Brave/.test(userAgent);
      let isEdge = /Edg/.test(userAgent); // Microsoft Edge detection
      return !isChrome && !isEdge;
    }


    this.selectSelectedUserSettings$
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.autoLogoutCounter = res?.autoLogout;
        this.isAutoLogoutEnabled = res?.autoLogoutEnabled;
      });

    this.checkUserActivity();
    this.onlineStatus = fromEvent(window, 'online');
    this.offlineStatus = fromEvent(window, 'offline');
    this.offlineStatus.pipe(untilDestroyed(this))
      .subscribe(event => {
        console.log('[APP] Offline')
      });
    this.onlineStatus.pipe(untilDestroyed(this))
      .subscribe(event => {
        this.store$
          .select(EdgeSelectors.selectAllEdges)
          .pipe(take(1))
          .subscribe(edges => {
            console.log('[APP] Online')
            const edgeIds = edges.map((edge) => edge.edgeId);
            this.store$.dispatch(EdgeActions.ResetEdgesIsLocal({ request: { edgeIds } }));
            this.store$.dispatch(EdgeActions.GetEdgesLocalNetwork({ edgeIds }));
          });
      });

    this.mainMenuService.config = {
      isInitiallyOpened: true,
      displayIconsOnCollapse: false,
      items: mainMenuItems,
      developerItems: developerMenuItems,
    };

    this.swUpdateNotificationService.currentNotification.subscribe(message => {
      this.notificationMessage = message;
      this.showNotification = !!message && !this.swUpdateNotificationService.isManuallyClosed;
    });

    this.edgeStatusService.subscribeToEdgeStatusSocket();
    //
    // this.edgeEffects$.getEdgesInitialPulsationStatus$
    //   .pipe(untilDestroyed(this))
    //   .subscribe(_ => {
    //     this.store$.select(EdgeSelectors.selectAllEdges)
    //       .pipe(untilDestroyed(this), take(1), filter(edges => !!edges && edges.length > 0))
    //       .subscribe((edges) => {
    //         const subscription: Observable<PulsationModels.PulsationResponse>[] = [];
    //         edges.forEach((edge) => {
    //           const sub = this.edgeStatusService.getPulsationFromSinglestoreV2(edge.edgeId, undefined, true);
    //           subscription.push(sub);
    //         });
    //         forkJoin(subscription)
    //           .subscribe(res =>
    //             console.log('ALL REQUEST EDGES FINISHED'),
    //           );
    //       });
    //
    //   });

    // this.subscribeToNotifications();
  }

  private loadSmartlookScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (environment.env === 'production') {
        const script = this.document.createElement('script');
        script.type = 'text/javascript';
        script.textContent = `
        window.smartlook||(function(d) {
      var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
      var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
      c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
      })(document);
      smartlook('init', '7903bca7aaaf070755910bb586f78828b5a044a1', { region: 'us' });
        `;
        this.document.head.appendChild(script);

        const checkSmartlook = () => {
          if (window['smartlook']) {
            resolve();
          } else {
            setTimeout(checkSmartlook, 1000);
          }
        };

        setTimeout(checkSmartlook, 100);
      } else {
        console.log('smartlook rejected - not in production');
        reject('Not in production environment');
      }
    });
  }

  public ngAfterContentChecked(): void {
    // https://gist.github.com/vades/a225170304f34f88a7a5d48bf4b1f58c
    this.changeDetector.detectChanges();
  }


  public subscribeToNotifications() {

    this.swPush.requestSubscription({
        serverPublicKey: this.VAPID_PUBLIC_KEY,
      })
      .then(sub => {
        console.log(sub);
        this.notificationService.subscribeToWebPushNotifications(sub)
          .subscribe();
      })
      .catch(err => console.error('Could not subscribe to notifications', err));


    this.swPush.notificationClicks.subscribe(res => {
      console.log(res);
    });
  }
}
