import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { SharedService } from '../../development/shared.service';
import { StatsService } from '../../development/stats.service';
import * as StatsActions from '@states/stats/stats.actions';
import { catchError, mergeMap, share, switchMap, tap } from 'rxjs';
import { getCameraUptimeSuccess, getEdgeNetworkBandwidthFromGrafana, getEdgeUptimeAllSuccess } from '@states/stats/stats.actions';
import { withLatestFrom } from 'rxjs/operators';
import * as EdgeEditActions from '@states/edge-edit/edge-edit.actions';
import * as SharedActions from '@states/shared/shared.actions';
import { UtilsService } from '../../edge/utils.service';

@Injectable()
export class StatsEffects {
  public getCameraUptime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StatsActions.getCameraUptime),
      switchMap(({ edgeId, cameraId, period, bar }) =>
        this.statsService.getCameraStats({ edgeId, cameraId, period, bar })
          .pipe(
            switchMap(res => {
              return [StatsActions.getCameraUptimeSuccess({ cameraUptime: res })];
            }),
          ),
      ),
      share(),
    ),
  );

  public getAnalyticUptime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StatsActions.getAnalyticUptime),
      switchMap(({ edgeId, cameraId, period, bar }) =>
        this.statsService.getAnalyticStats({ edgeId, cameraId, period, bar })
          .pipe(
            switchMap(res => {
              return [StatsActions.getAnalyticUptimeSuccess({ analyticUptime: res })];
            }),
          ),
      ),
      share(),
    ),
  );

  public getEdgeUptime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StatsActions.getEdgeUptime),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ period, bar }, { selectedEdgeId }]) =>
        this.statsService
          .getEdgeStats({
            edgeId: selectedEdgeId,
            period,
            bar,
          })
          .pipe(
            switchMap(res => {
              return [StatsActions.getEdgeUptimeSuccess({ edgeUptime: res })];
            }),
          ),
      ),
      share(),
    ),
  );

  public getEdgeUptimeAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StatsActions.getEdgeUptimeAll),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      mergeMap(([{ period, bar, analytics, edgeId, locationId }, { selectedEdgeId, selectedLocationId }]) =>
        this.statsService
          .getEdgeStatsAll({
            edgeId: edgeId ?? selectedEdgeId,
            locationId: locationId ?? selectedLocationId,
            period,
            bar,
            analytics,
          })
          .pipe(
            switchMap(res => {
              return [StatsActions.getEdgeUptimeAllSuccess({ edgeUptimeAll: res })];
            }),
          ),
      ),
      share(),
    ),
  );


  public getEdgeNetworkBandwidthFromGrafana$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StatsActions.getEdgeNetworkBandwidthFromGrafana),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      mergeMap(([{ locationId, edgeId, timeBack }, { selectedEdgeId, selectedLocationId }]) =>
        this.statsService
          .getEdgeNetworkBandwidthFromGrafana({
            locationId: locationId,
            edgeId: edgeId,
            timeBack: timeBack,
          })
          .pipe(
            switchMap(res => {
              return [StatsActions.getEdgeNetworkBandwidthFromGrafanaSuccess({ result: res })];
            }),
            catchError(response => {
              const errorMessage = this.utilsService.errMessage(response);
              return [
                StatsActions.getEdgeNetworkBandwidthFromGrafanaFail({ errorMessage }),
                SharedActions.showMessage({ error: errorMessage }),
              ];
            }),
          ),
      ),
      share(),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private statsService: StatsService,
    private utilsService: UtilsService,
  ) {
  }
}
