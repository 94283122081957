<mat-dialog-content>
  <div fxLayout="column"
       fxLayoutAlign="space-between start"
       fxLayoutGap="10px"
       style="min-height: 25vh; max-width: 450px"
  >
    <img src="assets/lumana_logo.svg" style="text-align: center; width: 200px"/>

    <ng-container *ngrxLet="selectSomethingWentWrong$; let somethingWentWrong">
      <ng-container *ngIf="!somethingWentWrong; else somethingWentWrongTmpl">
        <div *ngIf="versions; else loader">
          <div class="mb-20">The following modules are installed on this edge:</div>
          <div *ngFor="let version of versions"
               fxLayout="row"
               fxLayoutAlign="start start"
               fxLayoutGap="10px"
               class="mb-5"
          >
            <div>•</div>
            <div>{{ version.name | titlecase }} module (v{{ version.version }})</div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div class="flex-row space-between-center w-100">
      <div>Copyright © 2025 Lumana</div>
      <button mat-flat-button color="primary" mat-dialog-close>Close</button>
    </div>
  </div>
</mat-dialog-content>

<ng-template #loader>
  <ui-preloader [color]="preloaderColor.blue"></ui-preloader>
</ng-template>
<ng-template #somethingWentWrongTmpl>
  <app-ui-something-went-wrong></app-ui-something-went-wrong>
</ng-template>
