<div [class.all-bold]="allBold">
  <ng-container [ngSwitch]="category">
    <!-- SAFETY -->
    <ng-container *ngSwitchCase="AlertCategory.Safety">
      <ng-container [ngSwitch]="flowType">
        <ng-container *ngSwitchCase="SafetyType.ZoneProtection">
          When <b>{{ objects }}</b> enter <b>{{ zone }}</b> for more than
          <b>{{ duration }}</b>&nbsp;<b>{{ durationUnit }}</b>
          at
          <b>{{ schedule }}</b>
        </ng-container>
        <ng-container *ngSwitchCase="SafetyType.Trespassing">
          When trespassing of <b>{{ objects }}</b> is detected on <b>{{ camera }}</b> at <b>{{ schedule }}</b>
        </ng-container>
        <ng-container *ngSwitchCase="SafetyType.Tailgating">
          When tailgating of <b>{{ objects }}</b> is below <b>{{ duration }}</b>&nbsp;<b>{{ durationUnit }}</b> on
          <b>{{ camera }}</b>
          at
          <b>{{ schedule }}</b>
        </ng-container>
        <ng-container *ngSwitchCase="SafetyType.Tampering">
          When <b>{{ location }}</b>&nbsp;<b>{{ camera }}</b> is tampered with for more than
          <b>{{ duration }}</b>&nbsp;<b>{{ durationUnit }}</b> at
          <b>{{ schedule }}</b>
        </ng-container>
        <ng-container *ngSwitchCase="SafetyType.Motion">
          When motion sensitivity is above <b>{{ sensitivity }}</b> on <b>{{ camera }}</b> at <b>{{ schedule }}</b>
        </ng-container>
        <ng-container *ngSwitchCase="SafetyType.Proximity">
          When <b>{{ objects }}</b>&nbsp;<b>{{ proximity }}</b> for more than
          <b>{{ duration }}</b>&nbsp;<b>{{ durationUnit }}</b>
          on
          <b>{{ camera }}</b>
          at
          <b>{{ schedule }}</b>
        </ng-container>
        <ng-container *ngSwitchCase="SafetyType.SpeedLimit">
          When <b>{{ objects }}</b> are following a path on <b>{{ camera }}</b>
          in less than <b>{{ duration }}</b>&nbsp;<b>{{ durationUnit }}</b> at
          <b>{{ schedule }}</b>

        </ng-container>
        <ng-container *ngSwitchCase="SafetyType.Weapon">
          When a threat is detected on <b>{{ camera }}</b> at <b>{{ schedule }}</b>
        </ng-container>
        <ng-container *ngSwitchCase="SafetyType.Door">
          When <b>{{ doors }}</b> {{ this.formValue?.doors?.length > 1 || !this.formValue?.doors ? 'are' : 'is' }}
          <b>{{ openState }}</b> for more
          than
          <b>{{ duration }}</b>&nbsp;<b>{{ durationUnit }}</b>
          at <b>{{ schedule }}</b>

        </ng-container>
        <ng-container *ngSwitchCase="SafetyType.Fire">
          When a fire is detected on <b>{{ camera }}</b> at <b>{{ schedule }}</b>
        </ng-container>
        <ng-container *ngSwitchCase="SafetyType.Fall">
          When a person falls on <b>{{ camera }}</b> at <b>{{ schedule }}</b>
        </ng-container>
      </ng-container>
    </ng-container>
    <!-- TRACKING -->
    <ng-container *ngSwitchCase="AlertCategory.Tracking">
      <ng-container [ngSwitch]="flowType">
        <ng-container *ngSwitchCase="TrackingType.Apperance">When <b>{{ objects }}</b> appear on <b>{{ zone }}</b> at
          <b>{{ schedule }}</b>
        </ng-container>
        <ng-container *ngSwitchCase="TrackingType.Disappearing">When <b>{{ objects }}</b> disappear from
          <b>{{ zone }}</b>
                                                                at
          <b>{{ schedule }}</b></ng-container>
        <ng-container *ngSwitchCase="TrackingType.Loitering">When <b>{{ objects }}</b> are loitering for more than
          <b>{{ duration }}</b>&nbsp;<b>{{ durationUnit }}</b> on
          <b>{{ camera }}</b> at
          <b>{{ schedule }}</b></ng-container>
        <ng-container *ngSwitchCase="TrackingType.LineCrossing">When <b>{{ objects }}</b> cross a line on
          <b>{{ zone }}</b>
                                                                at
          <b>{{ schedule }}</b></ng-container>
        <ng-container *ngSwitchCase="TrackingType.Occupancy">When more than
          <b>{{ amount }}</b>&nbsp;<b>{{ objects }}</b>
                                                             are detected for more than
          <b>{{ duration }}</b>&nbsp;<b>{{ durationUnit }}</b> on
          <b>{{ zone }}</b> at
          <b>{{ schedule }}</b></ng-container>
        <ng-container *ngSwitchCase="TrackingType.TrafficControl">When <b>{{ objects }}</b> complete a path on
          <b>{{ camera }}</b> at
          <b>{{ schedule }}</b></ng-container>
        <ng-container *ngSwitchCase="TrackingType.MissingObject">When marked objects on <b>{{ camera }}</b> are missing
                                                                 at
          <b>{{ schedule }}</b></ng-container>
        <ng-container *ngSwitchCase="TrackingType.Absence">When there are no <b>{{ objects }}</b> for more than
          <b>{{ duration }}</b>&nbsp;<b>{{ durationUnit }}</b> on
          <b>{{ camera }}</b> at
          <b>{{ schedule }}</b></ng-container>
      </ng-container>
    </ng-container>
    <!-- IDENTIFICATION -->
    <ng-container *ngSwitchCase="AlertCategory.Identification">
      <ng-container [ngSwitch]="flowType">
        <ng-container *ngSwitchCase="IdentificationType.LPR">
          When a license plate <b>{{ appears }}</b> on the list on <b>{{ camera }}</b> at
          <b>{{ schedule }}</b>

        </ng-container>
        <ng-container *ngSwitchCase="IdentificationType.FaceDetection">
          When a person <b>{{ appears }}</b> on the list on <b>{{ camera }}</b> at
          <b>{{ schedule }}</b>
        </ng-container>
        <ng-container *ngSwitchCase="IdentificationType.Container">
          When container's serial number from the list <b>{{ appears }}</b> on <b>{{ camera }}</b> at
          <b>{{ schedule }}</b>

        </ng-container>
      </ng-container>
    </ng-container>
    <!-- STATUS -->
    <ng-container *ngSwitchCase="AlertCategory.Status">
      <ng-container [ngSwitch]="flowType">
        <ng-container *ngSwitchCase="StatusType.Edge">
          When <b>{{ edge }}</b> status is changed for more than <b>{{ duration }}</b>&nbsp;<b>{{ durationUnit }}</b> at
          <b>{{ schedule }}</b>
        </ng-container>
        <ng-container *ngSwitchCase="StatusType.Analytic">
          When analytic status is changed for more than <b>{{ duration }}</b>&nbsp;<b>{{ durationUnit }}</b> on
          <b>{{ camera }}</b> at
          <b>{{ schedule }}</b></ng-container>
        <ng-container *ngSwitchCase="StatusType.Camera">
          When <b>{{ camera }}</b> status is changed for more than <b>{{ duration }}</b>&nbsp;<b>{{ durationUnit }}</b>
          at
          <b>{{ schedule }}</b>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="AlertCategory.CustomizedCapabilities">
      <ng-container [ngSwitch]="flowType">
        <ng-container *ngSwitchCase="CustomizedCapabilitiesType.ProtectiveGear">
          When workers <b>{{ wear }}</b> a <b>{{ gear }}</b> on <b>{{ camera }}</b> for more than
          <b>{{ duration }}</b>&nbsp;<b>{{ durationUnit }}</b> at <b>{{ schedule }}</b>
        </ng-container>
        <ng-container *ngSwitchCase="CustomizedCapabilitiesType.Gloves">
          When a worker is <b>{{ wearGloves }}</b>&nbsp;<b>{{ gloves }}</b> on <b>{{ camera }}</b> at
          <b>{{ schedule }}</b>
        </ng-container>
        <ng-container *ngSwitchCase="CustomizedCapabilitiesType.PersonalSafety">
          When workers are too close to a vehicle on <b>{{ camera }}</b> for more than
          <b>{{ duration }}</b>&nbsp;<b>{{ durationUnit }}</b> at <b>{{ schedule }}</b>
        </ng-container>
        <ng-container *ngSwitchCase="CustomizedCapabilitiesType.PeriodicText">
          Check <b>text</b> on <b>{{ camera }}</b> every
          <b>{{ period }}</b>&nbsp;<b>{{ periodUnit }}</b> and disable after
          <b>{{ disablePeriod }}</b>&nbsp;<b>{{ disablePeriodUnit }}</b> at <b>{{ schedule }}</b>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="AlertCategory.Integrations">
      <ng-container [ngSwitch]="flowType">
        <ng-container *ngSwitchCase="IntegrationsType.EventTag">
          When <b>{{ eventTag }}</b> is triggered on <b>{{ camera }}</b>
          , wait <b>{{ duration }}</b>&nbsp;<b>{{ durationUnit }}</b>
        </ng-container>
        <ng-container *ngSwitchCase="IntegrationsType.EventTagPosValidation">
          When <b>{{ eventTag }}</b> is triggered on <b>{{ camera }}</b>
          , check <b>{{ appearance }}</b> of <b>{{ objects }}</b> for
          <b>{{ duration }}</b>&nbsp;<b>{{ durationUnit }}</b>
        </ng-container>
        <ng-container *ngSwitchCase="IntegrationsType.EventTagTailGating">
          When {{ accessDoors }} open on <b>{{ camera }}</b> , check tailgating
          <b>{{ objects }}</b> for <b>{{ duration }}</b>&nbsp;<b>{{ durationUnit }}</b> at
          <b>{{ schedule }}</b>
        </ng-container>
      </ng-container>
    </ng-container>

  </ng-container>
</div>
