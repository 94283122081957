import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LocationEditState } from '@states/location-edit/location-edit.reducer';

export const selectLocationEditState = createFeatureSelector<LocationEditState>('locationEditState');

export const selectedLocation = createSelector(selectLocationEditState, ({ selectedLocation }: LocationEditState) => selectedLocation);
export const selectedLocationId = createSelector(selectLocationEditState, ({ selectedLocationId }: LocationEditState) => selectedLocationId);

export const selectedSpeaker = createSelector(selectLocationEditState, ({ selectedSpeaker }: LocationEditState) => selectedSpeaker);
export const selectDeviceState = createSelector(selectLocationEditState, ({ deviceState }: LocationEditState) => deviceState);


export const selectLevel2MenuValid = createSelector(selectLocationEditState, ({ level2MenuValid }: LocationEditState) => level2MenuValid);
export const selectCanDeactivate = createSelector(selectLocationEditState, ({ canDeactivate }: LocationEditState) => canDeactivate);



