import { Injectable } from '@angular/core';
import { api } from '@consts/url.const';
import { HttpClient } from '@angular/common/http';
import { SearchConfigurationSendModel } from '@models/search-configuration.model';
import { Observable } from 'rxjs';
import { SelectedCamera } from '@models/alert-events.model';
import { AsyncCallModels } from '@models/async-call.models';

@Injectable()
export class SearchConfigurationService {
  constructor(private http: HttpClient) {
  }

  public getOne(locationId: string, edgeId: string, cameraId: string): Observable<SearchConfigurationSendModel> {
    return this.http.get<SearchConfigurationSendModel>(api.searchConfiguration.one(locationId, edgeId, cameraId));
  }

  /**
   * Using for create and update. Confirmed by Dvir 25.03.25
   * @param searchConfiguration
   */
  public update(searchConfiguration: SearchConfigurationSendModel) {
    return this.http.put<AsyncCallModels.AsyncCallResponse<SearchConfigurationSendModel>>(api.searchConfiguration.update, searchConfiguration);
  }

  public remove(id: string, selectedCameraParams: SelectedCamera): Observable<any> {
    return this.http.post(api.searchConfiguration.delete(id), selectedCameraParams);
  }

  public hasProtectiveGear(): Observable<boolean> {
    return this.http.get<boolean>(api.searchConfiguration.hasProtectiveGear);
  }

  public hasForklift(): Observable<boolean> {
    return this.http.get<boolean>(api.searchConfiguration.hasForklift);
  }

  public hasContainer(): Observable<boolean> {
    return this.http.get<boolean>(api.searchConfiguration.hasContainer);
  }

  public hasShoppingCart(): Observable<boolean> {
    return this.http.get<boolean>(api.searchConfiguration.hasShoppingCart);
  }

}
