import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CameraUpdateRequest, EdgeCamera } from '../cameras/camera.model';
import { Observable } from 'rxjs';
import { api } from '@consts/url.const';
import { AsyncCallModels } from '@models/async-call.models';
import { LocationModel } from '../locations/location.model';

@Injectable({ providedIn: 'root' })
export class CameraServiceV2 {
  constructor(private http: HttpClient) {
  }

  public updateDetails(data: CameraUpdateRequest): Observable<AsyncCallModels.AsyncCallResponse<LocationModel.LocationItem>> {
    return this.http.put<AsyncCallModels.AsyncCallResponse<LocationModel.LocationItem>>(api.camera.updateDetails, data);
  }

  public deleteCameraFromLocation(data: LocationModel.DeleteCameraFromLocationRequest): Observable<AsyncCallModels.AsyncCallResponse<LocationModel.LocationItem>> {
    return this.http.delete<AsyncCallModels.AsyncCallResponse<LocationModel.LocationItem>>(api.camera.delete, { body: data });
  }

  public addCameraToLocation(data: LocationModel.AddCameraToLocationRequest): Observable<AsyncCallModels.AsyncCallResponse<EdgeCamera.CameraItem>> {
    return this.http.post<AsyncCallModels.AsyncCallResponse<EdgeCamera.CameraItem>>(api.camera.create, data);
  }

  public updateAudioVideoUpdate(data: CameraUpdateRequest): Observable<AsyncCallModels.AsyncCallResponse<LocationModel.LocationItem>> {
    return this.http.post<AsyncCallModels.AsyncCallResponse<LocationModel.LocationItem>>(api.camera.updateVideoAudio(data.locationId, data.edgeId, data.cameraId), data);
  }

  public updatePTZ(data: CameraUpdateRequest): Observable<AsyncCallModels.AsyncCallResponse<LocationModel.LocationItem>> {
    return this.http.post<AsyncCallModels.AsyncCallResponse<LocationModel.LocationItem>>(api.camera.updatePtz(data.locationId, data.edgeId, data.cameraId), data);
  }

  public updateStorage(data: CameraUpdateRequest): Observable<AsyncCallModels.AsyncCallResponse<LocationModel.LocationItem>> {
    return this.http.post<AsyncCallModels.AsyncCallResponse<LocationModel.LocationItem>>(api.camera.updateStorage(data.locationId, data.edgeId, data.cameraId), data);
  }

  public rebootCamera(edgeId: string, cameraId: string, restartStreamer?: boolean): Observable<AsyncCallModels.AsyncCallResponse<LocationModel.LocationItem>> {
    return this.http.post<AsyncCallModels.AsyncCallResponse<LocationModel.LocationItem>>(api.camera.reboot, { cameraId, edgeId, restartStreamer });
  }
}
