import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Edge } from '../../edge/edge.model';
import { concatMap, mergeMap, filter, map, Observable, tap, catchError, throwError, of, take, timeout } from 'rxjs';
import { SQSMsgInfo } from '../../core/interfaces';
import { TokenDataStatus } from '../../core/messaging.interfaces';
import { EdgeService } from 'src/app/edge/edge.service';
import { GetEdgeIpAddressToken } from '../sessions/get-edge-ip-address-session';
import { EdgeActions } from '@states/edge/edge.action-types';
import { select, Store } from '@ngrx/store';
import { EdgeSelectors } from '@states/edge/edge.selector-types';
import { api } from '@consts/url.const';
import { GrafanaModels } from '@models/grafana.model';


@Injectable({
  providedIn: 'root',
})
export class GetEdgeIpAddressService {
  constructor(private http: HttpClient, private store: Store, private edgeService: EdgeService) {
  }

  getEdgeIpAddressInfo(data: Edge.GetEdgeIpAddressRequest): Observable<SQSMsgInfo> {
    const url = `${environment.apiUrl}/locations/get-edge-ip-address`;
    return this.http.post<SQSMsgInfo>(url, data)
      .pipe(
        timeout(4000),
        catchError(err => {
          return throwError(() => err);
        }),
      );
  }

  getEdgeIpAddressInfoFromGrafana(data: Edge.GetEdgeIpAddressRequest): Observable<GrafanaModels.GetEdgeIpAddressInfoFromGraphana> {
    const url = `${api.stats.grafana}/edge/get-edge-ip-address/${data.edgeId}`;
    return this.http.get<GrafanaModels.GetEdgeIpAddressInfoFromGraphana>(url)
      .pipe(
        catchError(err => {
          return throwError(() => err);
        }),
      );
  }

  createOrUpdateLocalProxy(prefix: string, localIp: string) {
    const data: Edge.ConfigureEdgeProxy = {
      prefix,
      localIp,
    };
    const url = `${environment.apiUrl}/cameras/camera/local/configure`;
    return this.http.post(url, data);
  }

  calculateExpiryTime(expiresInSeconds): number {
    return new Date().getTime() + expiresInSeconds * 1000;
  }

}
