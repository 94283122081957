import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { api } from '@consts/url.const';
import { Observable } from 'rxjs';
import { EdgeMetadataModels } from '@models/edge-metadata.model';

@Injectable({
  providedIn: 'root',
})
export class EdgeMetadataService {

  constructor(private http: HttpClient) {
  }

  public getOrgBucketTransitionTimestamp(): Observable<EdgeMetadataModels.GetGcpTransitionResponseItem[]> {
    return this.http.get<EdgeMetadataModels.GetGcpTransitionResponseItem[]>(api.thumbnails.gcpTransitionTime);
  }

}
