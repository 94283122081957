import { createAction, props } from '@ngrx/store';
import { CameraTestData, CameraUpdatePTZRequest, CameraUpdateRequest, EdgeCamera, UpdateCameraDocument } from '../../../cameras/camera.model';
import { SearchConfigurationModel, VpnConfiguration } from '@models/search-configuration.model';
import { AnalyticClasses } from '@enums/alert-events.enum';
import { CameraAsset, CameraPrivacy, NetworkManualTestForm } from '@models/camera.model';
import { CameraSearchSettings } from '@models/camera-search.settings';
import { ScanZones } from '../../../pages/camera-edit/tabs/search-select-zones/search-select-zones.model';
import { ChangeSubstreamListAction, LocationModel } from '../../../locations/location.model';
import { LiveStreamModels } from '@models/live-stream.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { CameraSettingsModel } from '@models/camera-settings.model';
import Shortcut = CameraSettingsModel.Shortcut;
import { SearchDevicesManualGetCameraDetailsToken } from '../../../core/sessions/searce-devices-manual-get-camera-details-session';
import { HttpErrorResponse } from '@angular/common/http';

export const resetToInitialState = createAction('[CAMERA-EDIT] Reset to Initial state');

export const setSelectedCamera = createAction(
  '[CAMERA-EDIT] Set Selected Camera',
  props<{ selectedCamera: EdgeCamera.CameraItem }>(),
);

export const setZones = createAction('[CAMERA-EDIT] Set Zones', props<{ zones: ScanZones }>());
export const setZonesExclude = createAction('[CAMERA-EDIT] Set Zones Exclude', props<{ zonesExclude: boolean }>());

export const setMarkedIdx = createAction('[CAMERA-EDIT] Set Marked Indexes', props<{ markedIdx: number[] }>());

export const setDefinedZones = createAction('[CAMERA-EDIT] Set Defined Zones', props<{ definedZones: boolean }>());
export const setProtectiveGear = createAction('[CAMERA-EDIT] Set Protective Gear', props<{ protectiveGear: boolean }>());
export const setForklift = createAction('[CAMERA-EDIT] Set Forklift', props<{ forklift: boolean }>());
export const setContainer = createAction('[CAMERA-EDIT] Set Container', props<{ container: boolean }>());
export const setShoppingCart = createAction('[CAMERA-EDIT] Set Shopping Cart', props<{ shoppingCart: boolean }>());

export const setObjectToPixelate = createAction(
  '[CAMERA-EDIT] Set Object To Pixelate',
  props<{ objectToPixelate: AnalyticClasses }>(),
);

export const saveSearchConfiguration = createAction('[CAMERA-EDIT] Save Search Configuration');

export const sendSearchConfiguration = createAction('[CAMERA-EDIT] Send Search Configuration to the Server');

export const saveSearchConfigurationSuccess = createAction('[CAMERA-EDIT] Save Search Configuration Success');

export const setObjectToScan = createAction('[CAMERA-EDIT] Set Object To Scan', props<{ objectToScan: AnalyticClasses }>());

export const setObjectsToScan = createAction('[CAMERA-EDIT] Set Objects To Scan', props<{ objectsToScan: AnalyticClasses[] }>());


export const getSelectedSearchConfiguration = createAction(
  '[CAMERA-EDIT] Get Selected Search Configuration',
  props<{ locationId: string; edgeId: string; cameraId: string }>(),
);

export const getSelectedSearchConfigurationSuccess = createAction(
  '[CAMERA-EDIT] Get Selected Search Configuration Success',
  props<{ selectedSearchConfiguration: SearchConfigurationModel }>(),
);

export const getSelectedCameraVPNConfiguration = createAction(
  '[CAMERA-EDIT] Get Selected Camera VPN Configuration',
  props<{ locationId: string; edgeId: string; cameraId: string }>(),
);

export const getSelectedCameraVPNConfigurationSuccess = createAction(
  '[CAMERA-EDIT] Get Selected Vpn Configuration Success',
  props<{ vpnConfiguration: VpnConfiguration }>(),
);

export const getSelectedCameraVPNConfigurationFail = createAction(
  '[CAMERA-EDIT] Get Selected Vpn Configuration Fail',
  props<{ errorMessage: string }>(),
);

export const setVpnConfiguration = createAction(
  '[CAMERA-EDIT] Set Vpn Configuration',
  props<{ vpnConfiguration?: VpnConfiguration, lumanaConfig?: boolean }>(),
);

export const setLicensePlates = createAction('[CAMERA-EDIT] Set License Plates', props<{ licensePlates: boolean }>());

export const setVehicleMMC = createAction('[CAMERA-EDIT] Set Vehicle MMC', props<{ vehicleMMC: boolean }>());

export const deleteSearchConfiguration = createAction('[CAMERA-EDIT] Delete Search Configuration');

export const sendDeleteSearchConfiguration = createAction('[CAMERA-EDIT] Delete Search Configuration Send to Server');

export const deleteSearchConfigurationSuccess = createAction('[CAMERA-EDIT]  Delete Search Configuration Success');

export const getCameraAssets = createAction('[CAMERA-EDIT] Get Camera Assets');

export const getCameraAssetsSuccess = createAction(
  '[CAMERA-EDIT] Get Camera Assets Success',
  props<{ cameraAssets: CameraAsset[] }>(),
);

export const deleteCameraAssets = createAction('[CAMERA-EDIT] Delete Camera Asset', props<{ cameraAsset: CameraAsset }>());

export const notifyCameraAssetsUpload = createAction('[CAMERA-EDIT] Notify Camera Asset Upload', props<{ name: string }>());

export const getCameraDetailsManually = createAction(
  '[CAMERA-EDIT] Get Camera Details Manually',
  props<{ manual: boolean; form: NetworkManualTestForm; cameraId?: string }>(),
);

export const sendGetCameraDetailsManually = createAction(
  '[CAMERA-EDIT] Send Get Camera Details Manually',
  props<{ manual: boolean; form: NetworkManualTestForm; cameraId?: string }>(),
);

export const sendGetCameraDetailsManuallySuccess = createAction(
  '[CAMERA-EDIT] Send Get Camera Details Manually Success',
  props<{ result: SearchDevicesManualGetCameraDetailsToken.Result }>(),
);

export const sendGetCameraDetailsManuallyFailed = createAction(
  '[CAMERA-EDIT] Send Get Camera Details Manually Failed',
  props<{ error: { name?: string, status?: number, error: { error: { responseCode: number; message: string, name: string, status?: number } } } }>(),
);


export const editCameraPressSave = createAction('[CAMERA-EDIT] Edit Camera Press Save');
export const editCameraVpnPressSave = createAction('[CAMERA-EDIT] Edit Camera VPN Press Save');

export const saveNetwork = createAction('[CAMERA-EDIT] Save Network');

export const setCameraNetworkTest = createAction(
  '[CAMERA-EDIT] Set Camera Network Test',
  props<{ cameraNetworkTestSuccess: boolean }>(),
);

export const cameraUpdate = createAction('[CAMERA-EDIT] Camera Update', props<{ data: Partial<UpdateCameraDocument> }>());
export const cameraStorageUpdate = createAction('[CAMERA-EDIT] Camera Storage Update', props<{ data: CameraUpdateRequest, camera?: EdgeCamera.CameraItem }>());
export const cameraAudioVideoUpdate = createAction('[CAMERA-EDIT] Camera Audio Video Update', props<{ data: CameraUpdateRequest }>());
export const cameraHlsUpdate = createAction('[CAMERA-EDIT] Camera Hls Update', props<{ data: Partial<LiveStreamModels.HLS> }>());
export const cameraDetailsUpdate = createAction('[CAMERA-EDIT] Camera Details Update', props<{ data: Partial<UpdateCameraDocument> }>());
export const cameraUpdatePTZ = createAction('[CAMERA-EDIT] Camera Update PTZ', props<{ data: CameraUpdatePTZRequest }>());
export const sendCameraUpdate = createAction('[CAMERA-EDIT] Send Camera Update to Server', props<{ data: Partial<UpdateCameraDocument> }>());
export const sendCameraDetailsUpdate = createAction('[CAMERA-EDIT] Send Camera Details Update to Server', props<{ data: Partial<UpdateCameraDocument> }>());
export const sendCameraAudioVideoUpdate = createAction('[CAMERA-EDIT] Send Camera Audio Video Update to Server', props<{ data: CameraUpdateRequest }>());
export const sendCameraHlsUpdate = createAction('[CAMERA-EDIT] Send Camera HLS Update to Server', props<{ data: Partial<LiveStreamModels.HLS> }>());
export const sendCameraUpdatePTZ = createAction('[CAMERA-EDIT] Send Camera Update PTZ to Server', props<{ data: CameraUpdatePTZRequest }>());
export const sendCameraStorageUpdate = createAction('[CAMERA-EDIT] Send Camera Storage Update to Server', props<{ data: CameraUpdateRequest, camera?: EdgeCamera.CameraItem }>());

export const cameraVpnUpdate = createAction('[CAMERA-EDIT] Camera VPN Update', props<{ autoConfigure?: boolean }>());
export const sendCameraVpnUpdate = createAction('[CAMERA-EDIT] Send Camera VPN Update to Server', props<{ autoConfigure?: boolean }>());
export const sendCameraVpnUpdateFail = createAction('[CAMERA-EDIT] Send Camera VPN Update to Server Fail', props<{ response?: HttpErrorResponse }>());

export const cameraVpnReboot = createAction('[CAMERA-EDIT] Camera VPN Reboot', props<{ restartStreamer?: boolean }>());
export const sendCameraVpnReboot = createAction('[CAMERA-EDIT] Send Camera VPN Reboot to Server', props<{ restartStreamer?: boolean }>());
export const sendCameraVpnRebootSuccess = createAction('[CAMERA-EDIT] Send Camera VPN Reboot to Server Success');
export const sendCameraVpnRebootError = createAction('[CAMERA-EDIT] Send Camera VPN Reboot to Server Error');

export const setIsAddCameraManuallySuccess = createAction(
  '[CAMERA-EDIT] Set is Add Camera Manually Success Success',
  props<{ addCameraManuallySuccess: boolean }>(),
);

export const saveCameraSearchSettings = createAction(
  '[CAMERA-EDIT] Save Camera Search Settings',
  props<{ filters: { [key: string]: boolean } }>(),
);

export const sendCameraSearchSettings = createAction(
  '[CAMERA-EDIT] Send Camera Search Settings',
  props<{ filters: { [key: string]: boolean } }>(),
);

export const getCameraSearchSettings = createAction('[CAMERA-EDIT] Get Camera Search Settings');

export const getCameraSearchSettingsSuccess = createAction(
  '[CAMERA-EDIT] Get Camera Search Settings Success',
  props<{ cameraSearchSettings: CameraSearchSettings }>(),
);

export const getCameraById = createAction('[CAMERA-EDIT] Get Camera By Id');

export const setSelectedLocationId = createAction(
  '[CAMERA-EDIT] Set Selected Location Id',
  props<{ selectedLocationId: string }>(),
);

export const setSelectedEdgeId = createAction('[CAMERA-EDIT] Set Selected edge Id', props<{ selectedEdgeId: string }>());

export const setSelectedCameraId = createAction('[CAMERA-EDIT] Set Selected Camera Id', props<{ selectedCameraId: string }>());

export const setPrivacy = createAction('[CAMERA-EDIT] Set Privacy', props<{ privacy: CameraPrivacy }>());

export const getHasOrgForklift = createAction('[CAMERA-EDIT] Get Has Org Forklift ');
export const getHasOrgContainer = createAction('[CAMERA-EDIT] Get Has Org Container ');
export const setOrgForklift = createAction('[CAMERA-EDIT] Set Org Forklift', props<{ orgForklift: boolean }>());
export const setOrgContainer = createAction('[CAMERA-EDIT] Set Org Container', props<{ orgContainer: boolean }>());
export const getHasProtectiveGear = createAction('[CAMERA-EDIT] Get Has Protective Gear');
export const setOrgProtectiveGear = createAction('[CAMERA-EDIT] Set Org Protective Gear', props<{ orgProtectiveGear: boolean }>());
export const getHasShoppingCart = createAction('[CAMERA-EDIT] Get Has Shopping Cart');
export const setOrgShoppingCart = createAction('[CAMERA-EDIT] Set Org Shopping Cart', props<{ orgShoppingCart: boolean }>());


export const updateSubStream = createAction('[CAMERA-EDIT] Update Sub stream', props<{ index: number; connectionString: string }>());
export const testSubStream = createAction('[CAMERA-EDIT] Test Sub stream', props<{ index: number }>());
export const testSubStreamSuccess = createAction('[CAMERA-EDIT] Test Sub stream Success');
export const testSubStreamInit = createAction('[CAMERA-EDIT] Test Sub stream init');
export const testSubStreamFail = createAction('[CAMERA-EDIT] Test Sub stream Fail', props<{ session: string; success: boolean; deleteSub: boolean; width?: number; height?: number }>());

export const setSubStreamSession = createAction('[CAMERA-EDIT] Set Sub stream session', props<{ index: number; session: string }>());

export const updateCameraCoords = createAction('[CAMERA] update camera coords', props<{ coords: { lat: number, lng: number }, address: string }>());
export const updateCameraCoordsSuccess = createAction('[CAMERA] update camera coords success', props<{ coords: { lat: number, lng: number }, address: string }>());
export const updateCameraCoordsFail = createAction('[CAMERA] update camera coords fail');

export const updateCameraShortcuts = createAction('[CAMERA] update camera shortcuts', props<{ shortcuts: Dictionary<Shortcut> }>());
export const sendCameraDetailsUpdateSuccess = createAction('[CAMERA-EDIT] Send Camera Details Update to Server Success', props<{
  location: LocationModel.LocationItem,
  edgeId: string,
  cameraId: string
}>());
