import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { api } from '@consts/url.const';
import { GeneaModel } from '@models/genea.model';

@Injectable()
export class GeneaService {

  constructor(private http: HttpClient) {
  }

  public getOrganizationGeneaDoors(): Observable<GeneaModel.DocumentMongo[]> {
    return this.http.get<GeneaModel.DocumentMongo[]>(api.genea.crud);
  }

  public create(data: GeneaModel.CreateDocumentDto): Observable<GeneaModel.DocumentMongo> {
    return this.http.post<GeneaModel.DocumentMongo>(api.genea.crud, data);
  }

  public delete(id: string): Observable<boolean> {
    return this.http.delete<boolean>(api.genea.geOne(id));
  }

  public update(data: GeneaModel.DocumentMongo): Observable<GeneaModel.DocumentMongo> {
    return this.http.put<GeneaModel.DocumentMongo>(api.genea.geOne(data._id), data);
  }

  public getGeneaLocations(): Observable<any> {
    return this.http.get<any>(api.genea.locations);
  }

  public getGeneaLocationDoors(locationUUID: string): Observable<any> {
    return this.http.get<any>(api.genea.doors(locationUUID));
  }
}
