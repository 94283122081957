import { Person, GroupModels, GroupStatus, Buckets } from '@models/people.model';
import { createAction, props } from '@ngrx/store';

export const resetToInitialState = createAction('[PEOPLE] Reset to Initial state');
export const resetUnsaved = createAction('[PEOPLE] Reset unsaved to Initial state');

export const getPeople = createAction('[PEOPLE] Get People', props<{ status?: GroupStatus, page?: number, size?: number }>());
export const getPeopleSuccess = createAction('[PEOPLE] Get People Success', props<{ people: Person[] }>());
export const getBucketsSuccess = createAction('[PEOPLE] Get Buckets Success', props<{ buckets: Person[], token: string }>());
export const setToken = createAction('[PEOPLE] Set Token', props<{ token: string }>());

export const setPeople = createAction('[PEOPLE] Set People', props<{ people: Person[] }>());

export const addPerson = createAction('[PEOPLE] Save Person', props<{ person: Person }>());
export const addPersonSuccess = createAction('[PEOPLE] Save Person Success', props<{ person: Person }>());

export const removePerson = createAction('[PEOPLE] Remove Person', props<{ person: Person }>());
export const removePersonSuccess = createAction('[PEOPLE] Remove Person Success', props<{ personId: number }>());

export const editPerson = createAction('[PEOPLE] Edit Person', props<{ person: Person }>());
export const editPersonSuccess = createAction('[PEOPLE] Edit Person Success', props<{ person: Person }>());

export const UploadFaceAssets = createAction(
  '[PEOPLE] Upload Face Assets',
  props<{ request: GroupModels.FileAsset[], multiple?: boolean }>(),
);

export const UploadFaceAssetsSend = createAction(
  '[PEOPLE] Upload Face Assets Send',
  props<{ request: GroupModels.FileAsset[], multiple?: boolean }>(),
);

export const UploadFaceAssetsSendSuccess = createAction(
  '[PEOPLE] Upload Face Assets Send Success',
  props<{ response: GroupModels.LearnFaceResponse, multiple?: boolean }>(),
);

export const UploadFaceAssetsSendError = createAction(
  '[PEOPLE] Upload Face Assets Send Error',
);

export const UploadFaceAsset = createAction(
  '[PEOPLE] Upload Face Asset',
  props<{ request: GroupModels.FileAsset }>(),
);

export const UploadFaceAssetSend = createAction(
  '[PEOPLE] Upload Face Asset Send',
  props<{ requset: GroupModels.FileAsset }>(),
);

export const UploadFaceAssetsSearchSend = createAction(
  '[PEOPLE] Upload Face Assets Search Send',
  props<{ request: GroupModels.SearchFaceRequest }>(),
);

export const UploadFaceAssetsSearchSendSuccess = createAction(
  '[PEOPLE] Upload Face Assets Search Send Success',
  props<{ response: GroupModels.SearchFaceResponse }>(),
);

export const UploadFaceCreateSend = createAction(
  '[PEOPLE] Upload Face Create Send',
  props<{ request: GroupModels.CreateRequest }>(),
);

export const UploadFaceCreateSendSuccess = createAction(
  '[PEOPLE] Upload Face Create Send Success',
);

export const UploadFaceAttachSend = createAction(
  '[PEOPLE] Upload Face Attach Send',
  props<{ request: GroupModels.AttachRequest }>(),
);

export const UploadFaceAttachSendSuccess = createAction(
  '[PEOPLE] Upload Face Attach Send Success',
);








