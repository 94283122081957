import { AnalyticStatus, CameraStatus, EdgeCamera } from '../cameras/camera.model';
import { LocationModel } from '../locations/location.model';
import { KeyValuePairs } from '../core/interfaces';
import { Region } from '@enums/shared.enum';
import { SnsMessages } from '../core/sns-messages.interfaces';
import { LiveKitModels } from '@models/livekit.model';

export namespace Edge {
  export interface EdgeConfig {
    app: {
      heartbeatInterval: number;
      edgeId: string;
      onlineCheckRate: number;
      thumbnailsSNSThreshold: number;
      alertThumbnailsSNSThreshold: number;
      motionVectorsSNSThreshold: number;
      analyticsSNSThreshold: number;
      trainingThumbnailsSNSThreshold: number;
      liveViewTimeout: number;
      liveViewScaleTimeout: number;
      monitorCameraOfflineInterval: number;
      env: string;
    };
    analytics: {
      extractMaxInputFileOpenWaitTime: number;
      extractProgressUpdate: number;
      extractHeaderPrintRate: number;
      thumbnailsDuration: number;
      msgTimeout: number;
      motionsDuration: number;
      batchSize: number;
      maxAlertThumbnailsIn100Seconds: number;
      maxTrainThumbnailsIn100Seconds: number;
      minTrainObjectCount: number;
    };
    db: {
      username: string;
      password: string;
      dbName: string;
      uri: string;
      edgeCollection: string;
    };
    aws: {
      sqsRegion: string;
      sqsEndpoint: string;
      sqsWaitTimeSeconds: number;
      sqsVisibilityTimeoutSeconds: number;
      snsRegion: string;
      snsBase: string;
      mediastoreRegion: string;
      medaistoreEndpoint: string;
      s3VideoStreamRegion: string;
      s3VideoStreamBucket: string;
      s3ThumbnailsRegion: string;
      s3ThumbnailsBucket: string;
      s3snapshotsRegion: string;
      s3snapshotsBucket: string;
      s3alertThumbnailsRegion: string;
      s3alertThumbnailsBucket: string;
      s3trainingThumbnailsRegion: string;
      s3trainingThumbnailsBucket: string;
    };
    discovery: {
      nic0: string;
      nic1: string;
    };
    msgQueue: {
      url: string;
      login: string;
      pass: string;
      port: number;
      externalURL: string;
      bmpQueue: string;
      edgeStatusExchange: string;
      edgeStatusQueue: string;
      analyticQueue: string;
      thumbQueue: string;
      trainingThumbanilQueue: string;
      analyticsCmdQueue: string;
      analyticsResultsCmdQueue: string;
      analyticsResponseQueue: string;
      ffmpegQueue: string;
      uploaderQueue: string;
      uploaderResponseQueue: string;
      streamerQueue: string;
      playbackQueue: string;
      streamerResponseQueue: string;
      discoveryQueue: string;
      discoveryDataQueue: string;
      motionVectorsQueue: string;
      streamerAppCmdQueue: string;
      streamerAppCmdResponseQueue: string;
      playbackAppCmdResponseQueue: string;
      playbackAppCmdQueue: string;
      bmpMessageTTL: number;
      edgeStatusQueueTTL: number;
      analyticsCmdQueueTTL: number;
      ffmpegMessageTTL: number;
      uploaderMessageTTL: number;
      discoveryMessageTTL: number;
      analyticsResultsCmdQueueTTL: number;
      analyticsResultsQueueTTL: number;
      motionVectorsQueueTTL: number;
      uploaderResponseQueueTTL: number;
      streamerQueueTTL: number;
      playbackQueueTTL: number;
      streamerResponseQueueTTL: number;
      streamerAppCmdQueueTTL: number;
      streamerAppCmdResponseQueueTTL: number;
      playbackAppCmdResponseQueueTTL: number;
      playbackAppCmdQueueTTL: number;
      analyticsResponseQueueTTL: number;
      trainingThumbanilQueueTTL: number;
    };
    ffmpeg: {
      path: string;
      cmdLine: string;
      cmdLineLiveScaleStream: string;
      cmdLineAnalytics: string;
      cameraExistTimeoutTest: number;
      cameraGetSnapshotTimeout: number;
      cameraTimeoutParamter: string;
    };
    streamer: {
      path: string;
      hwAcceleration: number;
      defaultRescaleWidth: number;
      defaultRescaleHeight: number;
      progressUpdateFreq: number;
      minMsBetweenFrames: number;
      analyticsFramesTTL: number;
      useCpuMemory: boolean;
      maxNumberOfAnalyticFiles: number;
      streamerProgressTimeout: number;
      processTimoutKill: number;
    };
    playback: {
      path: string;
      playbackProgressTimeout: number;
      yuvFile: string;
      placeholderW: number;
      placeholderH: number;
      placeholderConvert: string;
      placeholderThr: number;
      hlsSize: number;
      hlsSegmentTime: number;
      timeBeforeGenerateNewSegment: number;
      duration: number;
      makeErrorIfNoVideos: boolean;
      processTimoutKill: number;
    };
    locations: {
      videosStorage: string;
      live: string;
      liveScale: string;
      wall: string;
      wallScale: string;
      storageView: string;
      snapshots: string;
      analyticInImages: string;
      alertThumbnails: string;
      trainingThumbnails: string;
      thumbnails: string;
      cloudLiveView: string;
      cloudLiveViewScale: string;
      tempStoragePath: string;
      thumbnailsTempStoragePath: string;
      alertsThumbnailsTempStoragePath: string;
      trainingThumbnailsTempStoragePath: string;
      thumbnailsMsgsTempStoragePath: string;
      analyticsMsgsTempStoragePath: string;
      motionVectorsMsgsTempStoragePath: string;
      thumbnailsCloudPath: string;
      alertsThumbnailsCloudPath: string;
      trainingThumbnailsCloudPath: string;
      snapshotsCloudPath: string;
      playback: string;
      download: string;
      assets: string;
    };
    debug: {
      ffmpeg: number;
      cameraStartManuallyOnStartUp: boolean;
    };
    logger: {
      loggerFilePath: string;
      loggerLevel: string;
      loggerToConsole: boolean;
      loggerDays: string;
      loggerToConsoleLevel: string;
      playbackMaxLogsSizeMb: number;
    };
  }

  export interface GetEdgeIpAddressRequest {
    locationId?: string;
    edgeId: string;
  }

  export interface GetLastVideoDatesRequest {
    locationId: string;
    edgeId: string;
  }

  export interface ConfigureEdgeProxy {
    localIp: string;
    prefix: string;
  }

  export interface UpdateEdgeLocalAddressRequest {
    edgeId: string;
    isLocal: boolean;
    localUrl: string;
    localExpiry: number;
    baseUrl?: string;
  }

  export type GetEdgeIpAddressResponse = EdgeIpAddressInfo;

  export interface EdgeIpAddressInfo {
    [key: string]: [
      {
        address: string;
        netmask: string;
        family: string;
        mac: string;
        internal: boolean;
        cidr: string;
      }
    ];
  }

  export interface EdgeStatus {
    _id?: string;
    edgeId: string;
    timestamp: string | number;
    edgeStatus?: EdgeHeartBeatStatus;
    // cameraStatus?: Array<{
    //   cameraId: string;
    //   componentsStatus: HeartbeatModels.ComponentsStatus;
    //   cameraStatus: CameraStatus;
    //   analyticStatus: AnalyticStatus;
    // }>;
    cameraStatus?: HeatbeatCameraStatus[];
  }

  export interface HeatbeatCameraStatus {
    cameraId: string;
    cameraStatus?: CameraStatus;
    analyticStatus?: AnalyticStatus;
    componentsStatus?: EdgeCamera.ComponentsStatus;
    cameraStreamerProgressData?: EdgeCamera.CameraStreamerProgressData;
    analyticProgressData?: EdgeCamera.AnalyticProgressData;
    cameraOldestStorageTimestamp?: number;
  }

  export interface EdgeCreateRequest {
    edgeId: string;
  }

  export class EdgeDocument {
    _id?: string;
    name?: string;
    edgeId?: string;
    locationId?: string;
    queueUrl?: string;
    discoveredCameras?: string;
    provisioned?: boolean;
    edgeAdditionalConfiguration?: string;
    cameras?: LocationModel.LocationCamerasMap;
    confirmed?: boolean;
    ipAddress?: EdgeIpAddressInfo;
    isLocal?: boolean;
    localUrl?: string;
    baseUrl?: string;
    localExpiry?: number;
    maxStorage?: number;
    region?: Region;
    livekitRegion?: LiveKitModels.LiveKitServerRegion;
    complianceData?: complianceInterface;
    ntpServers?: string[];
    createdAt?: number;
    swVersion?: string;
    lastMp4Ts?: number;
    localIpAddress?: string;
  }

  export interface EdgeItem extends EdgeDocument {
    expanded?: boolean;
  }

  export interface complianceInterface {
    biometricData?: biometricInterface;
    synced?: boolean;
  }

  export interface biometricInterface {
    genderClassification?: boolean;
    faceRecognition?: boolean;
  }

  export interface Camera {
    cameraId: string;
    startYear: number;
    endYear: number;
    username: string;
    password: string;
    ipAddress: string;
    macAddress: string;
    port: string;
    connectionString: string;
    profile?: number;
    width?: number;
    height?: number;
  }

  export interface CameraCreateRequest {
    locationId: string;
    edgeId: string;
    camera: Camera;
    create?: boolean;
  }

  export type EdgeMap = KeyValuePairs<EdgeItem>;

  export interface SWUpdateProgressMsg {
    timestamp: number;
    info: string;
    progress: number;
    url?: string;
    dest?: string;
    filename?: string;
    error?: string;
    status: SnsMessages.SNSResponseStatusInterface;
  }

  export interface NTPConfigMsg {
    locationId: string;
    edgeId: string;
    action: NTPConfigAction;
    servers?: string[];
  }


  export interface EdgeCertificationManageDocument {
    id: string;
    expiredDate: number;
    deployAt: number;
  }

  export enum EdgeSyncOperation {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
  }

  export enum EdgeSyncCollection {
    LOCATIONS = 'locations',
    ALERTS = 'alerts',
  }

  export interface EdgeSyncRes {
    edgeId: string;
    isSync: boolean;
  }

  export enum EdgeSyncStatus {
    COMMITTED = 'committed',
    PENDING = 'pending',
    ERROR = 'error',
    CANCELED = 'canceled',
  }

  export enum EdgeSyncEntityType {
    LOCATION = 'LOCATION',
    EDGE = 'EDGE',
    CAMERA = 'CAMERA',
    EVENT = 'EVENT'
  }

  export interface EdgeSyncQueueDocument {
    _id?: string;
    edgeId: string;
    operation: EdgeSyncOperation;
    collection: EdgeSyncCollection;
    requestData?: Record<string, any>;
    requestQuery?: Record<string, any>;
    requestParams?: Record<string, any>;
    messageData: any;
    sqsMessageType: number;
    status: EdgeSyncStatus; // show in UI
    entityId: string;
    entityType: EdgeSyncEntityType;
    createdAt?: number;
    sessionId?: string;
    context?: any;
    committedAt?: number;
    canceledAt?: number;
    publishCount?: number; // show in UI
    publishAt?: { timestamp: number }[]; // show in UI
    error?: {
      message: string;
      timestamp: number;
    }[]; // show in UI
  }


  export interface Dhcp {
    startIp: string;
    endIp: string;
    dns: string;
    gateway: string;
    leaseTime: string;
  }

  export interface DhcpDevice {
    name: string,
    macAddress: string,
    ip: string
  }

  export interface LocalNetworkConfigurationObject {
    eth0?: string;
    eth0Base?: string;
    eth1?: string;
    eth1Base?: string;
    edgeId: string;
    cameraId: string;
    eth0IpAddress?: string;
    eth1IpAddress?: string;
  }

  export namespace V2 {
    export interface EdgeCreateResponse {
      edgeId: string;
      entityCounter: number;
      locationId: string;
      name: string;
    }
  }
}

export enum NTPConfigAction {
  Update = 0,
  Load = 1,
}

export enum EdgeHeartBeatStatus {
  Online,
  Offline,
  Init,
  Stopped,
  Unknown,
  Installing,
  Rebooting,
}

export const EdgeHeartBeatStatusToString = {
  0: 'online',
  1: 'offline',
  2: 'init',
  3: 'stopped',
  4: 'unknown',
  5: 'installing',
  6: 'rebooting',
};
