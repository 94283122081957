import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SharedState } from '@states/shared/shared.reducer';
import { PermissionModel } from '@models/permission.model';

export const selectSharedState = createFeatureSelector<SharedState>('sharedState');

export const selectIsSaving = createSelector(selectSharedState, ({ isSaving }: SharedState) => isSaving);

export const selectIsInviting = createSelector(selectSharedState, ({ isInviting }: SharedState) => isInviting);

export const selectIsLoading = createSelector(selectSharedState, ({ isLoading }: SharedState) => isLoading);

export const selectSomethingWentWrong = createSelector(selectSharedState, ({ somethingWentWrong }: SharedState) => somethingWentWrong);

export const selectIsDeleting = createSelector(selectSharedState, ({ isDeleting }: SharedState) => isDeleting);

export const selectIsInactive = createSelector(selectSharedState, ({ isInactive }: SharedState) => isInactive);

export const selectInactivityTime = createSelector(selectSharedState, ({ inactivityTime }: SharedState) => inactivityTime);
export const selectInactivityCountdown = createSelector(selectSharedState, ({ inactivityCountdown }: SharedState) => inactivityCountdown);

export const selectIsApplicationLoaded = createSelector(selectSharedState, ({ isApplicationLoaded }: SharedState) => isApplicationLoaded);

export const selectProcessingId = createSelector(selectSharedState, ({ processingId }: SharedState) => processingId);

export const selectPermissions = createSelector(selectSharedState, ({ permissions }: SharedState) => permissions);

// export const checkPermission = (entity: PermissionModel.Entity, permission: PermissionModel.Permission, id: string) => createSelector(selectSharedState, ({ permissions }: SharedState) => {
//   let permissionString = '';
//   if (id) {
//     permissionString = `${entity}:${id}:${permission}`;
//   } else {
//     permissionString = `${entity}:${permission}`;
//   }
//   return permissions.includes(permissionString);
// });
