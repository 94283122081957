import { createAction, props } from '@ngrx/store';
import { AlertEventNotification, AlertEventSendModel } from '@models/alert-events.model';
import { EventModels, EventV2Document, FlowLookup, UiEventSyncResponse } from '@models/alerts-v2.model';
import { Dictionary } from '@ngrx/entity/src/models';

export const resetToInitialState = createAction('[ALERT-EVENTS] Reset to Initial state');


export const setNotifications = createAction('[ALERT-EVENTS] Set Notifications', props<{ notifications: AlertEventNotification }>());

export const startRemoveEvent = createAction(
  '[ALERT-EVENTS] Start Remove Event',
  props<{ id: string; }>(),
);

export const removeEvent = createAction(
  '[ALERT-EVENTS] Remove Event',
  props<{ id: string; }>(),
);

export const setRemovingEventId = createAction(
  '[ALERT-EVENTS] Set Removing Event Id',
  props<{ id: string; }>(),
);

export const unSetRemovingEventId = createAction(
  '[ALERT-EVENTS] Un Set Removing Event Id',
  props<{ id: string; }>(),
);
export const removeEventSuccess = createAction(
  '[ALERT-EVENTS] Remove Event Success',
  props<{ id: string; }>(),
);

export const enableEvent = createAction('[ALERT-EVENTS] Enable Event', props<{ alert: AlertEventSendModel; enabled: boolean }>());

export const resetPagination = createAction('[ALERT-EVENTS] Reset Pagination');

export const getEventSyncStatus = createAction(
  '[ALERT-EVENTS] Get Event Sync status',
  props<{ id: string; }>(),
);

export const getEventSyncStatusSuccess = createAction(
  '[ALERT-EVENTS] Get Event Sync status Success',
  props<{ id: string, data: Dictionary<UiEventSyncResponse> }>(),
);

export const getEventSyncStatusFail = createAction(
  '[ALERT-EVENTS] Get Event Sync status Fail',
);

export const resetEventSyncStatus = createAction(
  '[ALERT-EVENTS] Reset Event Sync Status',
  props<{ id: string; }>(),
);


export const getAlertEventsV3 = createAction('[ALERT-EVENTS-V3] Get Alert Events from Server V2', props<{ filters: EventModels.Filters }>());
export const getAlertEventsV3Success = createAction('[ALERT-EVENTS-V3] Get Alert Events from Server V2 Success',
  props<{ documents: EventV2Document[] }>());
export const getAlertEventsV3Fail = createAction('[ALERT-EVENTS-V3] Get Alert Events from Server V2 Fail');

/**
 * Pagination
 */
export const loadNextPage = createAction('[ALERT-EVENTS-V3] Load Next Page', props<{ query: EventModels.Filters }>());
export const setPagination = createAction('[ALERT-EVENTS-V3] Set Pagination Params', props<{ lastId: string; lastOrderedValue: string | number | boolean }>());

export const updateEventSuccess = createAction(
  '[ALERT-EVENTS-V3] Update Event Success',
  props<{ document: EventModels.EventDocument }>(),
);

export const createEventSuccess = createAction(
  '[ALERT-EVENTS-V3] Save Event Success',
  props<{ document: EventModels.EventDocument }>(),
);

export const setListLoader = createAction('[ALERT-EVENTS-V3] Set List Loader', props<{ listLoader: boolean }>());

export const onQueryParamsChanged = createAction('[ALERT-EVENTS-V3] On Query Params Changed', props<{ query: EventModels.Filters }>());

