import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, exhaustMap, of, share, switchMap, withLatestFrom } from 'rxjs';
import { SharedActions } from '@states/shared/shared.action-types';
import { UtilsService } from '../../edge/utils.service';
import { mergeMap } from 'rxjs/operators';
import { GeneaActions } from '@states/genea/genea.action-types';
import { GeneaService } from '../../services/genea.service';


@Injectable()
export class GeneaEffects {

  public startAddGeneaDoor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneaActions.startAddGeneaDoor),
      switchMap(({ geneaDoor }) => {
        return [
          GeneaActions.setIsCreatingLoader({ isCreating: true }),
          GeneaActions.sendAddGeneaDoorRequest({ geneaDoor })];
      }),
    ),
  );

  public sendAddGeneaDoorRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneaActions.sendAddGeneaDoorRequest),
      switchMap(({ geneaDoor }) => {
        return this.geneaService.create(geneaDoor)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({ success: 'Genea has been created' }),
                GeneaActions.setIsCreatingLoader({ isCreating: false }),
                GeneaActions.sendAddGeneaDoorRequestSuccess({ geneaDoor: res }),
              ];
            }),
            catchError((error) => {
              const errorObj = JSON.parse(error?.error?.exception);
              console.log(errorObj);
              return [
                GeneaActions.setIsCreatingLoader({ isCreating: false }),
                GeneaActions.sendAddGeneaDoorRequestFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public getOrganizationGeneaDoors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneaActions.getOrganizationGeneaDoors),
      switchMap(() => {
        return this.geneaService.getOrganizationGeneaDoors()
          .pipe(
            switchMap(res => {
              return [
                GeneaActions.getOrganizationGeneaDoorsSuccess({ documents: res }),
              ];
            }),
            catchError(() => {
              return of(
                GeneaActions.getOrganizationGeneaDoorsFail(),
              );
            }),
          );
      }),
    ),
  );

  public startDeleteGenea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneaActions.startDeleteGenea),
      debounceTime(400),
      exhaustMap(({ id }) => [GeneaActions.sendDeleteGeneaRequest({ id })]),
    ),
  );

  public sendDeleteGeneaRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneaActions.sendDeleteGeneaRequest),
      mergeMap(({ id }) => {
        return this.geneaService
          .delete(id)
          .pipe(
            mergeMap(res => {
              return [
                GeneaActions.sendDeleteGeneaRequestSuccess({ id }),
                SharedActions.showMessage({ success: 'Genea has been removed' }),
              ];
            }),
            catchError(response => {
              const err = this.utilsService.errMessage(response);
              return [
                GeneaActions.sendDeleteGeneaRequestFail({ id }),
                SharedActions.showMessage({ error: err }),
              ];
            }),
            share(),
          );
      }),
    ),
  );


  public startUpdateGenea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneaActions.startUpdateGenea),
      switchMap(({ geneaDoor }) => {
        return [
          GeneaActions.setIsCreatingLoader({ isCreating: true }),
          GeneaActions.sendUpdateGeneaRequest({ geneaDoor })];
      }),
    ),
  );

  public sendUpdateGeneaRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneaActions.sendUpdateGeneaRequest),
      switchMap(({ geneaDoor }) => {
        return this.geneaService.update(geneaDoor)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({ success: 'Genea has been updated' }),
                GeneaActions.setIsCreatingLoader({ isCreating: false }),
                GeneaActions.sendUpdateGeneaRequestSuccess({ geneaDoor: res }),
              ];
            }),
            catchError((error) => {
              const errorObj = JSON.parse(error?.error?.exception);
              console.log(errorObj);
              return [
                GeneaActions.setIsCreatingLoader({ isCreating: false }),
                GeneaActions.sendUpdateGeneaRequestFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  constructor(private geneaService: GeneaService,
              private actions$: Actions,
              private utilsService: UtilsService) {
  }

}
