import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatCalendar } from '@angular/material/datepicker';
import * as moment from 'moment-timezone';
import { UiCalendarPickerType, UIInputStyle } from '@enums/shared.enum';
import { UiDatetimeRangePickerModel } from './ui-datetime-range-picker.model';
import { CamerasThumbnailsService } from '../../../cameras/camera-thumbnails/camera-thumnails.service';
import { UiCalendarBase } from '../../../helpers/ui-calendar-base.component';
import ABSOLUTE_DIALOG_WIDTH = UiDatetimeRangePickerModel.ABSOLUTE_DIALOG_WIDTH;
import RELATIVE_DIALOG_WIDTH = UiDatetimeRangePickerModel.RELATIVE_DIALOG_WIDTH;
import { DialogPosition, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, shareReplay, take } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { OrganizationSelectors } from '@states/organization/organization.selector-types';


@Component({
  selector: 'empty-header',
  template: ``,
})
export class EmptyHeader {
  date: any;
}

@UntilDestroy()
@Component({
  selector: 'ui-calendar',
  templateUrl: './ui-calendar.component.html',
  styleUrls: ['./ui-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiCalendarComponent extends UiCalendarBase implements OnInit, OnChanges, OnDestroy {
  CustomUnit: typeof UiDatetimeRangePickerModel.CustomUnit = UiDatetimeRangePickerModel.CustomUnit;


  pickerType: UiCalendarPickerType = UiCalendarPickerType.RELATIVE;


  @ViewChild('selectorInput')
  private selectorInput: ElementRef<HTMLElement>;

  @ViewChild('leftCalendar')
  public leftCalendarRef: MatCalendar<any>;

  @ViewChild('rightCalendar')
  private rightCalendar: MatCalendar<any>;

  @ViewChild('pickerTemplate')
  private selectorTemplate: TemplateRef<any>;

  @ViewChild('dialogContainer')
  private dialogContainer: ElementRef<HTMLElement>;

  @Input() inputStyle: UIInputStyle = UIInputStyle.labelInside;


  @Input() label: string = 'Dates';

  @Input() isRelative = true;

  @Input() isAbsolute = true;

  @Input()
  disabled = false;

  @Input()
  timezone: string;

  @Input() displayFromTo = true;
  @Input() displayTimezone = true;

  @Input() dateFormat = 'YYYY-MM-dd HH:mm';
  @Input() showYear = false;
  @Input() onlyDays = false;

  customUnits: UiDatetimeRangePickerModel.CustomUnit[];

  public startDateLeftCalendar: Date;
  public startDateRightCalendar: Date;

  public inputStyles = UIInputStyle;
  public opened = false;
  public dialogRef: MatDialogRef<any>;
  public selectorItemsViewportHeight: number = 400;


  public headerComponent = EmptyHeader;

  private dialogOpening = false;
  private calendarModalWidth: number = +RELATIVE_DIALOG_WIDTH.replace('px', '');
  private clickOutsideEventHandler: (event: any) => void;

  public selectMaxRetentionDays$: Observable<number> = this.store$.pipe(select(OrganizationSelectors.selectMaxRetentionDays));

  public minDate: Date;
  public maxDate: Date;

  constructor(
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private window: Window,
    private camerasThumbnailsService: CamerasThumbnailsService,
    private store$: Store,
  ) {
    super();
  }

  @HostListener('document:click', ['$event'])
  clickOutsideEvent(event: MouseEvent) {
    if (this.clickOutsideEventHandler) {
      this.clickOutsideEventHandler(event);
    }
  }

  @HostListener('window:resize', ['$event'])
  public resize() {
    if (this.opened) {
      this.dialogRef.close();
      setTimeout(() => {
        this.toggleDialog();
      }, 500);
    }
  }

  public ngOnInit() {
    this.customUnits = !!this.onlyDays ? [UiDatetimeRangePickerModel.CustomUnit.days, UiDatetimeRangePickerModel.CustomUnit.weeks] : Object.values(this.CustomUnit)
    this.now = moment()
      .toDate();
    if (!this.isRelative) {
      this.selectedPickerType = UiCalendarPickerType.ABSOLUTE;
      this.calendarModalWidth = +(
        this.selectedPickerType === UiCalendarPickerType.ABSOLUTE ? ABSOLUTE_DIALOG_WIDTH : RELATIVE_DIALOG_WIDTH
      ).replace('px', '');
    }
    this.maxDate = moment()
      .toDate();
    this.selectMaxRetentionDays$.pipe(untilDestroyed(this), shareReplay(), take(1))
      .subscribe(
        maxRetentionDays => {
          this.minDate = moment()
            .subtract(maxRetentionDays, 'days')
            .toDate();
        },
      );
  }


  public override dateChanged(ev: Date) {
    super.dateChanged(ev, this.timezone);
    this.leftCalendarRef.updateTodaysDate();
    this.rightCalendar.updateTodaysDate();
  }


  public toggleDialog(): void {
    if (!this.dialogOpening) {
      this.dialogOpening = true;

      if (!this.opened) {
        const position = this.getPosition();
        /**
         * If date is now - left calendar one month before now, right - current month
         * if start date before than now - 1month, show start date
         */
        if (moment(this.startDate)
          .isBefore(moment()
            .subtract(1, 'month'))) {
          this.startDateLeftCalendar = moment(this.startDate)
            .toDate();
        } else {
          this.startDateLeftCalendar = moment()
            .subtract(1, 'month')
            .toDate();
        }
        this.startDateRightCalendar = moment(this.startDateLeftCalendar)
          .add(1, 'month')
          .toDate();

        this.dialogRef = this.dialog.open(this.selectorTemplate, {
          hasBackdrop: false,
          width: this.selectedPickerType === UiCalendarPickerType.ABSOLUTE ? ABSOLUTE_DIALOG_WIDTH : RELATIVE_DIALOG_WIDTH,
          position,
          panelClass: 'ui-selector-panel',
          closeOnNavigation: true,
        });

        this.dialogRef
          .afterOpened()
          .pipe(untilDestroyed(this))
          .subscribe(_ => {
            this.clickOutsideEventHandler = this.closeDialogByClickOutsideEvent;
            setTimeout(() => {
              // Fix some css issues when it's fast loads.
              this.opened = true;
              this.cdr.detectChanges();
            });
            this.dialogOpening = false;
            window.addEventListener('scroll', this.scroll.bind(this), true);
          });

        this.dialogRef
          .afterClosed()
          .pipe(untilDestroyed(this))
          .subscribe(_ => {
            this.clickOutsideEventHandler = null;
            this.opened = false;
            this.cdr.detectChanges();
            this.dialogOpening = false;
            window.removeEventListener('scroll', this.scroll, true);
          });
      }
    }
  }

  /**
   * Position of calendar
   * Should be:
   * 1. If space bottom smaller than calendar height should open to top
   * 2. If space top smaller than calendar height should open to bottom
   * 3. If space right smaller than calendar width should open to left
   * 4. If space left smaller than calendar width should open to right
   * @private
   */
  private getPosition(): DialogPosition {
    const smallScreen = this.window.innerHeight < 900;

    const elementRectangle = this.selectorInput.nativeElement.getBoundingClientRect();
    const bottomSpace = this.window.innerHeight - elementRectangle.bottom;

    let panelHeight = this.selectorItemsViewportHeight;
    let top = elementRectangle.bottom;
    let left = Math.max(elementRectangle.left, 5);
    let right = Math.max(this.window.innerWidth - elementRectangle.right, 5);
    let smallerLeft = this.window.innerWidth - left > this.calendarModalWidth;
    let smallerRight = this.window.innerWidth - left < +ABSOLUTE_DIALOG_WIDTH.replace('px', '');

    /**
     * If space left smaller than calendar width should open to right
     */
    if (smallerLeft) {
      left = elementRectangle.left;
    } else if (smallerRight) {
      /**
       *  If space right smaller than calendar width should open to left
       */
      left = elementRectangle.right - this.calendarModalWidth;
    }
    panelHeight += 10;
    if (panelHeight < bottomSpace) {
      if (smallScreen) {
        top -= 60;
      }
      if (smallerRight) {
        return {
          right: `${right}px`,
          top: `${top}px`,
        };
      }
      return {
        left: `${left}px`,
        top: `${top}px`,
      };
    } else {
      let top = elementRectangle.top - panelHeight - 75;
      if (top < 5) {
        top = 5;
      }
      if (smallerRight) {
        return { right: `${right}px`, top: `${top}px` };
      }
      return { left: `${elementRectangle.left}px`, top: `${top}px` };
    }
  }

  private closeDialogByClickOutsideEvent(event: MouseEvent) {
    const matDialogContainerElement = this.dialogContainer.nativeElement.parentElement;
    const elementRectangle = matDialogContainerElement.getBoundingClientRect();
    if (
      event.clientX <= elementRectangle.left ||
      event.clientX >= elementRectangle.right ||
      event.clientY <= elementRectangle.top ||
      event.clientY >= elementRectangle.bottom
    ) {
      this.dialogRef.close();
    }
  }

  private scroll(): void {
    if (this.dialogRef && this.opened) {
      const position = this.getPosition();
      this.dialogRef.updatePosition(position);
    }
  }

  public ngOnDestroy(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  public changeType(pickerType: UiCalendarPickerType): void {
    switch (pickerType) {
      case UiCalendarPickerType.ABSOLUTE:
        this.dialogRef.updateSize(ABSOLUTE_DIALOG_WIDTH);
        this.calendarModalWidth = +ABSOLUTE_DIALOG_WIDTH.replace('px', '');
        break;
      case UiCalendarPickerType.RELATIVE:
        this.dialogRef.updateSize(RELATIVE_DIALOG_WIDTH);
        this.calendarModalWidth = +RELATIVE_DIALOG_WIDTH.replace('px', '');
        break;
    }
    this.selectedPickerType = pickerType;
    const position = this.getPosition();
    this.dialogRef.updatePosition(position);
  }

  public convertTsToZone(ts: number, fromZone: string, toZone: string): number {
    return this.camerasThumbnailsService.convertTsToZone(ts, fromZone, toZone);
  }

  public timezoneAbbreviation() {
    if (this.timezone) {
      return moment()
        .tz(this.timezone)
        .format('z');
    }
    return moment()
      .tz(moment.tz.guess())
      .format('z');
  }

  public emitDate(): void {
    super.emitDateRangeChanged();
    this.detectChanges();
    this.dialogRef.close();
  }

  public detectChanges() {
    this.cdr.detectChanges();
  }

  public get betweenHoursDisabled(): boolean {
    const endDateMoment = moment(this.endDate);
    return !moment(this.startDate)
      .diff(endDateMoment, 'day');
  }

  public close() {
    super.cancel();
    this.dialogRef.close();
  }

  public override clearCalendar() {
    super.clearCalendar();
    this.dialogRef.close();
  }
}
