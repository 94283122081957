import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Edge } from '../edge/edge.model';
import { api } from '@consts/url.const';

@Injectable({
  providedIn: 'root',
})
export class SyncQueueService {
  constructor(private http: HttpClient) {}

  getLastCommittedOperations(edgeId): Observable<Edge.EdgeSyncQueueDocument[]> {
    return this.http.get<Edge.EdgeSyncQueueDocument[]>(api.edgeSyncQueue.lastCommitted + `?edgeId=${edgeId}`);
  }

  getNextOperations(edgeId): Observable<Edge.EdgeSyncQueueDocument[]> {
    return this.http.get<Edge.EdgeSyncQueueDocument[]>(api.edgeSyncQueue.nextOperation + `?edgeId=${edgeId}`);
  }

  retryOperation(edgeId: string, edgeLogId: string): Observable<any> {
    return this.http.post(api.edgeSyncQueue.publishOperation, null, {
      params: { edgeId, edgeLogId }
    });
  }

  isEdgeSyncForOrg(): Observable< Edge.EdgeSyncRes[]> {
    return this.http.get<Edge.EdgeSyncRes[]>(api.edgeSyncQueue.orgSync );
  }

  cancelOperation(edgeId: string, edgeLogId: string): Observable<any> {
    return this.http.post(api.edgeSyncQueue.cancelOperation, null, {
      params: { edgeId, edgeLogId }
    });
  }

  resetPublishCounter(edgeId: string, edgeLogId: string): Observable<any> {
    return this.http.post(api.edgeSyncQueue.resetPublishCounter, null, {
      params: { edgeId, edgeLogId }
    });
  }
}
