import { createAction, props } from '@ngrx/store';
import { Auth0 } from '../../../authentication/auth0.model';
import { UserModel } from '@models/user.model';

enum AuthenticationActionTypes {
  Login = '[Authentication API] Login',
  LoginFail = '[Authentication API] Login Fail',
  Logout = '[Logout] Action',

  FirebaseAuthentication = '[Authentication API] FirebaseAuthentication',
  FirebaseAuthenticationSuccess = '[Authentication API] FirebaseAuthentication Success',
  FirebaseAuthenticationFail = '[Authentication API] FirebaseAuthentication Fail',

  Idle = '[Authentication API] Idle',
  IdleSuccess = '[Authentication API] IdleSuccess',
  IdleFail = '[Authentication API] IdleFail',
}

export const Login = createAction(AuthenticationActionTypes.Login, props<{ auth: Partial<Auth0.AuthenticationResponse>, isSilent?: boolean }>());

export const Logout = createAction(AuthenticationActionTypes.Logout, props<{ params: UserModel.LogoutParams }>());

export const LogoutSuccess = createAction('Logout success');

export const Idle = createAction(AuthenticationActionTypes.Idle, props<{ payload: { idle: boolean } }>());

export const IdleSuccess = createAction(AuthenticationActionTypes.IdleSuccess, props<{ payload: { idle: boolean } }>());

export const IdleFail = createAction(AuthenticationActionTypes.IdleFail, props<{ message?: string }>());
