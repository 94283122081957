import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, exhaustMap, of, share, switchMap, withLatestFrom } from 'rxjs';
import { AppState } from '../app.state';
import { select, Store } from '@ngrx/store';
import { MultiSearchActions } from '@states/multi-search/multi-search.action-types';
import * as SharedActions from '@states/shared/shared.actions';
import { Search, SearchType } from '../../shared/search.model';
import { SavedSearchService } from 'src/app/development/saved-search.service';


@Injectable()
export class MultiSearchEffect {


  public updateSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MultiSearchActions.updateSearch),
      exhaustMap(({ name }) => [
        MultiSearchActions.sendSavedSearch({
          name,
          update: true,
        }),
      ]),
    ),
  );

  public cameraChipChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MultiSearchActions.removeSelectedCamera),
      switchMap(() => [
        SharedActions.doNothing(),
      ]),
    ),
  );

  public saveSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MultiSearchActions.saveSearch),
      exhaustMap(({
                    name,
                    motionSearch,
                    unusualEventSearch,
                    customEventSearch,
                  }) => [
        MultiSearchActions.sendSavedSearch({
          name,
          motionSearch,
          unusualEventSearch,
          customEventSearch,
        }),
      ]),
    ),
  );

  public sendSavedSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MultiSearchActions.sendSavedSearch),
      withLatestFrom(this.store$.pipe(select(state => state.multiSearchState))),
      switchMap(
        ([
           {
             name, update, motionSearch,
             unusualEventSearch,
             customEventSearch,
           },
           {
             selectedCameras,
             highConfidence,
             precision,
             faces,
             dwellRange,
             cameraSearchSettingsFilters,
             objectSelections,
             objectSelectionsFormatted,
             outerOperator,
             dateTimeRange,
             selectedSavedSearch,
             customEvent,
             motion,
             unusualEvent,
             searchType,
           },
         ]) => {
          const cameras: Search.SearchCamera[] = selectedCameras.map(camera => {
            return {
              cameraId: camera.edgeOnly.cameraId,
              edgeId: camera.edgeId,
              markedIdx: camera.markedIdx,
              zones: camera.zones,
              zonesExclude: camera.zonesExclude,
            };
          });
          const savedSearch: Search.SavedSearch = {
            cameraSearchSettingsFilters,
            highConfidence,
            precision,
            faces,
            dwellRange,
            objectSelections,
            objectSelectionsFormatted,
            outerOperator,
            selectedCameras: cameras,
            dateTimeRange: {
              ...dateTimeRange,
              absolute: {
                start: dateTimeRange?.absolute?.start ? new Date(dateTimeRange?.absolute?.start).getTime() : undefined,
                end: dateTimeRange?.absolute?.start ? new Date(dateTimeRange?.absolute?.end).getTime() : undefined,
              },
            },
            customEvent,
            motion,
            unusualEvent,
            searchType,
            motionSearch,
            unusualEventSearch,
            customEventSearch,
          };

          if (update) {
            savedSearch._id = selectedSavedSearch._id;
          }

          const request: Search.SaveSearchRequest = {
            name,
            savedSearch,
          };
          return this.savedSearchService.createOrUpdate(request)
            .pipe(
              switchMap(res => {
                return [SharedActions.showMessage({ success: 'Search saved successfully' })];
              }),
              catchError(response => {
                return [SharedActions.setIsSaving({ isSaving: false }), this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );

  public getSavedSearches$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MultiSearchActions.getSavedSearches),
      withLatestFrom(this.store$.pipe(select(state => state.multiSearchState))),
      switchMap(([, { page, perPage, orderBy, orderDirection, query }]) => {
        return this.savedSearchService.getSavedSearches(page, perPage, orderBy, orderDirection, query)
          .pipe(
            switchMap(result => {
              return [
                MultiSearchActions.setSavedSearches({
                  savedSearches: result.items,
                }),
                MultiSearchActions.setTotalItemsCount({
                  totalItemsCount: result.totalItemsCount,
                }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public removeSavedSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MultiSearchActions.removeSavedSearch),
      switchMap(({ id }) => {
        return this.savedSearchService.remove(id)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.setIsDeleting({ isDeleting: false }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.setIsDeleting({ isDeleting: false }), //loader off
                this.catchError(response),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public setSearchQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MultiSearchActions.setQuery),
      debounceTime(400),
      switchMap(() => of(MultiSearchActions.getSavedSearches())),
    ),
  );

  private catchError(response) {
    return SharedActions.showMessage({ error: response?.error?.message });
  }

  constructor(private actions$: Actions, private store$: Store<AppState>, private savedSearchService: SavedSearchService) {
  }
}
