import { createAction, props } from '@ngrx/store';
import { Edge } from '../../../edge/edge.model';

export const resetToInitialState = createAction('[SYNC QUEUE] Reset to Initial state');

export const startGetCommittedOperations = createAction('[SYNC QUEUE] Start get committed operations');
export const getCommittedOperations = createAction('[SYNC QUEUE] Get committed operations');
export const getCommittedOperationsSuccess = createAction('[SYNC QUEUE] Get committed operations Success',
  props<{ committedOperations: Edge.EdgeSyncQueueDocument[] }>()
);
export const getCommittedOperationsFail = createAction('[SYNC QUEUE] Get committed operations Fail');

export const startGetUncommittedOperations = createAction('[SYNC QUEUE] Start get uncommitted operations');
export const getUncommittedOperations = createAction('[SYNC QUEUE] Get uncommitted operations');
export const getUncommittedOperationsSuccess = createAction('[SYNC QUEUE] Get uncommitted operations Success',
  props<{ uncommittedOperations: Edge.EdgeSyncQueueDocument[] }>()
);
export const getUncommittedOperationsFail = createAction('[SYNC QUEUE] Get uncommitted operations Fail');

export const setSyncQueueLoading = createAction('[SYNC QUEUE] Set Sync Queue Loading', props<{ isLoading: boolean }>());

export const retryOperation = createAction(
  '[SYNC QUEUE] Retry operation',
  props<{ edgeLogId: string }>()
);

export const retryOperationSuccess = createAction(
  '[SYNC QUEUE] Retry operation Success'
);

export const retryOperationFail = createAction(
  '[SYNC QUEUE] Retry operation Fail'
);

export const getEdgesSyncStatus = createAction('[SYNC QUEUE] Get Edges Sync Status');
export const getEdgesSyncStatusSuccess = createAction(
  '[SYNC QUEUE] Get Edges Sync Status Success',
  props<{ edgesSyncStatus: Edge.EdgeSyncRes[] }>()
);
export const getEdgesSyncStatusFail = createAction('[SYNC QUEUE] Get Edges Sync Status Fail');

export const cancelOperation = createAction(
  '[SYNC QUEUE] Cancel operation',
  props<{ edgeLogId: string }>()
);

export const cancelOperationSuccess = createAction(
  '[SYNC QUEUE] Cancel operation Success'
);

export const cancelOperationFail = createAction(
  '[SYNC QUEUE] Cancel operation Fail'
);
export const resetPublishCounter = createAction(
  '[SYNC QUEUE] Reset publish counter',
  props<{ edgeLogId: string }>()
);

export const resetPublishCounterSuccess = createAction(
  '[SYNC QUEUE] Reset publish counter Success'
);

export const resetPublishCounterFail = createAction(
  '[SYNC QUEUE] Reset publish counter Fail'
);
