<ng-container *ngrxLet="selectMaxRetentionDays$; let maxRetentionDays">
  <ng-container *ngIf="{
  hideAdvancedOptions:hideAdvancedOptions$|ngrxPush,
  showInitialDateRange:showInitialDateRange$|ngrxPush
  } as conditions"
  >

    <div
      [class.disabled]="disabled"
      (click)="toggleDialog()"
      #selectorInput
      class="date-range-wrapper"
      [class.no-label]="inputStyle === inputStyles.noLabel"
      matRipple
    >
      <label *ngIf="inputStyle !== inputStyles.noLabel" class="label">{{ label }}</label>
      <ng-container *ngIf="!conditions.showInitialDateRange">
        Select Time
      </ng-container>
      <ng-container *ngIf="conditions.showInitialDateRange">
        <div *ngIf="selectedPickerType=== pickerTypes.ABSOLUTE && isAbsolute" class="time-range">
          <label>
            <span class="from-to"
                  *ngIf="displayFromTo"
            >From:</span> {{ startDate|date: (showYear ? 'yyyy ' : '') + dateFormat }} -
            <span class="from-to"
                  *ngIf="displayFromTo"
            >To:</span> {{ endDate|date: dateFormat }} {{ displayTimezone ? timezoneAbbreviation() : '' }}
          </label>
        </div>
        <div *ngIf="selectedPickerType === pickerTypes.RELATIVE && isRelative">
          <label> Last {{ pickerState.custom | relativeTime:pickerState.customUnit }}</label>
        </div>
      </ng-container>

    </div>

    <ng-template #pickerTemplate hasBackdrop="false">
      <ng-container #dialogContainer *ngIf="opened">
        <div class="flex-col" appElementLoaded (onLoaded)="detectChanges()">
          <!--        CHECK IF TABS AVAILABLE-->
          <ng-container *ngIf="isRelative && isAbsolute">
            <div class="flex-row type-selector-wrapper space-between-center">
              <div class="type-selector flex-row start-center">
                <div
                  class="mr-15"
                  [class.selected]="selectedPickerType == pickerTypes.RELATIVE"
                  (click)="changeType(pickerTypes.RELATIVE); $event.stopPropagation()"
                >
                  <label>Relative</label>
                </div>
                <div
                  (click)="changeType(pickerTypes.ABSOLUTE); $event.stopPropagation()"
                  [class.selected]="selectedPickerType == pickerTypes.ABSOLUTE"
                >
                  <label>Calendar</label>
                </div>
              </div>
              <label class="type-selector-timezone">{{ timezoneAbbreviation() }}</label>
            </div>
          </ng-container>

          <ng-container *ngIf="selectedPickerType == pickerTypes.RELATIVE && isRelative">
            <div fxLayout="column" fxLayoutGap="10px" class="relative-wrapper">
              <div fxLayout="column" fxLayoutGap="10px">
                <div *ngIf="!onlyDays" fxLayout="column" fxLayoutAlign="start start" class="relative-pickers-wrapper">
                  <div><label>Minutes</label></div>
                  <div fxLayout="row" fxLayoutAlign="start center" class="relative-pickers" fxLayoutGap="10px">
                    <div
                      *ngFor="let minute of minuteOptions"
                      (click)="selectMinutes(minute)"
                      [class.selected]="minute == pickerState?.minutes"
                      fxLayoutAlign="center center"
                    >
                      <label> {{ minute }}</label>
                    </div>
                  </div>
                </div>
                <div  *ngIf="!onlyDays"fxLayout="column" fxLayoutAlign="start start" class="relative-pickers-wrapper">
                  <div><label>Hours</label></div>
                  <div fxLayout="row" fxLayoutAlign="start center" class="relative-pickers" fxLayoutGap="10px">
                    <div
                      *ngFor="let hour of hourOptions"
                      (click)="selectHours(hour)"
                      [class.selected]="hour == pickerState?.hours"
                      fxLayoutAlign="center center"
                    >
                      <label>{{ hour }}</label>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="start start" class="relative-pickers-wrapper">
                  <div><label>Days</label></div>
                  <div fxLayout="row" fxLayoutAlign="start center" class="relative-pickers" fxLayoutGap="10px">
                    <div
                      *ngFor="let day of dayOptions"
                      (click)="selectDays(day)"
                      [class.selected]="day == pickerState?.days"
                      fxLayoutAlign="center center"
                    >
                      <label> {{ day }}</label>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="start start" class="relative-pickers-wrapper">
                  <div><label>Weeks</label></div>
                  <div fxLayout="row" fxLayoutAlign="start center" class="relative-pickers" fxLayoutGap="10px">
                    <ng-container *ngFor="let week of weekOptions">
                      <div
                        *ngIf="week * 7 <= maxRetentionDays"
                        (click)="selectWeeks(week)"
                        [class.selected]="week == pickerState?.weeks"
                        fxLayoutAlign="center center"
                      >
                        <label> {{ week }}</label>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px" class="narrow-input">
                  <input placeholder="180"
                         class="ui-input"
                         [(ngModel)]="pickerState.custom"
                         (ngModelChange)="setCustom(maxRetentionDays)"
                  />
                  <mat-select class="ui-selector"
                              [(ngModel)]="pickerState.customUnit"
                              (ngModelChange)="setCustom(maxRetentionDays)"
                  >
                    <mat-option *ngFor="let unit of customUnits" [value]="unit">
                      {{ unit | titlecase }}
                    </mat-option>
                  </mat-select>
                </div>
                <div *ngIf="showAdvancedOption">
                  <hr class="hr mt-8 mb-8">
                  <div class="mt-8 mb-8 advanced-toggler c-p" (click)="hideAdvancedOptions()">
                    {{ conditions.hideAdvancedOptions ? 'Show advanced options' : 'Hide advanced options' }}
                  </div>
                  <div *ngIf="!conditions.hideAdvancedOptions">
                    <ng-container *ngrxLet="calendarSelectedDays$;let calendarSelectedDays">
                      <div class="include-title">Include results at</div>
                      <div class="flex-row mt-4">
                        <span class="day-week c-p"
                              *ngFor="let day of calendarDays; let i = index"
                              [class.selected]="calendarSelectedDays[i]"
                              [class.disabled]="pickerState.customUnit === CustomUnit.hours || pickerState.customUnit === CustomUnit.minutes"
                              (click)="selectCalendarDay(i)"
                        >{{ day }}</span>
                      </div>
                      <div class="flex-row space-between-center mt-16">
                        <div class="flex-col start-start w-50 mr-15">
                          <label class="mb-8">Between hours</label>
                          <input class="ui-input w-100"
                                 type="time"
                                 [disabled]="pickerState.customUnit === CustomUnit.hours || pickerState.customUnit === CustomUnit.minutes"
                                 [(ngModel)]="startTimeBetween"
                                 (ngModelChange)="emitDateRangeChanged()"

                          />
                        </div>
                        <div class="flex-col start-start w-50">
                          <label style="height: 20px" class="mb-8"></label>
                          <input class="ui-input w-100"
                                 type="time"
                                 [disabled]="pickerState.customUnit === CustomUnit.hours || pickerState.customUnit === CustomUnit.minutes"
                                 [(ngModel)]="endTimeBetween"
                                 (ngModelChange)="emitDateRangeChanged()"
                          />
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedPickerType == pickerTypes.ABSOLUTE && isAbsolute">
            <div class="p-20" appElementLoaded (onLoaded)="detectChanges()">
              <ui-calendar-time-header
                [activeStartDate]="startDate"
                [activeEndDate]="endDate"
                [calendarLeft]="leftCalendar"
                [calendarRight]="rightCalendar"
                [minDate]="minDate"
                [maxDate]="maxDate"
              >
              </ui-calendar-time-header>
              <div fxLayout="row">
                <div fxLayout="column" class="calendar-block">
                  <mat-calendar
                    [headerComponent]="headerComponent"
                    #leftCalendar
                    [startAt]="startDateLeftCalendar"
                    [maxDate]="maxDate" [minDate]="minDate"
                    [dateClass]="dateClass"
                    (selectedChange)="dateChanged($event)"
                  >
                  </mat-calendar>
                  <div *ngIf="!onlyDays" fxLayout="row" fxLayoutAlign="center center">
                    <input class="time-input" type="time" [(ngModel)]="startTime"/>
                  </div>
                </div>
                <div fxLayout="column" class="calendar-block">
                  <mat-calendar
                    [headerComponent]="headerComponent"
                    #rightCalendar
                    [maxDate]="maxDate" [minDate]="minDate"
                    [dateClass]="dateClass"
                    [startAt]="startDateRightCalendar"
                    (selectedChange)="dateChanged($event)"
                  >
                  </mat-calendar>
                  <div *ngIf="!onlyDays" fxLayout="row" fxLayoutAlign="center center">
                    <input class="time-input" type="time" [(ngModel)]="endTime"/>
                  </div>
                </div>
              </div>
              <div *ngIf="showAdvancedOption">
                <hr class="hr mt-8 mb-8">
                <div class="mt-8 mb-8 advanced-toggler c-p" (click)="hideAdvancedOptions()">
                  {{ conditions.hideAdvancedOptions ? 'Show advanced options' : 'Hide advanced options' }}
                </div>
                <div *ngIf="!conditions.hideAdvancedOptions">
                  <ng-container *ngrxLet="calendarSelectedDays$;let calendarSelectedDays">
                    <div class="include-title">Include results at</div>
                    <div class="flex-row mt-4">
                    <span class="day-week c-p"
                          *ngFor="let day of calendarDays; let i = index"
                          [class.selected]="calendarSelectedDays[i]"
                          [class.disabled]="betweenHoursDisabled"
                          (click)="selectCalendarDay(i)"
                    >{{ day }}</span>
                    </div>
                    <div class="flex-row space-between-center mt-16">
                      <div class="flex-col start-start w-50 mr-15">
                        <label class="mb-8">Between hours</label>
                        <input class="ui-input w-100"
                               type="time"
                               [disabled]="betweenHoursDisabled"
                               [(ngModel)]="startTimeBetween"
                               (ngModelChange)="emitDateRangeChanged()"

                        />
                      </div>
                      <div class="flex-col start-start w-50">
                        <label style="height: 20px" class="mb-8"></label>
                        <input class="ui-input w-100"
                               type="time"
                               [disabled]="betweenHoursDisabled"
                               [(ngModel)]="endTimeBetween"
                               (ngModelChange)="emitDateRangeChanged()"
                        />
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="flex-row space-between-center picker-actions gap-8">
            <div>
              <ui-button *ngIf="showClearButton" type="noBorderDefault" text="Clear" (clickAction)="clearCalendar()">

              </ui-button>
            </div>
            <div class="flex-row end-center">
              <ui-button type="noBorderPrimary" (clickAction)="close()">Cancel</ui-button>
              <ui-button
                type="primary"
                (clickAction)="emitDate()"
                [disabled]="!isValid"
              >
                Done
              </ui-button>
            </div>

          </div>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
</ng-container>
