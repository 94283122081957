import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThumbnailsService } from 'src/app/development/thumbnails.service';
import { LocationModel } from '../../../../locations/location.model';

@Component({
  selector: 'app-shared-liveview',
  templateUrl: './shared-liveview.component.html',
  styleUrl: './shared-liveview.component.scss',
})
export class SharedLiveviewComponent implements OnInit {

  @Input() public cameraId;
  @Input() public edgeId;
  @Input() public locationId;
  @Input() public accessToken: string;
  @Input() public isRespectRatio: boolean = true;
  @Input() public cameraName: string;
  @Input() public location: LocationModel.LocationItem;

  constructor(private thumbnailsService: ThumbnailsService) {
  }

  public get now() {
    return Date.now();
  }

  async ngOnInit(): Promise<void> {
    await this.thumbnailsService.getGcpTransitionForEdge(this.edgeId);
  }

}
