import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Edge } from './edge.model';
import { BehaviorSubject, catchError, filter, from, map, Observable, of, Subscription, takeWhile, tap, throwError, timeout, TimeoutError } from 'rxjs';
import { CameraListResponse, CameraMap, CameraStartRequest, CameraStopRequest, CameraUpdateHlsRequest, CameraUpdateRequest, DiscoveryMessageType, SearchDevicesByIpRangeData, SearchDevicesScanCamerasRequest } from '../cameras/camera.model';
import { HttpService } from '../core/http.service';
import { LocationModel } from '../locations/location.model';
import { KeyValuePairs, SQSMsgInfo } from '../core/interfaces';
import { TokenDataMessageBase, TokenDataStatus } from '../core/messaging.interfaces';
import { CreateEdgeToken } from '../core/sessions/create-edge-session';
import { SearchDevicesGetCameraDetailsToken } from '../core/sessions/search-device-get-camera-details-session';
import { SearchDevicesManualGetCameraDetailsToken } from '../core/sessions/searce-devices-manual-get-camera-details-session';
import { DeleteCameraToken } from '../core/sessions/delete-camera-session';
import { CreateCameraToken } from '../core/sessions/create-camera-session';
import { SearchDevicesCameraDiscoveryToken } from '../core/sessions/search-devices-camera-discovery-session';
import { deleteDoc, doc, docSnapshots, DocumentData, DocumentReference, DocumentSnapshot, Firestore } from '@angular/fire/firestore';
import { SessionDataAction } from '@enums/session-data.enum';
import { api } from '@consts/url.const';
import { SocketMainService } from '../socket/socket-main.service';
import { Dictionary } from '@ngrx/entity/src/models';
import { LocalNetworkWorkerService } from '../development/local-network.worker.service';
import { SHARE_ACCESS_TOKEN_KEY } from '../authentication/authentication.service';
import { SessionStorageService } from '../core/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class EdgeService {
  subscriptions: KeyValuePairs<Subscription> = {};

  private currentEdgeSubject = new BehaviorSubject<Edge.EdgeDocument>({});
  private discoverSubject = new BehaviorSubject<CameraListResponse>({
    cameras: {},
  });

  public discover$: Observable<CameraListResponse> = this.discoverSubject
    .asObservable()
    .pipe(filter(discover => Object.keys(discover?.cameras).length > 1));

  public currentEdge$: Observable<Edge.EdgeDocument> = this.currentEdgeSubject.asObservable();

  constructor(
    private router: Router,
    private http: HttpClient,
    private httpService: HttpService,
    private firestore: Firestore,
    private localNetworkWorkerService: LocalNetworkWorkerService,
    private socketMainService: SocketMainService,
    private sessionStorageService: SessionStorageService) {
  }

  createEdge(edgeCreateRequest: Edge.EdgeCreateRequest) {
    const url = `${environment.apiUrl}/catalog`;
    return this.http.post(url, edgeCreateRequest);
  }

  cameraCreate(data: Edge.CameraCreateRequest) {
    const url = `${environment.apiUrl}/cameras`;
    return this.http.post(url, data);
  }

  cameraUpdateNoSession(data: CameraUpdateRequest): Observable<SQSMsgInfo> {
    const url = `${environment.apiUrl}/locations/update-camera-no-session/${data.locationId}/${data.edgeId}/${data.cameraId}`;
    return this.http.post<SQSMsgInfo>(url, data);
  }

  cameraUpdate(data: CameraUpdateRequest): Observable<SQSMsgInfo> {
    const url = `${environment.apiUrl}/locations/update-camera/${data.locationId}/${data.edgeId}/${data.cameraId}`;
    return this.http.post<SQSMsgInfo>(url, data);
  }

  cameraStorageUpdate(data: CameraUpdateRequest): Observable<SQSMsgInfo> {
    const url = `${environment.apiUrl}/locations/update-camera/storage/${data.locationId}/${data.edgeId}/${data.cameraId}`;
    return this.http.post<SQSMsgInfo>(url, data);
  }

  cameraUpdatePTZ(data: CameraUpdateRequest): Observable<SQSMsgInfo> {
    const url = `${environment.apiUrl}/locations/update-camera/ptz/${data.locationId}/${data.edgeId}/${data.cameraId}`;
    return this.http.post<SQSMsgInfo>(url, data);
  }

  cameraDetailsUpdate(data: CameraUpdateRequest): Observable<SQSMsgInfo> {
    const url = `${environment.apiUrl}/locations/update-camera/details/${data.locationId}/${data.edgeId}/${data.cameraId}`;
    return this.http.post<SQSMsgInfo>(url, data);
  }
  cameraDetailsUpdateNew(data: CameraUpdateRequest): Observable<boolean> {
    const url = `${environment.apiUrl}/cameras-operations/update`;
    return this.http.put<boolean>(url, data);
  }

  cameraAudioVideoUpdate(data: CameraUpdateRequest): Observable<SQSMsgInfo> {
    const url = `${environment.apiUrl}/locations/update-camera/audio-video/${data.locationId}/${data.edgeId}/${data.cameraId}`;
    return this.http.post<SQSMsgInfo>(url, data);
  }

  cameraHlsUpdate(data: CameraUpdateHlsRequest): Observable<void> {
    const url = `${environment.apiUrl}/locations/update-camera/hls-config/${data.locationId}/${data.edgeId}/${data.cameraId}`;
    return this.http.post<void>(url, data);
  }

  getEdgeIpAddressInfo(data: Edge.GetEdgeIpAddressRequest): Observable<SQSMsgInfo> {
    const url = `${environment.apiUrl}/locations/get-edge-ip-address`;
    return this.http.post<SQSMsgInfo>(url, data);
  }

  getEdgeLocalNetwork(address: string): Observable<Edge.EdgeDocument> {
    const url = `${address}`;
    return this.http.get<Edge.EdgeDocument>(url)
      .pipe(
        timeout(4000),
        catchError(err => {
          if (err instanceof TimeoutError) {
            return throwError(() => new Error(`ping timout occured for edgeId: ${address} `));
          }
          return throwError(() => err);
        }),
      );
  }

  getEdgeLocalNetworkViaWorker(edgeId: string, address: string): Observable<Edge.EdgeDocument> {
    return this.localNetworkWorkerService.fetchLocalNetworkInWorker(edgeId, address)
      .pipe(
        timeout(4000),
        catchError((err) => {
          if (err instanceof TimeoutError) {
            return throwError(() => new Error(`ping timeout occurred for address: ${address}`));
          }
          return throwError(() => err);
        }),
      );
  }


  getCameraDetails(data: LocationModel.GetCameraDetailsRequest, manual = false): Observable<SQSMsgInfo> {
    const url = manual
      ? `${environment.apiUrl}/locations/get-camera-details-manually`
      : `${environment.apiUrl}/locations/get-camera-details`;
    return this.http.post<SQSMsgInfo>(url, data);
  }

  getMultipleCameraDetails(data: LocationModel.GetMultipleCameraDetailsRequest): Observable<SQSMsgInfo[]> {
    const url = `${environment.apiUrl}/locations/get-multiple-camera-details-manually`;
    return this.http.post<SQSMsgInfo[]>(url, data);
  }

  probeCamera(data: LocationModel.ProbeCameraRequest): Observable<SQSMsgInfo> {
    const url = `${environment.apiUrl}/locations/probe-camera`;
    return this.http.post<SQSMsgInfo>(url, data);
  }

  getCameraDetailsManually(data: LocationModel.GetCameraDetailsRequest): Observable<SQSMsgInfo> {
    const url = `${environment.apiUrl}/locations/get-camera-details-manually`;
    return this.http.post<SQSMsgInfo>(url, data);
  }

  addCameraToLocation(data: LocationModel.AddCameraToLocationRequest): Observable<SQSMsgInfo> {
    const url = `${environment.apiUrl}/locations/add-camera`;
    return this.http.post<SQSMsgInfo>(url, data);
  }

  probeSubstream(data: LocationModel.ProbeSubstreamRequest): Observable<SQSMsgInfo> {
    const url = `${environment.apiUrl}/locations/probe-substream`;
    return this.http.post<SQSMsgInfo>(url, data);
  }

  addCamerasToLocation(data: LocationModel.AddCamerasToLocationRequest): Observable<SQSMsgInfo[]> {
    const url = `${environment.apiUrl}/locations/add-cameras`;
    return this.http.post<SQSMsgInfo[]>(url, data);
  }

  addCameraManuallyToLocation(data: LocationModel.AddCameraManuallyToLocationRequest): Observable<SQSMsgInfo> {
    const url = `${environment.apiUrl}/locations/add-camera-manually`;
    return this.http.post<SQSMsgInfo>(url, data);
  }

  // TODO: delete
  deleteCameraFromLocation(data: LocationModel.DeleteCameraFromLocationRequest): Observable<SQSMsgInfo> {
    const url = `${environment.apiUrl}/locations/camera/${data.locationId}/${data.edgeId}/${data.cameraId}`;
    return this.http.delete<SQSMsgInfo>(url);
  }

  deleteCamera(data: LocationModel.DeleteCameraFromLocationRequest): Observable<SQSMsgInfo> {
    try {
      const url = `${environment.apiUrl}/locations/camera/${data.locationId}/${data.edgeId}/${data.cameraId}`;
      return this.http.delete<SQSMsgInfo>(url);
    } catch (error) {
      return throwError(() => error);
    }
  }

  cameraStart(data: CameraStartRequest) {
    const url = `${environment.apiUrl}/cameras/start`;
    return this.http.post(url, data);
  }

  cameraStop(data: CameraStopRequest) {
    const url = `${environment.apiUrl}/cameras/stop`;
    return this.http.post(url, data);
  }

  confirmEdge(edgeId) {
    const url = `${environment.apiUrl}/catalog/confirm`;
    return this.http.post(url, { edgeId });
  }

  selectEdge(edge) {
    this.currentEdgeSubject.next(edge);
  }

  discoverCameras(edgeId): Observable<SQSMsgInfo> {
    try {
      const url = `${environment.apiUrl}/catalog/${edgeId}/discovery`;
      return this.http.get<SQSMsgInfo>(url);
    } catch (error) {
      return throwError(() => error);
    }
  }

  scanCameras(edgeId: string, scanData: SearchDevicesByIpRangeData): Observable<SQSMsgInfo> {
    const request: SearchDevicesScanCamerasRequest = {
      edgeId,
      msgType: DiscoveryMessageType.SearchDevicesByIpRange,
      data: scanData,
    };
    try {
      const url = `${environment.apiUrl}/catalog/device-discovery`;
      return this.http.post<SQSMsgInfo>(url, request);
    } catch (error) {
      return throwError(() => error);
    }
  }

  setDiscoveredList(cameras: CameraMap) {
    this.discoverSubject.next({ cameras });
  }

  getSessionData<T extends TokenDataMessageBase>(token: string, snsResultOnError = false): Observable<T> {
    try {
      const url = `${environment.apiUrl}/sessions/${token}`;
      return this.http.get<T>(url)
        .pipe(
          tap((res: T) => {
            if (res.status === TokenDataStatus.ERROR) {
              if (snsResultOnError) {
                throw !!(res as any)?.snsMessage?.data
                  ? (res as any)?.snsMessage?.data
                  : !!(res as any)?.snsMessage?.status?.data
                    ? (res as any)?.snsMessage?.status?.data
                    : 'Could not parse SNS response error';
              } else {
                throw new Error(
                  JSON.stringify({
                    status: res.responseCode || 555,
                    msg: res.msg || res.responseCodeR || 'Please try again',
                  }),
                );
              }
            } else {
              return res;
            }
          }),
        );
    } catch (error) {
      return throwError(() => error);
    }
  }

  deleteDocument(token: string): Observable<void> {
    try {
      if (!!token) {
        const document: DocumentReference = doc(this.firestore, `/tokens/${token}`);
        return from(deleteDoc(document));
      } else {
        return of(null);
      }
    } catch (error) {
      return throwError(() => error);
    }
  }

  subscribeToSessionStatus(
    token: string,
    throwOnError = true,
    msTimeout = 20000,
    snsMsgError = false,
    sessionDataAction: SessionDataAction = null,
  ): Observable<{ status: TokenDataStatus } | undefined> {

    if (this.socketMainService.isValid() && sessionDataAction === SessionDataAction.getEdgeConfig) {
      return this.subscribeToSessionStatusSocket(token, throwOnError, msTimeout, snsMsgError);
    }

    return this.subscribeToSessionStatusFirebase(token, throwOnError, snsMsgError)
      .pipe(
        timeout(msTimeout),
        catchError(err => {
          return throwError(() => err);
        }),
      );
  }

  subscribeToSessionStatusFirebase(
    token: string,
    throwOnError = true,
    snsMsgError = false,
  ): Observable<{ status: TokenDataStatus } | undefined> {
    try {
      const document: DocumentReference = doc(this.firestore, `/tokens/${token}`);

      console.log(`subscribed to: /tokens/${token}`);

      const snapshot$ = docSnapshots(document)
        .pipe(
          map((doc: DocumentSnapshot<DocumentData>) => {
            const data = doc.data();
            if (!data) {
              return null;
            }
            return data as { status: TokenDataStatus };
          }),
          tap(data => {
            if (!snsMsgError) {
              if (throwOnError && data?.status === TokenDataStatus.ERROR) {
                throw new Error('operation result is set to error');
              }
            }
          }),
          takeWhile(data => data?.status === TokenDataStatus.PENDING, true),
        );
      return snapshot$;
    } catch (error) {
      return throwError(() => error);
    }
  }

  subscribeToSessionStatusSocket(
    token: string,
    throwOnError = true,
    msTimeout = 20000,
    snsMsgError = false,
  ): Observable<{ status: TokenDataStatus, result?: any } | undefined> {
    try {

      console.log(`subscribed to: /tokens/${token}`);

      const snapshot$ = this.socketMainService.consume<any>(token)
        .pipe(
          map((doc: any) => {

            const data = doc;

            if (!data) {
              return null;
            }
            return data as {
              status: TokenDataStatus,
              result?: any
            };
          }),
          timeout(msTimeout),
          tap(data => {
            if (!snsMsgError) {
              if (throwOnError && data?.status === TokenDataStatus.ERROR) {
                throw new Error('operation result is set to error');
              }
            }
          }),
          takeWhile(data => !data?.status, true),
        );

      return snapshot$;
    } catch (error) {
      return throwError(() => error);
    }
  }


  pollDiscovered(token: string) {
    const url = `${environment.apiUrl}/sessions/${token}`;
    return this.httpService.poll<SearchDevicesCameraDiscoveryToken.AllSessionData>(
      url,
      3000,
      (data: SearchDevicesCameraDiscoveryToken.AllSessionData) => {
        return data.status === TokenDataStatus.COMPLETED;
      },
      120000,
    );
  }

  pollConfirmed(token: string): Observable<CreateEdgeToken.AllSessionData> {
    const url = `${environment.apiUrl}/sessions/${token}`;
    return this.httpService.poll<CreateEdgeToken.AllSessionData>(
      url,
      3000,
      (data: CreateEdgeToken.AllSessionData) => {
        return data.status === TokenDataStatus.COMPLETED;
      },
      40000,
    );
  }

  pollGetCameraDetails(token: string) {
    const url = `${environment.apiUrl}/sessions/${token}`;
    return this.httpService.poll<SearchDevicesGetCameraDetailsToken.AllSessionData>(
      url,
      5000,
      (data: SearchDevicesGetCameraDetailsToken.AllSessionData) => {
        return data.status === TokenDataStatus.COMPLETED;
      },
      90000,
    );
  }

  pollGetCameraManuallyDetails(token: string) {
    const url = `${environment.apiUrl}/sessions/${token}`;
    return this.httpService.poll<SearchDevicesManualGetCameraDetailsToken.AllSessionData>(
      url,
      5000,
      (data: SearchDevicesManualGetCameraDetailsToken.AllSessionData) => {
        return data.status === TokenDataStatus.COMPLETED;
      },
      90000,
    );
  }

  pollCameraDeleted(token: string) {
    const url = `${environment.apiUrl}/sessions/${token}`;
    return this.httpService.poll<DeleteCameraToken.AllSessionData>(
      url,
      5000,
      (data: DeleteCameraToken.AllSessionData) => {
        return data.status === TokenDataStatus.COMPLETED;
      },
      30000,
    );
  }

  pollCreateCameraManually(token: string) {
    const url = `${environment.apiUrl}/sessions/${token}`;
    return this.httpService.poll<CreateCameraToken.AllSessionData>(
      url,
      5000,
      (data: CreateCameraToken.AllSessionData) => {
        return data.status === TokenDataStatus.COMPLETED;
      },
      90000,
    );
  }

  pollCreateCamera(token: string) {
    const url = `${environment.apiUrl}/sessions/${token}`;
    return this.httpService.poll<CreateCameraToken.AllSessionData>(
      url,
      5000,
      (data: CreateCameraToken.AllSessionData) => {
        return data.status === TokenDataStatus.COMPLETED;
      },
      90000,
    );
  }

  restoreEdge(locationId: string, edgeId: string) {
    const url = api.edge.restore(locationId, edgeId);
    return this.http.get(url);
  }

  public getCertifications(edgeId: string): Observable<Edge.EdgeCertificationManageDocument[]> {
    const url = api.edge.certifications(edgeId);
    return this.http.get<Edge.EdgeCertificationManageDocument[]>(url);
  }

  public updateEdgeWithCertificate(locationId: string, edgeId: string, certId: string): Observable<Edge.EdgeCertificationManageDocument[]> {
    const url = api.edge.updateEdgeWithExistsCert;
    return this.http.post<Edge.EdgeCertificationManageDocument[]>(url, { locationId, edgeId, id: certId });
  }

  public validateExistsCert(locationId: string, edgeId: string, certId: string): Observable<Edge.EdgeCertificationManageDocument[]> {
    const url = api.edge.validateExistsCert;
    return this.http.post<Edge.EdgeCertificationManageDocument[]>(url, { locationId, edgeId, id: certId });
  }

  public getEdgeLocalNetworkUrlBulk(edgeIds: string[]): Observable<Dictionary<{ url: string }>> {
    if (!edgeIds.length) {
      return of(null);
    }
    const sharedToken = this.sessionStorageService.getItem(SHARE_ACCESS_TOKEN_KEY);
    if (sharedToken) {
      const url = `${api.shareApi.getEdgeLocalAddresses}?ids=${edgeIds.join(',')}`;
      return this.http.get<Dictionary<{ url: string }>>(url, {
          params: {
            sharedToken: true,
          },
        })
        .pipe(
          catchError(err => {
            if (err instanceof TimeoutError) {
              return throwError(() => new Error(`ping timout occured for edgeId: ${edgeIds.join(',')} `));
            }
            return throwError(() => err);
          }),
        );
    } else {
      const url = `${api.edge.getEdgeLocalAddresses}?ids=${edgeIds.join(',')}`;
      return this.http.get<Dictionary<{ url: string }>>(url)
        .pipe(
          catchError(err => {
            if (err instanceof TimeoutError) {
              return throwError(() => new Error(`ping timout occured for edgeId: ${edgeIds.join(',')} `));
            }
            return throwError(() => err);
          }),
        );
    }

  }

  public toggleDhcp(edgeId: string, dhcp: Edge.Dhcp, enabled: boolean): Observable<{
    reservedAddresses: Edge.DhcpDevice[],
    attachedAddresses: Edge.DhcpDevice[],
    dhcpForm: Edge.Dhcp
    enabled: boolean,
  }> {
    return this.http.post<{
      reservedAddresses: Edge.DhcpDevice[],
      attachedAddresses: Edge.DhcpDevice[],
      dhcpForm: Edge.Dhcp
      enabled: boolean,
    }>(api.edgeManagement.toggleDhcp(edgeId), { ...dhcp, enabled });
  }

  public dhcpGetConfig(edgeId: string): Observable<{
    reservedAddresses: Edge.DhcpDevice[],
    attachedAddresses: Edge.DhcpDevice[],
    enabled: boolean,
    dhcpForm: Edge.Dhcp
  }> {
    return this.http.get<{
      reservedAddresses: Edge.DhcpDevice[],
      attachedAddresses: Edge.DhcpDevice[],
      enabled: boolean,
      dhcpForm: Edge.Dhcp
    }>(api.edgeManagement.dhcpGetConfig(edgeId));
  }


  public addNewReservedAddress(edgeId: string, dhcp: Edge.DhcpDevice[]): Observable<{
    reservedAddresses: Edge.DhcpDevice[],
    attachedAddresses: Edge.DhcpDevice[],
    enabled: boolean,
    dhcpForm: Edge.Dhcp
  }> {
    return this.http.post<{
      reservedAddresses: Edge.DhcpDevice[],
      attachedAddresses: Edge.DhcpDevice[],
      enabled: boolean,
      dhcpForm: Edge.Dhcp
    }>(api.edgeManagement.addNewReservedAddress(edgeId), { devices: dhcp });
  }
}
