import { createAction, props } from '@ngrx/store';
import { Edge } from '../../../edge/edge.model';
import { LocationModel } from '../../../locations/location.model';
import { EdgeConfigFileType, EthConfiguration } from '@models/edge.model';
import { EdgeDocumentType } from '@enums/edge.enum';
import { HeartbeatEntry } from '../../../development/heartbeat.service';
import { EdgeCamera, GetCameraDetailsCamera } from '../../../cameras/camera.model';
import { ThumbnailEntity } from '../../../cameras/camera-thumbnails/camera-thumbnails.model';
import { NtpModels } from '@models/ntp.model';
import { HashedIds } from '@models/shared.model';

export const resetToInitialState = createAction('[EDIT-EDGE] Reset to Initial state');

export const setSelectedEdgeId = createAction('[EDIT-EDGE] Set Selected edge Id', props<{ selectedEdgeId: string }>());

export const setSelectedLocationId = createAction('[EDIT-EDGE] Set Selected Location Id', props<{ selectedLocationId: string }>());

export const setSelectedEdge = createAction('[EDIT-EDGE] Set Selected core', props<{ selectedEdge: Edge.EdgeDocument }>());

export const getEdgeSwUpdate = createAction('[EDIT-EDGE] Get edge SW Update');

export const getEdgeSwUpdateSuccess = createAction('[EDIT-EDGE] Get edge SW Update Success', props<{ result: Object }>());

export const getEdgeSwUpdateFailed = createAction('[EDIT-EDGE] Get edge SW Update Failed', props<{ errorMessage: string }>());

export const uploadEdgeConfigJson = createAction('[EDIT-EDGE] Upload edge Config JSON', props<{ config: string, configType: EdgeConfigFileType }>());

export const pressSave = createAction('[EDIT-EDGE] Press Save');

export const pressRefresh = createAction('[EDIT-EDGE] Press Refresh');

export const saveEdgeEditForm = createAction('[EDIT-EDGE] Save edge Edit Form', props<{ edgeUpdate: Partial<Edge.EdgeDocument> }>());

export const sendEdgeEditForm = createAction(
  '[EDIT-EDGE] Send edge Edit Form to Server',
  props<{ edgeUpdate: Partial<Edge.EdgeDocument> }>(),
);

export const updateEdgeInLocation = createAction(
  '[EDIT-EDGE] Update edge In Location',
  props<{
    updateEdgeInLocationRequest: LocationModel.UpdateEdgeInLocationRequest;
  }>(),
);

export const getDeviceMsgsVersion = createAction('[EDIT-EDGE] Get Device Msgs Version');

export const getEdgeLogs = createAction('[EDIT-EDGE] Get edge Logs', props<{ daysBack: number }>());

export const requestEdgeLogs = createAction('[EDIT-EDGE] Request edge Logs from Server', props<{ daysBack: number }>());
export const requestEdgeLogsSuccess = createAction('[EDIT-EDGE] Request edge Logs from Server Success');
export const requestEdgeLogsFail = createAction('[EDIT-EDGE] Request edge Logs from Server Fail');

export const getEdgeAWSLogs = createAction('[EDIT-EDGE] Request edge AWS Logs from Server');

export const setEdgeAWSLogs = createAction('[EDIT-EDGE] Set edge AWS Logs from Server', props<{ logs: string[] }>());

export const getHeartbeats = createAction('[EDIT-EDGE] Get Heartbeats');

export const getHeartbeatsSuccess = createAction('[EDIT-EDGE] Get Heartbeats Success', props<{ heartbeats: HeartbeatEntry[] }>());

export const getCameraThumbnails = createAction(
  '[EDIT-EDGE] Get Camera Thumbnails',
  props<{ start: number; end: number; selectedCamera: EdgeCamera.CameraItem }>(),
);

export const getCameraThumbnailsSuccess = createAction(
  '[EDIT-EDGE] Get Camera Thumbnails Success',
  props<{ thumbnailsBitmap: ThumbnailEntity[] }>(),
);


export const setEdgeSystemStatusPagination = createAction('[EDIT-EDGE] Set edge System Status Pagination TimeStamps');

export const setHeartbeatsDateRAnge = createAction('[EDIT-EDGE] Set Heartbeats Date Range', props<{ from: number; to: number }>());

export const getEdgeById = createAction('[EDIT-EDGE] Get edge By Id');

export const uploadVideoStorage = createAction(
  '[EDIT-EDGE] Upload Video Storage',
  props<{ dirPattern: string; filePattern: string; cameraId: string }>(),
);

export const rmEdgeLink = createAction('[EDIT-EDGE] Remove edge Link', props<{ link: string }>());

export const updateAnalyticConfigJson = createAction(
  '[EDIT-EDGE] Upload Analytic Config JSON',
  props<{ config: string; cameraId: string }>(),
);

export const sendUpdateAnalyticConfigJson = createAction(
  '[EDIT-EDGE] Send  Update Analytic Config JSON',
  props<{ config: string; cameraId: string }>(),
);

export const cancelManageRequest = createAction('[EDIT-EDGE] Cancel Manage Request');

export const restoreEdge = createAction('[EDIT-EDGE] Restore core', props<{ locationId: string; edgeId: string }>());
export const restoreEdgeSuccess = createAction('[EDIT-EDGE] Restore edge Success', props<{ result: any }>());
export const restoreEdgeFail = createAction('[EDIT-EDGE] Restore edge Fail');

export const setHasChanged = createAction('[EDIT-EDGE] Set Has Changed', props<{ hasChanged: boolean }>());

export const getCoreRecoveryDocument = createAction('[EDIT-EDGE] Get Core Recovery Document');
export const getCoreRecoveryDocumentSuccess = createAction('[EDIT-EDGE] Get Core Recovery Document Success', props<{ edgeRecoveryDocument: any }>());
export const getCoreRecoveryDocumentFail = createAction('[EDIT-EDGE] Get Core Recovery Document Fail');
export const saveCoreRecoveryDocument = createAction('[EDIT-EDGE] Save Core Recovery Document', props<{ body: Object }>());
export const serverCallSaveCoreRecoveryDocument = createAction('[EDIT-EDGE] Server Call Save Core Recovery Document', props<{ body: Object }>());
export const serverCallSaveCoreRecoveryDocumentSuccess = createAction('[EDIT-EDGE] Server Call Save Core Recovery Document Success');
export const serverCallSaveCoreRecoveryDocumentFail = createAction('[EDIT-EDGE] Server Call Save Core Recovery Document Fail');

export const getEdgeHeartBeatStatus = createAction('[EDIT-EDGE] Get Edge HeartBeat Status');
export const getEdgeHeartBeatStatusSuccess = createAction('[EDIT-EDGE] Get Edge HeartBeat Status Success', props<{ body: any[] }>());
export const getEdgeHeartBeatStatusFail = createAction('[EDIT-EDGE] Get Edge HeartBeat Status Fail', props<{ errorMessage: string }>());


export const restartEdge = createAction('[EDIT-EDGE] Restart edge', props<{ edgeId: string; locationId: string }>());
export const restartEdgeSuccess = createAction('[EDIT-EDGE] Restart edge Success');
export const restartEdgeFail = createAction('[EDIT-EDGE] Restart edge Fail');

export const setIsSaving = createAction('[EDIT-EDGE] Set is Saving', props<{ isSaving: boolean }>());

export const getEdgeInfo = createAction('[EDIT-EDGE] Get edge Info');
export const getEdgeInfoSuccess = createAction('[EDIT-EDGE] Get edge Info Success', props<{ data: any }>());
export const getEdgeInfoFail = createAction('[EDIT-EDGE] Get edge Info Fail', props<{ errorMessage: string }>());

export const getEdgeDocument = createAction('[EDIT-EDGE] Get edge Document', props<{ docType: EdgeDocumentType }>());
export const getEdgeDocumentSuccess = createAction('[EDIT-EDGE] Get edge Document Success', props<{ data: any, hash: HashedIds }>());
export const getEdgeDocumentFail = createAction('[EDIT-EDGE] Get edge Document Fail', props<{ errorMessage: string }>());

export const cancelNetworkRequests = createAction('[EDIT-EDGE] Cancel Network Request');

export const getEdgeConfig = createAction('[EDIT-EDGE] Get Config', props<{ configType: EdgeConfigFileType }>());
export const getEdgeConfigSuccess = createAction('[EDIT-EDGE]  Get Config Success', props<{ data: any }>());
export const getEdgeConfigFail = createAction('[EDIT-EDGE]  Get Config Fail', props<{ errorMessage: string }>());

export const getAnalyticConfig = createAction('[EDIT-EDGE] Get Analytic Config', props<{ cameraId: string }>());
export const getAnalyticConfigSuccess = createAction('[EDIT-EDGE] Get Analytic Config Success', props<{ data: any }>());
export const getAnalyticConfigFail = createAction('[EDIT-EDGE] Get Analytic Config Fail', props<{ errorMessage: string }>());

export const sendEdgeConfigJson = createAction('[EDIT-EDGE] Send edge Config JSON', props<{ config: string, configType: EdgeConfigFileType }>());
export const sendEdgeConfigJsonSuccess = createAction('[EDIT-EDGE] Send edge Config JSON Success');
export const sendEdgeConfigJsonFail = createAction('[EDIT-EDGE] Send edge Config JSON Fail');

export const startRebootEdge = createAction('[EDIT-EDGE] Start Reboot Edge');
export const rebootEdge = createAction('[EDIT-EDGE] Reboot Edge');
export const rebootEdgeSuccess = createAction('[EDIT-EDGE]Reboot Edge Success');
export const rebootEdgeFail = createAction('[EDIT-EDGE] Reboot Edge Fail');

export const ping = createAction('[EDIT-EDGE] ping', props<{ pingAddress: string, tcp: boolean }>());
export const pingSuccess = createAction('[EDIT-EDGE] Ping Success', props<{ data: string }>());
export const pingFail = createAction('[EDIT-EDGE] Ping Fail', props<{ errorMessage: string }>());
export const setPingLoader = createAction('[EDIT-EDGE] Set Ping Loader', props<{ pingLoader: boolean }>());

export const getEdgeNtp = createAction('[EDIT-EDGE] Get edge NTP');
export const getEdgeNtpSuccess = createAction('[EDIT-EDGE] Get edge NTP Success', props<{ data: any }>());
export const getEdgeNtpFail = createAction('[EDIT-EDGE] Get edge NTP Fail', props<{ errorMessage: any }>());

export const updateSoftwareVersion = createAction('[EDIT-EDGE] Update Software Version', props<{ version: string }>());
export const updateSoftwareVersionSuccess = createAction('[EDIT-EDGE] Update Software Version Success');
export const updateSoftwareVersionFail = createAction('[EDIT-EDGE] Update Software Version Fail', props<{ errorMessage: any }>());


export const writeEthManualConfiguration = createAction(
  '[EDIT-EDGE] Write Eth Manual Configuration',
  props<{ ethConfig: EthConfiguration }>(),
);


export const writeEthManualConfigurationServerRequest = createAction(
  '[EDIT-EDGE] Write Eth Manual Configuration Server Request',
  props<{ ethConfig: EthConfiguration }>(),
);

export const writeEthManualConfigurationSuccess = createAction(
  '[EDIT-EDGE] Write Eth Manual Configuration Success',
);


export const writeEthManualConfigurationFail = createAction(
  '[EDIT-EDGE] Write Eth Manual Configuration Fail',
);

export const checkEdgeInstallation = createAction('[EDIT-EDGE] checkEdgeInstallation');
export const checkEdgeInstallationSuccess = createAction('[EDIT-EDGE] checkEdgeInstallation Success', props<{ data: any; }>());
export const checkEdgeInstallationFail = createAction('[EDIT-EDGE] checkEdgeInstallation Fail');
export const setInstallationLoader = createAction('[EDIT-EDGE] Set installation loader', props<{ installationLoader: boolean }>());

export const getExternalIP = createAction('[EDIT-EDGE] getExternalIP');
export const getExternalIPSuccess = createAction('[EDIT-EDGE] getExternalIP Success', props<{ data: any; }>());
export const getExternalIPFail = createAction('[EDIT-EDGE] getExternalIP Fail');

export const getEdgeSWByIds = createAction('[EDIT-EDGE] get edge SW', props<{ edgeId: string; locationId: string }>());
export const getEdgeSWByIdsSuccess = createAction('[EDIT-EDGE] get edge SW Success', props<{ res: any }>());
export const getEdgeSWByIdsFail = createAction('[EDIT-EDGE] get edge SW Fail');


export const sendUploadVideoStorage = createAction(
  '[EDIT-EDGE] Send Upload Video Storage',
  props<{ dirPattern: string; filePattern: string; cameraId: string }>(),
);
export const sendUploadVideoStorageSuccess = createAction(
  '[EDIT-EDGE] Send Upload Video Storage Success',
);
export const sendUploadVideoStorageFail = createAction(
  '[EDIT-EDGE] Send Upload Video Storage Fail',
);

export const getEdgeIPInfos = createAction('[EDIT-EDGE] getEdgeIPInfos');
export const getEdgeIPInfosSuccess = createAction('[EDIT-EDGE] getEdgeIPInfos Success', props<{ data: any; }>());
export const getEdgeIPInfosFail = createAction('[EDIT-EDGE] getEdgeIPInfos Fail', props<{ errorMessage: string }>());

export const getSoftwareVersion = createAction('[EDIT-EDGE] Get software version');
export const getSoftwareVersionSuccess = createAction('[EDIT-EDGE] Get software version Success', props<{ data: any }>());
export const getSoftwareVersionFail = createAction('[EDIT-EDGE] Get software version Fail', props<{ errorMessage: any }>());

export const dig = createAction('[EDIT-EDGE] dig', props<{ address: string }>());
export const digSuccess = createAction('[EDIT-EDGE] dig Success', props<{ data: string }>());
export const digFail = createAction('[EDIT-EDGE] dig Fail');
export const setDigLoader = createAction('[EDIT-EDGE] Set Dig Loader', props<{ digLoader: boolean }>());

export const traceroute = createAction('[EDIT-EDGE] traceroute', props<{ address: string }>());
export const tracerouteSuccess = createAction('[EDIT-EDGE] traceroute Success', props<{ data: any }>());
export const tracerouteFail = createAction('[EDIT-EDGE] traceroute Fail');
export const setTracerLoader = createAction('[EDIT-EDGE] Set trace Loader', props<{ traceLoader: boolean }>());

export const firewallTest = createAction('[EDIT-EDGE] Firewall test');
export const firewallTestSuccess = createAction('[EDIT-EDGE] Firewall test Success', props<{ data: any }>());
export const firewallTestFail = createAction('[EDIT-EDGE] Firewall test Fail');
export const setFirewallLoader = createAction('[EDIT-EDGE] Set firewall Loader', props<{ firewallLoader: boolean }>());

export const speedTest = createAction('[EDIT-EDGE] Speed test');
export const speedTestSuccess = createAction('[EDIT-EDGE] Speed test Success', props<{ data: any }>());
export const speedTestFail = createAction('[EDIT-EDGE] Speed test Fail');
export const setSpeedTestLoader = createAction('[EDIT-EDGE] Set speed test loader', props<{ speedTestLoader: boolean }>());

export const testConnection = createAction('[EDIT-EDGE] Test Connection', props<{ pingAddress: string }>());
export const testConnectionSuccess = createAction('[EDIT-EDGE] Test Connection Success', props<{ data: string }>());
export const testConnectionFail = createAction('[EDIT-EDGE] Test Connection Fail');
export const setTestConnectionLoader = createAction('[EDIT-EDGE] Set Test Connection Loader', props<{ testConnectionLoader: boolean }>());

export const setEdgeNtp = createAction('[EDIT-EDGE] Set edge NTP', props<{ enable: boolean, servers: NtpModels.NtpServerInterface[] }>());
export const setEdgeNtpServerCall = createAction('[EDIT-EDGE] Set edge NTP Server Call', props<{ enable: boolean, servers: NtpModels.NtpServerInterface[] }>());
export const setEdgeNtpServerCallSuccess = createAction('[EDIT-EDGE] Set edge NTP Server Call Success', props<{ enable: boolean, servers: NtpModels.NtpServerInterface[] }>());
export const setEdgeNtpServerCallFail = createAction('[EDIT-EDGE] Set edge NTP Server Call Fail');

export const updateEdgeInLocationSuccessApiAndSocket = createAction(
  '[EDIT-EDGE] Update edge In Location Success',
  props<{
    updatedLocation: LocationModel.LocationItem;
    edgeId: string
  }>(),
);

export const setIsRebooting = createAction('[EDIT-EDGE] Set is Rebooting Core', props<{ isRebooting: boolean }>());
export const getCameraDetails = createAction('[EDIT-EDGE] Get camera details', props<{ edgeId: string, locationId: string, data: GetCameraDetailsCamera; }>());
export const getCameraDetailsSuccess = createAction('[EDIT-EDGE] Get camera details Success', props<{ result: any }>());
export const getCameraDetailsFail = createAction('[EDIT-EDGE] Get camera details Fail', props<{ errorMessage: string }>());
