import { createAction, props } from '@ngrx/store';
import { MenuKey } from '@enums/menu.enum';
import { Camera, CameraCreateStatus, CameraProfileInterface, EdgeCamera, FileCamera, GetCameraDetailsCamera, ScanType, SearchDevicesByIpRangeData } from '../../../cameras/camera.model';
import { CamerasCreateType } from '@enums/cameras.enum';
import { SortDirection } from '@angular/material/sort';
import { ChangeSubstreamListAction } from '../../../locations/location.model';
import { CameraAuthUpdateDialogResult } from '../../../pages/camera/dialogs/camera-auth-update-dialog/camera-auth-update-dialog.component';
import { CameraStreamCapabilities } from '@models/search-configuration.model';
import { SearchDevicesManualGetCameraDetailsToken } from '../../../core/sessions/searce-devices-manual-get-camera-details-session';

export const resetToInitialState = createAction('[CAMERAS] Reset to Initial state');
export const resetToScanOptions = createAction('[CAMERAS] Reset to Scan Options');
export const resetConnectionData = createAction('[CAMERAS] Reset Connection Data');
export const resetCameraDetails = createAction('[CAMERAS] Reset Camera Details');


export const continueCamera = createAction('[CAMERAS] Continue Camera');
export const setCameraNames = createAction('[CAMERAS] Set Camera Names');

export const backCamera = createAction('[CAMERAS] Back Camera');

export const validateDetails = createAction('[CAMERAS] Validate Details');
export const validateData = createAction('[CAMERAS] Validate Data');

export const saveCamera = createAction('[CAMERAS] Save Camera');
export const saveCameraSuccess = createAction('[CAMERAS] Save Camera Success');

export const saveCameraError = createAction('[CAMERAS] Save Camera Error');

export const sendCamera = createAction('[CAMERAS] Send Camera');

export const addValidMenuPoint = createAction('[CAMERAS] Add Valid Menu Point', props<{ point: MenuKey }>());

export const rmValidMenuPoint = createAction('[CAMERAS] Rm Valid Menu Point', props<{ point: MenuKey }>());

export const setScanType = createAction('[CAMERAS] Set Scan Type', props<{ scanType: ScanType }>());

export const removeCamera = createAction(
  '[CAMERAS] Remove Camera',
  props<{ id: string; cameraId: string; locationId: string; edgeId: string }>(),
);

export const setQuery = createAction('[CAMERAS] Set Query', props<{ query: string }>());

export const setCameraDetails = createAction('[CAMERAS] Set Camera Details', props<{ cameraDetails: EdgeCamera.CameraDocument, multiple?: boolean }>());
export const setCameraError = createAction('[CAMERAS] Set Camera Error', props<{ cameraId: string, error: string }>());
export const setName = createAction('[CAMERAS] Set Camera name', props<{ name: string }>());
export const setDescription = createAction('[CAMERAS] Set Camera Description', props<{ description: string }>());
export const setAutoConfigure = createAction('[CAMERAS] Set Camera Auto Configure', props<{ autoConfigure: boolean }>());
export const setMainStream = createAction(
  '[CAMERAS] Set Main Stream',
  props<{ connectionString: string; height: number; width: number }>(),
);

export const setSubStream = createAction('[CAMERAS] Set Sub Stream', props<{ connectionString: string; height: number; width: number }>());

export const setSnapshotUrl = createAction('[CAMERAS] Set Snapshot URL', props<{ snapshotUrl: string, multiple?: boolean, token?: string }>());

export const setEdgeId = createAction('[CAMERAS] Set edge ID', props<{ edgeId: string }>());

export const setLocationId = createAction('[CAMERAS] Set Location ID', props<{ locationId: string }>());

export const setIsOnvif = createAction('[CAMERAS] Set Is Onvif', props<{ isOnvif: boolean }>());

export const setPaginationParams = createAction('[CAMERAS] Set Pagination Params', props<{ perPage: number; page: number }>());

export const getCameras = createAction('[CAMERAS] Get Cameras from Server');

export const getCamerasSuccess = createAction('[CAMERAS] Get Cameras from Server Success', props<{ cameras: Camera[] }>());

export const setCameras = createAction('[CAMERAS] Set Cameras from Server', props<{ cameras: Camera[] }>());
export const pushCameras = createAction('[CAMERAS] Push Cameras from Server', props<{ cameras: Camera[] }>());
export const setFile = createAction('[CAMERAS] Set Cameras file', props<{ file: Partial<File> }>());
export const setIsWebrtc = createAction('[CAMERAS] Set Is Webrtc', props<{ isWebrtc: boolean }>());
export const setIsWebrtcRelay = createAction('[CAMERAS] Set Is Webrtc Relay', props<{ isWebrtcRelay: boolean }>());

export const setListFromFile = createAction('[CAMERAS] Set Cameras List from file', props<{ listFromFile: Camera[] }>());
export const setCreateEnabled = createAction('[CAMERAS] Set Create Enabled', props<{ createEnabled: boolean }>());

export const setCameraProfiles = createAction('[CAMERAS] Set Camera Profiles', props<{ cameraProfiles: CameraProfileInterface[] }>());
export const setScanData = createAction('[CAMERAS] Set Scan Data', props<{ scanData: SearchDevicesByIpRangeData }>());

export const setTotalItemsCount = createAction('[CAMERAS] Set Total Item Counts', props<{ totalItemsCount: number }>());

export const setSelectedCamera = createAction('[CAMERAS] Set Selected Camera', props<{ selectedCamera: Camera }>());
export const setSelectedCameras = createAction('[CAMERAS] Set Selected Cameras', props<{ selectedCameras: Camera[] }>());

export const getSelectedCamera = createAction('[CAMERAS] Get Selected Camera', props<{ id: string }>());

export const setCameraCreateType = createAction('[CAMERAS] Set Camera Type', props<{ selectedCameraCreateType: CamerasCreateType }>());
export const setManual = createAction('[CAMERAS] Set Manual', props<{ manual: boolean }>());
export const loadLevel3Menu = createAction('[CAMERAS] Load level 3 menu', props<{ copy: boolean }>());
export const getCamerasAutocomplete = createAction(
  '[CAMERAS] Get Cameras Autocomplete',
  props<{ query: string; notType: CamerasCreateType }>(),
);

export const getCamerasAutocompleteSuccess = createAction(
  '[CAMERAS] Get Cameras Autocomplete Success',
  props<{ camerasAutoComplete: { _id: string; name: string }[] }>(),
);

export const getCamerasAutocompleteFail = createAction('[CAMERAS] Get Cameras Autocomplete Fail');

export const getCameraDetails = createAction(
  '[CAMERAS] Get Camera Details Manually',
  props<{ manual: boolean; form: GetCameraDetailsCamera; multiSession?: string, multiple?: boolean, index?: number }>(),
);

export const setOrderParams = createAction(
  '[CAMERAS] Set Order Params',
  props<{
    orderBy: string;
    orderDirection: SortDirection;
  }>(),
);

export const sendGetCameraDetails = createAction(
  '[CAMERAS] Send Get Camera Details Manually',
  props<{ manual: boolean; form: GetCameraDetailsCamera; multiSession?: string, multiple?: boolean, index?: number }>(),
);

export const sendGetCameraDetailsSuccess = createAction(
  '[CAMERAS] Send Get Camera Details Manually Success',
  props<{ result: SearchDevicesManualGetCameraDetailsToken.Result }>(),
);

export const sendGetCameraDetailsFailed = createAction(
  '[CAMERAS] Send Get Camera Details Manually Failed',
  props<{ error: { name?: string, status?: number, error: { error: { responseCode: number; message: string, name: string, status?: number } } } }>(),
);

export const getMultipleCameraDetails = createAction(
  '[CAMERAS] Get Multiple Camera Details Manually',
  props<{ cameras: GetCameraDetailsCamera[] }>(),
);

export const sendGetMultipleCameraDetails = createAction(
  '[CAMERAS] Send Get Multiple Camera Details Manually',
  props<{ cameras: GetCameraDetailsCamera[] }>(),
);
export const resetCamersSessions = createAction('[CAMERAS] Reseet Camera Sessions');

export const setCameraSession = createAction('[CAMERAS] Set Camera Session', props<{ session: string; index: number; reset?: boolean }>());
export const replaceCameraSession = createAction('[CAMERAS] Replace Camera Session', props<{ oldSession: string; newSession: string }>());

export const setCameraSessionStatus = createAction(
  '[CAMERAS] Set Camera Session status',
  props<{ session: string; success?: boolean; error?: boolean; errorMsg?: string; loading?: boolean }>(),
);

export const setCameraCreateStatus = createAction(
  '[CAMERAS] Set Camera Create status',
  props<{ session: string; createStatus: CameraCreateStatus; createErrorMsg?: string }>(),
);

export const setMultiCameraData = createAction(
  '[CAMERAS] Set Multi Camera Data',
  props<{ session: string; snapshotUrl: string; camera: EdgeCamera.CameraDocument }>(),
);

export const updateMultiCamera = createAction('[CAMERAS] Update Multi Camera', props<{ session: string; camera: FileCamera }>());

export const clearSelectedCamera = createAction('[CAMERAS] Clear Selected Camera');

export const createCamera = createAction('[CAMERAS] Create Camera');
export const createCameras = createAction('[CAMERAS] Create Cameras');
export const createMultipleCameras = createAction('[CAMERAS] Create Multiple Cameras');

export const sendCreateCamera = createAction('[CAMERAS] Send Create Camera', props<{ index?: number }>());

export const sendCreateCameraSuccess = createAction('[CAMERAS] Send Create Camera Success', props<{ camera: EdgeCamera.CameraItem }>());
export const sendCreateCameras = createAction('[CAMERAS] Send Create Cameras');

export const addSubstream = createAction('[CAMERAS] Add Substream');
export const deleteSubstream = createAction('[CAMERAS] Delete Sub stream', props<{ index: number }>());
export const setSubstreamSession = createAction('[CAMERAS] Set Sub stream session', props<{ index: number; session: string }>());
export const updateSubstream = createAction('[CAMERAS] Update Sub stream', props<{ index: number; connectionString: string }>());
export const testSubstream = createAction('[CAMERAS] Test Sub stream', props<{ index: number }>());
/**
 * @deprecated
 */
export const testSubstreamDone = createAction(
  '[CAMERAS] Test Sub stream done',
  props<{ session: string; success: boolean; deleteSub: boolean; width?: number; height?: number }>(),
);

// Multiple
export const setSelectedCameraToken = createAction('[CAMERAS] Set Selected Camera Token', props<{ index: number, token: string }>());
export const setSelectedCameraDetails = createAction('[CAMERAS] Set Selected Camera Details', props<{ token: string, cameraDetails: EdgeCamera.CameraDocument }>());
export const setSelectedCameraLoading = createAction('[CAMERAS] Set Selected Camera Loading', props<{ token?: string, index?: number, loading: boolean }>());
export const setSelectedCameraError = createAction('[CAMERAS] Set Selected Camera Error', props<{ token?: string, index?: number, error: string }>());
export const setSelectedCameraCreateSuccess = createAction('[CAMERAS] Set Selected Camera Create success', props<{ token: string }>());
export const setSelectedCameraCreateError = createAction('[CAMERAS] Set Selected Camera Create error', props<{ token: string, error: string }>());

export const setSelectedCameraAuth = createAction('[CAMERAS] Set Selected Camera Auth', props<{ index: number, auth: CameraAuthUpdateDialogResult }>());
export const setSelectedCameraName = createAction('[CAMERAS] Set Selected Camera Name', props<{ index: number, name: string }>());
export const setSelectedCameraSnapshotLoaded = createAction('[CAMERAS] Set Selected Camera Snapshot Loaded', props<{ index: number }>());
export const setSelectedCameraAutoConfigure = createAction('[CAMERAS] Set Selected Camera Auto Configure', props<{ index: number, autoConfigure: boolean }>());

export const setSelectedCameraExistingStreamCapabilities = createAction('[CAMERAS] Set Selected Camera Existing Stream Capabilities', props<{
  token?: string,
  index?: number,
  existingStreamCapabilities: CameraStreamCapabilities[]
}>());
export const setSelectedCameraSuggestedStreamCapabilities = createAction('[CAMERAS] Set Selected Camera Suggested Stream Capabilities', props<{
  token?: string,
  index?: number,
  suggestedStreamCapabilities: CameraStreamCapabilities[]
}>());
export const setSelectedCameraStreamCapabilities = createAction('[CAMERAS] Set Selected Camera Stream Capabilities', props<{
  token?: string,
  index?: number,
  streamCapabilities: CameraStreamCapabilities[]
}>());


export const removeSelectedCamera = createAction('[CAMERAS] Remove Selected Camera', props<{ index: number }>());

export const rescan = createAction('[CAMERAS] Rescan');


