import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import * as EdgeEditActions from '@states/edge-edit/edge-edit.actions';
import { withLatestFrom } from 'rxjs/operators';
import { catchError, exhaustMap, mergeMap, share, switchMap, takeUntil, timeout } from 'rxjs';
import * as SharedActions from '@states/shared/shared.actions';
import { LocationsService } from '../../locations/locations.service';
import { LocationModel } from '../../locations/location.model';
import { EdgeActions } from '@states/edge/edge.action-types';
import { EdgeManagementService, EdgeManagerMsgType } from '../../locations/edge-management.service';
import { HeartbeatService } from '../../development/heartbeat.service';
import { CamerasThumbnailsService } from '../../cameras/camera-thumbnails/camera-thumnails.service';
import { EdgeService } from '../../edge/edge.service';
import { EdgeSelectors } from '@states/edge/edge.selector-types';
import { CertificationActions } from '@states/certifications/certifications.action-types';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from '../../edge/utils.service';
import { EdgeServiceV2 } from '../../services/edge.service';
import { isAsyncCallResponseError } from '../../helpers/error.helpers';
import { AsyncCallModels } from '@models/async-call.models';
import { LocationActions } from '@states/location/location.action-types';
import { HomeActions } from '@states/home/home.action-types';

@Injectable()
export class EdgeEditEffects {
  public pressSave$ = createEffect(() => this.actions$.pipe(ofType(EdgeEditActions.pressSave), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public pressRefresh$ = createEffect(() => this.actions$.pipe(ofType(EdgeEditActions.pressRefresh), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public getEdgeSWByIdsSuccess$ = createEffect(() => this.actions$.pipe(ofType(EdgeEditActions.getEdgeSWByIdsSuccess), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });


  public getEdgeSwUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getEdgeSwUpdate),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService.getEdgeSwUpdate(selectedEdgeId)
          .pipe(
            takeUntil(this.actions$.pipe(ofType(EdgeEditActions.cancelNetworkRequests))),
            switchMap(result => {
              return [
                EdgeEditActions.getEdgeSwUpdateSuccess({
                  result,
                }),
              ];
            }),
            catchError(response => {
              const errorMessage = this.utilsService.errMessage(response);
              return [
                SharedActions.showMessage({ error: errorMessage }),
                EdgeEditActions.getEdgeSwUpdateFailed({
                  errorMessage: errorMessage,
                }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getEdgeConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getEdgeConfig),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ configType }, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeServiceV2.getEdgeConfig(selectedLocationId, selectedEdgeId, configType)
          .pipe(
            switchMap(res => {
              return [
                EdgeEditActions.getEdgeConfigSuccess({ data: res.config }),
              ];
            }),
            catchError(response => {
              const errorMessage = this.utilsService.errMessage(response);
              return [
                SharedActions.showMessage({ error: errorMessage }),
                EdgeEditActions.getEdgeConfigFail({ errorMessage }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public uploadEdgeConfigJson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.uploadEdgeConfigJson),
      exhaustMap(({ config, configType }) => {
        return [EdgeEditActions.setIsSaving({ isSaving: true }), EdgeEditActions.sendEdgeConfigJson({ config, configType })];
      }),
    ),
  );

  public sendEdgeConfigJson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.sendEdgeConfigJson),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ config, configType }, { selectedLocationId, selectedEdgeId }]) => {
        return this.locationService.uploadEdgeConfigJson(config, selectedLocationId, selectedEdgeId, configType)
          .pipe(
            switchMap(res => {
              return [
                EdgeEditActions.sendEdgeConfigJsonSuccess(),
                EdgeEditActions.setIsSaving({ isSaving: false }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
                EdgeEditActions.setIsSaving({ isSaving: false })];
            }),
          );
      }),
      share(),
    ),
  );

  public saveEdgeEditForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.saveEdgeEditForm),
      exhaustMap(({ edgeUpdate }) => [
        EdgeEditActions.setIsSaving({ isSaving: true }),
        EdgeEditActions.sendEdgeEditForm({ edgeUpdate }),
      ]),
    ),
  );

  public sendEdgeEditForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.sendEdgeEditForm),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ edgeUpdate }, { selectedEdgeId, selectedLocationId, selectedEdge }]) => {
        const request: LocationModel.UpdateEdgeInLocationRequest = {
          edgeId: selectedEdgeId,
          locationId: selectedLocationId,
          name: edgeUpdate?.name ?? selectedEdge.name,
          update: edgeUpdate,
          edge: { ...selectedEdge, ...edgeUpdate },
        };
        return [EdgeEditActions.updateEdgeInLocation({ updateEdgeInLocationRequest: request })];
      }),
      share(),
    ),
  );

  public updateEdgeInLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.updateEdgeInLocation),
      switchMap(({ updateEdgeInLocationRequest }) => {
        return this.edgeServiceV2.updateEdgeInLocationASync(updateEdgeInLocationRequest)
          .pipe(
            switchMap(res => {
              const responseBody = res?.responseBody;
              return [
                EdgeEditActions.setIsSaving({ isSaving: false }),
                SharedActions.showMessageFromSocket({ level: responseBody?.messageLevel, msg: responseBody?.message }),
                EdgeEditActions.updateEdgeInLocationSuccessApiAndSocket({ updatedLocation: responseBody.document, edgeId: updateEdgeInLocationRequest.edgeId }),
              ];
            }),
            catchError(response => {
              const actions: Action[] = [
                EdgeEditActions.setIsSaving({ isSaving: false }),
              ];
              if (isAsyncCallResponseError(response)) {
                const responseBody = response.error.responseBody as AsyncCallModels.ResponseBody<any>;
                actions.push(
                  SharedActions.showMessageFromSocket({ level: responseBody?.messageLevel, msg: responseBody?.message }),
                );
              } else {
                actions.push(
                  SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
                );
              }
              return actions;
            }),
          );
      }),
      share(),
    ),
  );

  public getEdgeDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getEdgeDocument),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ docType }, { selectedLocationId, selectedEdgeId }]) => {
        return this.locationService.getEdgeDocument(selectedLocationId, selectedEdgeId, docType)
          .pipe(
            takeUntil(this.actions$.pipe(ofType(EdgeEditActions.cancelNetworkRequests))),
            timeout(20000),
            switchMap(res => {
              return [
                EdgeEditActions.getEdgeDocumentSuccess({ data: res.data, hash: res.hash }),
              ];
            }),
            catchError((response: HttpErrorResponse) => {
              const errorMessage = this.utilsService.errMessage(response);
              return [
                EdgeEditActions.getEdgeDocumentFail({ errorMessage }),
                SharedActions.showMessage({ error: errorMessage }),
                SharedActions.setSomethingWentWrong({ somethingWentWrong: true }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getEdgeInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getEdgeInfo),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.locationService.getEdgeInfo(selectedLocationId, selectedEdgeId)
          .pipe(
            timeout(20000),
            takeUntil(this.actions$.pipe(ofType(EdgeEditActions.cancelNetworkRequests))),
            switchMap(res => {
              return [
                EdgeEditActions.getEdgeInfoSuccess({ data: res.data }),
              ];
            }),
            catchError(response => {
              const errorMessage = this.utilsService.errMessage(response);
              return [
                SharedActions.showMessage({ error: errorMessage }),
                EdgeEditActions.getEdgeInfoFail({ errorMessage }),
              ];
            }),
          );
      }),
      share(),
    ),
  );
  public updateSoftwareVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.updateSoftwareVersion),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([res, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdgeV2(EdgeManagerMsgType.SWUpdate, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            version: res.version,
          })
          .pipe(
            timeout(60000),
            takeUntil(this.actions$.pipe(ofType(EdgeEditActions.cancelNetworkRequests))),
            switchMap(res => {
              return [
                EdgeEditActions.updateSoftwareVersionSuccess(),
              ];
            }),
            catchError(response => {
              const errorMessage = this.utilsService.errMessage(response);
              return [
                SharedActions.showMessage({ error: errorMessage }),
                EdgeEditActions.updateSoftwareVersionFail({ errorMessage }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public pingAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.ping),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ pingAddress, tcp }, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdgeV2(EdgeManagerMsgType.Ping, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            pingAddress,
            tcp,
          })
          .pipe(
            timeout(60000),
            takeUntil(this.actions$.pipe(ofType(EdgeEditActions.cancelNetworkRequests))),
            switchMap(res => {
              return [
                EdgeEditActions.pingSuccess({ data: res.data }),
                EdgeEditActions.setPingLoader({ pingLoader: false }),
              ];
            }),
            catchError((response: HttpErrorResponse) => {
              const errorMessage = this.utilsService.errMessage(response);
              return [
                SharedActions.showMessage({ error: errorMessage }),
                EdgeEditActions.setPingLoader({ pingLoader: false }),
                EdgeEditActions.pingFail({ errorMessage: errorMessage }),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public writeEthManualConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.writeEthManualConfiguration),
      exhaustMap(({ ethConfig }) => [
        EdgeEditActions.setIsSaving({ isSaving: true }),
        EdgeEditActions.writeEthManualConfigurationServerRequest({ ethConfig }),
      ]),
    ),
  );

  public writeEthManualConfigurationServerRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.writeEthManualConfigurationServerRequest),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ ethConfig }, { selectedLocationId, selectedEdgeId }]) => {
        const { ethInterface, ...rest } = ethConfig;
        return this.edgeManagementService
          .manageEdgeV2(EdgeManagerMsgType.NMCLIConfigure, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            ethConfig: rest,
            ethInterface,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({ success: 'Eth manual configuration has been saved' }),
                EdgeEditActions.setIsSaving({ isSaving: false }),
                EdgeEditActions.writeEthManualConfigurationSuccess(),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
                EdgeEditActions.setIsSaving({ isSaving: false }),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public getEdgeLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getEdgeLogs),
      exhaustMap(({ daysBack }) => [
        SharedActions.setSomethingWentWrong({ somethingWentWrong: false }),
        EdgeEditActions.requestEdgeLogs({ daysBack }),
      ]),
    ),
  );

  public requestEdgeLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.requestEdgeLogs),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ daysBack }, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeServiceV2
          .getEdgeLogs({
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            daysBack,
          })
          .pipe(
            switchMap(res => {
              return [
                EdgeEditActions.requestEdgeLogsSuccess(),
              ];
            }),
            catchError(response => {
              return [
                EdgeEditActions.requestEdgeLogsFail(),
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getEdgeAWSLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getEdgeAWSLogs),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      exhaustMap(([, { selectedEdgeId }]) => {
        return this.locationService.getEdgeAwsLogs({ edgeId: selectedEdgeId })
          .pipe(
            switchMap(res => {
              return [EdgeEditActions.setEdgeAWSLogs({ logs: res })];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public checkEdgeInstallation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.checkEdgeInstallation),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdgeV2(EdgeManagerMsgType.CheckEdgeInstallation, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            timeout(60000),
            takeUntil(this.actions$.pipe(ofType(EdgeEditActions.cancelNetworkRequests))),
            switchMap(res => {
              return [
                EdgeEditActions.setInstallationLoader({ installationLoader: false }),
                EdgeEditActions.checkEdgeInstallationSuccess({ data: res.data }),
              ];
            }),
            catchError(response => {
              return [
                EdgeEditActions.setInstallationLoader({ installationLoader: false }),
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public getEdgeIPInfos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getEdgeIPInfos),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdgeV2(EdgeManagerMsgType.GetEdgeIPInfos, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            timeout(60000),
            takeUntil(this.actions$.pipe(ofType(EdgeEditActions.cancelNetworkRequests))),
            switchMap(res => {
              return [
                EdgeEditActions.getEdgeIPInfosSuccess({ data: res.data }),
              ];
            }),
            catchError((response: HttpErrorResponse) => {
              const errorMessage = this.utilsService.errMessage(response);
              return [
                EdgeEditActions.getEdgeIPInfosFail({ errorMessage }),
                SharedActions.showMessage({ error: errorMessage }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getExternalIP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getExternalIP),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdgeV2(EdgeManagerMsgType.GetExternalIP, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            timeout(60000),
            takeUntil(this.actions$.pipe(ofType(EdgeEditActions.cancelNetworkRequests))),
            switchMap(res => {
              return [
                EdgeEditActions.getExternalIPSuccess({ data: res.data }),
              ];
            }),
            catchError((response: HttpErrorResponse) => {
              return [
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getHeartbeats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getHeartbeats),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId, heartBeatsPaginationTimeStamps, heartBeatsDateFrom, heartBeatsDateTo }]) => {
        return this.heartbeatService
          .getAllHeartbeats(0, 20, {
            edgeId: selectedEdgeId,
            start: heartBeatsDateFrom,
            end: heartBeatsPaginationTimeStamps ?? heartBeatsDateTo,
          })
          .pipe(
            switchMap(res => {
              return [EdgeEditActions.getHeartbeatsSuccess({ heartbeats: res })];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getCameraThumbnails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getCameraThumbnails),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ start, end, selectedCamera }, { selectedLocationId, selectedEdgeId }]) => {
        return this.cameraThumbnailsService.getThumbnailsByDateFromDb(selectedEdgeId, selectedCamera.edgeOnly.cameraId, start, end)
          .pipe(
            switchMap(res => {
              return [EdgeEditActions.getCameraThumbnailsSuccess({ thumbnailsBitmap: res })];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getEdgeById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getEdgeById),
      withLatestFrom(
        this.store$.pipe(select(state => state.edgeEditState)),
        this.store$.pipe(select(EdgeSelectors.selectEdgeState)),
      ),
      exhaustMap(([, { selectedEdgeId, selectedLocationId }, { entities }]) => {
        const selectedEdge = entities[selectedEdgeId];
        return [EdgeEditActions.setSelectedEdge({ selectedEdge })];
      }),
    ),
  );

  public getEdgeSWByIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getEdgeSWByIds),
      switchMap(({ edgeId, locationId }) => {
        return this.edgeManagementService.manageEdge(EdgeManagerMsgType.GetSWVersion, { locationId, edgeId })
          .pipe(
            switchMap(res => {
              return [
                EdgeEditActions.getEdgeSWByIdsSuccess({ res }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
                // SharedActions.setSomethingWentWrong({somethingWentWrong: true}),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public uploadVideoStorage = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.uploadVideoStorage),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      exhaustMap(([{ filePattern, dirPattern, cameraId }, { selectedLocationId, selectedEdgeId }]) => [
        EdgeEditActions.setIsSaving({ isSaving: true }),
        EdgeEditActions.sendUploadVideoStorage({ filePattern, dirPattern, cameraId }),
      ]),
    ),
  );


  public uploadVideoStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.sendUploadVideoStorage),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ filePattern, dirPattern, cameraId }, { selectedLocationId, selectedEdgeId }]) => {
        return this.locationService
          .uploadVideoStorage({
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            cameraId,
            filePattern,
            dirPattern,
          })
          .pipe(
            switchMap(res => {
              return [
                EdgeEditActions.setIsSaving({ isSaving: false }),
                EdgeEditActions.sendUploadVideoStorageSuccess(),
                SharedActions.showMessage({ success: 'Upload video storage saved' }),
              ];
            }),
            catchError(response => {
              return [
                EdgeEditActions.setIsSaving({ isSaving: false }),
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public rmEdgeLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.rmEdgeLink),
      switchMap(({ link }) => {
        return this.locationService
          .rmEdgeAWSLog({
            link,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({
                  success: 'Log has been removed',
                }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getAnalyticConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getAnalyticConfig),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ cameraId }, { selectedLocationId, selectedEdgeId }]) =>
        this.locationService
          .getAnalyticConfigJson({
            cameraId,
            edgeId: selectedEdgeId,
            locationId: selectedLocationId,
          })
          .pipe(
            switchMap(res => {
              return [
                EdgeEditActions.getAnalyticConfigSuccess({ data: res.data }),
              ];
            }),
            catchError(response => {
              const errorMessage = this.utilsService.errMessage(response);
              return [
                EdgeEditActions.getAnalyticConfigFail({ errorMessage: errorMessage }),
                SharedActions.showMessage({ error: errorMessage }),
              ];
            }),
          ),
      ),
      share(),
    ),
  );

  public updateAnalyticConfigJson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.updateAnalyticConfigJson),
      exhaustMap(({ config, cameraId }) => {
        return [EdgeEditActions.setIsSaving({ isSaving: true }), EdgeEditActions.sendUpdateAnalyticConfigJson({ config, cameraId })];
      }),
    ),
  );

  public sendUpdateAnalyticConfigJson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.sendUpdateAnalyticConfigJson),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ config, cameraId }, { selectedLocationId, selectedEdgeId }]) => {
        return this.locationService
          .updateAnalyticConfigJson({
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            config,
            cameraId,
          })
          .pipe(
            timeout(20000),
            switchMap(res => {
              return [
                SharedActions.showMessage({
                  success: 'Analytic config has been updated',
                }),
                EdgeEditActions.setIsSaving({ isSaving: false }),
              ];
            }),
            catchError((response: HttpErrorResponse) => {
              return [
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
                EdgeEditActions.setIsSaving({ isSaving: false }),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public restoreEdge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.restoreEdge),
      switchMap(({ locationId, edgeId }) => {
        return this.edgeService
          .restoreEdge(locationId, edgeId)
          .pipe(
            takeUntil(this.actions$.pipe(ofType(EdgeEditActions.cancelManageRequest))),
            switchMap(result => {
              return [
                EdgeEditActions.restoreEdgeSuccess({ result }),
              ];
            }),
            catchError(response => {
              return [
                EdgeEditActions.restoreEdgeFail(),
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
                // SharedActions.setSomethingWentWrong({somethingWentWrong: true}),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getEdgeNtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getEdgeNtp),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{}, { selectedLocationId, selectedEdgeId }]) => {
        return this.locationService.getEdgeNtp(selectedLocationId, selectedEdgeId)
          .pipe(
            timeout(15000),
            takeUntil(this.actions$.pipe(ofType(EdgeEditActions.cancelNetworkRequests))),
            switchMap(res => {
              return [
                EdgeEditActions.getEdgeNtpSuccess({ data: res }),
              ];
            }),
            catchError((response: HttpErrorResponse) => {
              const errorMessage = this.utilsService.errMessage(response);
              return [
                SharedActions.showMessage({ error: errorMessage }),
                EdgeEditActions.getEdgeNtpFail({ errorMessage: errorMessage }),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public setEdgeNtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.setEdgeNtp),
      exhaustMap(({ enable, servers }) => {
        return [
          EdgeEditActions.setIsSaving({ isSaving: true }), EdgeEditActions.setEdgeNtpServerCall({ enable, servers })];
      }),
    ),
  );

  public setEdgeNtpServerCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.setEdgeNtpServerCall),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ enable, servers }, { selectedLocationId, selectedEdgeId }]) => {
        return this.locationService.setEdgeNtp(selectedLocationId, selectedEdgeId, enable, servers)
          .pipe(
            switchMap(res => {
              return [
                EdgeEditActions.setIsSaving({ isSaving: false }),
                SharedActions.showMessage({ success: 'NTP servers saved successfully' }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
                SharedActions.setSomethingWentWrong({ somethingWentWrong: true }),
                EdgeEditActions.setIsSaving({ isSaving: false }),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public getCoreRecoveryDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getCoreRecoveryDocument),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedEdge }]) => {
        return this.locationService.getCoreRecoveryDocument(selectedEdge.edgeId)
          .pipe(
            switchMap(res => {
              return [
                EdgeEditActions.getCoreRecoveryDocumentSuccess({ edgeRecoveryDocument: res ?? {} }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
                EdgeEditActions.getCoreRecoveryDocumentFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public saveCoreRecoveryDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.saveCoreRecoveryDocument),
      exhaustMap(({ body }) => {
        return [EdgeEditActions.setIsSaving({ isSaving: true }), EdgeEditActions.serverCallSaveCoreRecoveryDocument({ body })];
      }),
    ),
  );

  public serverCallSaveCoreRecoveryDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.serverCallSaveCoreRecoveryDocument),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ body }, { selectedEdge }]) => {
        return this.locationService.saveCoreRecoveryDocument(selectedEdge.edgeId, body)
          .pipe(
            switchMap(res => {
              return [
                EdgeEditActions.serverCallSaveCoreRecoveryDocumentSuccess(),
                SharedActions.showMessage({ success: 'Saved' }),
                EdgeEditActions.setIsSaving({ isSaving: false }),
              ];
            }),
            catchError(response => {
              return [
                EdgeEditActions.setIsSaving({ isSaving: false }),
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
                EdgeEditActions.serverCallSaveCoreRecoveryDocumentFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public dig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.dig),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ address }, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdgeV2(EdgeManagerMsgType.Dig, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            address,
          })
          .pipe(
            timeout(60000),
            takeUntil(this.actions$.pipe(ofType(EdgeEditActions.cancelNetworkRequests))),
            switchMap(res => {
              return [
                EdgeEditActions.digSuccess({ data: res.data }),
                EdgeEditActions.setDigLoader({ digLoader: false }),
              ];
            }),
            catchError(response => {
              return [
                EdgeEditActions.setDigLoader({ digLoader: false }),
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public traceroute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.traceroute),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ address }, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdgeV2(EdgeManagerMsgType.Traceroute, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            address,
          })
          .pipe(
            timeout(60000),
            takeUntil(this.actions$.pipe(ofType(EdgeEditActions.cancelNetworkRequests))),
            switchMap(res => {
              return [
                EdgeEditActions.tracerouteSuccess({ data: res.data }),
                EdgeEditActions.setTracerLoader({ traceLoader: false }),
              ];
            }),
            catchError(response => {
              return [
                EdgeEditActions.setTracerLoader({ traceLoader: false }),
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public speedTest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.speedTest),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{}, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdgeV2(EdgeManagerMsgType.SpeedtestInfo, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            timeout(60000),
            takeUntil(this.actions$.pipe(ofType(EdgeEditActions.cancelNetworkRequests))),
            switchMap(res => {
              return [
                EdgeEditActions.speedTestSuccess({ data: res.data }),
                EdgeEditActions.setSpeedTestLoader({ speedTestLoader: false }),
              ];
            }),
            catchError(response => {
              return [
                EdgeEditActions.setSpeedTestLoader({ speedTestLoader: false }),
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public firewallTest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.firewallTest),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{}, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdgeV2(EdgeManagerMsgType.CheckWhiteList, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            timeout(60000),
            takeUntil(this.actions$.pipe(ofType(EdgeEditActions.cancelNetworkRequests))),
            switchMap(res => {
              return [
                EdgeEditActions.firewallTestSuccess({ data: res.data }),
                EdgeEditActions.setFirewallLoader({ firewallLoader: false }),
              ];
            }),
            catchError(response => {
              return [
                EdgeEditActions.setFirewallLoader({ firewallLoader: false }),
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public getEdgeHeartBeatStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getEdgeHeartBeatStatus),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{}, { selectedLocationId, selectedEdgeId }]) => {
        return this.heartbeatService
          .getEdgeHeartBeatsStatus(selectedEdgeId)
          .pipe(
            switchMap(res => {
              return [
                EdgeEditActions.getEdgeHeartBeatStatusSuccess({ body: res.res }),
              ];
            }),
            catchError(response => {
              const errorMessage = this.utilsService.errMessage(response);
              return [
                EdgeEditActions.getEdgeHeartBeatStatusFail({ errorMessage }),
                SharedActions.showMessage({ error: errorMessage }),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public startGetCertifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CertificationActions.startGetCertifications),
      exhaustMap(() => [
        CertificationActions.setCertificationsLoading({ isLoading: true }),
        CertificationActions.getCertifications(),
      ]),
    ),
  );

  public getCertifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CertificationActions.getCertifications),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{}, { selectedEdgeId }]) => {
        return this.edgeService
          .getCertifications(selectedEdgeId)
          .pipe(
            switchMap(res => {
              return [
                CertificationActions.getCertificationsSuccess({ entities: res }),
              ];
            }),
            catchError(response => {
              return [
                CertificationActions.getCertificationsFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public updateEdgeWithExistsCert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CertificationActions.updateEdgeWithExistsCert),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ id }, { selectedEdgeId, selectedLocationId }]) => {
        return this.edgeService
          .updateEdgeWithCertificate(selectedLocationId, selectedEdgeId, id)
          .pipe(
            switchMap(res => {
              return [
                CertificationActions.updateEdgeWithExistsCertSuccess(),
                CertificationActions.getCertifications(),
                SharedActions.showMessage({ success: 'Edge certificates updated successfully' }),
              ];
            }),
            catchError(response => {
              return [
                CertificationActions.updateEdgeWithExistsCertFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public startValidateExistsCert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CertificationActions.startValidateExistsCert),
      exhaustMap(({ id }) => [
        CertificationActions.setValidationLoading({ validationLoading: true }),
        CertificationActions.validateExistsCert({ id }),
      ]),
    ),
  );


  public validateExistsCert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CertificationActions.validateExistsCert),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ id }, { selectedEdgeId, selectedLocationId }]) => {
        return this.edgeService
          .validateExistsCert(selectedLocationId, selectedEdgeId, id)
          .pipe(
            switchMap(res => {
              return [
                CertificationActions.validateExistsCertSuccess(),
              ];
            }),
            catchError(response => {
              return [
                CertificationActions.updateEdgeWithExistsCertFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public restartEdge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.restartEdge),
      switchMap(({ edgeId, locationId }) => {
        return this.locationService
          .restartEdge(locationId, edgeId)
          .pipe(
            timeout(60000),
            switchMap(res => {
              return [
                EdgeEditActions.restartEdgeSuccess(),
              ];
            }),
            catchError(response => {
              return [
                EdgeEditActions.restartEdgeFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public startRebootEdge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.startRebootEdge),
      switchMap(() => [
        EdgeEditActions.setIsRebooting({ isRebooting: true }),
        EdgeEditActions.rebootEdge(),
      ]),
      share(),
    ),
  );

  public rebootEdge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.rebootEdge),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService.manageEdgeV2(EdgeManagerMsgType.Reboot, { locationId: selectedLocationId, edgeId: selectedEdgeId })
          .pipe(
            timeout(60000),
            switchMap(res => {
              return [
                EdgeEditActions.setIsRebooting({ isRebooting: false }),
                EdgeEditActions.rebootEdgeSuccess(),
              ];
            }),
            catchError(response => {
              return [
                EdgeEditActions.setIsRebooting({ isRebooting: false }),
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
                EdgeEditActions.rebootEdgeFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public testConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.testConnection),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ pingAddress }, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdgeV2(EdgeManagerMsgType.Ping, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            pingAddress,
          })
          .pipe(
            timeout(20000),
            switchMap(res => {
              return [
                EdgeEditActions.testConnectionSuccess({ data: res.data }),
                EdgeEditActions.setTestConnectionLoader({ testConnectionLoader: false }),
              ];
            }),
            catchError((response: HttpErrorResponse) => {
              return [
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
                EdgeEditActions.setTestConnectionLoader({ testConnectionLoader: false }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getSoftwareVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getSoftwareVersion),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdgeV2(EdgeManagerMsgType.GetSWVersion, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            timeout(60000),
            takeUntil(this.actions$.pipe(ofType(EdgeEditActions.cancelNetworkRequests))),
            switchMap(res => {
              return [
                EdgeEditActions.getSoftwareVersionSuccess({ data: res }),
              ];
            }),
            catchError(response => {
              const errorMessage = this.utilsService.errMessage(response);
              return [
                SharedActions.showMessage({ error: errorMessage }),
                EdgeEditActions.getSoftwareVersionFail({ errorMessage }),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public updateEdgeInLocationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.updateEdgeInLocationSuccessApiAndSocket),
      switchMap(({ updatedLocation, edgeId }) => {
        const edge = updatedLocation.edges[edgeId];
        const name = edge?.name;
        let request: LocationModel.UpdateEdgeInLocationRequest = { edge, update: edge, locationId: updatedLocation._id, edgeId, name };
        return [
          LocationActions.UpdateLocationNoBackendCall({ location: updatedLocation }),
          EdgeActions.UpdateEdgeNoBackendCallSuccess({ request: request }),
          HomeActions.getLocations(),
        ];
      }),
      share(),
    ),
  );

  public getCameraDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getCameraDetails),
      switchMap(({ locationId, edgeId, data }) => {
        const cameraDetailsRequest: LocationModel.GetCameraDetailsRequest = {
          edgeId,
          locationId,
          camera: {
            ...data,
          },
          create: false,
        };
        return this.edgeService.getCameraDetails(cameraDetailsRequest, true)
          .pipe(
            mergeMap(result => {
              return [
                EdgeEditActions.getCameraDetailsSuccess({ result }),
              ];
            }),
            catchError(error => {
              const errorMessage = this.utilsService.errMessage(error);
              return [
                EdgeEditActions.getCameraDetailsFail({ errorMessage }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private locationService: LocationsService,
    private edgeManagementService: EdgeManagementService,
    private cameraThumbnailsService: CamerasThumbnailsService,
    private heartbeatService: HeartbeatService,
    private edgeService: EdgeService,
    private utilsService: UtilsService,
    private edgeServiceV2: EdgeServiceV2,
  ) {
  }
}
