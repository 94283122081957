import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LocationActions } from '@states/location/location.action-types';
import { catchError, concatMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { combineLatest, exhaustMap, of, share } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { LocationsService } from '../../locations/locations.service';
import { LocationModel } from '../../locations/location.model';
import { LocationSelectors } from '@states/location/location.selector-types';
import { EdgeActions } from '@states/edge/edge.action-types';
import { CameraActions } from '@states/camera/camera.action-types';
import { AuthenticationService } from '../../authentication/authentication.service';
import { AppState } from '../app.state';
import { EdgeService } from '../../edge/edge.service';
import { TokenDataStatus } from '../../core/messaging.interfaces';
import { SharedActions } from '@states/shared/shared.action-types';
import { DeviceStatusActions } from '@states/device-status/device-status.actions-types';
import { PulsationModels } from '@models/pulsation.model';
import ComponentStatusDisplay = PulsationModels.ComponentStatusDisplay;
import { AuthenticationActions } from '@states/authentication/authentication.action-types';
import { LocalStorageService } from '../../core/local-storage.service';
import { SensorsService } from 'src/app/services/sensors.service';

@Injectable()
export class LocationEffects {
  GetLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.GetLocations),
      withLatestFrom(this.store.pipe(select(LocationSelectors.isFirstLocationLoaded))),
      filter(([action, isFirstLoaded]) => !isFirstLoaded),
      map(([action, isLocationsLoaded]) => action),
      concatMap(action => {
        return this.locationService.getAllLocations()
          .pipe(
            map(res => {
              return this.computeLocationLookup(res);
            }),
            switchMap(res => {
              return [
                LocationActions.GetLocationsSuccess({ payload: res.locations }),
                LocationActions.SetLocationsLookup({ lookup: res.locationLookup }),
                EdgeActions.GetLocationEdgesNoBackendCall({ payload: res.edges }),
                CameraActions.GetLocationEdgesCamerasSnapshots(),
                CameraActions.GetLocationEdgesCamerasSuccess({ payload: res.cameras }),
              ];
            }),
            catchError(err => {
              const msg = err?.error?.message || 'uknown error occured get Locations Fail';
              return [
                LocationActions.GetLocationsFail({
                  message: msg,
                }),
                AuthenticationActions.Logout({ params: { msg } }),
              ];
            }),
          );
      }),
      catchError(err => {
        return of(
          LocationActions.GetLocationsFail({
            message: err?.error?.message || 'uknown error occured',
          }),
        );
      }),
    ),
  );

  CreateLocationBackendCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.CreateLocationBackendCall),
      map(action => action.request),
      concatMap((request: LocationModel.LocationCreateRequest) => {
        return this.locationService.createLocation(request)
          .pipe(
            map((res: LocationModel.LocationCreateResponse) => {
              if (!res._id) {
                const error: any = new Error(`Couldn't get location generated id`);
                error.timestamp = Date.now();
                throw error;
              }
              const location: LocationModel.LocationItem = {
                ...request,
                _id: res._id,
              };
              return LocationActions.CreateLocationSuccess({
                response: location,
              });
            }),
            catchError((err: Error) => of(LocationActions.CreateLocationFail({ message: err.message }))),
          );
      }),
    ),
  );

  CreateLocationNoBackendCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.CreateLocationNoBackendCall),
      map(action => action.request),
      tap(res => {
        if (!res || !res._id) {
          const error: any = new Error(`location id is required`);
          error.timestamp = Date.now();
          throw error;
        }
      }),
      switchMap(response => {
        // new added location become automatically online
        return [
          LocationActions.CreateLocationSuccess({ response }),
          DeviceStatusActions.setLocationStatusById({ locationId: response._id, status: ComponentStatusDisplay.Online })];
      }),
      catchError((err: Error) => of(LocationActions.CreateLocationFail({ message: err.message }))),
    ),
  );

  DeleteLocationNoBackendCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.DeleteLocationNoBackendCall),
      mergeMap(action => {
        return combineLatest([of(action), this.store.pipe(select(LocationSelectors.selectLocationById(action.request.locationId)))]);
      }),
      filter(([action, locationItem]) => {
        return !!locationItem;
      }),
      map(([action, locationItem]) => {
        return LocationActions.DeleteLocationSuccess({
          response: {
            locationId: locationItem!._id,
            result: true,
          },
        });
      }),
      catchError((err: Error) => {
        return of(
          LocationActions.DeleteLocationFail({
            message: err.message || 'Unknown error occurred',
          }),
        );
      }),
    ),
  );

  DeleteLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.DeleteLocation),
      map(action => action.request),
      concatMap((request: LocationModel.LocationDeleteRequest) => {
        return this.locationService.deleteLocation(request.locationId, true)
          .pipe(
            map((res: boolean) => {
              if (!res) {
                const error: any = new Error(`Couldn't get location generated id`);
                error.timestamp = Date.now();
                throw error;
              }

              return LocationActions.DeleteLocationSuccess({
                response: { result: res, locationId: request.locationId },
              });
            }),
            catchError((err: Error) => of(LocationActions.DeleteLocationFail({ message: err.message }))),
          );
      }),
    ),
  );

  DeleteCameraNoBackendCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.DeleteCameraNoBackendCall),
      mergeMap(action => {
        return combineLatest([of(action), this.store.pipe(select(LocationSelectors.selectLocationById(action.request.locationId)))]);
      }),
      filter(([action, locationItem]) => {
        return !!locationItem;
      }),
      map(([action, locationItem]) => {
        return LocationActions.DeleteCameraSuccess({
          response: {
            location: locationItem!,
            ...action.request,
          },
        });
      }),
      catchError((err: Error) => {
        return of(
          LocationActions.DeleteCameraFail({
            message: err.message || 'Unknown error occurred',
          }),
        );
      }),
    ),
  );
  
  /**
   *  edge info get
   */

  public getEdgeInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.GetEdgeInfo),
      switchMap(({ locationId, edgeId }) => {
        return this.locationService.getEdgeInfo(locationId, edgeId)
          .pipe(
            switchMap(res => {
              return [
                LocationActions.SetEdgeInfo({
                  edgeInfo: res.data,
                }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: JSON.stringify(response) }),
                // SharedActions.setSomethingWentWrong({somethingWentWrong: true}),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public computeLocationLookup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.computeLocationLookup),
      withLatestFrom(this.store.pipe(select(LocationSelectors.selectAllLocations))),
      switchMap(([{}, locations]) => {
        const res = this.computeLocationLookup(locations);
        return [LocationActions.SetLocationsLookup({ lookup: res.locationLookup })];
      }),
      share(),
    ),
  );

  public setViewType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.setViewType),
      switchMap(({ viewType }) => {
        this.localStorageService.setItem('viewType', viewType);
        return [];
      }),
      share(),
    ),
  );

  public getViewTypeFromLocalStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.getViewTypeFromLocalStorage),
      switchMap(() => {
        const viewType = +this.localStorageService.getItem('viewType') ?? LocationModel.ViewType.Home;

        return [LocationActions.getViewTypeFromLocalStorageSuccess({ viewType })];
      }),
      share(),
    ),
  );

public getSensors$ = createEffect(() =>
  this.actions$.pipe(
    ofType(LocationActions.GetSensors),
    exhaustMap(() => {
      return this.sensorsService.getAllSensors().pipe(
        map(sensors => LocationActions.GetSensorsSuccess({ sensors }))
      );
    })
  )
);

  private computeLocationLookup(res: LocationModel.LocationItem[]) {
    const locationLookup = {};
    const edges = res
      .map(e => {
        locationLookup[e._id] = e;
        return !!e.edges ? Object.values(e.edges!) : [];
      })
      .flat();
    const cameras = !!edges?.length ? edges.map(e => (!!e.cameras ? Object.values(e.cameras!) : []))
      .flat() : [];
    return {
      locations: res,
      edges,
      cameras,
      locationLookup,
    };
  }


  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private locationService: LocationsService,
    private authenticationService: AuthenticationService,
    private edgeService: EdgeService,
    private localStorageService: LocalStorageService,
    private sensorsService: SensorsService,
  ) {
  }
}
