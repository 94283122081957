<div class="ui-create-page edge-edit flex-col flex-fill">
  <div class="h-100 w-100">
    <ui-header-with-breadcrumbs>
      <div class="flex-row gap-8">
        <span class="ui-icon-delete ui-icon-button" (click)="delete()"></span>
        <span class="ui-icon-edit ui-icon-button" (click)="edit()"></span>
      </div>
    </ui-header-with-breadcrumbs>
    <div class="h-100 w-100 flex-row">
      <ui-submenu class="h-100" *ngIf="isHalo">
        <div class="flex-col gap-4 h-100">
        @for(type of supportTypes; track type) {
          <div class="flex-row space-between-center support-type-item"
               (click)="setSensorType(type?.key)"
               [class.selected]="sensorType === type.key">
            <div>{{type.name}}</div>
            <div>{{type.value}} {{haloEventTypeUnit[type.key]}}</div>
          </div>
        }
        </div>
      </ui-submenu>
      <div class="flex-col p-24 gap-24 w-100">
        <div class="flex-row space-between-center top-bar">
          <div class="flex-row gap-16">
            <label><b>{{lastValue | number : '1.0-2'}} {{haloEventTypeUnit[sensorType]}}</b></label>
            <div>Since {{lastTime | date: 'hh:mm dd/MM'}}</div>
          </div>
          <div class="flex-row start-center gap-16">
            <div>
              <span matTooltip="Online" *ngIf="connected" class="ui-icon-connected"></span>
              <span matTooltip="Offline" *ngIf="!connected" class="ui-icon-disconnected"></span>
            </div>
            <div class="flex-row gap-8">
              <ui-calendar
                *ngIf="dateDefault"
                [timezone]="timezone"
                [inputStyle]="inputStyles.noLabel"
                [selectDateRange]="dateDefault"
                (onRangeSelected)="onRangeChange($event)"
                [displayFromTo]="false"
                [showYear]="true"
                dateFormat="MMM dd, h:mm a"
              ></ui-calendar>
              <span
                (click)="refresh()"
                [class.disable]="dateDefault.type === UiCalendarPickerType.RELATIVE" matTooltip="Refresh data" class="ui-icon-forward ui-icon-button"></span>
            </div>
          </div>
        </div>
        <div class="flex-row gap-24">
          <div class="flex-col chart-container p-r" [class.flex-50]="!!camera">
            <ui-preloader *ngIf="chartLoader" [color]="PreloaderColor.blue"></ui-preloader>
            <div class="label mb-24">{{supportTypeName}}</div>
            <canvas #chart></canvas>
            <div *ngIf="!chartLoader && !sensorData?.length" class="no-data flex-col center-center h-100 w-100">
              <div>No data</div>
            </div>
          </div>
          <div *ngIf="sensor?.cameras?.length" class="flex-row flex">
            <app-wall-camera  class="flex-row w-100 h-100"
                             [autostart]="true"
                             [camera]="camera"
                             [cameraName]="getCameraName(camera?.cameraId) | async"
                             [floorCamera]="true"
                             [showPlaceholder]="false"
            ></app-wall-camera>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
