import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { api } from '@consts/url.const';
import { BehaviorSubject, catchError, concatMap, filter, map, Observable, throwError } from 'rxjs';
import { CameraAsset } from '@models/camera.model';
import { SearchDevicesCameraDiscoveryToken } from '../core/sessions/search-devices-camera-discovery-session';
import { TokenDataStatus } from '../core/messaging.interfaces';
import { EdgeService } from '../edge/edge.service';
import { Camera, CameraDeviceInfoInterface, ScanType, SearchDevicesByIpRangeData } from '../cameras/camera.model';
import { SQSMsgInfo } from '../core/interfaces';
import { CamerasCreateType } from '@enums/cameras.enum';
import { Store } from '@ngrx/store';
import { CamerasActions } from '@states/cameras/cameras.action-types';
import { CameraActions } from '@states/camera/camera.action-types';
import { SetScanToken } from '@states/camera/camera.actions';

@Injectable()
export class CameraService {
  //todo hot fix, remove once not needs
  private selectedEdgeId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private selectedLocationId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private edgeService: EdgeService,
    private store$: Store,
    private http: HttpClient) {
  }

  onCameraDiscoverySuccess(
    session: SearchDevicesCameraDiscoveryToken.AllSessionData,
  ) {
    const payload = session.result?.discoveredCameras!;
    const raw = JSON.parse(payload);

    const discoveredCameras = raw.map((d: CameraDeviceInfoInterface) => {
      return {
        name: decodeURI(d.name),
        cameraId: decodeURI(d.macAddress),
        ipAddress: d.ipAddress,
        status: d.status,
        mac: d.macAddress,
        cameraBrand: d.cameraBrand,
        onvifData: {
          urn: d.urn,
          name: d.name,
          hardware: d.hardware,
          location: d.location,
          types: d.types,
          xaddrs: d.xaddrs,
          scopes: d.scopes,
        },
      } as Camera;
    });
    return discoveredCameras;
  }

  onCameraDiscoveryError(message = 'unknown error occured') {
  }

  discoverCameras(edgeId: string, scanType: ScanType, selectedCameraCreateType: CamerasCreateType, scanData?: SearchDevicesByIpRangeData) {
    if (scanType === ScanType.Network) {
      if (!scanData) {
        scanData = {
          ipStart: '',
          ipEnd: '',
          port: 554,
        };
      }
      if (selectedCameraCreateType === CamerasCreateType.onvif) {
        return this.edgeService.discoverCameras(edgeId);
      } else {
        return this.edgeService.scanCameras(edgeId, scanData);
      }
    } else {
      return this.edgeService.discoverCameras(edgeId);
    }
  }

  public get(edgeId: string, cameraId: string): Observable<{ totalItems: number; items: CameraAsset[] }> {
    return this.http.get<{ totalItems: number; items: CameraAsset[] }>(api.editCamera.get(edgeId, cameraId));
  }

  public getErrorReport(cameraId: string) {
    return this.http.get(api.cameras.getErrorReport(cameraId));
  }

  public delete(cameraAsset: CameraAsset): Observable<any> {
    return this.http.post<any>(api.editCamera.one(cameraAsset._id), cameraAsset);
  }

  public setSelectedEdgeId(edgeId: string) {
    this.selectedEdgeId$.next(edgeId);
  }

  public getSelectedEdgeId(): Observable<string> {
    return this.selectedEdgeId$.asObservable();
  }

  public setSelectedLocationId(edgeId: string) {
    this.selectedLocationId$.next(edgeId);
  }

  public getSelectedLocationId(): Observable<string> {
    return this.selectedLocationId$.asObservable();
  }

}
