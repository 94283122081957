import { Injectable } from '@angular/core';
import { api } from '@consts/url.const';
import { Observable } from 'rxjs';
import * as uuid from 'uuid';
import { AuthenticationService } from '../authentication/authentication.service';
import { WorkerModel } from '@models/worker.model';
import { AlarmModel } from '@models/alarm.model';
import { Dictionary } from '@ngrx/entity/src/models';

@Injectable({
  providedIn: 'root',
})
export class AlarmEdgeSyncStatusWorkerService {
  result: any | null = null;
  error: string | null = null;

  private worker: Worker | undefined;

  constructor(private authenticationService: AuthenticationService) {
  }

  fetchData(alarmId: string): Observable<Dictionary<AlarmModel.AlarmEdgeStatus>> {

    if (!this.worker) {
      this.worker = new Worker(new URL('../../workers/alarm-edge-status.worker', import.meta.url));
    }

    return new Observable((subscriber) => {
      // Generate a unique ID for this request
      // (For a real app, you might use a UUID library or a better unique generator)
      const requestId = uuid.v4();

      const handleMessage = (evt: MessageEvent) => {
        const msg = evt.data;

        // Make sure we only handle the response corresponding to *this* requestId
        if (
          msg?.type === WorkerModel.ALARM_MESSAGE_STATUS.getAlarmEdgeStatusSuccess &&
          msg.requestId === requestId
        ) {
          subscriber.next(msg.payload);
          // console.log('Worker Message Received:', msg);

          subscriber.complete();
        } else if (
          msg?.type === WorkerModel.ALARM_MESSAGE_STATUS.getAlarmEdgeStatusFail &&
          msg.requestId === requestId
        ) {
          // console.log('Worker Message Received:', msg);

          subscriber.error(msg.error);
        }
      };

      this.worker.addEventListener('message', handleMessage);

      const token = this.authenticationService.getIdTokenFromLocalStorage();

      // Send the message to the worker *with* the requestId
      this.worker.postMessage({
        type: WorkerModel.ALARM_MESSAGE_STATUS.getAlarmEdgeStatus,
        requestId,     // Include requestId in the outgoing message
        url: api.alarm.alarmEdgeSyncStatus(alarmId),
        token,
      });

      // Cleanup when unsubscribed
      return () => {
        this.worker?.removeEventListener('message', handleMessage);
      };
    });

  }


  ngOnDestroy() {
    if (this.worker) {
      this.worker.terminate(); // Terminate the worker when the component is destroyed
    }
  }
}
