import { createReducer, on } from '@ngrx/store';
import { SyncQueueActions } from './sync-queue.action-types';
import { Edge } from '../../../edge/edge.model';

export interface SyncQueueState {
  committedOperations: Edge.EdgeSyncQueueDocument[];
  uncommittedOperations: Edge.EdgeSyncQueueDocument[];
  isLoading: boolean;
  edgesSyncStatus: { [edgeId: string]: boolean };
}

export const initialState: SyncQueueState = {
  committedOperations: [],
  uncommittedOperations: [],
  isLoading: true,
  edgesSyncStatus: {},
};

export const syncQueueReducer = createReducer(
  initialState,
  // Add your existing reducers
  on(SyncQueueActions.resetToInitialState, state => ({
    ...initialState,
  })),
  on(SyncQueueActions.setSyncQueueLoading, (state, { isLoading }) => ({
    ...state,
    isLoading,
  })),
  on(SyncQueueActions.getCommittedOperationsSuccess, (state, { committedOperations }) => ({
    ...state,
    committedOperations,
  })),
  on(SyncQueueActions.getUncommittedOperationsSuccess, (state, { uncommittedOperations }) => ({
    ...state,
    uncommittedOperations,
  })),
  on(SyncQueueActions.getEdgesSyncStatusSuccess, (state, { edgesSyncStatus }) => ({
    ...state,
    edgesSyncStatus: edgesSyncStatus.reduce((acc, curr) => ({
      ...acc,
      [curr.edgeId]: curr.isSync
    }), {})
  }))
);
