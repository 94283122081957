import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { Observable } from 'rxjs';
import { WorkerModel } from '@models/worker.model';
import { api } from '@consts/url.const';
import * as uuid from 'uuid';
import { UiEventSyncResponse } from '@models/alerts-v2.model';
import { Dictionary } from '@ngrx/entity/src/models';

@Injectable({
  providedIn: 'root',
})
export class EventSyncStatusWorkerService {
  private worker: Worker | undefined;

  constructor(private authenticationService: AuthenticationService) {
  }

  fetchData(eventId: string): Observable<Dictionary<UiEventSyncResponse>> {

    if (!this.worker) {
      this.worker = new Worker(new URL('../../workers/get-event-sync-status.worker', import.meta.url));
    }

    return new Observable((subscriber) => {
      // Generate a unique ID for this request
      // (For a real app, you might use a UUID library or a better unique generator)
      const requestId = uuid.v4();

      const handleMessage = (evt: MessageEvent) => {
        const msg = evt.data;

        // Make sure we only handle the response corresponding to *this* requestId
        if (
          msg?.type === WorkerModel.EVENT_SYNC_MESSAGE_STATUS.getEventSyncStatusSuccess &&
          msg.requestId === requestId
        ) {
          subscriber.next(msg.payload);
          // console.log('Worker Message Received:', msg);

          subscriber.complete();
        } else if (
          msg?.type === WorkerModel.EVENT_SYNC_MESSAGE_STATUS.getEventSyncStatusFail &&
          msg.requestId === requestId
        ) {
          // console.log('Worker Message Received:', msg);

          subscriber.error(msg.error);
        }
      };

      this.worker.addEventListener('message', handleMessage);

      const token = this.authenticationService.getIdTokenFromLocalStorage();

      // Send the message to the worker *with* the requestId
      this.worker.postMessage({
        type: WorkerModel.EVENT_SYNC_MESSAGE_STATUS.getEventSyncStatus,
        requestId,     // Include requestId in the outgoing message
        url: api.alertEvents.getSyncStatus(eventId),
        token,
      });

      // Cleanup when unsubscribed
      return () => {
        this.worker?.removeEventListener('message', handleMessage);
      };
    });

  }


  ngOnDestroy() {
    if (this.worker) {
      this.worker.terminate(); // Terminate the worker when the component is destroyed
    }
  }
}
