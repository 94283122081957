import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { catchError, exhaustMap, filter, map, mergeMap, of, share, switchMap, tap, throwError, withLatestFrom } from 'rxjs';
import * as SharedActions from '@states/shared/shared.actions';
import { SharedService } from '../../development/shared.service';
import { MsgBoxType } from '../../shared/msg-box/msg-box.model';
import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';
import { ConfirmDialogSelection, ConfirmDialogType } from '../../shared/confirm-dialog/confirm-dialog.model';
import { EdgeService } from '../../edge/edge.service';
import { TokenDataStatus } from '../../core/messaging.interfaces';
import { SessionDataSuccessResponse } from '@models/shared.model';
import { UtilsService } from '../../edge/utils.service';
import { AuthenticationService } from '../../authentication/authentication.service';
import { AuthenticationSelectors } from '@states/authentication/authentication.selector-types';
import { UserSelectors } from '@states/user/user.selector-types';
import { UserActions } from '@states/user/user.action-types';
import { ChildActivationEnd, NavigationEnd, NavigationSkipped, Router } from '@angular/router';
import { BreadcrumbsService } from '../../services/breadcrumbs.service';
import { RouteActions } from '@states/route/route.action-types';
import { ConfirmModalService } from '../../services/confirm-dialogs/confirm-modal.service';
import { ConfirmModalModel } from '../../services/confirm-dialogs/confirm-modal.model';
import { SocketModels } from '../../socket/socket.model';
import { RouterCancelAction } from '@ngrx/router-store';

@Injectable()
export class SharedEffects {

  public startLoadRequiredData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.startLoadRequiredData),
      withLatestFrom(
        this.store$.pipe(select(AuthenticationSelectors.getUserProfileDto)),
        this.store$.pipe(select(UserSelectors.isProfileLoaded)),
      ),
      switchMap(([, auth, isProfileLoaded]) => {
        const isLoggedIn = this.authenticationService.isLoggedIn();
        if (isLoggedIn && !isProfileLoaded) {
          return [
            UserActions.CreateOrGetUserProfile({
              userId: auth.authProviderId!,
              accessToken: auth.accessToken!,
            }),
          ];
        } else {
          return [SharedActions.setApplicationLoaded()];
        }
      }),
      share(),
    ),
  );


  public pressSave$ = createEffect(() => this.actions$.pipe(ofType(SharedActions.pressSave), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public pressEdit$ = createEffect(() => this.actions$.pipe(ofType(SharedActions.pressEdit), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public pressDelete$ = createEffect(() => this.actions$.pipe(ofType(SharedActions.pressDelete), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });


  public detectChanges$ = createEffect(() => this.actions$.pipe(ofType(SharedActions.detectChanges), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public showMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.showMessage),
      exhaustMap(({ error, warning, success, info }) => {
        if (error) {
          this.sharedService.showCustomAlert(error, MsgBoxType.ERROR);
        }
        if (warning) {
          this.sharedService.showCustomAlert(warning, MsgBoxType.WARNING);
        }
        if (success) {
          this.sharedService.showCustomAlert(success, MsgBoxType.SUCCESS);
        }
        if (info) {
          this.sharedService.showCustomAlert(info, MsgBoxType.INFO);
        }
        return of(SharedActions.doNothing());
      }),
    ),
  );

  public confirmation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.showConfirmModal),
      switchMap(({ options }) => {
        return this.confirm.open(options)
          .pipe(
            map(result => {
              if (result.selection == ConfirmDialogSelection.OK && result.type === ConfirmDialogType.CONFIRM) {
                return SharedActions.showConfirmModalResultConfirm({
                  params: options.params,
                });
              } else {
                return SharedActions.showConfirmModalResultCancel({
                  params: options.params,
                });
              }
            }),
            catchError(err => throwError(() => err)),
          );
      }),
      share(),
    ),
  );

  public startInactivityCountdown$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SharedActions.startInactivityCountdown),
      switchMap(_ => {
        this.utilsService.startInactivityCountdown();
        return of(SharedActions.doNothing());
      }),
      share(),
    );
  });

  public stoptInactivityCountdown$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SharedActions.stopInactivityCountdown),
      switchMap(_ => {
        this.utilsService.stopInactivityCountdown();
        return of(SharedActions.doNothing());
      }),
      share(),
    );
  });

  public consoleMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.consoleMessage),
      exhaustMap(({ error, warning, success }) => {
        // if (error) {
        //   console.error(error, MsgBoxType.ERROR);
        // }
        // if (warning) {
        //   console.warn(warning, MsgBoxType.WARNING);
        // }
        // if (success) {
        //   console.info(success, MsgBoxType.SUCCESS);
        // }
        return of(SharedActions.doNothing());
      }),
    ),
  );


  routerEvents$ = createEffect(() =>
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd || event instanceof NavigationSkipped),
      map((e) => {
        const root = this.router.routerState.snapshot.root;
        const breadcrumbs = this.breadcrumbService.buildBreadcrumbs(root);
        return RouteActions.setBreadcrumbs({ breadcrumbs });
      }),
    ),
  );


  public showConfirmNameModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.showConfirmNameModal),
      switchMap(({ options }) => {
        return this.confirmDialogService.open(options)
          .pipe(
            map(result => {
              if (result === ConfirmModalModel.ConfirmResult.OK) {
                return SharedActions.showConfirmNameModalResultConfirm({
                  params: options.params,
                });
              } else {
                return SharedActions.showConfirmNameModalResultCancel({
                  params: options.params,
                });
              }
            }),
            catchError(err => throwError(() => err)),
          );
      }),
      share(),
    ),
  );

  public showMessageFromSocket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.showMessageFromSocket),
      exhaustMap(({ level, msg }) => {
        switch (level) {
          case SocketModels.MessageLevel.WARN:
            this.sharedService.showCustomAlert(msg, MsgBoxType.WARNING);
            break;
          case SocketModels.MessageLevel.SUCCESS:
            this.sharedService.showCustomAlert(msg, MsgBoxType.SUCCESS);
            break;
          case SocketModels.MessageLevel.ERROR:
            this.sharedService.showCustomAlert(msg, MsgBoxType.ERROR);
            break;
        }
        return of(SharedActions.doNothing());
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private sharedService: SharedService,
    private utilsService: UtilsService,
    private confirm: ConfirmDialogService,
    private edgeService: EdgeService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private breadcrumbService: BreadcrumbsService,
    private confirmDialogService: ConfirmModalService,
  ) {
  }
}
